import { useQuery } from 'react-query';
import { useCurrentUser, useToastProvider } from '@qualio/ui-components';
import registryApi from '../api/registry.api';
import { RegistryCategoryMatrixID, RegistryItemsResponseType } from '../types/registry';
import { useFlags } from '../external/LaunchDarklyAdapter';
import { DefaultErrorMessage } from '../displayStrings';

const transformData = (data: RegistryItemsResponseType, eventItemId?: string | number) => {
  return (
    data?.items
      .filter((item) => item.status === 'effective' || eventItemId === item.registry_item_matrix_id)
      .map((item) => ({
        id: item.registry_item_matrix_id,
        name: item.name,
        description: item.description ?? '',
        companyId: item.company_id.toString(),
        status: item.status,
      })) || []
  );
};
export const useRegistryItems = (categoryId: string, eventItemId?: string | number) => {
  const { companyId } = useCurrentUser();
  const isRegistryProductRootCauseEnabled = useFlags('registryProductRootCause');
  const { showToast } = useToastProvider();

  return useQuery({
    queryKey: ['registry-items', companyId, categoryId],
    queryFn: () => registryApi.getRegistryItemsByCategory(companyId, categoryId),
    enabled: !!companyId && !!categoryId && isRegistryProductRootCauseEnabled,
    select: (data: RegistryItemsResponseType) => transformData(data, eventItemId),
    onError: (e) => {
      showToast({
        title: `Could not retrieve ${
          categoryId === RegistryCategoryMatrixID.PRODUCT_MATRIX_ID ? 'products' : 'root causes'
        }`,
        description: DefaultErrorMessage,
        status: 'error',
      });
    },
  });
};
