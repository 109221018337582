import React from 'react';
import RootCauseModal from './components/RootCauseModal';
import { RootCause } from '../../types';

type RootCauseCreateModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSave: (payload: RootCause) => Promise<void>;
};

const RootCauseCreateModal = (props: RootCauseCreateModalProps) => {
  const handleStatusMessage = (status: 'success' | 'error', name: string, errorMessage?: string) => {
    const errorDesc = errorMessage ? `Reason: ${errorMessage}` : 'Try again.';

    const messages = {
      success: { title: 'Root cause created', description: `"${name}" was created.` },
      error: {
        title: 'Root cause not created',
        description: `Root cause was not created because of an error. ${errorDesc}`,
      },
    };
    return { ...messages[status], status };
  };

  return (
    <>
      <RootCauseModal
        {...props}
        onStatusMessage={handleStatusMessage}
        title="Add root cause"
        buttonName="Add root cause"
      />
    </>
  );
};

export default RootCauseCreateModal;
