import * as z from 'zod';

export const ApprovalStatusSchema = z.enum(['approved', 'declined', 'pending']);
export const ApproverSchema = z.object({
  id: z.number(),
  usercompany_id: z.number(),
  action_status: ApprovalStatusSchema,
  assigned_at_timestamp: z.number(),
  action_timestamp: z.number().nullable(),
  approval_comment: z.string().nullable(),
  user_type: z.string(),
  approver_removed_by: z.number().nullable(),
  created_by: z.number(),
  approval_item_id: z.number(),
  approver_removal_reason: z.string().nullable(),
  created_comment: z.string().nullable(),
  approver_removal_timestamp: z.number().nullable(),
  full_name: z.string(),
  created_by_name: z.string(),
  removed_by_name: z.number().nullable(),
});

export const ApproverTypeSchema = z.enum(['approvers', 'reviewers']);

export const UpdateApproversPayloadSchema = z.object({
  approversToAdd: z.array(z.number()),
  approversToRemove: z.array(z.number()),
  userType: ApproverTypeSchema,
  removeComment: z.string().optional(),
});

export type ApprovalStatus = z.infer<typeof ApprovalStatusSchema>;
export type Approver = z.infer<typeof ApproverSchema>;
export type ApproverType = z.infer<typeof ApproverTypeSchema>;
export type UpdateApproversPayload = z.infer<typeof UpdateApproversPayloadSchema>;
