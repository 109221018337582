import * as z from 'zod';
import { gtTenYearsDaysErrorMessage, ltZeroDaysErrorMessage, tenYearsDays } from './common/constants';

export const TaskStatus = z.enum(['open', 'closed_success', 'closed_fail']);
export type TaskStatus = z.infer<typeof TaskStatus>;

export const Task = z.object({
  id: z.string(),
  code: z.string(),
  title: z.string(),
  owner_full_name: z.string(),
  owner_email: z.string(),
  owner_id: z.number(),
  created_at: z.string(),
  closed_at: z.string().nullable(),
  updated_at: z.string(),
  due_at: z.string(),
  description: z.string().optional(),
  issue_code: z.string(),
  issue_id: z.number(),
  time_limit: z.preprocess((val) => Number(val), z.number()),
  creator_id: z.number(),
  creator_email: z.string(),
  creator_full_name: z.string(),
  issue_step_id: z.number(),
  status: z.string(),
});

export type Task = z.infer<typeof Task>;

export const TasksRequest = z.object({
  event_type: z.string().optional(),
  created_at_start_date: z.string().optional(),
  created_at_end_date: z.string().optional(),
  order_by: z.string().optional(),
  is_overdue: z.string().optional(),
  product_id: z.string().optional(),
  rootcause_id: z.string().optional(),
  status: z.array(TaskStatus).optional(),
  owner_id: z.string().optional(),
  page_number: z.number().optional(),
  page_size: z.number().optional(),
});

export type TasksRequest = z.infer<typeof TasksRequest>;

export const TaskUpdateResponse = z.object({
  id: z.string(),
  title: z.string(),
  owner_full_name: z.string(),
  owner_id: z.number(),
  created_at: z.string(),
  closed_at: z.string().nullable(),
  updated_at: z.string(),
  due_at: z.string(),
  description: z.string().optional(),
  time_limit: z.coerce.number(),
  creator_id: z.number(),
  creator_full_name: z.string(),
  issue_step_id: z.number(),
  status: z.string(),
  type: z.string(),
});

export type TaskUpdateResponse = z.infer<typeof TaskUpdateResponse>;

export const TaskListResponse = z.object({
  data: z.array(Task),
  meta: z.object({
    page: z.object({
      page_size: z.number(),
      total_items: z.number(),
      total_pages: z.number(),
    }),
  }),
});

export type TaskListResponse = z.infer<typeof TaskListResponse>;

export const TaskCreate = Task.pick({ title: true, description: true, time_limit: true }).extend({
  owner_id: z.string(),
  time_limit: z.preprocess(
    (val) => Number(val),
    z.number().max(tenYearsDays, gtTenYearsDaysErrorMessage).min(0, ltZeroDaysErrorMessage),
  ),
});
export type TaskCreate = z.infer<typeof TaskCreate>;

export const TaskCreateRequest = TaskCreate.extend({
  step_id: z.number(),
});
export type TaskCreateRequest = z.infer<typeof TaskCreateRequest>;

export const TaskCompleteRequest = z.object({
  comment: z.string(),
  resolution: z.enum(['closed_success', 'closed_fail']),
  email: z.string().email(),
  password: z.string().min(1),
});
export type TaskCompleteRequest = z.infer<typeof TaskCompleteRequest>;
