import React from 'react';
import { QBox, QCenter } from '@qualio/ui-components';

type FieldContainerProps = {
  children?: React.ReactNode;
};

const FieldContainer = ({ children }: FieldContainerProps) => {
  return (
    <QCenter>
      <QBox
        borderColor="gray.200"
        borderWidth="1px"
        borderRadius="base"
        p={6}
        w="container.md"
        mt={3}
        mb={3}
        backgroundColor="white"
      >
        {children}
      </QBox>
    </QCenter>
  );
};

export default FieldContainer;
