export const eventsQuicksearch = {
  results: [
    {
      qri: 'https://qri.app.staging.beta.qualio.com/cXVhbGl0eS1ldmVudHMvcXVhbGl0eS1ldmVudC8xLzk1NTYw',
      title: 'Quicksearch Event',
      code: 'AAAA-2',
      companyId: 1,
      owner: 'Alice Mills',
      domain: 'quality-events',
      domainType: 'quality-event',
      id: 95560,
    },
    {
      qri: 'https://qri.app.staging.beta.qualio.com/cXVhbGl0eS1ldmVudHMvcXVhbGl0eS1ldmVudC8xLzk4NzEx',
      title: 'IOU',
      code: 'AAAA-3',
      companyId: 1,
      owner: 'Daniel Tosh',
      domain: 'quality-events',
      domainType: 'quality-event',
      id: 98711,
    },
    {
      qri: 'https://qri.app.staging.beta.qualio.com/cXVhbGl0eS1ldmVudHMvcXVhbGl0eS1ldmVudC8xLzkzODY3',
      title: 'Testing events',
      code: 'AAAA-1',
      companyId: 1,
      owner: 'Quality Staff Two',
      domain: 'quality-events',
      domainType: 'quality-event',
      id: 93867,
    },
    {
      qri: 'https://qri.app.staging.beta.qualio.com/cXVhbGl0eS1ldmVudHMvcXVhbGl0eS1ldmVudC8xLzE0NTQ3OQ',
      title: 'ert',
      code: 'aaa-9',
      companyId: 1,
      owner: 'José Bermúdez',
      domain: 'quality-events',
      domainType: 'quality-event',
      id: 145479,
    },
    {
      qri: 'https://qri.app.staging.beta.qualio.com/cXVhbGl0eS1ldmVudHMvcXVhbGl0eS1ldmVudC8xLzE0Mzc3OQ',
      title: 'AAA Assign',
      code: 'aaa-8',
      companyId: 1,
      owner: 'Alice Mills',
      domain: 'quality-events',
      domainType: 'quality-event',
      id: 143779,
    },
    {
      qri: 'https://qri.app.staging.beta.qualio.com/cXVhbGl0eS1ldmVudHMvcXVhbGl0eS1ldmVudC8xLzEzNDIxOA',
      title: 'a345',
      code: 'aaa-5',
      companyId: 1,
      owner: 'Alice Mills',
      domain: 'quality-events',
      domainType: 'quality-event',
      id: 134218,
    },
    {
      qri: 'https://qri.app.staging.beta.qualio.com/cXVhbGl0eS1ldmVudHMvcXVhbGl0eS1ldmVudC8xLzEzODIxMQ',
      title: 'NAAA1',
      code: 'aaa-6',
      companyId: 1,
      owner: 'Alice Mills',
      domain: 'quality-events',
      domainType: 'quality-event',
      id: 138211,
    },
    {
      qri: 'https://qri.app.staging.beta.qualio.com/cXVhbGl0eS1ldmVudHMvcXVhbGl0eS1ldmVudC8xLzE0MDE3NA',
      title: 'AC23',
      code: 'aaa-7',
      companyId: 1,
      owner: 'Alice Mills',
      domain: 'quality-events',
      domainType: 'quality-event',
      id: 140174,
    },
    {
      qri: 'https://qri.app.staging.beta.qualio.com/cXVhbGl0eS1ldmVudHMvcXVhbGl0eS1ldmVudC8xLzExNjg3OQ',
      title: 'asdfsadfdsa',
      code: 'aaa-3',
      companyId: 1,
      owner: 'Daniel Tosh',
      domain: 'quality-events',
      domainType: 'quality-event',
      id: 116879,
    },
    {
      qri: 'https://qri.app.staging.beta.qualio.com/cXVhbGl0eS1ldmVudHMvcXVhbGl0eS1ldmVudC8xLzExODQxNg',
      title: 'test',
      code: 'aaa-4',
      companyId: 1,
      owner: 'Daniel Tosh',
      domain: 'quality-events',
      domainType: 'quality-event',
      id: 118416,
    },
  ],
  totalRelation: 'eq',
  total: 31,
  page: 1,
  size: 10,
};
