import React from 'react';
import { QBox, QTag, QStack, QText, QIcon, QTagProps } from '@qualio/ui-components';
import { AuditGroup } from '../../../types';

type ReviewerListProps = {
  assignees: AuditGroup[];
};

const ReviewerList: React.FC<ReviewerListProps> = ({ assignees }) => {
  const getAssigneeIndicator = (
    assignee: AuditGroup,
  ): { variantColor: QTagProps['variantColor']; icon: React.ReactNode } => {
    if (assignee.action_done) {
      return { variantColor: 'gray', icon: <QIcon iconName="CheckCircleFilled" color="green.500" /> };
    }
    return { variantColor: 'gray', icon: <QIcon iconName="HelpCircle" /> };
  };

  return (
    <QStack spacing={3}>
      <QBox>
        <QText fontWeight="semibold" fontSize="xs" color="gray.800">
          Reviewers
        </QText>
      </QBox>
      {assignees.map((assignee) => {
        const { variantColor, icon } = getAssigneeIndicator(assignee);

        return (
          <QTag variantColor={variantColor} key={assignee.id}>
            {icon}
            <QText fontSize="xs" color="gray.800" fontWeight="normal" ml={1}>
              {assignee.full_name}
            </QText>
          </QTag>
        );
      })}
    </QStack>
  );
};

export default ReviewerList;
