import { useQuery } from 'react-query';
import { useToastProvider } from '@qualio/ui-components';
import { ContentTemplate } from '../types';
import contentTemplateApi from '../api/contentTemplate.api';
import { DefaultErrorMessage } from '../displayStrings';

type UseContentTemplateDetailsType = {
  data: ContentTemplate | undefined;
  isLoading: boolean;
};

export const useContentTemplateDetails = (
  contentTemplateId: number,
  onSuccess?: (data: ContentTemplate | undefined) => void,
  enabled = true,
): UseContentTemplateDetailsType => {
  const { showToast } = useToastProvider();

  const { data, isLoading } = useQuery(
    ['contentTemplateDetails', contentTemplateId],
    () => contentTemplateApi.getContentTemplateDetails(contentTemplateId),
    {
      enabled: enabled,
      onError: () => {
        showToast({
          description: DefaultErrorMessage,
          status: 'error',
        });
      },
      onSuccess: (data: ContentTemplate) => {
        if (onSuccess) {
          onSuccess(data);
        }
      },
    },
  );

  return { data, isLoading };
};
