import React from 'react';
import { RootCause } from '../../types';
import RootCauseModal from './components/RootCauseModal';

type RootCauseUpdateModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSave: (payload: RootCause) => Promise<void>;
  rootCause: RootCause;
};

const RootCauseUpdateModal = ({ onSave, rootCause, ...props }: RootCauseUpdateModalProps) => {
  const handleOnSave = async (payload: RootCause) => {
    return onSave(payload);
  };

  const handleStatusMessage = (status: 'success' | 'error', name: string, errorMessage?: string) => {
    const errorDesc = errorMessage ? `Reason: ${errorMessage}` : 'Try again.';

    const messages = {
      success: { title: 'Root cause updated', description: `"${name}" was updated.` },
      error: {
        title: 'Root cause not updated',
        description: `Root cause was not updated because of an error. ${errorDesc}`,
      },
    };
    return { ...messages[status], status };
  };

  return (
    <RootCauseModal
      {...props}
      onSave={handleOnSave}
      onStatusMessage={handleStatusMessage}
      title="Edit root cause"
      buttonName="Save"
      defaultValues={rootCause}
    />
  );
};

export default RootCauseUpdateModal;
