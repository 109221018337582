import * as z from 'zod';
import { BaseSchema, ServerOnlyOmitKeys } from './common';
import { FieldTypeSchema, MandatorySchema, MultiSchema } from '../common';
import { NumericIdSchema } from '../../id';
import * as DisplayStrings from '../../../displayStrings';

/**
 * All types in this file pertain to the template form fields (not the fields on
 * an instance of a form) that are "option select" input fields, meaning they all
 * are inputs where the user "pre defines" a list of options, the inputs display
 * those choices in different ways
 */

// A single Form option object as returned by the server
const FormOptionSchema = z.object({
  id: NumericIdSchema,
  form_option_list_id: NumericIdSchema,
  order: z.number(),
  value: z
    .string()
    .max(512, DisplayStrings.OptionValueTooLarge)
    .superRefine((val, ctx) => {
      if (val.trim().length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: DisplayStrings.OptionValueRequired,
        });
      }
    }),
});

// Ensure that dropdown options are unique, used by superRefine
const ensureOptionsAreUnique = (options: { value: string }[], ctx: z.RefinementCtx) => {
  const optionValues = options.map((option) => option.value);

  if (new Set(optionValues).size === optionValues.length) {
    return;
  }

  optionValues.forEach((optionValue, optionIndex, fullArray) => {
    const firstOccurrence = fullArray.indexOf(optionValue);

    if (firstOccurrence !== optionIndex) {
      // Marks the first occurrence of the duplicate option value as an issue
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: DisplayStrings.OptionValueUnique,
        path: [firstOccurrence, 'value'],
      });

      // Marks the current occurrence of the duplicate option value as an issue
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: DisplayStrings.OptionValueUnique,
        path: [optionIndex, 'value'],
      });
    }
  });
};

// Form Option List object as returned by server
const FormOptionListSchema = z.object({
  id: NumericIdSchema,
  name: z.string(),
  company_id: NumericIdSchema,
  archived: z.boolean(),
  options: z.array(FormOptionSchema).superRefine(ensureOptionsAreUnique),
});

// Extension object for all schemas when they are "new" and not yet saved
const SchemaExtension = {
  form_option_list: z.object({
    options: z
      .array(
        FormOptionSchema.omit({
          id: true,
          form_option_list_id: true,
        }),
      )
      .superRefine(ensureOptionsAreUnique),
  }),
} as const;

// Dropdown Field Schema
export const DropdownInputSchema = BaseSchema.extend({
  type: FieldTypeSchema.extract(['dropdown']),
  form_option_list: FormOptionListSchema,
  multi: MultiSchema,
  mandatory: MandatorySchema,
});
export const NewDropdownInputSchema = DropdownInputSchema.omit(ServerOnlyOmitKeys).extend(SchemaExtension);

// Checkbox Field Schema, checkboxes are always multi select
export const CheckboxInputSchema = BaseSchema.extend({
  type: FieldTypeSchema.extract(['checkbox']),
  form_option_list: FormOptionListSchema,
  multi: z.literal(true),
  mandatory: z.boolean(),
});
export const NewCheckboxInputSchema = CheckboxInputSchema.omit(ServerOnlyOmitKeys).extend(SchemaExtension);

// Radio Field Schema, radio buttons are always single select
export const RadioInputSchema = BaseSchema.extend({
  type: FieldTypeSchema.extract(['radio']),
  form_option_list: FormOptionListSchema,
  multi: z.literal(false),
  mandatory: z.boolean(),
});
export const NewRadioInputSchema = RadioInputSchema.omit(ServerOnlyOmitKeys).extend(SchemaExtension);
