import * as z from 'zod';
import { StandardFieldValueSchema } from '../../../../types/formFields/forms/common';
import { StandardFieldSchema } from '../../../../types/formFields/forms/standardFields';
import { OptionSelectFieldSchema } from '../../../../types/formFields/forms/optionSelectField';
import { SectionFieldSchema } from '../../../../types/formFields/forms/visualFields';

const BaseLocalFields = {
  id: true,
  label: true,
  type: true,
  helptext: true,
  default_content: true,
  mandatory: true,
  multi: true,
  resource_sub_type: true,
} as const;

const StandardLocalValueSchema = z.object({
  values: z.array(StandardFieldValueSchema.partial({ id: true })),
});

const LocalStandardFieldSchema = StandardFieldSchema.pick(BaseLocalFields).merge(StandardLocalValueSchema);
const LocalVisualFieldSchema = SectionFieldSchema.pick({ ...BaseLocalFields, values: true });
const LocalOptionSelectFieldSchema = OptionSelectFieldSchema.pick({ ...BaseLocalFields, options: true }).merge(
  StandardLocalValueSchema,
);

const EventFormFieldSchema = z.discriminatedUnion('type', [
  LocalStandardFieldSchema,
  LocalVisualFieldSchema,
  LocalOptionSelectFieldSchema,
]);

export const EventFormSchema = z.object({
  fields: z.array(EventFormFieldSchema),
  reviewers: z.array(z.number()),
  approvers: z.array(z.number()),
});
export type EventForm = z.infer<typeof EventFormSchema>;
export type EventFormFieldObject = z.infer<typeof EventFormFieldSchema>;
