import React from 'react';
import { DropdownSubProps } from './shared';
import { QMultiSelect } from '@qualio/ui-components';
import { createFieldValueObject } from '../../../../utils/eventFormUtils';
import { StandardValueObject } from '../../EventFormField.types';

// MultiSelect dropdown component.  Loaded by DropdownField.tsx
const DropdownFieldMultiSelect = (props: DropdownSubProps) => {
  const { onChange, fieldId, options } = props;

  // TODO:  QEV-2813
  const fieldValues = props.fieldValues as StandardValueObject[];

  return (
    <QMultiSelect
      data-cy="dropdown-field"
      options={options}
      value={fieldValues.map((val) => val.value)}
      onChange={(selectedOptions) => {
        const optionValues = selectedOptions.map((option) => option.value);
        const newValueArray = optionValues.map((val) => {
          const existingValue = fieldValues.find((fieldVal) => fieldVal.value === val);

          return createFieldValueObject(val, fieldId, existingValue);
        });

        onChange(newValueArray);
      }}
    />
  );
};

export default DropdownFieldMultiSelect;
