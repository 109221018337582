import { useContext } from 'react';
import { EventPermissionsContext, EventPermissionsContextType } from '../context';

export const useEventPermissions = (): EventPermissionsContextType => {
  const context = useContext(EventPermissionsContext);
  if (!context) {
    throw new Error('useEventPermissions can only be used inside EventPermissionsProvider');
  }
  return context;
};
