import * as z from 'zod';
import { StandardFieldSchema } from './standardFields';
import { OptionSelectFieldSchema } from './optionSelectField';
import { QLookupStandardValue, QLookupSuppliersValue } from './QLookupFields';
import { SectionFieldSchema } from './visualFields';
import { StandardFieldValue } from './common';

/**
 * All types in this file relate to form fields as they're used by the actual instances
 * of a form for an event step.
 */

// Discriminated union of all possible field types
export const FieldSchema = z.discriminatedUnion('type', [
  StandardFieldSchema,
  OptionSelectFieldSchema,
  SectionFieldSchema,
]);
export type Field = z.infer<typeof FieldSchema>;

export type CreateFieldValue =
  | Omit<StandardFieldValue, 'id'>
  | Omit<QLookupStandardValue, 'id'>
  | Omit<QLookupSuppliersValue, 'id'>;
