import React, { useEffect } from 'react';
import { QBox, QFormControl, QInput, QSelect, QStack, QTextarea } from '@qualio/ui-components';
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form';
import { TaskCreate, User, UserTask } from '../../../types';
import { useUsersOptions } from '../../../hooks/useUsersOptions';

type Props = {
  formMethods: UseFormReturn<TaskCreate>;
  onSubmit: any;
  data: {
    users: User[];
  };
  taskData?: UserTask;
  canEditTaskTimeLimit?: boolean;
};

const TaskForm: React.FC<Props> = ({ formMethods, onSubmit, data, taskData, canEditTaskTimeLimit = true }) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = formMethods;

  const usersOptions = useUsersOptions(data.users);

  useEffect(() => {
    if (!taskData) {
      return;
    }
    setValue('title', taskData.title ?? '');
    setValue('description', taskData.description || '');
    setValue('owner_id', String(taskData.owner_id) || '');
    setValue('time_limit', Number(taskData.time_limit) || 60);
  }, [taskData, setValue]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <QStack direction="column" spacing={4}>
          <QFormControl
            label="Title"
            error={errors.title && errors.title.message}
            isInvalid={!!errors.title}
            isRequired
          >
            <Controller
              control={control}
              name="title"
              render={({ field: { ref: _ref, ...field } }) => <QInput {...field} />}
            />
          </QFormControl>
          <QFormControl label="Description">
            <Controller
              control={control}
              name="description"
              render={({ field: { ref: _ref, ...field } }) => <QTextarea {...field} />}
            />
          </QFormControl>
          <QFormControl
            label="Assigned to"
            error={errors.owner_id && errors.owner_id.message}
            isInvalid={!!errors.owner_id}
            isRequired
          >
            <Controller
              control={control}
              name="owner_id"
              render={({ field: { ref: _ref, onChange, ...field } }) => (
                <QBox data-cy={'assigned-to-select'}>
                  <QSelect
                    {...field}
                    onChange={(changeObject) => {
                      if (changeObject) {
                        onChange(changeObject.value);
                      }
                    }}
                    options={usersOptions}
                    aria-label="Users list"
                  />
                </QBox>
              )}
            />
          </QFormControl>

          <QFormControl
            error={errors.time_limit && errors.time_limit.message}
            isInvalid={!!errors.time_limit}
            label="Complete before"
            helper="Period of time within which the task must be resolved."
          >
            <Controller
              control={control}
              name="time_limit"
              render={({ field: { ref: _ref, ...field } }) => (
                <QInput {...field} type="number" isDisabled={!canEditTaskTimeLimit} />
              )}
            />
          </QFormControl>
        </QStack>
      </form>
    </FormProvider>
  );
};

export default TaskForm;
