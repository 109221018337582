export class SectionErrors {
  invalidCharacters: string;
  duplicateTitle: boolean;
  titleBlank: boolean;

  constructor() {
    this.invalidCharacters = '';
    this.duplicateTitle = false;
    this.titleBlank = false;
  }

  hasErrors(): boolean {
    return this.invalidCharacters !== '' || this.duplicateTitle || this.titleBlank;
  }

  reset(): void {
    this.invalidCharacters = '';
    this.duplicateTitle = false;
    this.titleBlank = false;
  }
}
