import React from 'react';
import { StandardValueObject, SubComponentProps, ViewModeDictionary } from '../EventFormField.types';
import { QInput } from '@qualio/ui-components';
import { createFieldValueArray } from '../../../utils/eventFormUtils';
import ReadonlyWrapper from './ReadOnlyWrapper';
import TextOr from '../../TextOr/TextOr';

const NumericField = (props: SubComponentProps) => {
  const { fieldValues, onChange, fieldId, mode } = props;

  // TODO:  QEV-2813
  const valueObj = fieldValues[0] as StandardValueObject | undefined;

  if (mode === ViewModeDictionary.READONLY) {
    return (
      <ReadonlyWrapper>
        <TextOr fontSize="sm" value={valueObj?.value} />
      </ReadonlyWrapper>
    );
  }

  return (
    <QInput
      value={valueObj?.value ?? ''}
      type="number"
      data-cy={'numeric-input'}
      onChange={(event) => {
        const newVal = event.target.value;
        const newValueArray = createFieldValueArray(newVal, fieldId, valueObj);

        onChange(newValueArray);
      }}
      placeholder="Enter a number"
    />
  );
};

export default NumericField;
