import { useQuery } from 'react-query';
import { useCurrentUser, useToastProvider } from '@qualio/ui-components';
import formsApi from '../api/forms.api';
import { DefaultErrorMessage } from '../displayStrings';
import { Form } from '../types';

export const BaseQueryKey = 'formDetails';

const updateSmartlinkInRichTextFields = async (form: Form, companyId: number) => {
  for (const field of form.fields) {
    if (field.type === 'rich_text' && field.values && field.values[0]) {
      field.values[0].value = await CKEditor5.smartlink.QualioSmartlink.getUpdateSmartlinkTextInRichText(
        field.values[0].value,
        companyId,
        process.env.REACT_APP_QRI_SERVICE_URL,
        process.env.REACT_APP_MEDTECH_BACKEND_API_URL,
      );
    }
  }
};

export const useFormDetails = (eventId: number, stepId: number, formId: number | null) => {
  const { companyId } = useCurrentUser();
  const { showToast } = useToastProvider();

  return useQuery(
    [BaseQueryKey, formId],
    async () => {
      const form = await formsApi.getForm(companyId, eventId, stepId, formId as number);

      await updateSmartlinkInRichTextFields(form, companyId);

      return form;
    },
    {
      enabled: !!formId,
      onError: () => {
        showToast({
          description: DefaultErrorMessage,
          status: 'error',
        });
      },
    },
  );
};
