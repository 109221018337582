import { useCurrentUser } from '@qualio/ui-components';
import { useQuery } from 'react-query';
import registryApi from '../api/registry.api';

const BASE_QUERY_KEY = 'registryCategories';

export const useRegistryCategories = (enabled: boolean) => {
  const { companyId } = useCurrentUser();

  return useQuery([BASE_QUERY_KEY, companyId], () => registryApi.getRegistryCategories(companyId), { enabled });
};
