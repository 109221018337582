import React from 'react';
import { QBox, QCenter, QDivider, QDrawer, QSpinner } from '@qualio/ui-components';
import { useContentTemplateDetails } from '../../../hooks';
import TemplateDrawerHeader from './TemplateDrawerHeader';
import { Section, ContentTemplate, EventTemplateStepCreateEdit } from '../../../types';
import ContentViewer from './ContentViewer/ContentViewer';
import { QualioContentSection } from './CKESharedTypes';
import { PresentationUtils } from '../../shared/CKEditor/util/PresentationUtils';

type ContentTemplateDrawerProps = {
  onClose: any;
  onDelete: (step: EventTemplateStepCreateEdit) => void;
  step: EventTemplateStepCreateEdit;
  preventStepDelete: (step: EventTemplateStepCreateEdit) => boolean;
};

const ContentTemplateDrawer: React.FC<ContentTemplateDrawerProps> = ({
  onClose,
  onDelete,
  step,
  preventStepDelete,
}) => {
  const { data, isLoading } = useContentTemplateDetails(step.template_id as number);

  const sortSection = (sections: Section[]): Section[] => {
    return sections?.sort((s1, s2) => s1.position - s2.position);
  };

  const prepareSections = (sections: Section[]): QualioContentSection[] => {
    return sections.map((section) => {
      return {
        attachments: null,
        content: section.type === 'resolution' ? PresentationUtils.resolutionCheckboxContent() : section.content,
        id: section.id,
        position: section.position,
        title: section.title,
        type: section.type,
      };
    });
  };

  function prepareContent(templateData: ContentTemplate) {
    return {
      content_type: 'template',
      id: templateData.id,
      orientation: 'portrait',
      sections: prepareSections(sortSection(templateData.sections)),
    };
  }

  return (
    <QDrawer
      isOpen={true}
      onClose={onClose}
      size="xl"
      title={
        data && (
          <TemplateDrawerHeader
            isContentTemplate={true}
            template={data}
            onDelete={onDelete}
            step={step}
            preventStepDelete={preventStepDelete}
          />
        )
      }
    >
      <QDivider />
      <QBox p={1} mt="3">
        {isLoading ? (
          <QCenter pt={10}>
            <QSpinner />
          </QCenter>
        ) : (
          <ContentViewer content={prepareContent(data as ContentTemplate)} />
        )}
      </QBox>
    </QDrawer>
  );
};

export default ContentTemplateDrawer;
