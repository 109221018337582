import {
  QBox,
  QStep,
  QStepper,
  QStepperProgress,
  QSteps,
  QStepVariantOptions,
  useToastProvider,
} from '@qualio/ui-components';
import React, { useCallback, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import DefaultSections from '../../components/EditContentTemplate/DefaultSections/DefaultSections';
import EditContentTemplateFooter from '../../components/EditContentTemplate/Footer/EditContentTemplateFooter';
import {
  SectionDTO,
  SectionErrors,
  SectionResolutionDTO,
  ContentTemplateDTO,
  ContentTemplateRequestBody,
  ContentTemplateSave,
} from '../../types';
import { EditContentTemplatePages } from './EditContentTemplatePages';
import DefaultContent from '../../components/EditContentTemplate/DefaultContent/DefaultContent';
import SectionResolutionModal from '../../components/EditContentTemplate/SectionResolutionModal/SectionResolutionModal';
import contentTemplateApi from '../../api/contentTemplate.api';
import { Noop } from '../../utils/functionalUtils';
import { baseEventTemplateQueryKey } from '../../hooks';
import { elementAtIndex } from '../../utils/arrayUtils';

type EditContentTemplateContentProps = {
  companyId: number;
  templateId: string;
  contentTemplateId: string;
  originalContentTemplateDto: ContentTemplateDTO;
  contentTemplateDto: ContentTemplateDTO;
  setContentTemplateDto: (contentTemplateDto: ContentTemplateDTO) => void;
  sectionErrors: SectionErrors[];
  setSectionErrors: (errors: SectionErrors[]) => void;
  sectionTitlesTouched: boolean[];
  setSectionTitlesTouched: (touched: boolean[]) => void;
};

const initialSteps: StepVariant[] = ['default', 'default'];

const getDeletedSections = (originalSections: SectionDTO[], currentSections: SectionDTO[]): SectionDTO[] => {
  const keptSectionIds = currentSections.filter((section) => section.id).map((section) => section.id);
  return originalSections.filter((section) => section.id && !keptSectionIds.includes(section.id));
};

type StepVariant = Exclude<QStepVariantOptions, 'active'>;

const EditContentTemplateContent: React.FC<EditContentTemplateContentProps> = ({
  companyId,
  templateId,
  contentTemplateId,
  originalContentTemplateDto,
  contentTemplateDto,
  setContentTemplateDto,
  sectionErrors,
  setSectionErrors,
  sectionTitlesTouched,
  setSectionTitlesTouched,
}) => {
  const { showToast } = useToastProvider();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [defaultSectionsPageError, setDefaultSectionsPageError] = useState<boolean>(false);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState<boolean>(false);
  const [isSectionResolutionModalOpen, setIsSectionResolutionModalOpen] = useState<boolean>(false);
  const [page, setPage] = useState<EditContentTemplatePages>(EditContentTemplatePages.DefaultSections);
  const [steps, setSteps] = useState<StepVariant[]>(initialSteps);
  const [deletedSections, setDeletedSections] = useState<SectionDTO[]>([]);
  const mutation = useMutation(
    (templateRequestBody: ContentTemplateRequestBody) => updateTemplate(templateRequestBody),
    {
      onSuccess: () => {
        setIsSubmitButtonDisabled(true);
        showToast({
          title: 'Success',
          description: 'Template successfully updated',
          status: 'success',
        });

        queryClient
          .invalidateQueries({
            queryKey: [baseEventTemplateQueryKey, companyId, templateId],
          })
          .then(() => navigate(`../templates/${templateId}`, { replace: true }));
      },
      onError: (error: any) => {
        showToast({
          title: 'Error',
          description: 'Failed to update the template:' + error.response.data.body,
          status: 'error',
        });
      },
    },
  );
  const submit = useCallback(
    (resolutions: SectionResolutionDTO[]) => {
      mutation.mutate(new ContentTemplateRequestBody(contentTemplateDto, resolutions));
    },
    [contentTemplateDto, mutation],
  );

  const validateSectionTitles = () => {
    const errors = [...sectionErrors];
    for (let i = 0; i < sectionTitlesTouched.length; i++) {
      if (!sectionTitlesTouched[i]) {
        const errorObject = elementAtIndex(errors, i);

        errorObject.titleBlank = true;
      }
    }
  };

  const updateTemplate = (templateRequestBody: ContentTemplateRequestBody) => {
    const data: ContentTemplateSave = {
      template: {
        id: Number(contentTemplateId),
        company_id: companyId,
        sections: templateRequestBody.sections,
        name: contentTemplateDto.name,
        document_id_prefix: contentTemplateDto.document_id_prefix,
      },
    };
    return contentTemplateApi.updateContentTemplate(companyId, contentTemplateId, data);
  };

  const onSubmit = () => {
    const sectionsDeleted = getDeletedSections(originalContentTemplateDto.sections, contentTemplateDto.sections);
    setDeletedSections(sectionsDeleted);

    if (sectionsDeleted.length > 0) {
      setIsSectionResolutionModalOpen(true);
    } else {
      submit([]);
    }
  };

  const nextPage = () => {
    if (defaultSectionsPageError) {
      validateSectionTitles();
      return;
    }

    const newSteps = [...steps];
    newSteps[page] = 'visited';
    setSteps(newSteps);
    setPage(page + 1);
  };

  const previousPage = () => {
    const newSteps = [...steps];
    newSteps[page] = 'default';
    setSteps(newSteps);
    setPage(page - 1);
  };

  return (
    <>
      <QBox pt={6} className="stepper-wrapper">
        <QStepper activeStep={page} onStepItemClicked={Noop}>
          <QSteps>
            <QStepperProgress />
            <QStep title="Default sections" variant={steps[EditContentTemplatePages.DefaultSections]}>
              <DefaultSections
                setTemplateDto={setContentTemplateDto}
                templateDto={contentTemplateDto}
                setPageError={setDefaultSectionsPageError}
                sectionTitlesTouched={sectionTitlesTouched}
                setSectionTitlesTouched={setSectionTitlesTouched}
                sectionErrors={sectionErrors}
                setSectionErrors={setSectionErrors}
              />
            </QStep>
            <QStep title="Default content" variant={steps[EditContentTemplatePages.DefaultContent]}>
              <DefaultContent setTemplateDto={setContentTemplateDto} templateDto={contentTemplateDto} />
            </QStep>
          </QSteps>
        </QStepper>
      </QBox>
      <EditContentTemplateFooter
        page={page}
        nextPage={nextPage}
        onSubmit={onSubmit}
        previousPage={previousPage}
        submitInProgress={mutation.isLoading || isSubmitButtonDisabled}
        isLoaded={!!originalContentTemplateDto}
        templateId={templateId}
        contentTemplateId={contentTemplateId}
      />
      {isSectionResolutionModalOpen && contentTemplateDto && (
        <SectionResolutionModal
          isOpen={isSectionResolutionModalOpen}
          setIsModalOpen={setIsSectionResolutionModalOpen}
          submit={submit}
          deletedSections={deletedSections}
          sectionsToModify={contentTemplateDto.sections}
        />
      )}
    </>
  );
};

export default EditContentTemplateContent;
