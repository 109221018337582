import { CKEditorInstance } from '../types/CKEditorInstance';

export class ToolbarManager {
  private readonly toolbarParentElementId: string;

  constructor(toolbarParentElementId: string) {
    this.toolbarParentElementId = toolbarParentElementId;
  }

  getToolbarParentElement = (): HTMLElement => {
    const toolbarParentElement = document.getElementById(this.toolbarParentElementId);
    if (toolbarParentElement) {
      return toolbarParentElement;
    }
    throw new Error('could not locate editor toolbar target');
  };

  setToolbarFromEditor = (editor: CKEditorInstance): void => {
    const toolbarElement = editor.ui.view.toolbar.element ?? null;
    const toolbarParentElement = this.getToolbarParentElement();
    const existingToolbarElements = toolbarParentElement.querySelectorAll('[role="toolbar"]');
    if (toolbarElement && existingToolbarElements.length === 0) {
      toolbarParentElement.insertBefore(toolbarElement, null);
    }
  };
}

export const useToolbarManager = (toolbarParentElementId: string): ToolbarManager =>
  new ToolbarManager(toolbarParentElementId);
