import { QButton, QButtonGroup, QFlex, QIcon, QText } from '@qualio/ui-components';
import React from 'react';

type ApprovalRequestBannerProps = {
  approveButtonClick: () => void;
  declineButtonClick: () => void;
};
const ApprovalRequestBanner = ({ approveButtonClick, declineButtonClick }: ApprovalRequestBannerProps) => {
  return (
    <QFlex p={2} bg="yellow.100" borderRadius="md" mb={4} justifyContent={'space-between'}>
      <QFlex w="30%" alignItems="center" justifyContent={'space-between'}>
        <QIcon iconName="AlertCircle" color="yellow.500" />
        <QText weight="semibold">Approval request</QText>
        <QText>Your approval is requested</QText>
      </QFlex>
      <QButtonGroup>
        <QButton variant="solid" isDestructive onClick={declineButtonClick}>
          Decline
        </QButton>
        <QButton variant="solid" onClick={approveButtonClick}>
          Approve
        </QButton>
      </QButtonGroup>
    </QFlex>
  );
};

export default ApprovalRequestBanner;
