import { AxiosError } from 'axios';
import { BackendError } from '../types';
import * as DisplayStrings from '../displayStrings';

const extractMessageFromErrorArray = (errors: BackendError[]): string => {
  return errors && errors.length ? errors[0]!.description : DisplayStrings.DefaultErrorMessage;
};

export const extractMessageFromError = (e: AxiosError<any, any>): string => {
  // This is intended to work for a variety of errors, from medtech, fastapi validation, and custom
  if (e.response == null) {
    return DisplayStrings.DefaultErrorMessage;
  }
  const errorMessage = e.response.data.detail ?? e.response.data.message ?? e.response.data;
  if (typeof errorMessage === 'string') {
    return errorMessage;
  } else if (typeof errorMessage === 'object' && !Array.isArray(errorMessage)) {
    return extractMessageFromErrorArray(errorMessage.errors);
  }
  // This gets fastAPI errors
  const errors = e.response.data.errors;
  return extractMessageFromErrorArray(errors);
};
