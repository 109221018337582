import * as z from 'zod';
import { BaseSchema, ServerOnlyOmitKeys } from './common';
import { FieldTypeSchema, MandatorySchema, MultiSchema } from '../common';

/**
 * All types in this file pertain to the template form fields (not the fields on
 * an instance of a form) that are "non standard" input fields, meaning they don't
 * match exactly with the types of the more basic inputs (text, date, boolean, numeric)
 */

/**
 * Rich Text on a form template is currently the only type of input where default
 * content can be defined on the template field object.
 */
export const RichTextSchema = BaseSchema.extend({
  type: FieldTypeSchema.extract(['rich_text']),
  multi: MultiSchema,
  mandatory: MandatorySchema,
});
export const NewRichTextSchema = RichTextSchema.omit(ServerOnlyOmitKeys);

// In an attachment field the "multi" value must always be true
export const AttachmentSchema = BaseSchema.extend({
  type: FieldTypeSchema.extract(['attachment']),
  multi: z.literal(true),
  mandatory: MandatorySchema,
});
export const NewAttachmentSchema = AttachmentSchema.omit(ServerOnlyOmitKeys);
