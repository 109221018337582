import axios, { CancelTokenSource } from 'axios';
import { documentApi } from '../../api/document';

export class QualioAttachmentCKUploadAdapter {
  loader: { file: Promise<any>; uploadTotal: number; uploaded: number };
  cancelTokenSource: CancelTokenSource;
  constructor(loader: { file: Promise<any>; uploadTotal: number; uploaded: number }) {
    this.loader = loader;
    this.cancelTokenSource = axios.CancelToken.source();
  }

  async upload(): Promise<any> {
    const file = await this.loader.file;
    const attachment = await documentApi.uploadAttachment(
      file,
      (progress) => {
        this.loader.uploadTotal = progress.total as number;
        this.loader.uploaded = progress.loaded;
      },
      this.cancelTokenSource.token,
      {},
    );
    if (attachment) {
      const imageUrl = window.location.hostname === 'localhost' ? `http:${attachment.url}` : `https:${attachment.url}`;
      return {
        urls: { default: imageUrl },
      };
    }
  }

  abort(): void {
    this.cancelTokenSource.cancel('User requested');
  }
}
