import * as z from 'zod';
import { DefaultContentSchema, HelptextSchema, LabelSchema, OrderSchema } from '../common';
import { NumericIdSchema } from '../../id';

export const BaseSchema = z.object({
  id: NumericIdSchema,
  form_template_id: NumericIdSchema,
  order: OrderSchema,
  label: LabelSchema,
  helptext: HelptextSchema,
  default_content: DefaultContentSchema,
  // Resource subtype is marked as optional until BE is ready to support it
  resource_sub_type: z.string().nullable().optional(),
  event_type_code: z.string().nullable(),
  created_at: z.number(),
  updated_at: z.number(),
  lineage_id: z.string().optional(),
});

// These are keys that only exist on server objects and not new fields yet to be saved
export const ServerOnlyOmitKeys = {
  id: true,
  event_type_code: true,
  created_at: true,
  updated_at: true,
} as const;
