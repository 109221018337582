// eslint-disable-next-line no-restricted-imports
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';

/**
 * Adding a flag here will make it known to the app.  When running locally the
 * value listed here next to the flag is what will be used by calls to "useFlags".
 * When in staging/prod the value will be fetched from LaunchDarkly, but any flag
 * not listed here will not be watched by the LD client
 */
export const LocalFlagConfiguration = {
  qeValidationStepImprovements: true,
  documentOverviewRefresh: true,
  qualioFrontendRefresh: true,
  dataRegistry: true,
  designControlsSearch: true,
  useLegacyDomainForMtfe: true,
  qualityEventsAi: true,
  registryProductRootCause: true,
  qeDefaultOwners: true,
  qeOnepassTesting: true,
  qriReferenceDrawer: true,
  qeCancelAndReject: true,
  qeTemplateChangeManagement: true,
} as const satisfies Record<string, boolean>;

export type KnownFlags = keyof typeof LocalFlagConfiguration;
export type FeatureFlagProviderCreator = (user: CurrentUser) => ReturnType<typeof asyncWithLDProvider>;
