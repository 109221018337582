import React from 'react';
import { QStack } from '@qualio/ui-components';

type Props = {
  children: React.ReactNode;
};

const SectionWrapper = ({ children }: Props) => {
  return <QStack spacing={2}>{children}</QStack>;
};

export default SectionWrapper;
