import { useMutation } from 'react-query';
import { useCurrentUser, useToastProvider } from '@qualio/ui-components';
import { AddUserTaskCommentPayload, AddUserTaskCommentResponse } from '../types';
import userTasksApi from '../api/userTasks.api';
import { DefaultErrorMessage } from '../displayStrings';

type UseAddUserTaskCommentType = {
  addUserTaskCommentMutate: (payload: AddUserTaskCommentPayload) => void;
  isAddUserTaskCommentLoading: boolean;
};

export const useAddUserTaskComment = (
  taskId: number,
  onSuccess: (data: AddUserTaskCommentResponse | undefined) => void,
): UseAddUserTaskCommentType => {
  const { companyId } = useCurrentUser();
  const { showToast } = useToastProvider();

  const { mutate: addUserTaskCommentMutate, isLoading: isAddUserTaskCommentLoading } = useMutation(
    (payload: AddUserTaskCommentPayload) => userTasksApi.addComment(companyId, taskId, payload),
    {
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: () => {
        showToast({
          description: DefaultErrorMessage,
          status: 'error',
        });
      },
    },
  );

  return { addUserTaskCommentMutate, isAddUserTaskCommentLoading };
};
