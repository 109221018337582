import React, { useCallback, useMemo, useState } from 'react';
import { QHeading, QBox, QAlert, deprecated } from '@qualio/ui-components';
import { EventTemplateStepCreateEdit, EventTemplateFormValues, EventStepType } from '../../types';
import StepRowActions from './components/StepRowActions';
import { ClickPropagationBarrier } from '../../components';
import FormTemplateDrawer from './components/FormTemplateDrawer';
import ContentTemplateDrawer from './components/ContentTemplateDrawer';

type TemplateStepsListProps = {
  companyId: number;
  templateSteps: EventTemplateFormValues['steps'];
  setTemplateSteps: (steps: EventTemplateFormValues['steps']) => void;
  disableEdit: boolean;
  renameTemplateStep: (stepIndex: number) => void;
  changeValidationStepType: (stepIndex: number) => void;
};

const StepTypeMapping = {
  document: 'Content',
  actions: 'Tasks',
  form: 'Form',
} as const satisfies Record<EventStepType, string>;

const GetStepLabel = (templateSteps: EventTemplateFormValues['steps'], step: EventTemplateStepCreateEdit): string => {
  return step.order === templateSteps.length
    ? `Validation (${StepTypeMapping[step.type]})`
    : StepTypeMapping[step.type];
};

const stepColumns = [
  {
    Header: 'Order',
    accessor: 'order',
    width: '5%',
    disableSortBy: true,
  },
  {
    Header: 'Name',
    accessor: 'name',
    width: '40%',
    disableSortBy: true,
  },
  {
    Header: 'Type',
    accessor: 'type',
    width: '40%',
    disableSortBy: true,
  },
  {
    Header: '',
    accessor: 'actions',
    width: '15%',
    disableSortBy: true,
  },
] as const;

const TemplateStepsList: React.FC<TemplateStepsListProps> = ({
  companyId,
  templateSteps,
  setTemplateSteps,
  disableEdit,
  renameTemplateStep,
  changeValidationStepType,
}) => {
  const [selectedStep, setSelectedStep] = useState<EventTemplateStepCreateEdit | null>(null);
  const closeTaskDrawer = useCallback(() => {
    setSelectedStep(null);
  }, [setSelectedStep]);

  const deleteTemplateStep = useCallback(
    (step) => {
      const stepIndex = step.order - 1;
      templateSteps.splice(stepIndex, 1);
      setTemplateSteps([...templateSteps]);
      closeTaskDrawer();
    },
    [closeTaskDrawer, setTemplateSteps, templateSteps],
  );

  const onStepClick = (step: EventTemplateStepCreateEdit) => {
    if (step.template_id || step.form_template_id) {
      setSelectedStep(step);
    }
  };

  const preventStepDelete = (step: EventTemplateStepCreateEdit) => {
    return step.order === templateSteps.length;
  };

  const tableData: deprecated.QDataRow[] = useMemo(() => {
    return templateSteps.map((step: EventTemplateStepCreateEdit): deprecated.QDataRow => {
      return {
        order: step.order,
        name:
          ((step.type === 'document' && step.template_id) || (step.type === 'form' && step.form_template_id)) &&
          !disableEdit ? (
            <QBox color={'blue.500'} cursor={'pointer'} onClick={() => onStepClick(step)}>
              {step.label}
            </QBox>
          ) : (
            step.label
          ),
        type: GetStepLabel(templateSteps, step),
        actions: (
          <ClickPropagationBarrier>
            <StepRowActions
              step={step}
              templateSteps={templateSteps}
              onDelete={deleteTemplateStep}
              setTemplateSteps={setTemplateSteps}
              renameTemplateStep={renameTemplateStep}
              changeValidationStepType={changeValidationStepType}
              disableEdit={disableEdit}
            />
          </ClickPropagationBarrier>
        ),
      };
    });
  }, [templateSteps, deleteTemplateStep, setTemplateSteps, renameTemplateStep, changeValidationStepType, disableEdit]);

  return (
    <>
      <QHeading size="md" mt={4} mb={4}>
        Steps
      </QHeading>
      {disableEdit ? (
        <QAlert
          status="info"
          title="Save changes to edit content or form steps"
          description="In order to edit content or form steps, please save changes to the template."
        />
      ) : null}
      <deprecated.QDataTable columns={stepColumns} data={tableData} hasPagination={false} />
      {selectedStep && selectedStep.type === 'document' && (
        <QBox data-cy="content-drawer-screen">
          <ContentTemplateDrawer
            key={selectedStep.template_id}
            onClose={closeTaskDrawer}
            onDelete={deleteTemplateStep}
            preventStepDelete={preventStepDelete}
            step={selectedStep}
          />
        </QBox>
      )}
      {selectedStep && selectedStep.type === 'form' && (
        <QBox data-cy="form-drawer-screen">
          <FormTemplateDrawer
            companyId={companyId}
            key={selectedStep.template_id}
            onClose={closeTaskDrawer}
            onDelete={deleteTemplateStep}
            preventStepDelete={preventStepDelete}
            step={selectedStep}
          />
        </QBox>
      )}
    </>
  );
};

export default TemplateStepsList;
