import React, { MutableRefObject, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { ListQualityEvent, EventSortParam } from '../../types';
import { QualityEventListContainer, QualityEventListTitleLink } from './QualityEventList.styles';
import { QBox, QBadge, QTag, QSpinner, QText, QEmptyState, deprecated } from '@qualio/ui-components';
import { StatusTag, DueColumn, QDataTable, QDataColumn } from '../../components';
import { isFieldVisible } from '../../utils/eventTemplateUtils';
import { useFlags } from '../../external/LaunchDarklyAdapter';

type QualityEventListProps = {
  qualityEvents: ListQualityEvent[] | undefined;
  isLoading: boolean;
  pageCount: number;
  totalItems: number;
  paginationCallback: (pageIndex: number, pageSize: number) => void;
  sortByCallback: (sortParams: unknown[]) => void;
  initialPageIndex: number;
  initialPageSize: number;
  sortByColumn: EventSortParam[];
  refSetSortBy?: MutableRefObject<((columnAccessor: string, isSortedDesc: boolean | undefined) => void) | undefined>;
};

const QualityEventList: React.FC<QualityEventListProps> = ({
  qualityEvents,
  isLoading,
  pageCount,
  totalItems,
  paginationCallback,
  sortByCallback,
  initialPageIndex,
  initialPageSize,
  sortByColumn,
  refSetSortBy,
}) => {
  const today = DateTime.now();
  const isRegistryProductRootCauseEnabled = useFlags('registryProductRootCause');
  const COLUMNS: QDataColumn[] = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'code',
      },
      {
        Header: 'Title',
        accessor: 'title',
        width: '20%',
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: '8%',
      },
      {
        Header: 'Risk',
        accessor: 'risk',
      },
      {
        Header: 'Product',
        accessor: 'product',
        disableSortBy: isRegistryProductRootCauseEnabled,
      },
      {
        Header: 'Tags',
        accessor: 'tags',
        disableSortBy: true,
      },
      {
        Header: 'Owner',
        accessor: 'owner',
      },
      {
        Header: 'Due',
        accessor: 'due_at',
      },
      {
        Header: 'Steps',
        accessor: 'steps',
        width: '5%',
        disableSortBy: true,
      },
    ],
    [isRegistryProductRootCauseEnabled],
  );

  const mappedData = useMemo(
    () =>
      qualityEvents?.map((event: ListQualityEvent) => {
        const productIsVisible = isFieldVisible(event?.template_fields, 'product');
        const rootCauseIsVisible = isFieldVisible(event?.template_fields, 'root_cause');
        const riskIsVisible = isFieldVisible(event?.template_fields, 'risk');
        return {
          id: event.id || '',
          code: <QBadge textTransform="none">{event.code}</QBadge>,
          title: (
            <QualityEventListTitleLink
              className={'qe-list-title-link'}
              href={`quality-events/events/${event.id}`}
              // Let the row click handler handle navigation
              // but allow the user to right click this link.
              onClick={(e) => e.preventDefault()}
            >
              {event.title}
            </QualityEventListTitleLink>
          ),
          status: <StatusTag status={event.status} />,
          risk: (riskIsVisible && event.risk) || '',
          product: (productIsVisible && event.product_name) || '',
          tags: event.tags ? event.tags.split(',').map((tag: string) => <QTag key={tag}>{tag}</QTag>) : '',
          rootCause: (rootCauseIsVisible && event.rootcause_name) || '',
          owner: event.owner,
          due_at: (
            <DueColumn
              referenceDatetime={today}
              createdAtDatetime={DateTime.fromSeconds(event.created_at)}
              dueAtDatetime={DateTime.fromSeconds(event.due_at)}
              closedAtDatetime={event.resolved_at ? DateTime.fromSeconds(event.resolved_at) : undefined}
              isClosed={event.status === 'closed'}
            />
          ),
          steps: `${event.steps_closed}/${event.steps_total}`,
        } as deprecated.QDataRow;
      }),
    [qualityEvents, today],
  );

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleClick = (row: deprecated.QDataRow) => {
    navigate(`/events/${row.id}`, { state: { backQueryParams: searchParams.toString() } });
  };

  const manualPagination = useMemo(
    () => ({
      paginationCallback,
      pageCount,
      initialPageIndex,
      initialPageSize,
    }),
    [paginationCallback, pageCount, initialPageIndex, initialPageSize],
  );

  const manualSortBy = useMemo(
    () => ({ sortByCallback, defaultSortByColumn: sortByColumn }),
    [sortByCallback, sortByColumn],
  );

  return (
    <QualityEventListContainer>
      <QBox>
        {isLoading ? (
          <QBox w="100%" textAlign="center" p={5} mt={16}>
            <QSpinner data-cy={'events-loading-spinner'} />
          </QBox>
        ) : (
          <>
            {totalItems === 0 ? (
              <QEmptyState data-cy={'events-event-table'} title={'No matching events found'}></QEmptyState>
            ) : (
              <>
                <QText mb={1} weight="semibold" color="gray.500">
                  {totalItems === 1 ? `${totalItems} event` : `${totalItems} events`}
                </QText>
                <QBox data-cy={'events-table'}>
                  <QDataTable
                    columns={COLUMNS}
                    data={mappedData ?? []}
                    onRowClick={handleClick}
                    hasPageSizeOptions={true}
                    manualPagination={manualPagination}
                    manualSortBy={manualSortBy}
                    refSetSortBy={refSetSortBy}
                  />
                </QBox>
              </>
            )}
          </>
        )}
      </QBox>
    </QualityEventListContainer>
  );
};

export default QualityEventList;
