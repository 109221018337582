import * as z from 'zod';
import { FormTemplateFieldSchema } from './formFields/formTemplates';

export const FormTemplateStatus = z.enum(['draft', 'effective', 'superseded', 'deleted']);

export const FormTemplateSchema = z.object({
  id: z.number(),
  status: FormTemplateStatus,
  workflow_step_id: z.number(),
  event_type_code: z.string().nullable(),
  fields: z.array(FormTemplateFieldSchema),
  created_at: z.number(),
  updated_at: z.number().nullable(),
});

export type FormTemplate = z.infer<typeof FormTemplateSchema>;
export type FormTemplateWithWorkflowId = FormTemplate & { workflow_id: number };
export type FormTemplateStatus = z.infer<typeof FormTemplateStatus>;
