// eslint-disable-next-line no-restricted-imports
import { asyncWithLDProvider, useFlags as originalUseFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlagProviderCreator, KnownFlags, LocalFlagConfiguration } from './shared';
import { paramCase } from 'change-case';

export const CreateLaunchDarklyProvider: FeatureFlagProviderCreator = (user) => {
  const ldContext: { [key: string]: string | number } = {
    kind: 'user',
    key: String(user.companyId),
    loggedInUserId: String(user.userId),
    createdTime: user.company.createdTime,
    status: user.company.status,
  };
  if (user.company.pricingPlan) {
    ldContext['pricingPlan'] = user.company.pricingPlan;
  }
  return asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY_PK as string,
    context: ldContext,
    options: {
      application: { id: `quality-events-fe-${process.env.REACT_APP_ENVIRONMENT}` },
    },
    /**
     * This is used to tell LD which flags we want to subscribe to.  Any flag not
     * listed here will not be available in the app.
     */
    flags: Object.keys(LocalFlagConfiguration).reduce((acc, flagName) => {
      return {
        ...acc,
        [paramCase(flagName)]: true,
      };
    }, {}),
  });
};

export const useFlags = (flagName: KnownFlags | KnownFlags[]) => {
  const allFlags = originalUseFlags();

  if (Array.isArray(flagName)) {
    return flagName.reduce((acc, flagName) => {
      return {
        ...acc,
        [flagName]: allFlags[flagName],
      };
    }, {});
  }

  return allFlags[flagName];
};
