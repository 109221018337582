import React from 'react';
import { QBox, QStack, QText, QTitle } from '@qualio/ui-components';
import { LocalFlagConfiguration } from '../../external/LaunchDarklyAdapter/shared';

// We normally disallow using the raw LD flags in the app, but this view is for debugging purposes only
// eslint-disable-next-line no-restricted-imports
import { useFlags } from 'launchdarkly-react-client-sdk';

/**
 * THIS VIEW IS FOR DEBUGGING PURPOSES ONLY.
 *
 * This view is only meant to be reachable by staff users and is used to view
 * the LD configuration for QE FE. It should not be used for any other purpose,
 * or exposed to non-staff users.
 */
export const LaunchDarklyDebug = () => {
  const rawLDFlags = useFlags();
  const rawLDFlagsKeys = Object.keys(rawLDFlags);

  return (
    <QStack direction="column">
      <QBox>
        <QTitle>Known Flags</QTitle>
        {Object.keys(LocalFlagConfiguration).map((flag) => (
          <QText key={flag}>{flag}</QText>
        ))}
      </QBox>
      <QBox>
        <QTitle>Raw LD Flag Values</QTitle>
        {rawLDFlagsKeys.length ? (
          rawLDFlagsKeys.map((flag) => (
            <QText key={flag}>
              {flag}: {String(rawLDFlags[flag])}
            </QText>
          ))
        ) : (
          <QText>No flags returned in this environment</QText>
        )}
      </QBox>
    </QStack>
  );
};
