import { AssociateDocumentResponse } from '../types';
import { medtechApi } from './index';

const issuesApi = {
  async associateDocument(
    company: number,
    eventId: number,
    stepId: number,
  ): Promise<AssociateDocumentResponse | undefined> {
    const response = await medtechApi.post(`/v2/${company}/issues/${eventId}/associate/${stepId}`);
    return AssociateDocumentResponse.parse(response.data);
  },
};
export default issuesApi;
