import { Command } from '@ckeditor/ckeditor5-core';

export class QualioCodeBlockCommand extends Command {
  static readonly commandName = 'QualioCodeBlockCommand';

  execute(): void {
    this.editor.execute('codeBlock');
  }

  refresh(): void {
    this.isEnabled = true;
  }
}
