import { Smartlink } from '../types/smartlink';
import { SuggestionApi } from '../types/suggestionApi';
import { createRequestConfig, medtechApi } from './index';

const smartlinksApi: SuggestionApi = {
  async suggest(company: number, search: string): Promise<Smartlink[]> {
    const config = createRequestConfig({ params: { search: search, exclude_uuid: false, include_issues: true } });

    const res = await medtechApi.get(`/v2/${company}/smartlinks/suggest`, config);
    return res.data.map((item: Smartlink) => Smartlink.parse(item));
  },
};

export default smartlinksApi;
