import React from 'react';
import { QBox, QStack, QText } from '@qualio/ui-components';
import TaskComment from './TaskComment';
import { UserTaskComment } from '../../../types';

type TaskCommentsContainerProps = {
  userTaskComments: UserTaskComment[];
};

const TaskCommentsContainer: React.FC<TaskCommentsContainerProps> = ({ userTaskComments }) => {
  return (
    <QBox mt={6}>
      <QBox>
        <QText weight="semibold">Comments</QText>
        <QStack mt={4} direction="column" spacing={6}>
          {userTaskComments.map((userTaskComment: UserTaskComment) => (
            <TaskComment key={userTaskComment.id} userTaskComment={userTaskComment} />
          ))}
        </QStack>
      </QBox>
    </QBox>
  );
};

export default TaskCommentsContainer;
