import React from 'react';
import { QBox, QFlex } from '@qualio/ui-components';
import TasksCount from './TasksCount';
import { UserTasksListResponse } from '../../../types';

type TaskStepMetadataProps = {
  stepId: number;
  openTasks: UserTasksListResponse | undefined;
  doneTasks: UserTasksListResponse | undefined;
  closedTasks: UserTasksListResponse | undefined;
};

const TaskStepMetadata: React.FC<TaskStepMetadataProps> = ({ stepId, openTasks, doneTasks, closedTasks }) => {
  const open = openTasks?.total || 0;
  const done = doneTasks?.total || 0;
  const closed = closedTasks?.total || 0;
  const total = open + done + closed;

  return (
    <QBox h="70px" borderColor="gray.200" borderBottomWidth="1px">
      <QFlex h="100%" ml={4} direction="row" align="center">
        <TasksCount label="Total" count={total} />
        <TasksCount label="Open" count={open} />
        <TasksCount label="Done" count={done} />
        <TasksCount label="Closed" count={closed} />
      </QFlex>
    </QBox>
  );
};

export default TaskStepMetadata;
