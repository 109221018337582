export const changeRequestsQuicksearch = {
  results: [
    {
      qri: 'https://qri.app.staging.beta.qualio.com/Y2hhbmdlLW1hbmFnZW1lbnQvY2hhbmdlLXJlcXVlc3QvMjQwOC82MjQ1',
      title: 'Test doc change',
      code: 'DCR-1',
      companyId: 1,
      owner: 'Quality User 1',
      id: '6245',
      domain: 'change-management',
      domainType: 'change-request',
      updatedAt: '2024-05-07T15:08:24.000Z',
      createdAt: '2024-05-07T15:08:24.000Z',
    },
  ],
  totalRelation: 'eq',
  total: 1,
  page: 1,
  size: 10,
};
