import React from 'react';
import { QSelect } from '@qualio/ui-components';
import { DropdownSubProps } from './shared';
import { createFieldValueArray } from '../../../../utils/eventFormUtils';
import { StandardValueObject } from '../../EventFormField.types';

// SingleSelect dropdown component.  Loaded by DropdownField.tsx
const DropdownFieldSingleSelect = (props: DropdownSubProps) => {
  const { fieldValues, onChange, fieldId, options } = props;

  // TODO:  QEV-2813
  const valueObj = fieldValues[0] as StandardValueObject | undefined;

  return (
    <QSelect
      data-cy="dropdown-field"
      options={options}
      value={valueObj?.value}
      onChange={(selectedOption) => {
        const optionValue = selectedOption?.value;
        const newValueArray = createFieldValueArray(optionValue, fieldId, valueObj);

        onChange(newValueArray);
      }}
    />
  );
};

export default DropdownFieldSingleSelect;
