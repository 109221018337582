import * as z from 'zod';
import { FieldTypeSchema } from '../common';
import { BaseSchema, StandardFieldValueSchema } from './common';

/**
 * All types in this file pertain to any visual form field types, specifically
 * the "section" field type.
 */
export const SectionFieldSchema = BaseSchema.extend({
  type: FieldTypeSchema.extract(['section']),
  // TODO: This should be updated since sections shouldn't have values
  values: z.array(StandardFieldValueSchema),
});
