import { QualityEventDetailsResponse } from '../types';
import { QualityEventEdit } from '../types/qualityEvent';
import { DigitalSignature } from '../types/digitalSignature';
import api, { medtechApi } from './index';

const eventDetailsApi = {
  async getEventDetails(company: number, eventId: number): Promise<QualityEventDetailsResponse | undefined> {
    const response = await medtechApi.get(`/v2/${company}/issues/${eventId}`);
    return QualityEventDetailsResponse.parse(response.data);
  },

  async closeEvent(company: number, eventId: string, payload: DigitalSignature): Promise<void> {
    await api.post(`/company/${company}/events/${eventId}/close`, {
      ...payload,
    });
  },

  async editEvent(company: number, eventId: string, payload: QualityEventEdit): Promise<void> {
    await api.patch(`/company/${company}/events/${eventId}`, {
      ...payload,
    });
  },

  async reopenEvent(company: number, eventId: string, payload: DigitalSignature): Promise<void> {
    await api.post(`/company/${company}/events/${eventId}/reopen`, {
      ...payload,
    });
  },

  async rejectEvent(companyId: number, eventId: string, payload: DigitalSignature): Promise<void> {
    await medtechApi.post(`/quality-events/${companyId}/issues/${eventId}/change-status`, {
      ...payload,
      status: 'rejected',
    });
  },

  async acceptEvent(companyId: number, eventId: string, payload: DigitalSignature): Promise<void> {
    await medtechApi.post(`/quality-events/${companyId}/issues/${eventId}/change-status`, {
      ...payload,
      status: 'open',
    });
  },

  async cancelEvent(companyId: number, eventId: string, payload: DigitalSignature): Promise<void> {
    await medtechApi.post(`/quality-events/${companyId}/issues/${eventId}/change-status`, {
      ...payload,
      status: 'cancelled',
    });
  },
};

export default eventDetailsApi;
