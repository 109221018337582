import { ApproverType, EventStatus } from './types';
import { sentenceCase } from 'change-case';

// Common Text
export const Save = 'Save';
export const Edit = 'Edit';
export const SaveChanges = 'Save changes';
export const SaveAndExit = 'Save and exit';
export const Cancel = 'Cancel';
export const Cancelled = 'Cancelled';
export const Back = 'Back';
export const Delete = 'Delete';
export const Required = 'Required';
export const DefaultErrorMessage = 'There was a problem communicating with the server.';
export const TimeZoneMissingMessage = 'Could not find the user timezone';
export const InvalidCredentials = 'Invalid credentials';
export const Reject = 'Reject';
export const Rejected = 'Rejected';
export const Accept = 'Accept';
export const Accepted = 'Accepted';

/**
 * Core Error Messages
 * These messages go along with errors that may occur when loading "low level"
 * pieces of the application
 */
export const CurrentCompanyRoleError = `Unable to resolve user's role in the current company`;

// Event Templates
export const TemplateFieldControlDescription = `Show fields that you want users to collect for this type of event, or hide them if they're not relevant. You can also select if a field is required or not. Required fields will be checked for completion upon closing the event.`;
export const TemplateEffective = 'Effective';
export const TemplateArchived = 'Archived';
export const DefaultOwner = 'Default event owner';
export const DefaultOwnerHelper =
  'An owner will be assigned automatically upon event creation and can be changed at any time. A default event owner must be assigned in order to create events automatically via an integration.';

// Event content step templates
export const ResolveSection = (sectionTitle: string) => {
  return `Section '${sectionTitle}' is marked to be removed, please select a resolution`;
};

// Event tasks
export const CreateTask = 'Create task';

// Event Form templates
export const Preview = 'Preview';
export const AddField = 'Add field';
export const AddFormElement = 'Add form element';
export const CreateNewField = 'Create new field';
export const ChooseQualioResource = 'Choose Qualio resource';
export const FieldTitle = 'Field title';
export const HelpText = 'Help text';
export const AddHelpText = 'Add help text';
export const EditHelpText = 'Edit help text';
export const SectionFieldTitle = 'Section title';
export const SectionDescription = 'Section description';
export const AddSectionDescription = 'Add section description';
export const EditSectionDescription = 'Edit section description';
export const DefaultContent = 'Default content';
export const AddDefaultContent = 'Add default content';
export const EditDefaultContent = 'Edit default content';
export const MultiSelect = 'Multi-select';
export const OptionSectionLabel = 'Options';
export const AddOption = 'Add an option';
export const OptionValueRequired = 'Option value required';
export const OptionValueTooLarge = 'Option value must be less than 512 characters long';
export const OptionValueUnique = 'Options cannot have duplicate names';
export const NoFormYet = 'No form to preview yet';
export const StartFormCopy = `Let's start building your form. With forms, you can collect specific event information and later analyze and report on your findings.`;
export const StartBuildingForm = `Start building your form`;

// Event Forms
export const CompleteForm = 'Complete form';
export const FormPreviewWarning = 'You are previewing a form';
export const FormUpdateSuccessTitle = 'Form values updated';
export const FormUpdateSuccessMessage = 'Form successfully updated';
export const FormUpdateFailedTitle = 'Form not updated';
export const FormMissingMessage = 'Could not find the form';
export const ApplyChanges = 'Apply changes';
export const Add = 'Add';

export const ApproversUpdateSuccessTitle = (approverType: ApproverType) => {
  return `${sentenceCase(approverType)} updated`;
};
export const ApproversUpdateSuccessMessage = (approverType: ApproverType) => {
  return `${sentenceCase(approverType)} for this form successfully updated`;
};
export const ApproversUpdateFailedTitle = (approverType: ApproverType) => {
  return `${sentenceCase(approverType)} update failed`;
};
export const ApproversUpdateFailedMessage = (approverType: ApproverType) => {
  return `One or more ${approverType} is not updated, please review your ${approverType}`;
};
export const AtLeastOneAssigneeRequiredMessage = (status: string) => {
  return `Form in "${sentenceCase(status).replace('_', ' ')}" status requires at least one assignee`;
};
export const RemovingActionedApproverErrorMessage = (approverType: ApproverType) => {
  return `Already actioned ${approverType.replace('_', ' ')} cannot be removed`;
};
export const ReviewForm = (formName: string) => `Review "${formName}"`;

// Event Form Statuses
export const EventFormStatuses = {
  draft: 'Draft',
  for_review: 'For review',
  for_approval: 'For approval',
  approved: 'Approved',
  approval_declined: 'Approval declined',
  effective: 'Effective',
  superseded: 'Superseded',
  cancelled: 'Cancelled',
} as const;

// Events
export const EventMissingMessage = 'Could not find the event';
export const ViewAIAssessment = 'View Qualio AI Assessment';
export const CloseEarly = 'Close early';
export const EventStatues: Record<EventStatus, string> = {
  open: 'Open',
  closed: 'Closed',
  imported: 'Imported',
  rejected: 'Rejected',
  cancelled: 'Canceled',
};
export const EventAcceptedMessage = 'You accepted this event';
export const EventRejectedMessage = 'You rejected this event';
export const EventCancelledMessage = 'You cancelled this event';
export const DefaultOwnerNoPermissionTitle = 'Default owner has no permission';
export const DefaultOwnerNoPermissionMessage = 'The default owner does not have required permission';
export const EventAcceptSubText = 'Enter your digital signature to accept the event';
export const EventCancelSubText = 'This action will cancel all event steps and mark the event as cancelled.';
export const EventRejectSubText = 'Enter your digital signature to reject the event';

// Search
export const SearchFailed = 'Could not fetch search results';

// Validation
export const RequiredFieldHelper = 'This field is required for closed events';
export const FormContentInvalidError = 'Form content is not valid';
export const EventPropertiesNotUpdatedError = 'Event properties not updated';

// Products
export const DeleteProductConfirmation = (productName: string) => {
  return `Are you sure you want to delete "${productName}"?`;
};

// Root Causes
export const DeleteRootCauseConfirmation = (rootCauseName: string) => {
  return `Are you sure you want to delete "${rootCauseName}" root cause?`;
};
