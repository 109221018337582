export const compare = (array1: string[] | Set<string>, array2: string[] | Set<string>) => {
  const set1 = new Set(array1);
  const set2 = new Set(array2);
  if (set1.size !== set2.size) {
    return false;
  }
  return [...set1].every((v) => set2.has(v));
};

export const interleave = (arr1: unknown[], arr2: unknown[]): unknown[] => {
  if (arr2.length === 0) {
    return [...arr1];
  }
  if (arr1.length === 0) {
    return [...arr2];
  }
  return [arr1[0]].concat(interleave(arr2, arr1.slice(1)));
};

export const joinReadable = (arr: unknown[]): unknown[] =>
  interleave(
    arr,
    [' and ']
      .concat(new Array(arr.length).fill(', '))
      .slice(0, arr.length - 1)
      .reverse(),
  );

type NotUndefined = NonNullable<unknown> | null;
/**
 * This method can not be used with an array where undefined is a possible value,
 * but otherwise it can be used to retrieve an element from an array at a given index
 * and narrow the type of the element to exclude undefined.  Only use this method
 * when you are certain that the element at the given index is not possibly undefined.
 */
export const elementAtIndex = <T extends NotUndefined>(arr: readonly T[], index: number): T => {
  const element = arr[index];

  if (element === undefined) {
    throw new Error('Element not found');
  }

  return element;
};

// Readable name for check if an element is the last element in an array by index
export const isLastElement = <T>(arr: readonly T[], index: number): boolean => {
  return index === arr.length - 1;
};

/**
 * I realize this is a little silly/unnecessary, but I wanted a convenient readable
 * shorthand for "isLastElement" so I figured I would put an equivalent "isFirstElement"
 */
export const isFirstElement = <T>(arr: readonly T[], index: number): boolean => {
  return index === 0;
};

/**
 * Use this function when you need to move an element in an array of objects that have an "order" property.
 */
export const moveElementInOrderedArray = <T extends { order: number }>(
  arr: readonly T[],
  element: T,
  move: 'up' | 'down' | 'first' | 'last',
): T[] => {
  const copy = [...arr];
  const index = copy.findIndex((el) => el === element);

  if (index === -1) {
    throw new Error('Element not found');
  }

  if (move === 'up') {
    if (index === 0) {
      return copy;
    }
    copy[index] = copy[index - 1]!;
    copy[index - 1] = element;
  } else if (move === 'down') {
    if (index === copy.length - 1) {
      return copy;
    }
    copy[index] = copy[index + 1]!;
    copy[index + 1] = element;
  } else if (move === 'first') {
    copy.splice(index, 1);
    copy.unshift(element);
  } else {
    copy.splice(index, 1);
    copy.push(element);
  }

  return copy.map((el, index) => ({ ...el, order: index + 1 }));
};

/**
 * As the name suggests, this is used to sort an array alphabetically by a given property of that object
 * @param values - Array of objects to sort
 * @param property - Must be a key of the objects you pass in
 * @returns - Cloned array of the given array, sorted alphabetically by desired property
 */
export const sortAlphabeticallyByProperty = <T>(values: readonly T[], property: keyof T) => {
  return values.slice().sort((a, b) => {
    if (a[property] < b[property]) {
      return -1;
    }
    if (a[property] > b[property]) {
      return 1;
    }
    return 0;
  });
};
