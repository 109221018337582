import React from 'react';
import { QBox } from '@qualio/ui-components';

type Props = {
  children: React.ReactNode;
};

const ReadonlyWrapper = ({ children }: Props) => {
  return <QBox marginTop="-8px">{children}</QBox>;
};

export default ReadonlyWrapper;
