import React from 'react';
import {
  QButton,
  QCloseButton,
  QInput,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QText,
} from '@qualio/ui-components';
import { StepInputState } from '../../../types/eventTemplateStepInputState';

type Props = {
  stepInputState: StepInputState;
  closeInput: () => void;
  currentName: string;
  submit: (stepName: string) => void;
  checkDuplicated: (stepName: string) => boolean;
};
const stepRenameCancelButton = 'step-rename-cancel-button';
const stepRenameModalCloseButton = 'step-rename-modal-close-button';
const stepRenameSaveButton = 'step-rename-save-button';

const StepRenameModal: React.FC<Props> = ({ stepInputState, closeInput, currentName, submit, checkDuplicated }) => {
  const [stepName, setStepName] = React.useState(currentName);
  const stepNameIsDuplicated = checkDuplicated(stepName);
  const saveButtonDisabled = stepName.trim() === '' || stepNameIsDuplicated;
  return (
    <QModal isOpen={stepInputState.type === 'rename'} onClose={closeInput} size="xl">
      <QModalHeader>
        <QText>Rename step</QText>
        <QCloseButton
          onClick={closeInput}
          data-cy={stepRenameModalCloseButton}
          aria-label={stepRenameModalCloseButton}
        ></QCloseButton>
      </QModalHeader>
      <QModalBody>
        <QInput
          value={stepName}
          isInvalid={stepNameIsDuplicated}
          aria-label="step-name"
          data-cy="step-name"
          onChange={(changeEvent) => {
            setStepName(changeEvent.target.value);
          }}
        />
        {stepNameIsDuplicated === true && <QText color={'red'}>Duplicated step names!</QText>}
      </QModalBody>
      <QModalActions>
        <QButton
          onClick={closeInput}
          variant="outline"
          data-cy={stepRenameCancelButton}
          aria-label={stepRenameCancelButton}
        >
          Cancel
        </QButton>
        <QButton
          onClick={() => submit(stepName)}
          data-cy={stepRenameSaveButton}
          aria-label={stepRenameSaveButton}
          isDisabled={saveButtonDisabled}
        >
          Save
        </QButton>
      </QModalActions>
    </QModal>
  );
};

export default StepRenameModal;
