import { FieldType, FieldTypeSchema, QObjectFieldType } from '../../../../types/formFields/common';
import { ArrayElement } from '../../../../utils/typeUtils';

/**
 * Eventually this should just be QObjectFieldType, but we need to add
 * a View map and verify functionality before we migrate completely so until
 * then we should add supported fields here slowly.
 */
export const SupportedFieldTypes = [
  FieldTypeSchema.Enum.registry,
  FieldTypeSchema.Enum.design_controls,
] as const satisfies readonly QObjectFieldType[];
export type SupportedType = ArrayElement<typeof SupportedFieldTypes>;

/**
 * Once all QObject field types are supported, we can remove this and simply use `fieldTypeIsQObjectType` from
 * src/utils/eventFormUtils.ts
 */
export const fieldTypeIsSupported = (type: FieldType): type is SupportedType => {
  return SupportedFieldTypes.includes(type as SupportedType);
};
