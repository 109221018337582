import { AxiosRequestConfig } from 'axios';
import { User, UserRequest } from '../types';
import { medtechApi } from '../api';

const usersApi = {
  async getAllUsers(company: number, filterParams?: UserRequest): Promise<User[]> {
    const res = await medtechApi.get(`/v2/${company}/users`, { params: filterParams } as AxiosRequestConfig);
    return res.data as User[];
  },

  async getUser(userId: number): Promise<User> {
    const res = await medtechApi.get(`/users/${userId}`);
    return res.data as User;
  },

  async getUserGroup(userId: number, companyId: number) {
    const userData = await this.getUser(userId);
    const company = userData.companies.find(({ id }) => id === companyId);

    if (!company) {
      throw new Error(`Unable to resolve user's current company`);
    }

    /**
     * The data for a user is structured so that "usergroups" is an array,
     * however in reality it is only ever an array of one item as a qualio
     * user must have one, and only one, role.
     */
    const currentCompanyRole = company.usergroups[0];

    if (!currentCompanyRole) {
      throw new Error('User has no role in their current company');
    }

    return currentCompanyRole;
  },
};

export default usersApi;
