import React from 'react';
import { QBox, QFlex } from '@qualio/ui-components';
import { useLocalisedFormatDateTime } from '../../../../utils/datetimeUtils';
import { Form } from '../../../../types';
import TextOr from '../../../TextOr/TextOr';
import MetaDataItem from './components/MetaDataItem';
import FormStatus from '../../../FormStatusBadge';

type FormStepMetadataProps = {
  form: Form | undefined;
};

const FormStepMetadata: React.FC<FormStepMetadataProps> = ({ form }) => {
  const formatDateTime = useLocalisedFormatDateTime();

  return (
    <QBox h="70px" borderColor="gray.200" borderBottomWidth="1px">
      <QFlex h="100%" ml={4} direction="row" align="center">
        <MetaDataItem label="Status">{form ? <FormStatus formStatus={form.status} /> : <TextOr />}</MetaDataItem>
        <MetaDataItem label="Assigned to">
          <TextOr value={form?.owner_full_name} />
        </MetaDataItem>
        <MetaDataItem label="Created">
          <TextOr value={form?.created_at} transform={formatDateTime} />
        </MetaDataItem>
        <MetaDataItem label="Last modified">
          <TextOr value={form?.updated_at} transform={formatDateTime} />
        </MetaDataItem>
        <MetaDataItem label="Approval date">
          <TextOr value={form && form.status === 'approved' ? form.updated_at : undefined} transform={formatDateTime} />
        </MetaDataItem>
      </QFlex>
    </QBox>
  );
};

export default FormStepMetadata;
