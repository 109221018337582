import * as z from 'zod';

export const TemplateFieldDisplayNameMap = {
  root_cause: 'Root cause',
  supplier: 'Related suppliers',
  severity: 'Severity',
  probability: 'Probability',
  product: 'Product',
};

export const EventTemplateFieldAttributeNames = z.enum(['product', 'root_cause', 'risk', 'related_to', 'supplier']);

export const EventTemplateFieldSchema = z.object({
  id: z.number().optional(),
  workflow_id: z.number().optional(),
  attribute_name: EventTemplateFieldAttributeNames,
  mandatory: z.boolean(),
});

export const EventTemplateFieldsSchema = z.array(EventTemplateFieldSchema).optional().nullable();

export type EventTemplateFieldAttributeName = z.infer<typeof EventTemplateFieldAttributeNames>;

export type EventTemplateField = z.infer<typeof EventTemplateFieldSchema>;

export type EventTemplateFieldsMap = Map<EventTemplateFieldAttributeName, EventTemplateField>;

export type EventTemplateFields = z.infer<typeof EventTemplateFieldsSchema>;
