import React from 'react';
import {
  QLookup,
  QModalHeader,
  QTitle,
  QResourceLabel,
  QButton,
  QBox,
  QSpinner,
  SearchDomain,
  SearchIndex,
  QText,
  QReferenceDrawer,
} from '@qualio/ui-components';
import { SubComponentProps } from '../../EventFormField.types';
import { dataViewMap } from './DataView';
import { QObjectFieldType } from '../../../../types/formFields/common';
import { fieldTypeIsQObjectType } from '../../../../utils/eventFormUtils';
import { globalSearchApi } from '../../../../api';
import { QLookupStandardValueObject, StandardValueObject, ViewModeDictionary } from '../../EventFormField.types';
import ReadonlyWrapper from '../ReadOnlyWrapper';
import TextOr from '../../../TextOr/TextOr';
import * as DisplayStrings from '../../../../displayStrings';
import { useQriBulkDetails } from '../../../../hooks';
import { formResolvedQriDisplayText } from './selectedItemText';
import { useFlags } from '../../../../external/LaunchDarklyAdapter';
import { ResourceLabelThatTriggersReferenceDrawer } from '../ResourceLabelToTriggerReferenceDrawer';
import { useReferenceDrawer } from '../../../../hooks/useReferenceDrawer';

type V1SearchableTypes = Exclude<QObjectFieldType, 'registry' | 'design_controls'>;

const resourceMap = {
  event: 'quality-events',
  document: 'documents',
  user: 'users',
  supplier: 'suppliers',
  change_request: 'change-requests',
} as const satisfies Record<V1SearchableTypes, SearchDomain>;

const searchIndexMap = {
  event: 'events',
  document: 'documents',
  user: 'users',
  supplier: 'suppliers',
  change_request: 'change-requests',
} as const satisfies Record<V1SearchableTypes, SearchIndex>;

/**
 * @deprecated The QObjectField component uses quicksearch V1.  Moving forward we should use the
 * QObjectFieldV2 which uses quicksearch V2.
 */
const QObjectField = (props: SubComponentProps) => {
  const { fieldValues, inputType, onChange, fieldId, mode, isEditModalOpen, setIsEditModalOpen } = props;
  const valueObjects = fieldValues as StandardValueObject[];
  const parsedValueObjects = valueObjects.map((valueObject) => {
    return JSON.parse(valueObject.value) as QLookupStandardValueObject;
  });
  const qris = parsedValueObjects.map((value) => value.qri);
  const { data: qrisBulkDetails, isLoading: isQrisDetailsLoading } = useQriBulkDetails(qris);

  const btnOnClick = () => setIsEditModalOpen(true);

  const isQriReferenceDrawerEnabled = useFlags('qriReferenceDrawer');

  const referenceDrawerProps = useReferenceDrawer(isQriReferenceDrawerEnabled);

  if (!fieldTypeIsQObjectType(inputType) || inputType === 'registry' || inputType === 'design_controls') {
    console.log(`Unsupported field type`, inputType);
    return null;
  }

  const view = dataViewMap[inputType] as QLookup.DataView<any>;
  const resourceDomain = resourceMap[inputType];
  const searchEntity = searchIndexMap[inputType];

  const SelectComponent = props.multi ? QLookup.MultiSelect : QLookup.SingleSelect;

  return (
    <>
      {isQriReferenceDrawerEnabled &&
        referenceDrawerProps &&
        referenceDrawerProps.focusedQriDetails &&
        referenceDrawerProps.focusedQri && (
          <QReferenceDrawer
            qriProperties={{
              ...referenceDrawerProps.focusedQriDetails,
              urls: {
                view: referenceDrawerProps.focusedQriDetails.urls.view ?? '',
                data: referenceDrawerProps.focusedQriDetails.urls.data,
              },
            }}
            client={globalSearchApi}
            qri={referenceDrawerProps.focusedQri}
            isOpen={referenceDrawerProps.qriReferenceDrawerOpen}
            onClose={referenceDrawerProps.handleDrawerClose}
            label={referenceDrawerProps.focusedQriDetails.name}
            isLoading={false}
          />
        )}
      {mode === ViewModeDictionary.READONLY && valueObjects.length === 0 && (
        <ReadonlyWrapper>
          <TextOr />
        </ReadonlyWrapper>
      )}
      {mode !== ViewModeDictionary.READONLY && valueObjects.length === 0 && (
        <QButton data-cy={`add-${inputType}-button`} variant="link" onClick={btnOnClick}>
          {DisplayStrings.Add}
        </QButton>
      )}
      <QBox mt={2}>
        {isQrisDetailsLoading ? (
          <QBox w="100%" textAlign="center">
            <QSpinner />
          </QBox>
        ) : (
          parsedValueObjects.map((value: QLookupStandardValueObject) => {
            if (!qrisBulkDetails) {
              return null;
            }
            const qriDetails = qrisBulkDetails[value.qri];
            if (!qriDetails) {
              console.log(`QRI details for ${value.qri} not found`);
              return null;
            }

            if (isQriReferenceDrawerEnabled && referenceDrawerProps && resourceDomain !== 'users') {
              return (
                <ResourceLabelThatTriggersReferenceDrawer
                  key={`q-resource-${value.native_id}`}
                  qriDetails={qriDetails}
                  handleQResourceLabelClick={referenceDrawerProps.handleQResourceLabelClick}
                  resourceDomain={resourceDomain}
                />
              );
            }

            return (
              <QResourceLabel
                key={`q-resource-${value.native_id}`}
                domain={resourceDomain}
                link={resourceDomain === 'users' ? undefined : qriDetails.identifiers.qri}
                openInNewTab={true}
              >
                <QText fontSize="sm">{formResolvedQriDisplayText(qriDetails)}</QText>
              </QResourceLabel>
            );
          })
        )}
      </QBox>
      {isEditModalOpen ? (
        <QLookup.DataProvider.QuickSearch entity={searchEntity} client={globalSearchApi} defaultDataQRIs={qris}>
          <SelectComponent
            accessors={{ id: 'id' }}
            isItemPreSelected={(item) => {
              return !!qris.find((qri) => qri === item.qri);
            }}
            shouldPin={true}
            onSelect={async (selection) => {
              onChange(
                selection.map((selected) => {
                  const existingValue = parsedValueObjects.find((fieldVal) => fieldVal.qri === selected.qri);
                  return {
                    ...existingValue,
                    value: JSON.stringify({
                      native_id: selected.id,
                      qri: selected.qri,
                      domain: selected.domain,
                    }),
                    form_field_id: fieldId,
                  };
                }),
              );
              setIsEditModalOpen(false);
            }}
            onCancel={() => {
              setIsEditModalOpen(false);
            }}
            action={DisplayStrings.ApplyChanges}
            view={view}
            isOpen={isEditModalOpen}
          >
            <QModalHeader>
              <QTitle>{DisplayStrings.Add}</QTitle>
            </QModalHeader>
          </SelectComponent>
        </QLookup.DataProvider.QuickSearch>
      ) : null}
    </>
  );
};

export default QObjectField;
