import React, { useState } from 'react';
import { QBox, QButton, QHeading } from '@qualio/ui-components';
import HelpTextModal from './HelpTextModal';
import NonEditableRichText from '../../NonEditableRichText/NonEditableRichText';
import { useFieldsConfigContext } from './FormFieldConfiguration/shared';

type HelpTextFieldProps = {
  description: string;
  changeHelpText: any;
};

const HelpTextField = ({ description, changeHelpText }: HelpTextFieldProps) => {
  const [ifShowHelpTextModal, setIfShowHelpTextModal] = useState(false);
  const { labels } = useFieldsConfigContext();

  const showHelpTextModal = () => {
    setIfShowHelpTextModal(true);
  };

  return (
    <>
      {description ? (
        <>
          <QBox display="flex" flexDirection="row" justifyContent="space-between">
            <QHeading size="xs">{labels.fieldHeader}</QHeading>
            <QButton leftIcon="Edit3" variant="link" onClick={showHelpTextModal} data-cy="edit-description-button">
              {labels.editButton}
            </QButton>
          </QBox>
          <NonEditableRichText content={description} mode="READONLY" />
        </>
      ) : (
        <QButton variant="link" onClick={showHelpTextModal} data-cy="add-description-button">
          {labels.saveButton}
        </QButton>
      )}
      {ifShowHelpTextModal && (
        <HelpTextModal setIfShow={setIfShowHelpTextModal} description={description} changeHelpText={changeHelpText} />
      )}
    </>
  );
};

export default HelpTextField;
