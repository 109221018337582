import * as z from 'zod';
import {
  CreatedAtSchema,
  HelptextSchema,
  DefaultContentSchema,
  LabelSchema,
  MandatorySchema,
  MultiSchema,
  UpdatedAtSchema,
} from '../common';
import { NumericIdSchema } from '../../id';

/**
 * This schema represents a value of a form field as returned from the server.
 * All form fields are a "values" array even when the field does not support
 * multiple values.
 */
export const StandardFieldValueSchema = z.object({
  id: NumericIdSchema,
  form_field_id: NumericIdSchema,
  value: z.string(),
});
export type StandardFieldValue = z.infer<typeof StandardFieldValueSchema>;

export const BaseSchema = z.object({
  id: NumericIdSchema,
  form_id: NumericIdSchema,
  form_template_field_id: NumericIdSchema,
  mandatory: MandatorySchema,
  order: z.number(),
  label: LabelSchema,
  helptext: HelptextSchema,
  default_content: DefaultContentSchema,
  multi: MultiSchema,
  event_type_code: z.string().nullable(),
  created_at: CreatedAtSchema,
  updated_at: UpdatedAtSchema,
  resource_sub_type: z.string().nullable().optional(),
});
