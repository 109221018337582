import Element from '@ckeditor/ckeditor5-engine/src/model/element';
import Writer from '@ckeditor/ckeditor5-engine/src/model/writer';

export const removeContentBeforeSectionHeading = (writer: Writer) => {
  const { document } = writer.model;
  const root = document.getRoot('main');
  let hasModifiedContent = false;
  if (root) {
    // check if there are two section headings without any content in between
    for (const child of root.getChildren()) {
      const element = child as Element;
      if (element.name === 'sectionHeading') {
        let focusedElement = element.previousSibling;
        while (focusedElement) {
          hasModifiedContent = true;
          writer.remove(focusedElement);
          focusedElement = focusedElement.previousSibling;
        }
        return hasModifiedContent;
      }
    }
  }
  return false;
};
