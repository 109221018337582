import React from 'react';
import * as z from 'zod';
import {
  QBox,
  QButton,
  QCloseButton,
  QDivider,
  QFormControl,
  QInput,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QStack,
  QText,
  QTextarea,
  useCurrentUser,
} from '@qualio/ui-components';
import { UseMutateFunction } from 'react-query';
import * as DisplayStrings from '../../../displayStrings';
import { UpdateForm } from '../../../types';
import { FormApprovalMutateType } from '../../../hooks';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, FormProvider, useForm } from 'react-hook-form';

type ApproveDeclineModalProps = {
  isOpen: boolean;
  onSubmit: UseMutateFunction<UpdateForm, unknown, FormApprovalMutateType, unknown>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  action: string;
  isSubmitBtnLoading: boolean;
};

const ApproveDeclineBaseSchema = z.object({
  email: z.string().trim().min(1, { message: 'Required' }),
  password: z.string().trim().min(1, { message: 'Required' }),
});
const ApproveSchema = ApproveDeclineBaseSchema.extend({ comment: z.string().optional() });
const DeclineSchema = ApproveDeclineBaseSchema.extend({ comment: z.string().trim().min(1, { message: 'Required' }) });

const ApproveDeclineModal = ({ isOpen, onSubmit, setIsOpen, action, isSubmitBtnLoading }: ApproveDeclineModalProps) => {
  const { email: currentUserEmail } = useCurrentUser();
  const isApproveAction = action === 'Approve';
  const isDeclineAction = action === 'Decline';

  const FormSchema = isApproveAction ? ApproveSchema : DeclineSchema;
  type FormType = z.infer<typeof FormSchema>;

  const formMethods = useForm<FormType>({
    mode: 'onSubmit',
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: currentUserEmail,
    },
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = formMethods;

  const formHasErrors = !!Object.keys(errors).length;

  const handleClose = () => {
    setIsOpen(false);
    reset();
  };

  const handleFormSubmit = (data: FormType) => {
    onSubmit({ password: data.password, comment: data.comment || '', isApprove: isApproveAction });
  };

  return (
    <QModal isOpen={isOpen} onClose={handleClose} size="md">
      <QModalHeader>
        <QText>{action} step</QText>
        <QCloseButton onClick={handleClose}></QCloseButton>
      </QModalHeader>
      <QModalBody>
        <QDivider />
        <QBox pt={4} pb={4}>
          <QText>Enter your digital signature to {action.toLowerCase()} the step</QText>
        </QBox>

        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <QStack direction="column" spacing={4}>
              <QFormControl label="Email address" error={errors.email?.message} isInvalid={!!errors.email} isRequired>
                <Controller
                  control={control}
                  name="email"
                  render={({ field: { ref: _ref, ...field } }) => (
                    <QInput {...field} data-cy="username-input" placeholder="Email" autoComplete="username" />
                  )}
                />
              </QFormControl>
              <QFormControl label="Password" error={errors.password?.message} isInvalid={!!errors.password} isRequired>
                <Controller
                  control={control}
                  name="password"
                  render={({ field: { ref: _ref, ...field } }) => (
                    <QInput
                      type="password"
                      {...field}
                      data-cy="password-input"
                      aria-label="password"
                      placeholder="Password"
                      autoComplete="current-password"
                    />
                  )}
                />
              </QFormControl>
              <QFormControl
                label={`Comment (${isApproveAction ? 'Optional' : 'Required'})`}
                error={errors.comment?.message}
                isInvalid={isDeclineAction && !!errors.comment}
                isRequired={isDeclineAction}
              >
                <Controller
                  control={control}
                  name="comment"
                  render={({ field: { ref: _ref, ...field } }) => (
                    <QTextarea
                      {...field}
                      data-cy="comment-input"
                      aria-label="comment"
                      placeholder="Add an optional comment..."
                    />
                  )}
                />
              </QFormControl>
            </QStack>
          </form>
        </FormProvider>
      </QModalBody>
      <QModalActions>
        <QButton onClick={handleClose} variant="outline">
          {DisplayStrings.Cancel}
        </QButton>
        <QButton
          isDestructive={isDeclineAction}
          isLoading={isSubmitBtnLoading}
          isDisabled={formHasErrors}
          onClick={handleSubmit(handleFormSubmit)}
          data-cy="submit-signature-modal"
        >
          {action}
        </QButton>
      </QModalActions>
    </QModal>
  );
};

export default ApproveDeclineModal;
