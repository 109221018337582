import React from 'react';
import { QBox, QCenter, QStack, QText } from '@qualio/ui-components';

type Props = {
  text: string;
  children: React.ReactNode;
};

const EmptyStep: React.FC<Props> = ({ text, children }) => {
  return (
    <QCenter>
      <QBox
        w="640px"
        h="90px"
        p={6}
        mt={6}
        display="flex"
        flexDirection="column"
        textAlign="center"
        borderWidth="2px"
        borderColor="gray.200"
        borderStyle="dashed"
        boxSizing="content-box"
      >
        <QBox mt={4}>
          <QText fontSize="sm" color="gray.500">
            {text}
          </QText>
        </QBox>
        <QCenter>
          <QStack direction="row" mt={6}>
            {children}
          </QStack>
        </QCenter>
      </QBox>
    </QCenter>
  );
};

export default EmptyStep;
