import * as z from 'zod';

export const RelationDirection = z.enum(['left', 'right']);
export type RelationDirection = z.infer<typeof RelationDirection>;

export const Relation = z.object({
  entity_type: z.string().min(1),
  id: z.number(),
  label: z.string(),
  relation_direction: RelationDirection,
  title: z.string(),
  uuid: z.string(),
});

export type Relation = z.infer<typeof Relation>;
