import React, { useState } from 'react';
import { QBox, QButton, QCenter, QEmptyState, QStack, QBadge } from '@qualio/ui-components';
import { useNavigate } from 'react-router-dom';
import FieldContainer from './components/FieldContainer';
import { CardList } from '../../components';
import { DisplayNames, FormEditorForm, FormEditorFormSchema, AddField } from './shared';
import FormTemplateEditorHeader from './components/FromTemplateEditorHeader';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import PreviewFormTemplate from './components/PreviewFormTemplate';
import FormFieldConfiguration from './components/FormFieldConfiguration/FormFieldConfiguration';
import { FormTemplateField, NewFormTemplateField } from '../../types/formFields/formTemplates';
import { FieldType } from '../../types/formFields/common';
import * as DisplayStrings from '../../displayStrings';
import { generateNewFormField } from '../../utils/eventFormUtils';
import AddFieldButtons from './components/AddFieldButtons/AddFieldButtons';
import { RegistryCategory } from '../../types/registry';

const formFieldCustomHeaders: { [key in FieldType]?: React.ReactNode } = {
  section: <QBadge>{DisplayNames.section}</QBadge>,
};

type FormTemplateEditorProps = {
  formStepName: string;
  formTemplateId: number;
  eventTemplateId: number;
  fieldsArray: FormTemplateField[];
  onSave: (fields: (FormTemplateField | NewFormTemplateField)[]) => void;
  /**
   * List of registry categories to show in the QObjectField add field button
   */
  registryCategories: RegistryCategory[];
};

const FormTemplateEditor = (props: FormTemplateEditorProps) => {
  const { formStepName, formTemplateId, eventTemplateId, fieldsArray, registryCategories } = props;
  const navigate = useNavigate();

  const goToTemplateListPage = () => {
    navigate(`/templates/${eventTemplateId}`);
  };

  const formMethods = useForm<FormEditorForm>({
    mode: 'onChange',
    resolver: zodResolver(FormEditorFormSchema),
    defaultValues: {
      fields: fieldsArray,
    },
  });
  const { formState, handleSubmit, control } = formMethods;
  const { fields, append, remove, move } = useFieldArray({ control, name: 'fields', keyName: 'uniqueId' });
  const { isDirty, isValid } = formState;

  const addNewFormField = ((fieldType: FieldType, resourceSubtype?: string) => {
    append(generateNewFormField(fieldType, formTemplateId, fields.length, resourceSubtype));
  }) satisfies AddField;
  const [showPreview, setShowPreview] = useState(false);

  const onSubmit = handleSubmit((data) => props.onSave(data.fields));

  return (
    <FormProvider {...formMethods}>
      <FormTemplateEditorHeader
        onClose={goToTemplateListPage}
        pageHeaderLabel={fields.length === 0 ? 'Start building your form' : 'Edit form'}
        formStepName={formStepName}
      />
      <QCenter backgroundColor="gray.50" paddingY="2" paddingX="0" marginX="-6">
        <QBox w="container.md" display="flex" flexDirection="column" margin="8">
          <QStack direction="column" spacing="24px">
            {fields.length === 0 ? (
              <FieldContainer>
                <QEmptyState
                  title={'No form element added yet'}
                  subtitle={'Add a form element to start building your form'}
                />
              </FieldContainer>
            ) : (
              <CardList
                items={fields}
                getCardTitle={(item) => {
                  if (item.type === 'registry') {
                    const registryCategory = registryCategories.find(
                      (category) => category.registry_category_matrix_id === item.resource_sub_type,
                    );

                    // Not sure if this is what we want to do here, but this will at least show "Registry" if the category is not found
                    return registryCategory ? registryCategory.name : DisplayNames[item.type];
                  } else {
                    return formFieldCustomHeaders[item.type] ?? DisplayNames[item.type];
                  }
                }}
                removeItem={(_item, index) => remove(index)}
                moveItem={(_item, index, newIndex) => move(index, newIndex)}
                renderItem={(item, index) => <FormFieldConfiguration fieldIndex={index} fieldType={item.type} />}
              />
            )}

            <AddFieldButtons registryCategories={registryCategories} addField={addNewFormField} />
          </QStack>
        </QBox>
      </QCenter>
      <QStack direction={['column', 'row']} paddingX={6} pt={6} display="flex" gap={'14px'} justifyContent="flex-end">
        <QButton isDisabled={!isValid} variant={'outline'} data-cy="preview-form" onClick={() => setShowPreview(true)}>
          {DisplayStrings.Preview}
        </QButton>
        <QButton isDisabled={!isDirty || !isValid} data-cy="save-form" onClick={onSubmit}>
          {DisplayStrings.Save}
        </QButton>
      </QStack>
      {showPreview && <PreviewFormTemplate title={formStepName} onClose={() => setShowPreview(false)} />}
    </FormProvider>
  );
};

export default FormTemplateEditor;
