import { Document } from '../types';
import { medtechApi } from './index';

const documentsApi = {
  async getDocumentDetails(documentId: number): Promise<Document | undefined> {
    const response = await medtechApi.get(`/documents/${documentId}`);
    const parsed = Document.safeParse(response.data);
    if (parsed.success) {
      return parsed.data;
    } else {
      console.log('Document response parsing error', parsed.error);
    }
  },
};
export default documentsApi;
