import React, { useMemo } from 'react';
import { QBadge, QBox, QFlex, QHeading, QText, QPersonProps, QStack } from '@qualio/ui-components';
import { Link } from 'react-router-dom';
import { ViewModeDictionary } from '../../../components/EventFormField/EventFormField.types';
import EventFormField from '../../../components/EventFormField/EventFormField';
import { Approver, ApproverType, Form, FormCommentSchema, QualityEvent } from '../../../types';
import { DateTimeFormat, useLocalisedFormatDateTime } from '../../../utils/datetimeUtils';
import EventFormComments from './EventFormComments';
import { createPerson, createSection } from '../../../utils/eventFormPropertiesUtil';

type EventFormDetailsProps = {
  form: Form;
  event: QualityEvent;
  reviewers: Approver[];
  approvers: Approver[];
};

const EventFormDetails = ({ form, event, reviewers, approvers }: EventFormDetailsProps) => {
  const formatDateTime = useLocalisedFormatDateTime();

  const createApprover = (approverType: ApproverType, approver: Approver, formNotInDraft: boolean) => {
    const personParams: QPersonProps = { fullName: approver.full_name, status: 'default' };
    const completeStatus = approverType === 'approvers' ? 'approved' : 'reviewed';
    if (formNotInDraft) {
      personParams['status'] = 'pending';
      personParams['showPendingStatus'] = true;
    }
    if (approver.action_status === 'approved') {
      personParams['status'] = completeStatus;
    }
    if (approver.action_status === 'declined') {
      personParams['status'] = 'declined';
    }
    if (approver.action_timestamp) {
      personParams['actionTimestamp'] = formatDateTime(approver.action_timestamp, DateTimeFormat.APPROVER);
    }

    return createPerson(personParams);
  };
  const createApprovers = (approverType: ApproverType, approvers: Approver[], formNotInDraft: boolean) => {
    return approvers.length > 0 ? (
      approvers.map((approver) => createApprover(approverType, approver, formNotInDraft))
    ) : (
      <QText>-</QText>
    );
  };

  const comments = useMemo(() => {
    const reviewer_comments = reviewers.map((reviewer) => {
      return {
        ...reviewer,
        action_status: reviewer.action_status === 'approved' ? 'reviewed' : reviewer.action_status,
      };
    });
    const approversAndReviewers = [...approvers, ...reviewer_comments];
    return approversAndReviewers
      .filter((approver) => approver.action_timestamp && approver.approval_comment)
      .sort((approver1, approver2) => approver2.action_timestamp! - approver1.action_timestamp!)
      .map((approver) =>
        FormCommentSchema.parse({
          commentTimeStamp: formatDateTime(approver.action_timestamp!, DateTimeFormat.APPROVER),
          userFullName: approver.full_name,
          status: approver.action_status,
          comment: approver.approval_comment,
        }),
      );
  }, [approvers, reviewers, formatDateTime]);

  return (
    <QFlex
      data-cy="form-details-properties-section"
      padding={6}
      justifyContent="center"
      alignItems="flex-start"
      gap={10}
    >
      <QBox width="640px">
        <QStack direction="column" spacing="40px">
          {form.fields.map((field) => {
            return (
              <EventFormField
                key={field.id}
                inputType={field.type}
                label={field.label}
                helper={field.helptext ?? undefined}
                isRequired={field.mandatory}
                mode={ViewModeDictionary.READONLY}
                fieldId={field.id}
                resourceSubType={field.resource_sub_type}
                // TODO: fieldValues needs updated to allow for new value types
                fieldValues={(field.values ?? []) as any}
                multi={field.multi}
              />
            );
          })}
        </QStack>
        {comments.length > 0 && <EventFormComments comments={comments} />}
      </QBox>
      <QBox width="280px">
        <QBox pb={6}>
          <QHeading size={'sm'} color="gray.700">
            Properties
          </QHeading>
        </QBox>
        {createSection(
          'Event title',
          <Link to={`/events/${event.id}`}>
            <QText>{event.title}</QText>
          </Link>,
        )}
        {createSection('Event ID', <QBadge color="gray.500">{event.code}</QBadge>)}
        {createSection('Assigned to', createPerson({ fullName: form.owner_full_name!, status: 'default' }))}
        {createSection('Reviewers', createApprovers('reviewers', reviewers, form.status === 'for_review'))}
        {createSection('Approvers', createApprovers('approvers', approvers, form.status === 'for_approval'))}
      </QBox>
    </QFlex>
  );
};

export default EventFormDetails;
