import { useQuery } from 'react-query';
import { useToastProvider } from '@qualio/ui-components';
import formTemplateApi from '../api/formTemplate.api';
import { DefaultErrorMessage } from '../displayStrings';

export const baseFormTemplateQueryKey = 'formTemplateDetails';
export const useFormTemplateDetails = (
  companyId: number,
  eventTemplateId: number,
  stepId: number | undefined,
  formTemplateId: number,
  enabled = true,
) => {
  const { showToast } = useToastProvider();

  return useQuery(
    [baseFormTemplateQueryKey, companyId, eventTemplateId, formTemplateId],
    () => formTemplateApi.getFormTemplate(companyId, eventTemplateId, stepId!, formTemplateId),
    {
      enabled: enabled && !!stepId,
      onError: () => {
        showToast({
          title: 'Failed to fetch form template details',
          description: DefaultErrorMessage,
          status: 'error',
        });
      },
    },
  );
};
