import { medtechApi } from './index';
import {
  RegistryCategoriesResponseSchema,
  RegistryItemsResponseSchema,
  RegistryItemsResponseType,
} from '../types/registry';

const registryApi = {
  async getRegistryCategories(companyId: number) {
    const response = await medtechApi.get(`/v2/${companyId}/data-registry/categories`, {
      params: { status: 'effective', offset: 0, limit: 100 },
    });

    return RegistryCategoriesResponseSchema.parse(response.data);
  },

  async getRegistryItemsByCategory(companyId: number, categoryId: string): Promise<RegistryItemsResponseType> {
    const response = await medtechApi.get(`/v2/${companyId}/data-registry/${categoryId}/items`);

    return RegistryItemsResponseSchema.parse(response.data);
  },
};

export default registryApi;
