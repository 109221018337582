import React from 'react';
import { QBox, QDivider, QRadio, QRadioGroup, QSelect, QText } from '@qualio/ui-components';
import { Operations, Resolution } from '../../../../types';
import { QSelectItem } from '@qualio/ui-components/lib/QAtoms/Select/types';
import * as DisplayStrings from '../../../../displayStrings';

export type SectionResolutionModalBodyProps = {
  resolutions: Resolution[];
  updateOperation: (operation: Operations, index: number, resolutionsInput: Resolution[]) => void;
  updateRecipientSectionIndex: (recipientSectionIndex: number, index: number, resolutionsInput: Resolution[]) => void;
  selectOptions: QSelectItem[];
};

const SectionResolutionModalBody = (props: SectionResolutionModalBodyProps) => {
  return (
    <>
      <QDivider orientation="horizontal" />
      {props.resolutions.map((resolution, index) => (
        <QBox mt="2" key={resolution.section_id}>
          <QText>{DisplayStrings.ResolveSection(resolution.section_title)}</QText>
          <QBox mb="1" display={'flex'} flexDirection="row" justifyContent="space-between">
            <QRadioGroup
              onChange={(val) => {
                props.updateOperation(Operations[val as unknown as Operations], index, props.resolutions);
              }}
            >
              <QRadio value={'prepend'} data-cy={'prependRadio' + index}>
                Insert before
              </QRadio>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <QRadio value={'append'} data-cy={'appendRadio' + index}>
                Insert after
              </QRadio>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <QRadio value={'discard'} data-cy={'discardRadio' + index}>
                Discard
              </QRadio>
            </QRadioGroup>
            {resolution.operation !== Operations.discard && (
              <>
                <QText>In section:&nbsp;</QText>
                <QBox width="25%">
                  <QSelect
                    isLoading={false}
                    isSearchable={false}
                    isDisabled={false}
                    value={resolution.recipient_section_index.toString()}
                    onChange={(changeObject) => {
                      if (changeObject) {
                        props.updateRecipientSectionIndex(Number(changeObject.value), index, props.resolutions);
                      }
                    }}
                    options={props.selectOptions}
                    data-cy={'selectSection' + index}
                    filterOption={null}
                  />
                </QBox>
              </>
            )}
          </QBox>
          <QDivider orientation="horizontal" />
        </QBox>
      ))}
    </>
  );
};

export default SectionResolutionModalBody;
