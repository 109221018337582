import { EventMetadataType } from '../types/eventMetadata';
import api from './index';

const eventMetadataApi = {
  async getMetadata(company: number): Promise<EventMetadataType> {
    const res = await api.get(`/company/${company}/workflowsmetadata`);
    return res.data.data as EventMetadataType;
  },
};

export default eventMetadataApi;
