import React from 'react';
import { QBox, QBoxProps } from '@qualio/ui-components';
import EditorContentViewer, { EditorViewModes } from '../shared/EditorContentViewer/EditorContentViewer';

type ViewMode = Exclude<EditorViewModes, 'FOCUSED'>;
type ReadonlyProps = {
  content: string;
  mode: ViewMode;
  textColor?: QBoxProps['textColor'];
};

const ReadonlyStyle = {} as const;
const DormantStyle = {
  minHeight: '120px',
  borderColor: 'blue.200',
  borderWidth: '1px',
  borderRadius: 'base',
  backgroundColor: 'white',
  cursor: 'pointer',
} as const;

const NonEditableRichText = (props: ReadonlyProps) => {
  const { content, mode, textColor } = props;
  const stylingProps = mode === 'READONLY' ? ReadonlyStyle : DormantStyle;

  return (
    <QBox {...stylingProps} backgroundColor="white" textColor={textColor}>
      <EditorContentViewer content={content} mode={mode} />
    </QBox>
  );
};

export default NonEditableRichText;
