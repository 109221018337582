import { useContext } from 'react';
import { FieldError, FieldErrors } from 'react-hook-form';
import { FormEditorForm } from '../../shared';
import { FieldType } from '../../../../types/formFields/common';
import { FormConfigurationContext, FieldConfigurationContextType } from './components/FieldConfigurationWrapper';

const getErrorMessage = (fieldError: FieldError, defaultMessage = 'Unknown issue with field') => {
  return fieldError.message ?? defaultMessage;
};

type ErrorMessageObject = {
  label?: string;
  helptext?: string;
};

export const getFieldErrors = (
  fieldErrorArray: FieldErrors<FormEditorForm>['fields'],
  fieldIndex: number,
): ErrorMessageObject => {
  const fieldErrors: ErrorMessageObject = {
    label: undefined,
    helptext: undefined,
  };

  if (!fieldErrorArray) {
    return fieldErrors;
  }

  const errorForField = fieldErrorArray[fieldIndex];

  if (!errorForField) {
    return fieldErrors;
  }

  if (errorForField.label) {
    fieldErrors.label = getErrorMessage(errorForField.label);
  }

  if (errorForField.helptext) {
    fieldErrors.helptext = getErrorMessage(errorForField.helptext);
  }

  return fieldErrors;
};

export type FfcProps = {
  fieldIndex: number;
  fieldType: FieldType;
};

export type FfcChildProps = Pick<FfcProps, 'fieldIndex'>;

export const useFieldsConfigContext = (): FieldConfigurationContextType => {
  const context = useContext(FormConfigurationContext);
  if (!context) {
    throw new Error('useFieldsConfigContext can only be used inside FieldConfigurationWrapper');
  }
  return context;
};
