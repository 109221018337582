import axios, { AxiosRequestConfig } from 'axios';
import querystring from 'query-string';

export const standardHeaders = {
  'Cache-Control': 'no-store, no-cache, must-revalidate',
  'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
  Pragma: 'no-cache',
};

const api = axios.create({
  headers: standardHeaders,
  baseURL: `${process.env.REACT_APP_API_URL}`,
  withCredentials: true,
});

const applicationJson = 'application/json';

export const globalSearchApi = axios.create({
  headers: {
    'Content-Type': applicationJson,
  },
  baseURL: `${process.env.REACT_APP_GLOBAL_SEARCH_SERVICE_URL}`,
  withCredentials: true,
});

export const medtechApi = axios.create({
  headers: {
    ...standardHeaders,
    Accept: applicationJson,
    'Content-Type': applicationJson,
  },
  baseURL: `${process.env.REACT_APP_MEDTECH_BACKEND_API_URL}`,
  withCredentials: true,
});

export const medtechFileUploadApi = axios.create({
  headers: standardHeaders,
  baseURL: `${process.env.REACT_APP_MEDTECH_BACKEND_API_URL}`,
  withCredentials: true,
});

export const qriApi = axios.create({
  headers: {
    'Content-Type': applicationJson,
  },
  baseURL: `${process.env.REACT_APP_QRI_SERVICE_URL}`,
  withCredentials: true,
});

export const aiApi = axios.create({
  baseURL: `${process.env.REACT_APP_AI_SERVICE_URL}`,
  withCredentials: true,
});

export const createRequestConfig = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const defaults = {
    paramsSerializer: (params: any) => {
      return querystring.stringify(params);
    },
  };

  return { ...config, ...defaults };
};

export default api;
