import React, { useCallback, useMemo } from 'react';
import { StandardValueObject, SubComponentProps, ViewModeDictionary } from '../EventFormField.types';
import { QAttachments, QAttachment, QLink, QText } from '@qualio/ui-components';
import { attachmentUploadInfoProvider } from '../../../api/attachments.api';
import { generateAttachmentUrl } from '../../../utils/attachmentUtils';
import ReadonlyWrapper from './ReadOnlyWrapper';
import { v4 } from 'uuid';

const AttachmentField = (props: SubComponentProps) => {
  const { mode, onChange, fieldId } = props;

  // TODO:  QEV-2813
  const fieldValues = props.fieldValues as StandardValueObject[];

  const parsedAttachments = useMemo(
    () => fieldValues.map(({ value }) => JSON.parse(value) as QAttachment),
    [fieldValues],
  );

  const removeAttachment = useCallback(
    (fileId: string) => {
      const newAttachments = parsedAttachments
        .filter(({ id }) => id !== fileId)
        .map((attachment) => ({ value: JSON.stringify(attachment), form_field_id: fieldId }));

      onChange(newAttachments);

      return Promise.resolve();
    },
    [onChange, parsedAttachments, fieldId],
  );

  if (mode === ViewModeDictionary.READONLY) {
    return (
      <ReadonlyWrapper>
        {fieldValues.length ? (
          parsedAttachments.map((attachment) => (
            <p key={attachment.id ?? v4()}>
              <QLink href={generateAttachmentUrl(attachment.id)} isExternal={true}>
                {attachment.filename}
              </QLink>
            </p>
          ))
        ) : (
          <QText fontSize="sm">--</QText>
        )}
      </ReadonlyWrapper>
    );
  }

  return (
    <QAttachments
      attachments={parsedAttachments}
      uploadInfoProvider={attachmentUploadInfoProvider}
      onAdd={(attachment: QAttachment) => {
        onChange([...fieldValues, { value: JSON.stringify(attachment), form_field_id: fieldId }]);
        return Promise.resolve();
      }}
      onClick={(attachmentId: string) => {
        const attachmentUrl = generateAttachmentUrl(attachmentId);
        window.open(attachmentUrl);
        return Promise.resolve();
      }}
      onRemove={removeAttachment}
      onRevert={removeAttachment}
      maxFiles={10}
      maxFileSize="100MB"
      maxTotalFileSize="1000MB"
      disabled={mode !== ViewModeDictionary.INTERACTIVE}
      data-cy={'attachments-upload'}
    />
  );
};

export default AttachmentField;
