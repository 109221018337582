import React, { PropsWithChildren } from 'react';
import { QueryClient } from 'react-query/core';
import { QueryClientProvider } from 'react-query/react';

export const QeFeQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const QeFeQueryClientProvider = ({ children }: PropsWithChildren<Record<never, never>>) => {
  return <QueryClientProvider client={QeFeQueryClient}>{children}</QueryClientProvider>;
};
