import React from 'react';
import { QBox } from '@qualio/ui-components';

type StepContainerProps = {
  children?: React.ReactNode;
};

const StepContainer: React.FC<StepContainerProps> = ({ children }) => {
  return (
    <QBox borderColor="gray.200" borderWidth="1px" borderRadius="base">
      {children}
    </QBox>
  );
};

export default StepContainer;
