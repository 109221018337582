export enum Operations {
  notChosen = 'notChosen',
  prepend = 'prepend',
  append = 'append',
  discard = 'discard',
}

export type Resolution = {
  section_id: number;
  section_title: string;
  operation: Operations;
  recipient_section_index: number;
};
