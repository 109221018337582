import * as z from 'zod';

export const DigitalSignature = z.object({
  email: z.string(),
  password: z.string(),
  comment: z.string(),
});

export type DigitalSignature = z.infer<typeof DigitalSignature>;

// Temporary while we are working on qeOnepasswordTesting is in progress
export const DigitalSignatureV2Schema = z.object({
  email: z.string().min(1, { message: 'Required' }),
  password: z.string().min(1, { message: 'Required' }),
  comment: z.string(),
});
export type DigitalSignatureV2 = z.infer<typeof DigitalSignatureV2Schema>;

export const DigitalSignatureV2CommentRequiredSchema = DigitalSignatureV2Schema.extend({
  comment: z.string().min(1, { message: 'Required' }),
});
export type DigitalSignatureV2CommentRequired = z.infer<typeof DigitalSignatureV2CommentRequiredSchema>;

export const DigitalSignatureInput = z.object({
  headingText: z.string(),
  subText: z.string(),
  submitButtonText: z.string(),
  successToastHeader: z.string(),
  successToastDescription: z.string(),
});

export type DigitalSignatureInput = z.infer<typeof DigitalSignatureInput>;
