import { useCurrentUser, useToastProvider } from '@qualio/ui-components';
import usersApi from '../api/users.api';
import { useQuery } from 'react-query';
import * as DisplayStrings from '../displayStrings';

export const useUserRole = () => {
  const { userId, companyId } = useCurrentUser();
  const { showToast } = useToastProvider();
  const queryResult = useQuery(['userRole', userId, companyId], () => usersApi.getUserGroup(userId, companyId), {
    onError: () => {
      showToast({
        title: DisplayStrings.CurrentCompanyRoleError,
        status: 'error',
      });
    },
  });

  return queryResult.data;
};
