import { ContentTemplate, ContentTemplateSave, ContentTemplateUpdate } from '../types';
import { medtechApi } from './index';

const contentTemplateApi = {
  async getContentTemplateDetails(contentTemplateId: number): Promise<ContentTemplate> {
    const response = await medtechApi.get(`/document_templates/${contentTemplateId}`);

    return ContentTemplate.parse(response.data);
  },

  async updateContentTemplate(
    companyId: number,
    contentTemplateId: string | undefined,
    contentTemplate: ContentTemplateSave,
  ): Promise<ContentTemplateUpdate> {
    const response = await medtechApi.patch(
      `/v2/${companyId}/document_templates/${contentTemplateId}`,
      contentTemplate,
    );
    return ContentTemplateUpdate.parse(response.data);
  },
};

export default contentTemplateApi;
