import * as z from 'zod';

export const ProbabilitySchema = z.object({
  id: z.string(),
  name: z.string(),
  value: z.number(),
  order: z.number(),
});

export const SeveritySchema = ProbabilitySchema;

export const RiskSchema = z.object({
  id: z.string(),
  name: z.string(),
  range_start: z.number(),
  range_end: z.number(),
  order: z.number(),
});

export const EventMetadataSchema = z.object({
  id: z.string().optional(),
  probabilities: z.array(ProbabilitySchema).optional(),
  severities: z.array(SeveritySchema).optional(),
  risks: z.array(RiskSchema).optional(),
});

export type ProbabilityType = z.infer<typeof ProbabilitySchema>;
export type SeverityType = z.infer<typeof SeveritySchema>;
export type RiskType = z.infer<typeof RiskSchema>;
export type EventMetadataType = z.infer<typeof EventMetadataSchema>;
