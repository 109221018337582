import React from 'react';

/**
 * Prevents propagation of click events to the parent component.
 *
 * Why use this? Example: It allows us to have clickable table rows with
 * other buttons in them that won't trigger the table row's onClick event
 * when the buttons inside it are clicked (given that those buttons are
 * wrapped in this component).
 *
 * Blatantly stolen from training plans
 */
const ClickPropagationBarrier: React.FC<React.HTMLProps<HTMLSpanElement>> = ({ children, ...rest }) => (
  <span {...rest} onClick={(e) => e.stopPropagation()}>
    {children}
  </span>
);

export default ClickPropagationBarrier;
