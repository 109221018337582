import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';

import { zodResolver } from '@hookform/resolvers/zod';
import {
  QBox,
  QButton,
  QCloseButton,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QSpinner,
  QStack,
  QText,
  useCurrentUser,
  useToastProvider,
} from '@qualio/ui-components';

import eventTypesApi from '../../api/eventTypes.api';
import templatesApi from '../../api/templates.api';
import { EventTemplateField } from '../../types';
import { ProbabilityType, RiskType, SeverityType } from '../../types/eventMetadata';
import { EventTag } from '../../types/eventTag';
import { Product } from '../../types/product';
import { QualityEvent, QualityEventCreate } from '../../types/qualityEvent';
import { RootCause } from '../../types/rootCause';
import { User } from '../../types/user';
import EventForm from './components/EventForm';
import { DefaultErrorMessage } from '../../displayStrings';
import { ModalForm, ModalFormSchema, processFormValuesForSubmit } from './formHelpers';

type EscalateEventModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSave: (payload: QualityEventCreate) => Promise<QualityEvent>;
  event: QualityEvent;
  selectsData: {
    eventTags: EventTag[];
    probabilities: ProbabilityType[];
    severities: SeverityType[];
    risks: RiskType[];
    users: User[];
    products: Product[];
    rootCauses: RootCause[];
  };
};

const EscalateEventModal: React.FC<EscalateEventModalProps> = ({ isOpen, setIsOpen, onSave, event, selectsData }) => {
  const { showToast } = useToastProvider();
  const { userId, companyId } = useCurrentUser();
  const { isLoading, data } = useQuery('eventTypesList', () => eventTypesApi.getAllEventTypes(companyId));
  const [optionalFields, setOptionalFields] = useState<EventTemplateField[]>([]);

  const formMethods = useForm<ModalForm>({
    mode: 'onSubmit',
    resolver: zodResolver(ModalFormSchema),
    defaultValues: {
      title: '',
      workflow_id: '',
      owner_id: '',
      description: '',
      time_limit: '',
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful, isSubmitting },
    watch,
  } = formMethods;

  const eventType = watch('workflow_id');

  useEffect(() => {
    if (eventType) {
      const getOptionalFields = async () => {
        const templateDetails = await templatesApi.getEventTemplateDetails(companyId, parseInt(eventType, 10));
        if (!templateDetails) {
          return;
        }
        setOptionalFields(templateDetails.fields);
      };
      getOptionalFields().catch(console.error);
    }
  }, [eventType, companyId]);

  /**
   * Normally against using an effect to watch state, but this is the react hook form official
   * guidance for resetting on submit:
   * https://react-hook-form.com/docs/useform/reset
   */
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const formHasErrors = !!Object.keys(errors).length;

  const handleClose = () => {
    setIsOpen(false);
    reset();
  };

  const createNewEvent = useCallback(
    async (data: ModalForm) => {
      const processedData = processFormValuesForSubmit(data);

      try {
        const optionalFieldValues = optionalFields.reduce((values, field) => {
          switch (field.attribute_name) {
            case 'risk':
              if (event.probability_id) {
                return { ...values, probability_id: String(event.probability_id) };
              }
              break;
            case 'product':
              if (event.product_id) {
                return { ...values, product_id: String(event.product_id) };
              }
              break;
            case 'root_cause':
              if (event.rootcause_id) {
                return { ...values, rootcause_id: String(event.rootcause_id) };
              }
              break;
            case 'supplier':
              return { ...values, suppliers: event.suppliers };
              break;
            default:
              break;
          }
          return values;
        }, {});
        const newEventPayload: QualityEventCreate = {
          ...processedData,
          ...optionalFieldValues,
          related_documents: event.related_documents,
          tags_ids: event.tags_ids.map(String),
          escalated_from: [event.id],
        };
        const newEvent = await onSave(newEventPayload);
        setIsOpen(false);
        showToast({
          title: 'New event created',
          description: `"${newEvent.code} | ${newEvent.title}" was created`,
          status: 'success',
        });
      } catch (err: any) {
        showToast({
          title: 'Event not created',
          description: DefaultErrorMessage,
          status: 'error',
        });
      }
    },
    [onSave, showToast, setIsOpen, event, optionalFields],
  );
  const submitFn = handleSubmit(createNewEvent);

  return (
    <QModal isOpen={isOpen} onClose={() => null} size="xl">
      <QModalHeader>
        <QText>Escalate and create new event</QText>
        <QCloseButton onClick={handleClose} data-metrics="escalate-event-close-modal-button"></QCloseButton>
      </QModalHeader>

      <QModalBody>
        <QStack direction="column" color="gray.700" spacing={4}>
          {isLoading ? (
            <QBox w="100%" textAlign="center">
              <QSpinner />
            </QBox>
          ) : (
            <EventForm
              formMethods={formMethods}
              onSubmit={submitFn}
              data={{ ...selectsData, eventTypes: data ? data : [] }}
              event={event}
              userId={userId}
              optionalFields={optionalFields}
            />
          )}
        </QStack>
      </QModalBody>

      <QModalActions>
        <QButton onClick={handleClose} variant="outline" data-testid="cancel">
          Cancel
        </QButton>
        <QButton
          onClick={submitFn}
          isDisabled={formHasErrors || isSubmitting}
          data-metrics="escalate-event-button"
          data-testid="escalate-event"
        >
          Escalate and create new event
        </QButton>
      </QModalActions>
    </QModal>
  );
};

export default EscalateEventModal;
