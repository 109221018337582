import { useCallback, useState } from 'react';
import * as R from 'ramda';

/**
 * This hook exists to ensure that the given state is always a pure copy of the given
 * value.  This is especially useful when the state is a complicated objected or array
 * of multiple dimensions where shallow copying the top level may not be enough. We should
 * always try to, instead of using this hook, ensure our state interactions are completely pure on
 * their own, but this hook is a good fallback for when that is not possible or convenient.
 */
export function usePureState<T>(initialState: T): [T, (newVal: T) => void];
export function usePureState<T = undefined>(): [T | undefined, (newVal: T) => void];
export function usePureState<T>(initialState?: T): [T, (newVal: T) => void] | [T | undefined, (newVal: T) => void] {
  const [state, setState] = useState<T>(R.clone(initialState) as T);
  const setPureState = useCallback(
    (newValue: T) => {
      setState(R.clone(newValue));
    },
    [setState],
  );

  return [state, setPureState];
}
