import React from 'react';
import { StandardValueObject, SubComponentProps, ViewModeDictionary } from '../EventFormField.types';
import { QDatepicker } from '@qualio/ui-components';
import {
  convertToISOCalendarDate,
  convertISOCalendarDateToJSDate,
  convertISOCalendarDateToDisplayDate,
} from '../../../utils/datetimeUtils';
import { createFieldValueArray } from '../../../utils/eventFormUtils';
import ReadonlyWrapper from './ReadOnlyWrapper';
import TextOr from '../../TextOr/TextOr';
import './DateField.css';
import { useFlags } from '../../../external/LaunchDarklyAdapter';

const DateField = (props: SubComponentProps) => {
  const { fieldValues, onChange, fieldId, mode } = props;
  const isUseLegacyDomainForMtfeEnabled = useFlags('useLegacyDomainForMtfe');

  // TODO:  QEV-2813
  const valueObj = fieldValues[0] as StandardValueObject | undefined;
  const convertToDisplayDate = (value: string): string => {
    return convertISOCalendarDateToDisplayDate(value, isUseLegacyDomainForMtfeEnabled);
  };

  if (mode === ViewModeDictionary.READONLY) {
    return (
      <ReadonlyWrapper>
        <TextOr value={valueObj?.value} transform={convertToDisplayDate} fontSize="sm" />
      </ReadonlyWrapper>
    );
  }

  return (
    <QDatepicker
      isClearable={true}
      value={valueObj?.value ? convertISOCalendarDateToJSDate(valueObj.value) : undefined}
      data-cy={'date-picker'}
      onChange={(value) => {
        const newValueArray = createFieldValueArray(value, fieldId, valueObj, convertToISOCalendarDate);

        onChange(newValueArray);
      }}
    />
  );
};

export default DateField;
