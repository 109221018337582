import { useMutation, useQueryClient } from 'react-query';
import formTemplateApi from '../../api/formTemplate.api';
import { FormTemplateStatus } from '../../types';
import { baseEventTemplateQueryKey, baseFormTemplateQueryKey } from '../../hooks';
import { FormTemplateField, NewFormTemplateField } from '../../types/formFields/formTemplates';
import { useToastProvider } from '@qualio/ui-components';
import { AxiosError } from 'axios';
import { extractMessageFromError } from '../../utils/errorUtils';

type MutationParams = {
  companyId: number;
  eventTemplateId: number;
  stepId: number;
  formTemplateId: number;
  newFields: (FormTemplateField | NewFormTemplateField)[];
  formTemplateStatus: FormTemplateStatus;
};

export const useUpdateFormTemplate = () => {
  const queryClient = useQueryClient();
  const { showToast } = useToastProvider();

  const mutationFu = (params: MutationParams) => {
    const { companyId, formTemplateId, newFields, eventTemplateId, stepId, formTemplateStatus } = params;

    return formTemplateStatus === 'draft'
      ? formTemplateApi.updateFormTemplate(companyId, eventTemplateId, stepId, formTemplateId, newFields)
      : formTemplateApi.createNewFormTemplateVersion(companyId, eventTemplateId, stepId, formTemplateId, newFields);
  };

  return useMutation(mutationFu, {
    onSuccess: () => {
      queryClient.invalidateQueries(baseEventTemplateQueryKey).then();
      queryClient.invalidateQueries(baseFormTemplateQueryKey).then();
    },
    onError: (error: AxiosError) => {
      const errorMessage = extractMessageFromError(error);
      showToast({
        title: 'Form template update failed',
        description: errorMessage,
        status: 'error',
      });
    },
  });
};
