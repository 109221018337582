import React from 'react';
import { QBadge, QLookup, QText } from '@qualio/ui-components';
import { SupportedType } from './types';

const RegistryView: QLookup.DataView<QLookup.DataProvider.V2Result> = {
  identifiers: {
    header: 'ID',
    render: ({ code }) => <QBadge>{code}</QBadge>,
  },
  name: {
    header: 'Title',
    render: (title) => <QText>{title}</QText>,
  },
};

const DesignControlsView: QLookup.DataView<QLookup.DataProvider.V2Result> = {
  identifiers: {
    header: 'ID',
    render: ({ code }) => <QBadge>{code}</QBadge>,
  },
  name: {
    header: 'Title',
    render: (title) => <QText>{title}</QText>,
  },
};

export const ViewMap = {
  registry: RegistryView,
  design_controls: DesignControlsView,
} as const satisfies Record<SupportedType, QLookup.DataView<unknown>>;
