import * as z from 'zod';
import { sortSections } from '../utils/contentTemplateUtils';

export const Section = z.object({
  id: z.number().nullable(),
  title: z.string(),
  content: z
    .string()
    .nullable()
    .transform((i) => i ?? ''),
  position: z.number(),
  type: z.string(),
  template_id: z.number(),
});

const _contentTemplate = z.object({
  id: z.number(),
  name: z.string(),
  created_time: z.number(),
  modified_time: z.number().nullable(),
  company_id: z.number(),
  type: z.string(),
  document_id_prefix: z.string(),
  effective_on_approval: z.boolean(),
  training_available: z.boolean(),
  training_required: z.boolean(),
  text_sections_titles_visible: z.boolean(),
  review_period: z.number().nullable(),
  review_due_in_days: z.number().nullable(),
  approval_due_in_days: z.number().nullable(),
  version: z.number(),
  key: z.string(),
  deleted: z.boolean(),
  category: z.string(),
  sections: z.array(Section),
  resolution_section_required: z.boolean(),
  workflow_id: z.number(),
});

export const ContentTemplate = _contentTemplate.transform((data) => ({
  ...data,
  sections: sortSections(data.sections),
}));

export const ContentTemplateUpdate = _contentTemplate.omit({ resolution_section_required: true, workflow_id: true });

export const ContentTemplateSave = z.object({
  template: z.object({
    id: z.number().nullable(),
    company_id: z.number(),
    sections: z.array(Section.omit({ template_id: true })),
    name: z.string(),
    document_id_prefix: z.string(),
  }),
});

export type Section = z.infer<typeof Section>;
export type ContentTemplate = z.infer<typeof ContentTemplate>;
export type ContentTemplateSave = z.infer<typeof ContentTemplateSave>;
export type ContentTemplateUpdate = z.infer<typeof ContentTemplateUpdate>;
