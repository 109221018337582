import { useCurrentUser } from '@qualio/ui-components';
import React from 'react';
import { QualityEvent } from '../types';
import { useUserRole } from '../hooks/useUserRole';

export type EventPermissionsContextType = {
  canModifyEvent: boolean;
  canRevertStep: boolean;
  canChangeOwner: boolean;
  canCloseEventEarly: boolean;
  canReopenEvent: boolean;
  isEventOwner: boolean;
  canCreateAndModifyEvent: boolean;
  canAddDocumentToStep: boolean;
  canCreateTask: boolean;
  canAddFormToStep: boolean;
  canCompleteTaskStep: boolean;
  canOpenImportedEvent: boolean;
  isQuality: boolean;
};

export const EventPermissionsContext = React.createContext<EventPermissionsContextType | undefined>(undefined);

export type EventPermissionsProviderType = {
  userId: number;
  event: QualityEvent;
};

export const EventPermissionsProvider: React.FC<EventPermissionsProviderType> = ({ userId, event, children }) => {
  const role = useUserRole();
  const { permissions } = useCurrentUser();

  const isQuality = role === 'quality';
  const isEventOwner = event.owner_id === userId;
  const isEventOpen = event.status === 'open';

  const canCreateEvent = Boolean(permissions['can_create_issue']);
  const canWorkOnEvent = Boolean(permissions['can_work_on_issue']);
  const canManageEventTemplate = Boolean(permissions['can_manage_quality_event_workflow']);

  const canModifyEvent = isEventOwner && canWorkOnEvent;
  const canCreateAndModifyEvent = canModifyEvent && canCreateEvent;

  const canRevertStep = canModifyEvent && isEventOpen;
  const canChangeOwner = canManageEventTemplate && canWorkOnEvent;
  const canCloseEventEarly = canModifyEvent && isQuality;
  const canReopenEvent = canCreateAndModifyEvent && isQuality;
  const canAddDocumentToStep = canModifyEvent;
  const canCreateTask = canCreateAndModifyEvent;
  const canAddFormToStep = canCreateAndModifyEvent;
  const canCompleteTaskStep = canModifyEvent;
  const canOpenImportedEvent = canCreateAndModifyEvent;

  const value = {
    canModifyEvent,
    canRevertStep,
    canChangeOwner,
    canCloseEventEarly,
    canReopenEvent,
    isEventOwner,
    canCreateAndModifyEvent,
    canWorkOnEvent,
    canAddDocumentToStep,
    canCreateTask,
    canAddFormToStep,
    canCompleteTaskStep,
    canOpenImportedEvent,
    isQuality,
  };
  return <EventPermissionsContext.Provider value={value}>{children}</EventPermissionsContext.Provider>;
};
