import React, { useCallback } from 'react';
import {
  QModal,
  QStack,
  QText,
  useToastProvider,
  QModalHeader,
  QModalBody,
  QModalActions,
  QButton,
  QCloseButton,
} from '@qualio/ui-components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { QualityEventCreate } from '../../types/qualityEvent';
import UpdateEventPropertiesForm, { UpdateEventPropertiesSelectsData } from './components/UpdateEventPropertiesForm';
import { QualityEvent } from '../../types';
import { DefaultErrorMessage, EventPropertiesNotUpdatedError, FormContentInvalidError } from '../../displayStrings';

type UpdateEventPropertiesModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  event: QualityEvent;
  selectsData: UpdateEventPropertiesSelectsData;
  onSave: (payload: QualityEventCreate) => Promise<void>;
  suppliersConfiguration: {
    enabled: boolean;
    required: boolean;
  };
};

const UpdateEventPropertiesModal: React.FC<UpdateEventPropertiesModalProps> = ({
  isOpen,
  setIsOpen,
  selectsData,
  onSave,
  event,
  suppliersConfiguration,
}) => {
  const { showToast } = useToastProvider();
  const actionText = 'Update event properties';

  const formMethods = useForm<QualityEventCreate>({
    mode: 'onSubmit',
    resolver: zodResolver(QualityEventCreate),
  });

  const {
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
    setError,
  } = formMethods;

  const formHasErrors = !!Object.keys(errors).length;

  const handleClose = () => {
    setIsOpen(false);
    reset();
  };

  const onSubmit = useCallback(
    async (data: QualityEventCreate) => {
      let err = false;
      if (suppliersConfiguration.required && !data.suppliers?.length) {
        setError('suppliers', { type: 'required' });
        err = true;
      }
      if (formHasErrors || err) {
        showToast({
          title: EventPropertiesNotUpdatedError,
          description: FormContentInvalidError,
          status: 'error',
        });
        return;
      }
      try {
        await onSave(data);
        setIsOpen(false);
        showToast({
          title: 'Event properties updated',
          description: `Properties update for event ${event.code} successfully updated`,
          status: 'success',
        });
        reset();
      } catch (err: any) {
        showToast({
          title: EventPropertiesNotUpdatedError,
          description: DefaultErrorMessage,
          status: 'error',
        });
      }
    },
    [onSave, showToast, setIsOpen, reset, event, formHasErrors, setError, suppliersConfiguration],
  );

  return (
    <QModal isOpen={isOpen} onClose={handleClose} size="xl">
      <QModalHeader>
        <QText>{actionText}</QText>
        <QCloseButton onClick={handleClose}></QCloseButton>
      </QModalHeader>
      <QModalBody>
        <QStack direction="column" spacing={4}>
          <UpdateEventPropertiesForm
            formMethods={formMethods}
            onSubmit={onSubmit}
            data={{ ...selectsData }}
            event={event}
            suppliersConfiguration={suppliersConfiguration}
          />
        </QStack>
      </QModalBody>
      <QModalActions>
        <QButton onClick={handleClose} variant="outline">
          Cancel
        </QButton>
        <QButton onClick={() => handleSubmit(onSubmit)()} isDisabled={formHasErrors || isSubmitting}>
          {actionText}
        </QButton>
      </QModalActions>
    </QModal>
  );
};

export default UpdateEventPropertiesModal;
