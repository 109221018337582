import React from 'react';
import { QText } from '@qualio/ui-components';

type Props = {
  children: React.ReactNode;
};

export const PropertySectionText = ({ children }: Props) => {
  return (
    <QText fontSize="sm" textColor={'gray.500'}>
      {children}
    </QText>
  );
};
