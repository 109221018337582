import React, { useCallback, useState } from 'react';
import { SectionDTO, SectionResolutionDTO } from '../../../types';
import { QButton, QCloseButton, QModal, QModalActions, QModalBody, QModalHeader, QText } from '@qualio/ui-components';
import { Operations, Resolution } from '../../../types/resolution';
import SectionResolutionModalBody from './SectionResolutionModalBody/SectionResolutionModalBody';
import { elementAtIndex } from '../../../utils/arrayUtils';

export type SectionResolutionModalProps = {
  isOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  submit: (resolutions: SectionResolutionDTO[]) => void;
  sectionsToModify: SectionDTO[];
  deletedSections: SectionDTO[];
};

function isUpdateTemplateButtonDisabled(resolutions: Resolution[]): boolean {
  const validResolutionAmount = resolutions.filter(
    (r) =>
      r.operation === Operations.discard ||
      ((r.operation === Operations.prepend || r.operation === Operations.append) && r.recipient_section_index >= 0),
  ).length;

  return validResolutionAmount !== resolutions.length;
}

const SectionResolutionModal = (props: SectionResolutionModalProps) => {
  const [resolutions, setResolutions] = useState(
    props.deletedSections
      .filter((section) => section.id)
      .map((section) => {
        return {
          section_id: section.id,
          section_title: section.title,
          operation: 'notChosen',
          recipient_section_index: -1,
        } as Resolution;
      }),
  );

  const updateOperation = useCallback((operation: Operations, index: number, resolutionsInput: Resolution[]) => {
    const resolutionsToSet = [...resolutionsInput];
    elementAtIndex(resolutionsToSet, index).operation = operation;

    if (operation === Operations.discard) {
      elementAtIndex(resolutionsToSet, index).recipient_section_index = -1;
    }

    setResolutions(resolutionsToSet);
  }, []);

  const updateRecipientSectionIndex = useCallback(
    (recipientSectionIndex: number, index: number, resolutionsInput: Resolution[]) => {
      const resolutionsToSet = [...resolutionsInput];
      elementAtIndex(resolutionsToSet, index).recipient_section_index = recipientSectionIndex;
      setResolutions(resolutionsToSet);
    },
    [],
  );

  const selectOptions = props.sectionsToModify.map((s, i) => {
    return { label: s.title, value: i.toString() };
  });

  return (
    <QModal size={'xl'} isOpen={props.isOpen} onClose={() => props.setIsModalOpen(false)}>
      <QModalHeader>
        <QText>Select resolution</QText>
        <QText fontSize={'sm'}>
          Please choose a resolution for the following template changes. No changes will be applied to the document at
          this time.
        </QText>
        <QCloseButton onClick={() => props.setIsModalOpen(false)} />
      </QModalHeader>
      <QModalBody>
        <SectionResolutionModalBody
          resolutions={resolutions}
          updateOperation={updateOperation}
          updateRecipientSectionIndex={updateRecipientSectionIndex}
          selectOptions={selectOptions}
        />
      </QModalBody>
      <QModalActions>
        <QButton variant="outline" onClick={() => props.setIsModalOpen(false)}>
          Cancel
        </QButton>
        <QButton
          isDisabled={isUpdateTemplateButtonDisabled(resolutions)}
          onClick={() =>
            props.submit(
              resolutions
                .filter((r) => r.operation !== 'discard')
                .map(
                  (r) => new SectionResolutionDTO(r.section_id, r.operation === 'prepend', r.recipient_section_index),
                ),
            )
          }
          data-cy="updateButtonInModal"
        >
          Update template
        </QButton>
      </QModalActions>
    </QModal>
  );
};

export default SectionResolutionModal;
