import { QBox, QText } from '@qualio/ui-components';
import React from 'react';
import { DateTimeFormat, useLocalisedFormatDateTime } from '../../../utils/datetimeUtils';

type ApprovalDeclinedBannerDescProps = {
  approverDeclinedFullName: string;
  approverDeclinedActionTimestamp: number | null;
  approverDeclinedComment: string | null;
};
const ApprovalDeclinedBannerDesc = ({
  approverDeclinedFullName,
  approverDeclinedActionTimestamp,
  approverDeclinedComment,
}: ApprovalDeclinedBannerDescProps) => {
  const formatDateTime = useLocalisedFormatDateTime();

  return (
    <QBox>
      <QText>
        {approverDeclinedFullName +
          ' declined this step on ' +
          formatDateTime(Number(approverDeclinedActionTimestamp), DateTimeFormat.APPROVER) +
          ' with the following comment: '}
      </QText>
      <QText>{'"' + approverDeclinedComment + '"'}</QText>
    </QBox>
  );
};

export default ApprovalDeclinedBannerDesc;
