import { QBox, QFormControl, QHeading, useCurrentUser } from '@qualio/ui-components';
import React, { useMemo } from 'react';
import { ContentTemplateDTO } from '../../../types';
import { sortSections } from '../../../utils/contentTemplateUtils';
import { PageOrientation, QualioContentType } from '../../shared/CKEditor/models';
import { CKEditorInstance } from '../../shared/CKEditor/types/CKEditorInstance';
import { ContentEditor } from '../../shared/ContentEditor/ContentEditor';
import { PresentationUtils } from '../../shared/CKEditor/util/PresentationUtils';

export type DefaultContentProps = {
  setTemplateDto: (event: any) => void;
  templateDto: ContentTemplateDTO;
};

const DefaultContent = ({ setTemplateDto, templateDto }: DefaultContentProps) => {
  const { companyId } = useCurrentUser();
  const defaultContentSections = useMemo(
    () =>
      templateDto.sections.map((field) => ({
        content: field.content,
        position: field.position,
        title: field.title,
        type: field.type,
        attachments: null,
        id: field.id,
      })),
    [templateDto.sections],
  );

  sortSections(defaultContentSections);

  const defaultContent = {
    sections: defaultContentSections,
    content_type: QualioContentType.Template,
    orientation: PageOrientation.Portrait,
    id: null,
  };

  const handleCKEditorChange = (event: any, editor: CKEditorInstance) => {
    // This function is quite costly, if we run into perf issues, we should save the editor.getData()
    // and then convert when the user clicks 'Update Template'
    let sectionContentUpdated = false;

    const editorSections = PresentationUtils.splitEditorTextIntoSections(editor.getData(), defaultContent);
    editorSections.reverse();

    defaultContent.sections.forEach((section) => {
      const editorSectionText = editorSections.pop()?.sectionText;
      if (editorSectionText !== undefined && section.content !== editorSectionText && section.type !== 'resolution') {
        section.content = editorSectionText;
        sectionContentUpdated = true;
      }
    });
    if (sectionContentUpdated) {
      setTemplateDto({ ...templateDto, sections: defaultContent.sections });
    }
    return Promise.resolve();
  };

  return (
    <QBox className="stepper-content" pl="2" pr="2">
      <QBox pb={5}>
        <QHeading size="md">Default content</QHeading>
      </QBox>
      <QFormControl>
        <ContentEditor sectionedData={defaultContent} companyId={companyId} handleChange={handleCKEditorChange} />
      </QFormControl>
    </QBox>
  );
};

export default DefaultContent;
