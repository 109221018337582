import { QTag } from '@qualio/ui-components';
import React from 'react';

const mapTaskStatusToColor = (status: string) => {
  switch (status) {
    case 'open':
      return 'blue';
    case 'closed_success':
      return 'green';
    case 'closed_fail':
      return 'red';
    default:
      return 'default';
  }
};

const mapTaskStatusToLabel = (status: string) => {
  switch (status) {
    case 'open':
      return 'Open';
    case 'closed_success':
      return 'Completed';
    case 'closed_fail':
      return 'Cancelled';
    default:
      return status;
  }
};

type Props = {
  status: string;
};

const TaskStatusTag: React.FC<Props> = ({ status }) => {
  return <QTag variantColor={mapTaskStatusToColor(status || '')}>{mapTaskStatusToLabel(status)}</QTag>;
};

export default TaskStatusTag;
