import React from 'react';
import { User } from '../types';

export const useUsersOptions = (users: readonly User[], requiredPermission?: string) =>
  React.useMemo(
    () =>
      users
        .filter((user) => !requiredPermission || user.permissions.includes(requiredPermission))
        .map((user) => ({ value: String(user.id), label: user.full_name }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [users, requiredPermission],
  );
