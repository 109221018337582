import { medtechApi } from '.';
import { EventActionLog as EventActionLogType } from '../types/eventActionLog';

const eventActionLogApi = {
  async getEventActionLogs(companyId: number, eventId: number): Promise<EventActionLogType[]> {
    const path = `/quality-events/${companyId}/issues/${eventId}/action-logs`;
    const res = await medtechApi.get(path);
    return res.data.map((item: EventActionLogType) => EventActionLogType.parse(item));
  },
};
export default eventActionLogApi;
