import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { FormEditorForm } from '../../../shared';
import { QSwitch } from '@qualio/ui-components';
import * as DisplayStrings from '../../../../../displayStrings';

type Props = {
  control: UseFormReturn<FormEditorForm>['control'];
  fieldIndex: number;
};

const RequiredSwitch = ({ control, fieldIndex }: Props) => {
  return (
    <Controller
      name={`fields.${fieldIndex}.mandatory`}
      control={control}
      render={({ field }) => {
        const { value, onChange } = field;

        return (
          <QSwitch data-cy={`field-required-${fieldIndex}`} isChecked={value} onChange={onChange}>
            {DisplayStrings.Required}
          </QSwitch>
        );
      }}
    />
  );
};

export default RequiredSwitch;
