import * as z from 'zod';

import { Smartlink } from './smartlink';

export const QuickSearchSuggestionSchema = z.object({
  title: z.string(),
  code: z.string().optional(),
  domain: z.string().min(1),
  qri: z.string(),
  id: z.union([z.string(), z.number()]),
});

export type QuickSearchSuggestion = z.infer<typeof QuickSearchSuggestionSchema>;

export type Suggestion = QuickSearchSuggestion | Smartlink;

export const QuickSearchSuggestionsResponseSchema = z.array(QuickSearchSuggestionSchema).default([]);

export const AnySuggestionSchema = QuickSearchSuggestionSchema.merge(Smartlink).partial();

export type AnySuggestion = z.infer<typeof AnySuggestionSchema>;

export const SuggestionsOption = z.object({
  label: z.string(),
  value: z.union([z.string(), z.number()]).pipe(z.coerce.string()),
});
