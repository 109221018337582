import { ContentTemplate } from './contentTemplate';

export const DEFAULT_TEMPLATE_TYPE = 'text';

export class ContentTemplateDTO {
  id: string;
  sections: SectionDTO[];
  name: string;
  document_id_prefix: string;
  canHaveResolutionSection: boolean;

  constructor(contentTemplate: ContentTemplate) {
    this.id = String(contentTemplate.id);
    this.name = contentTemplate.name;
    this.document_id_prefix = contentTemplate.document_id_prefix;
    this.sections =
      contentTemplate.sections.map((s) => {
        return new SectionDTO(s.id, s.title, s.position, s.content, s.type);
      }) || [];
    this.canHaveResolutionSection = contentTemplate.resolution_section_required;
  }
}

export class SectionDTO {
  id: number | null;
  title: string;
  position: number;
  content: string;
  type: string;

  constructor(id: number | null, title: string, position: number, content: string, type: string) {
    this.id = id;
    this.title = title;
    this.position = position;
    this.content = content;
    this.type = type;
  }
}

export class SectionResolutionDTO {
  section_id: number;
  if_prepend: boolean;
  recipient_section_index: number;

  constructor(section_id: number, if_prepend: boolean, recipient_section_index: number) {
    this.section_id = section_id;
    this.if_prepend = if_prepend;
    this.recipient_section_index = recipient_section_index;
  }
}
