import React, { useMemo } from 'react';
import {
  createQColumnHelper,
  DataProvider,
  Pagination,
  TableMenuItem,
  QDataTable,
  QBox,
  QSpinner,
} from '@qualio/ui-components';
import { RootCause, RootCauseStatsResponse, RootCauseListRow } from '../../types';
import { useUserPermissions } from '../../hooks';
import { useFlags } from '../../external/LaunchDarklyAdapter';

type RootCauseListProps = {
  rootCauses: RootCause[] | undefined;
  stats: RootCauseStatsResponse | undefined;
  isLoading: boolean;
  onEditRootCause: (payload: RootCause) => void;
  onDeleteRootCause: (payload: RootCause) => void;
};

const RootCauseList = ({ rootCauses, stats, isLoading, onEditRootCause, onDeleteRootCause }: RootCauseListProps) => {
  const { canCUDRootCause } = useUserPermissions();
  const qualioFrontendRefreshEnabled = useFlags('qualioFrontendRefresh');

  const columnHelper = createQColumnHelper<RootCauseListRow>();
  const columns = [
    columnHelper.text('name', { header: 'Root cause', minWidth: '40%' }),
    columnHelper.text('description', { header: 'Description', minWidth: '40%' }),
    columnHelper.textLink(
      (root_cause) => openIssuesLabel(root_cause.open_issues, root_cause.total_issues),
      (root_cause) => openIssuesLink(root_cause.id),
      { id: 'open_events', header: 'Open events', minWidth: '15%', enableSorting: true },
    ),
  ];
  if (canCUDRootCause) {
    columns.push(
      columnHelper.menu({
        items: (
          <>
            <TableMenuItem onClick={(item: RootCause) => onEditRootCause(item)}>Edit</TableMenuItem>
            <TableMenuItem onClick={(item: RootCause) => onDeleteRootCause(item)}>Delete</TableMenuItem>
          </>
        ),
      }),
    );
  }

  const openIssuesLabel = (openIssues: number, totalIssues: number): string => {
    return `${openIssues} / ${totalIssues}`;
  };

  const openIssuesLink = (rootCauseId: string): string => {
    let url = `/events?status=open&rootcause_id=${rootCauseId}`;
    if (!qualioFrontendRefreshEnabled) {
      url = '/quality-events' + url;
    }

    return url;
  };

  const mappedData = useMemo(
    () =>
      rootCauses?.map((item: RootCause) => ({
        id: item.id || '',
        name: item.name,
        description: item.description || '',
        open_issues: (stats && stats[item.id]?.open_issues) || 0,
        total_issues: (stats && stats[item.id]?.total_issues) || 0,
      })) || [],
    [rootCauses, stats],
  );

  return (
    <QBox>
      {isLoading ? (
        <QBox w="100%" textAlign="center" p={5}>
          <QSpinner />
        </QBox>
      ) : (
        <Pagination.Auto itemCount={mappedData.length} clientSide>
          <DataProvider.Fixed data={mappedData}>
            <QDataTable columns={columns} />
          </DataProvider.Fixed>
        </Pagination.Auto>
      )}
    </QBox>
  );
};

export default RootCauseList;
