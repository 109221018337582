import React, { useMemo } from 'react';
import { QBox, QButton, QHeading } from '@qualio/ui-components';
import ContentBox from './ContentBox';
import { DEFAULT_TEMPLATE_TYPE, SectionErrors, ContentTemplateDTO } from '../../../types';
import { setDuplicatedTitleErrors } from './DupeTitles';

export type DefaultSectionsContentProps = {
  setTemplateDto: (templateDto: ContentTemplateDTO) => void;
  templateDto: ContentTemplateDTO;
  setPageError: (value: boolean) => void;
  sectionTitlesTouched: boolean[];
  setSectionTitlesTouched: (touched: boolean[]) => void;
  sectionErrors: SectionErrors[];
  setSectionErrors: (errors: SectionErrors[]) => void;
};

const DefaultSections = (props: DefaultSectionsContentProps) => {
  const resolutionSection: number | null = useMemo(() => {
    const section = props.templateDto.sections.find((s) => s.type === 'resolution');

    return section ? section.position : null;
  }, [props.templateDto]);

  const addSection = () => {
    const template = { ...props.templateDto };
    template.sections.push({
      id: null,
      title: '',
      position: props.templateDto.sections.length + 1,
      content: '',
      type: DEFAULT_TEMPLATE_TYPE,
    });
    props.setTemplateDto(template);

    const errors = [...props.sectionErrors];
    errors.push(new SectionErrors());
    props.setSectionErrors(errors);

    const sectionTitlesTouched = [...props.sectionTitlesTouched];
    sectionTitlesTouched.push(false);
    props.setSectionTitlesTouched(sectionTitlesTouched);

    props.setPageError(true);
  };

  const sectionErrors = [...props.sectionErrors];
  setDuplicatedTitleErrors(props.templateDto.sections, sectionErrors);

  const sectionsDisplay = props.templateDto.sections.map((section, index) => {
    return (
      <ContentBox
        key={section.id ?? index}
        index={index}
        templateDto={props.templateDto}
        setTemplateDto={props.setTemplateDto}
        sectionErrors={sectionErrors}
        setSectionErrors={props.setSectionErrors}
        sectionTitlesTouched={props.sectionTitlesTouched}
        setSectionTitlesTouched={props.setSectionTitlesTouched}
        setPageError={props.setPageError}
        resolutionSection={resolutionSection}
      />
    );
  });

  return (
    <QBox className="stepper-content" pl="2" pr="2">
      <QHeading size="md">Default sections</QHeading>
      <div>{sectionsDisplay}</div>
      <QBox pl="6" py="4">
        <QButton variant="outline" onClick={addSection} data-cy="addSection">
          Add Section
        </QButton>
      </QBox>
    </QBox>
  );
};

export default DefaultSections;
