import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { QBadge, QBox, QButton, QDivider, QFlex, QHeading, QStack, QText } from '@qualio/ui-components';
import * as DisplayStrings from '../../displayStrings';
import exportsApi from '../../api/exports.api';
import { StatusTag } from '../../components';
import ExportUtilDefinition from '../../utils/exportUtils';
import KebabMenu from './components/KebabMenu/KebabMenu';
import { useLocalisedFormatDateTime } from '../../utils/datetimeUtils';
import { useFlags } from '../../external/LaunchDarklyAdapter';
import SummaryDrawer from '../AiSummary/SummaryDrawer';
import { EventStatus } from '../../types';
import { useEventPermissions } from '../../hooks';

type LocationStateType = {
  backQueryParams: string | undefined;
};

type QualityEventHeaderProps = {
  code: string;
  title: string;
  status: EventStatus;
  due: number;
  updated_at: number;
  description: string;
  onEditEventClicked: () => void;
  onChangeOwnerClicked: () => void;
  onEscalateEventClicked: () => void;
  onReopenClicked: () => void;
  onRejectClicked: () => void;
  onAcceptClicked: () => void;
  onOpenClicked: () => void;
  onCloseEventClicked: () => void;
  onCancelEventClicked: () => void;
  onUpdateEventPropertiesClicked: () => void;
  exportUtil: ExportUtilDefinition;
  eventId: number;
  companyId: number;
  userTZ: string;
  templateName: string;
};

const QualityEventHeader: React.FC<QualityEventHeaderProps> = ({
  code,
  title,
  status,
  due,
  updated_at,
  description,
  onEditEventClicked,
  onChangeOwnerClicked,
  onEscalateEventClicked,
  onReopenClicked,
  onRejectClicked,
  onAcceptClicked,
  onOpenClicked,
  onCloseEventClicked,
  onCancelEventClicked,
  onUpdateEventPropertiesClicked,
  exportUtil,
  eventId,
  companyId,
  templateName,
}) => {
  const navigate = useNavigate();
  const formatDateTime = useLocalisedFormatDateTime();
  const handleExportClick = async () => {
    try {
      const response = await exportsApi.createExportEvent(companyId, eventId);
      exportUtil.displayExportStatus(companyId, response?.id as string);
    } catch (error) {
      exportUtil.displayErrorTryingToExport();
    }
  };

  const location = useLocation();
  const [showAiSummary, setShowAiSummary] = useState<boolean>(false);
  const aiEnabled = useFlags('qualityEventsAi');
  const qeCancelAndRejectEnabled = useFlags('qeCancelAndReject');
  const locationState = location.state as LocationStateType;
  const backQueryParams = locationState?.backQueryParams;
  const backPath = backQueryParams ? `/events?${backQueryParams}` : '/events';
  const isComplaint = () => templateName.toLowerCase().includes('complaint');
  const showAiButton = aiEnabled && status === 'imported' && isComplaint();
  const { canModifyEvent } = useEventPermissions();
  const showRejectAndAcceptButton = qeCancelAndRejectEnabled && canModifyEvent && status === 'imported';

  return (
    <QBox>
      <QBox>
        <QButton variant="link" onClick={() => navigate(backPath)} leftIcon="ArrowLeft">
          {DisplayStrings.Back}
        </QButton>
      </QBox>
      <QBadge mt={4} textTransform="none">
        {code}
      </QBadge>
      <QFlex justifyContent="space-between">
        <QHeading size="lg">{title}</QHeading>
        <QStack direction={['column', 'row']}>
          {showRejectAndAcceptButton && (
            <QStack direction={['column', 'row']}>
              <QButton data-cy="reject-imported-event" isDestructive variant="outline" onClick={onRejectClicked}>
                {DisplayStrings.Reject}
              </QButton>
              <QButton data-cy="accept-imported-event" onClick={onAcceptClicked}>
                {DisplayStrings.Accept}
              </QButton>
            </QStack>
          )}
          <KebabMenu
            eventStatus={status}
            eventId={eventId}
            onEditEvent={onEditEventClicked}
            onChangeOwner={onChangeOwnerClicked}
            onEscalateEvent={onEscalateEventClicked}
            onCloseEvent={onCloseEventClicked}
            onCancelEvent={onCancelEventClicked}
            onReopen={onReopenClicked}
            onOpen={onOpenClicked}
            onUpdateEventProperties={onUpdateEventPropertiesClicked}
          />
          {showAiButton && (
            <QButton leftIcon="QualioAI" data-cy="show-ai-triage" onClick={() => setShowAiSummary(true)}>
              {DisplayStrings.ViewAIAssessment}
            </QButton>
          )}
          <QButton onClick={handleExportClick} data-metrics="export-event-button">
            Export event
          </QButton>
        </QStack>
      </QFlex>
      <QStack direction="row" mt={2}>
        <QText fontSize="xs">
          <StatusTag status={status} />
        </QText>
        <QText>&middot;</QText>
        <QText fontSize="xs">
          <b>Due </b>
          {formatDateTime(due, 'MMMM dd, yyyy', false)}
        </QText>
        <QText>&middot;</QText>
        <QText fontSize="xs">
          <b>Last modified </b>
          {formatDateTime(updated_at, "MMMM dd, yyyy 'at' hh:mm a")}
        </QText>
      </QStack>
      <QText fontSize="xs" weight="regular" color="gray.500" mt={3} mb={6}>
        {description}
      </QText>
      <QDivider orientation="horizontal" />
      {showAiButton && showAiSummary && (
        <QBox data-cy="form-drawer-screen">
          <SummaryDrawer companyId={companyId} eventId={eventId} onClose={() => setShowAiSummary(false)} />
        </QBox>
      )}
    </QBox>
  );
};

export default QualityEventHeader;
