import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { useToastProvider, useCurrentUser } from '@qualio/ui-components';
import formsApi from '../api/forms.api';
import { UpdateForm } from '../types';
import { extractMessageFromError } from '../utils/errorUtils';
import * as DisplayStrings from '../displayStrings';

const ToastMessageConfig = {
  approved: { title: 'Approved', errorTitle: 'Step not approved', description: 'You approved this step' },
  declined: { title: 'Declined', errorTitle: 'Step not declined', description: 'You declined this step' },
};

const invalidCredentialsMsg = (msg: string) => {
  const invalidEmail = 'Email mismatch';
  const invalidPassword = 'Wrong password';
  if (msg.includes(invalidPassword) || msg.includes(invalidEmail)) {
    return true;
  }
  return false;
};

export type FormApprovalMutateType = {
  password: string;
  comment: string;
  isApprove: boolean;
};

export const useFormApproval = (eventId: number, stepId: number, formId: number, onSuccess?: () => void) => {
  const { companyId, email } = useCurrentUser();
  const { showToast } = useToastProvider();
  const queryClient = useQueryClient();

  const mutationOptions = {
    onSuccess: (form: UpdateForm | undefined, variables: FormApprovalMutateType) => {
      if (onSuccess) {
        onSuccess();
      }

      queryClient.invalidateQueries({ queryKey: ['approversList', formId] });
      queryClient.invalidateQueries({ queryKey: ['formDetails', formId] });

      const toast = variables.isApprove ? ToastMessageConfig.approved : ToastMessageConfig.declined;

      showToast({
        title: toast.title,
        description: toast.description,
        status: 'success',
      });
    },
    onError: (error: AxiosError, variables: FormApprovalMutateType) => {
      const toast = variables.isApprove ? ToastMessageConfig.approved : ToastMessageConfig.declined;
      const errorMessage = extractMessageFromError(error);
      showToast({
        title: toast.errorTitle,
        description: invalidCredentialsMsg(errorMessage) ? DisplayStrings.InvalidCredentials : errorMessage,
        status: 'error',
      });
    },
  };

  return useMutation(
    ({ password, comment, isApprove }: FormApprovalMutateType) =>
      formsApi.approveOrDecline(companyId, eventId, stepId, formId, isApprove, email, password, comment),
    mutationOptions,
  );
};
