import { QButton, QFlex, QIcon, QText } from '@qualio/ui-components';
import React from 'react';

type ReviewRequestBannerProps = {
  markAsReviewedButtonClick: () => void;
};
const ReviewRequestBanner = ({ markAsReviewedButtonClick }: ReviewRequestBannerProps) => {
  return (
    <QFlex p={2} bg="yellow.100" borderRadius="md" mb={4} justifyContent={'space-between'}>
      <QFlex w="30%" alignItems="center" justifyContent={'space-between'}>
        <QIcon iconName="AlertCircle" color="yellow.500" />
        <QText weight="semibold">Review request</QText>
        <QText>Your review is required</QText>
      </QFlex>
      <QButton data-cy="mark-as-reviewed-button" variant="solid" onClick={markAsReviewedButtonClick}>
        Mark as reviewed
      </QButton>
    </QFlex>
  );
};

export default ReviewRequestBanner;
