import React from 'react';
import { QDivider, QDrawer } from '@qualio/ui-components';
import AiSummary from './AiSummary';

type SummaryDrawerProps = {
  companyId: number;
  eventId: number;
  onClose: () => void;
};

const SummaryDrawer: React.FC<SummaryDrawerProps> = ({ companyId, eventId, onClose }) => {
  return (
    <QDrawer isOpen={true} onClose={onClose} size="xl" title="Qualio AI Event Assessment">
      <QDivider />
      <AiSummary companyId={companyId} eventId={eventId} />
    </QDrawer>
  );
};

export default SummaryDrawer;
