import { FormSchema, Form, UpdateFormSchema } from '../types';
import { CreateFieldValue, Field, FieldSchema } from '../types/formFields/forms';
import { medtechApi } from './index';

const formsApi = {
  async associateForm(companyId: number, eventId: number, stepId: number, ownerId?: number) {
    const payload = ownerId ? { owner_id: ownerId } : {};
    const response = await medtechApi.post(
      `/quality-events/${companyId}/issues/${eventId}/steps/${stepId}/associate-form`,
      payload,
    );
    return UpdateFormSchema.parse(response.data);
  },

  async getForm(companyId: number, eventId: number, stepId: number, formId: number): Promise<Form> {
    const response = await medtechApi.get(
      `/quality-events/${companyId}/issues/${eventId}/steps/${stepId}/forms/${formId}`,
    );
    return FormSchema.parse(response.data);
  },

  async updateFormValues(
    companyId: number,
    eventId: number,
    stepId: number,
    formId: number,
    values: CreateFieldValue[],
  ): Promise<Field[]> {
    const response = await medtechApi.put(
      `/quality-events/${companyId}/issues/${eventId}/steps/${stepId}/forms/${formId}/values`,
      { values: values },
    );
    return FieldSchema.array().parse(response.data['fields']);
  },

  async updateForm(companyId: number, eventId: number, stepId: number, formId: number, payload: any) {
    const response = await medtechApi.patch(
      `/quality-events/${companyId}/issues/${eventId}/steps/${stepId}/forms/${formId}`,
      {
        ...payload,
      },
    );
    return UpdateFormSchema.parse(response.data);
  },

  async approveOrDecline(
    companyId: number,
    issueId: number,
    stepId: number,
    formId: number,
    approve: boolean,
    email: string,
    password: string,
    comment: string,
  ) {
    const requestBody = {
      approve: approve,
      comment: comment,
      email: email,
      password: password,
    };
    const response = await medtechApi.post(
      `/quality-events/${companyId}/issues/${issueId}/steps/${stepId}/forms/${formId}/approval`,
      requestBody,
    );

    return UpdateFormSchema.parse(response.data);
  },

  async markAsReviewed(companyId: number, issueId: number, stepId: number, formId: number, comment: string) {
    const requestBody = {
      comment: comment,
    };
    const response = await medtechApi.post(
      `/quality-events/${companyId}/issues/${issueId}/steps/${stepId}/forms/${formId}/review`,
      requestBody,
    );

    return UpdateFormSchema.parse(response.data);
  },
};
export default formsApi;
