import { QAttachmentUploadInfo } from '@qualio/ui-components';

export const attachmentAPIUrl = `${process.env.REACT_APP_MEDTECH_BACKEND_API_URL}/attachments`;

export const attachmentUploadInfoProvider = (): Promise<QAttachmentUploadInfo> => {
  return Promise.resolve({
    url: attachmentAPIUrl,
    destination: 'MEDTECH',
  } as QAttachmentUploadInfo);
};
