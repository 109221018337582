import React, { useEffect } from 'react';
import { QBox, QFormControl, QSelect, QStack } from '@qualio/ui-components';
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form';
import { User } from '../../../types';
import { QualityEventCreate, QualityEvent } from '../../../types';
import { useUsersOptions } from '../../../hooks/useUsersOptions';
import { eventFieldsSetter } from '../../../utils/eventUtils';

type Props = {
  formMethods: UseFormReturn<QualityEventCreate>;
  onSubmit: any;
  data: {
    users: User[];
  };
  event?: QualityEvent;
};

const ChangeOwnerForm: React.FC<Props> = ({ formMethods, onSubmit, data, event }) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = formMethods;

  const usersOptions = useUsersOptions(data.users);

  useEffect(() => {
    eventFieldsSetter(event, setValue);
  }, [event, setValue]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <QStack direction="column" spacing={4}>
          <QFormControl
            label="Owner"
            error={errors.owner_id && errors.owner_id.message}
            isInvalid={!!errors.owner_id}
            isRequired
          >
            <Controller
              control={control}
              name="owner_id"
              render={({ field: { ref: _ref, onChange, ...field } }) => (
                <QBox data-cy="change-owner-select">
                  <QSelect
                    {...field}
                    onChange={(changeObject) => changeObject && onChange(changeObject.value)}
                    options={usersOptions}
                  />
                </QBox>
              )}
            />
          </QFormControl>
        </QStack>
      </form>
    </FormProvider>
  );
};

export default ChangeOwnerForm;
