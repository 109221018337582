import React, { useCallback, useState, useMemo } from 'react';
import { QriDetails } from '../types';

export type ReferenceDrawerContextType = {
  handleQResourceLabelClick: (qriDetails: QriDetails) => void;
  handleDrawerClose: () => void;
  qriReferenceDrawerOpen: boolean;
  focusedQri: string | undefined;
  focusedQriDetails: QriDetails | undefined;
};

export const ReferenceDrawerContext = React.createContext<ReferenceDrawerContextType | null>(null);

export const ReferenceDrawerProvider: React.FC<{ isQriReferenceDrawerEnabled: boolean }> = ({
  children,
  isQriReferenceDrawerEnabled = false,
}) => {
  const [qriReferenceDrawerOpen, setQriReferenceDrawerOpen] = useState(false);
  const [focusedQri, setFocusedQri] = useState<string | undefined>(undefined);
  const [focusedQriDetails, setFocusedQriDetails] = useState<QriDetails | undefined>(undefined);

  const handleQResourceLabelClick = useCallback(
    async (qriDetails: QriDetails) => {
      setFocusedQri(qriDetails.identifiers.qri);
      setFocusedQriDetails(qriDetails);
      setQriReferenceDrawerOpen(true);
    },
    [setQriReferenceDrawerOpen],
  );

  const handleDrawerClose = useCallback(() => {
    setQriReferenceDrawerOpen(false);
    setFocusedQri(undefined);
    setFocusedQriDetails(undefined);
  }, [setQriReferenceDrawerOpen]);

  const providerValue = useMemo(() => {
    return { handleQResourceLabelClick, handleDrawerClose, qriReferenceDrawerOpen, focusedQri, focusedQriDetails };
  }, [handleQResourceLabelClick, qriReferenceDrawerOpen, focusedQri, focusedQriDetails, handleDrawerClose]);

  if (!isQriReferenceDrawerEnabled) {
    return <>{children}</>;
  }

  return <ReferenceDrawerContext.Provider value={providerValue}>{children}</ReferenceDrawerContext.Provider>;
};
