import React from 'react';
import { chakra, Th } from '@chakra-ui/react';
import { QIcon } from '@qualio/ui-components';
import { HeaderGroup } from 'react-table';
import { QDataRow } from '../DataTable';

export interface QHeaderProps {
  column: HeaderGroup<QDataRow>;
}

export const QHeader: React.FC<QHeaderProps> = ({ column }) => (
  // `getHeaderProps` returns an interface with a key, so we
  // don't need to explicitly pass one ourselves.
  <Th
    {...column.getHeaderProps(column.getSortByToggleProps())}
    width={column.width}
    minWidth={column.minWidth}
    maxWidth={column.maxWidth}
  >
    {column.render('Header')}
    <chakra.span position="relative" top="4px" visibility={column.isSorted ? 'visible' : 'hidden'}>
      <QIcon iconName={column.isSortedDesc ? 'ChevronDown' : 'ChevronUp'} />
    </chakra.span>
  </Th>
);
