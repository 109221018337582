import { QualityEventDetailsResponse, QriDetails } from '../types';

const extractRelatedQrisFromQueryEventData = (eventData?: QualityEventDetailsResponse) => {
  if (!eventData) {
    return [];
  }
  const suppliers = eventData.issue.suppliers ?? [];
  const relatedDocuments = eventData.issue.related_documents ?? [];
  const relatedEvents = eventData.issue.related_events ?? [];
  const relatedProduct = eventData.issue.related_product ? [eventData.issue.related_product] : [];
  const relatedRootCause = eventData.issue.related_rootcause ? [eventData.issue.related_rootcause] : [];
  return [...suppliers, ...relatedDocuments, ...relatedEvents, ...relatedProduct, ...relatedRootCause];
};

const qriDetailsAsSelectData = (data: QriDetails[]) => {
  return data.map((item) => {
    let label, value;
    if (item.domain === 'suppliers' || item.domain === 'registry') {
      label = item.name;
      value = item.identifiers.qri;
    } else {
      label = item.displayLabel;
      value = item.identifiers.qri;
    }
    return { value, label };
  });
};

export { extractRelatedQrisFromQueryEventData, qriDetailsAsSelectData };
