import { StandardValueObject } from '../components/EventFormField/EventFormField.types';
import { FieldType, FieldTypeSchema, QObjectFieldType, QObjectFieldTypes } from '../types/formFields/common';
import { NewFormTemplateField } from '../types/formFields/formTemplates';
import { alwaysClone, modifiedToString } from './functionalUtils';

type TransformerFunction<T> = (value: T) => string;

// Used to create a single Field "StandardValueObject" from a value
export const createFieldValueObject = <T>(
  value: T,
  fieldId: number,
  existingData?: StandardValueObject,
  transformer?: TransformerFunction<T>,
) => {
  const guardedTransformer = transformer ?? modifiedToString;

  return {
    ...existingData,
    form_field_id: fieldId,
    value: guardedTransformer(value),
  };
};

/**
 * Used to create a Field "ValueObject" array from a single value, or an empty
 * array if the value is undefined
 */
export const createFieldValueArray = <T>(
  value: T | undefined,
  fieldId: number,
  existingData?: StandardValueObject,
  transformer?: TransformerFunction<T>,
) => {
  return value ? [createFieldValueObject(value, fieldId, existingData, transformer)] : [];
};

/**
 * Use to check if the given field type is one that has "options" (i.e. dropdown, radio, checkbox)
 */
export const isFieldWithOptions = (fieldType: FieldType) => {
  const fieldsWithOptions: FieldType[] = [
    FieldTypeSchema.Enum.dropdown,
    FieldTypeSchema.Enum.checkbox,
    FieldTypeSchema.Enum.radio,
  ];

  return fieldsWithOptions.includes(fieldType);
};

const getBaseFieldObject = alwaysClone({
  form_template_id: null,
  label: '',
  helptext: '',
  default_content: '',
  mandatory: false,
  order: null,
  multi: false,
});

const generateOptionList = alwaysClone({
  options: [{ value: '' }, { value: '' }, { value: '' }],
});

const generateAttachmentField = alwaysClone({
  ...getBaseFieldObject(),
  type: FieldTypeSchema.Enum.attachment,
  multi: true,
});

const generateDropdownField = alwaysClone({
  ...getBaseFieldObject(),
  type: FieldTypeSchema.Enum.dropdown,
  form_option_list: generateOptionList(),
});

const generateRadioField = alwaysClone({
  ...getBaseFieldObject(),
  type: FieldTypeSchema.Enum.radio,
  form_option_list: generateOptionList(),
  multi: false,
});

const generateCheckboxField = alwaysClone({
  ...getBaseFieldObject(),
  type: FieldTypeSchema.Enum.checkbox,
  form_option_list: generateOptionList(),
  multi: true,
});

const generateBasicField = (type: FieldType) => ({
  ...getBaseFieldObject(),
  type,
});

const fieldGenerators = {
  [FieldTypeSchema.Enum.attachment]: generateAttachmentField,
  [FieldTypeSchema.Enum.dropdown]: generateDropdownField,
  [FieldTypeSchema.Enum.radio]: generateRadioField,
  [FieldTypeSchema.Enum.checkbox]: generateCheckboxField,
  [FieldTypeSchema.Enum.text]: () => generateBasicField(FieldTypeSchema.Enum.text),
  [FieldTypeSchema.Enum.numeric]: () => generateBasicField(FieldTypeSchema.Enum.numeric),
  [FieldTypeSchema.Enum.boolean]: () => generateBasicField(FieldTypeSchema.Enum.boolean),
  [FieldTypeSchema.Enum.date]: () => generateBasicField(FieldTypeSchema.Enum.date),
  [FieldTypeSchema.Enum.rich_text]: () => generateBasicField(FieldTypeSchema.Enum.rich_text),
  [FieldTypeSchema.Enum.section]: () => generateBasicField(FieldTypeSchema.Enum.section),
  [FieldTypeSchema.Enum.document]: () => generateBasicField(FieldTypeSchema.Enum.document),
  [FieldTypeSchema.Enum.event]: () => generateBasicField(FieldTypeSchema.Enum.event),
  [FieldTypeSchema.Enum.supplier]: () => generateBasicField(FieldTypeSchema.Enum.supplier),
  [FieldTypeSchema.Enum.user]: () => generateBasicField(FieldTypeSchema.Enum.user),
  [FieldTypeSchema.Enum.change_request]: () => generateBasicField(FieldTypeSchema.Enum.change_request),
  [FieldTypeSchema.Enum.design_controls]: () => generateBasicField(FieldTypeSchema.Enum.design_controls),
};

const registryFieldGenerator = (resourceSubtype?: string) => ({
  ...getBaseFieldObject(),
  type: 'registry',
  resource_sub_type: resourceSubtype,
});

/**
 * Generate a new Template Form Field object for the given field type
 * Note: Code smell here with the type assertion, but as of now it is needed as
 * typescript seems unable to infer the correct type because NewFormTemplateField
 * is a discriminated union where the "standard" should not have a form_option_list
 */
export const generateNewFormField = (
  fieldType: FieldType,
  formTemplateId: number,
  order: number,
  resourceSubtype?: string,
): NewFormTemplateField => {
  const baseGenerator =
    fieldType === 'registry' ? () => registryFieldGenerator(resourceSubtype) : fieldGenerators[fieldType];

  return {
    ...baseGenerator(),
    form_template_id: formTemplateId,
    order,
  } as NewFormTemplateField;
};

/**
 * Check if the given field type is one of the form fields corresponding to the
 * QSelect form fields
 */
export const fieldTypeIsQObjectType = (fieldType: FieldType): fieldType is QObjectFieldType => {
  return QObjectFieldTypes.includes(fieldType as QObjectFieldType);
};
