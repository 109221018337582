import React from 'react';
import { QBox, QTag, QStack, QText, QIcon, QTagProps } from '@qualio/ui-components';
import { Approver } from '../../../types';

type FormApproversListProps = {
  label: string;
  approvers: Approver[];
};

const FormApproversList: React.FC<FormApproversListProps> = ({ label, approvers }) => {
  const getApproverIndicator = (
    approvalStatus: string,
  ): { variantColor: QTagProps['variantColor']; icon: React.ReactNode } => {
    switch (approvalStatus) {
      case 'approved':
        return { variantColor: 'gray', icon: <QIcon iconName="CheckCircleFilled" color="green.500" /> };
      case 'declined':
        return { variantColor: 'red', icon: <QIcon iconName="Slash" color="red.500" /> };
      default:
        return { variantColor: 'gray', icon: <QIcon iconName="HelpCircle" /> };
    }
  };

  return (
    <QStack spacing={3}>
      <QBox>
        <QText fontWeight="semibold" fontSize="xs" color="gray.800">
          {label}
        </QText>
      </QBox>
      {approvers.map((approver) => {
        const { variantColor, icon } = getApproverIndicator(approver.action_status);

        return (
          <QTag variantColor={variantColor} key={approver.id}>
            {icon}
            <QText fontSize="xs" color="gray.800" fontWeight="normal" ml={1}>
              {approver.full_name}
            </QText>
          </QTag>
        );
      })}
    </QStack>
  );
};

export default FormApproversList;
