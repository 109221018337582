export const EnsureBooleanString = (value: string): 'true' | 'false' => {
  if (value !== 'true' && value !== 'false') {
    throw new Error('Invalid boolean string');
  }

  return value;
};

export const ConvertBooleanStringToYesOrNo = (value: string): 'Yes' | 'No' => {
  const guardedString = EnsureBooleanString(value);

  return guardedString === 'true' ? 'Yes' : 'No';
};
