import { useQuery, UseQueryResult, useQueries } from 'react-query';
import { useToastProvider } from '@qualio/ui-components';
import { QualityEventsGet, QualityEventListResponse, User } from '../types';
import eventsApi from '../api/events.api';
import usersApi from '../api/users.api';
import { AxiosError } from 'axios';
import { DefaultErrorMessage } from '../displayStrings';

type UseEventsListType = {
  eventsListData: QualityEventListResponse | undefined;
  isEventsListLoading: boolean;
  isEventsListFetching: boolean;
  refetchEvents: () => Promise<UseQueryResult>;
};

export const useEventsList = (
  companyId: number,
  filterParams: QualityEventsGet | undefined,
  onSuccess?: (data: QualityEventListResponse | undefined) => void,
): UseEventsListType => {
  const { showToast } = useToastProvider();

  const {
    data: eventsListData,
    isLoading: isEventsListLoading,
    isFetching: isEventsListFetching,
    refetch: refetchEvents,
  } = useQuery('eventsList', () => eventsApi.getAllEvents(companyId, filterParams), {
    enabled: false,
    onSuccess: (data: QualityEventListResponse) => {
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (err) => {
      if (filterParams?.term && err instanceof AxiosError && err.code === 'ERR_BAD_REQUEST') {
        showToast({
          description: 'Search engine has failed, Verify that your query is formatted correctly and try again.',
          status: 'error',
        });
        return;
      }
      showToast({
        description: DefaultErrorMessage,
        status: 'error',
      });
    },
  });

  const eventsList = eventsListData?.data || [];

  useQueries(
    eventsList.map((event) => {
      return {
        queryKey: ['user', event.owner_id],
        queryFn: () => usersApi.getUser(event.owner_id),
        enabled: false,
        select: (data: User) => {
          event.owner = data.full_name;
        },
      };
    }),
  );

  return {
    isEventsListLoading,
    eventsListData,
    isEventsListFetching,
    refetchEvents,
  };
};
