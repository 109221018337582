import React from 'react';
import { UserTask } from '../types';

export type TaskPermissionsContextType = {
  canCompleteTask: boolean;
  canEditTaskTimeLimit: boolean;
  canAddCommentToTask: boolean;
  canEditTask: boolean;
};

export const TaskPermissionsContext = React.createContext<TaskPermissionsContextType | undefined>(undefined);

const canEditTaskFn = (userId: number, task: UserTask, isEventOwner: boolean): boolean => {
  const isTaskCreator = task.creator_id === userId;
  const isTaskAssignee = task.owner_id === userId;

  return isTaskCreator || isTaskAssignee || isEventOwner;
};

export type TaskPermissionsProviderType = {
  task: UserTask;
  userId: number;
  canCompleteTaskFn: (userId: number, task: UserTask, isEventOwner: boolean) => boolean;
  isEventOwner: boolean;
};

export const canCompleteTaskFn = canEditTaskFn;

export const TaskPermissionsProvider: React.FC<TaskPermissionsProviderType> = ({
  task,
  userId,
  canCompleteTaskFn,
  isEventOwner,
  children,
}) => {
  const isTaskCreator = task.creator_id === userId;
  const canCompleteTask = canCompleteTaskFn(userId, task, isEventOwner);
  const canEditTask = canEditTaskFn(userId, task, isEventOwner);
  const canEditTaskTimeLimit = isTaskCreator || isEventOwner;
  const canAddCommentToTask = !['closed_success', 'closed_fail'].includes(task.status);
  const value = { canCompleteTask, canEditTaskTimeLimit, canAddCommentToTask, canEditTask };

  return <TaskPermissionsContext.Provider value={value}>{children}</TaskPermissionsContext.Provider>;
};
