import * as z from 'zod';
import { DateTime } from 'luxon';
export const DateSchema = z.preprocess((arg) => {
  if (typeof arg === 'number') {
    return arg;
  }
  if (typeof arg === 'string') {
    return DateTime.fromISO(arg).toSeconds();
  }
}, z.number());
export type DateSchema = z.infer<typeof DateSchema>;

export const NumberSchema = z.preprocess((arg) => {
  return Number(arg);
}, z.number());

export const TrimmedString = z.preprocess(
  (val) => (val ? String(val).trim() : val),
  z.string().min(1, { message: 'Required' }),
);
