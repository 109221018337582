import {
  QBox,
  QButton,
  QCloseButton,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QMultiSelect,
  QSelectPlaceholder,
  QStack,
  QText,
} from '@qualio/ui-components';
import React, { useEffect, useMemo, useState } from 'react';
import { ApproverType, User } from '../../../types';
import * as DisplayStrings from '../../../displayStrings';

type ApproversModalProps = {
  isOpen: boolean;
  onSubmit: (approvers: number[], userType: ApproverType) => void;
  onClose: () => void;
  validApproversList: User[];
  validReviewersList: User[];
  existingApprovers: number[];
  existingReviewers: number[];
  approversType: ApproverType;
};

const ApproversModal = ({
  onSubmit,
  onClose,
  isOpen,
  validApproversList,
  validReviewersList,
  existingApprovers,
  existingReviewers,
  approversType,
}: ApproversModalProps) => {
  const [approvers, setApprovers] = useState<number[]>(existingApprovers);
  const [reviewers, setReviewers] = useState<number[]>(existingReviewers);

  useEffect(() => {
    setApprovers(existingApprovers);
    setReviewers(existingReviewers);
  }, [existingApprovers, existingReviewers, setApprovers, setReviewers]);

  const usersOptions = useMemo(() => {
    const usersData = approversType === 'approvers' ? validApproversList : validReviewersList;
    return usersData
      .map((user) => ({ value: user.usercompany_id, label: user.full_name }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [approversType, validApproversList, validReviewersList]);

  const handleSubmit = () => {
    const userCompanyIds = approversType === 'approvers' ? approvers.map(Number) : reviewers.map(Number);
    onSubmit(userCompanyIds, approversType);
  };

  return (
    <QModal isOpen={isOpen} onClose={onClose} size="md">
      <QModalHeader>
        <QText>Manage {approversType}</QText>
        <QCloseButton onClick={onClose}></QCloseButton>
      </QModalHeader>
      <QModalBody data-cy={`${approversType}-modal`}>
        <QStack direction="column" color="gray.700" spacing={2}>
          <QText fontWeight={600} textTransform={'capitalize'}>
            {approversType}
          </QText>
          <QBox data-cy={`${approversType}-multi-select`}>
            <QMultiSelect
              options={usersOptions}
              onChange={(selectValues) => {
                if (approversType === 'approvers') {
                  setApprovers(selectValues.map((v) => Number(v.value)));
                } else {
                  setReviewers(selectValues.map((v) => Number(v.value)));
                }
              }}
              value={approversType === 'approvers' ? approvers : reviewers}
              aria-label={approversType}
            >
              <QSelectPlaceholder>
                <QText>Manage {approversType}</QText>
              </QSelectPlaceholder>
            </QMultiSelect>
          </QBox>
        </QStack>
      </QModalBody>
      <QModalActions>
        <QButton data-cy={'cancel-changes'} onClick={onClose} variant="outline">
          {DisplayStrings.Cancel}
        </QButton>
        <QButton data-cy={'save-changes'} onClick={handleSubmit}>
          {DisplayStrings.SaveChanges}
        </QButton>
      </QModalActions>
    </QModal>
  );
};

export default ApproversModal;
