import * as z from 'zod';

export const EventActionLogStatus = z.enum(['closed', 'open', 'imported', 'rejected', 'cancelled']);
export type EventActionLogStatus = z.infer<typeof EventActionLog>;

export const EventActionLog = z.object({
  previous_status: EventActionLogStatus,
  destination_status: EventActionLogStatus,
  comment: z.string(),
  user_id: z.number(),
  issue_id: z.number(),
  id: z.number(),
  action_timestamp: z.number(),
  user_name: z.string(),
});

export type EventActionLog = z.infer<typeof EventActionLog>;
