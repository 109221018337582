import React from 'react';
import { useLocation } from 'react-router-dom';
import { AngularRouterSatellite } from '@qualio/ui-components';

const RouteWatcher = (): JSX.Element => {
  const { pathname, search, hash } = useLocation();
  const location = `quality-events${pathname}${search}${hash}`;

  return <AngularRouterSatellite location={location} />;
};

export default RouteWatcher;
