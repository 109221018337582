import { DigitalSignature } from '../types/digitalSignature';
import { medtechApi } from './index';

const issueStepsApi = {
  async signOff(company: number, eventId: number, stepId: number, payload: DigitalSignature): Promise<void> {
    const formatted_payload = {
      comment: payload.comment,
      user: {
        email: payload.email,
        password: payload.password,
      },
    };
    await medtechApi.post(`/v2/${company}/issues/${eventId}/steps/${stepId}/signoff`, {
      ...formatted_payload,
    });
  },

  async revert(company: number, eventId: number, stepId: number, payload: DigitalSignature): Promise<void> {
    await medtechApi.put(`/v2/${company}/issues/${eventId}/revert/${stepId}`, {
      ...payload,
    });
  },
};
export default issueStepsApi;
