import { EventType, EventTypeSchema } from '../types/eventType';
import api from './index';

const eventTypesApi = {
  async getAllEventTypes(company: number, includeArchived = false): Promise<EventType[] | undefined> {
    const options = includeArchived ? { params: { include_archived: true } } : undefined;
    const res = await api.get(`/company/${company}/event-types`, options);

    return EventTypeSchema.array().parse(res.data.data);
  },
};

export default eventTypesApi;
