import React from 'react';
import { QRadio, QRadioGroup, QStack } from '@qualio/ui-components';
import { StandardValueObject, SubComponentProps, ViewModeDictionary } from '../EventFormField.types';
import { createFieldValueArray } from '../../../utils/eventFormUtils';
import { ConvertBooleanStringToYesOrNo, EnsureBooleanString } from '../../../utils/booleanUtils';
import ReadonlyWrapper from './ReadOnlyWrapper';
import TextOr from '../../TextOr/TextOr';

const BooleanField = (props: SubComponentProps) => {
  const { fieldValues, onChange, fieldId, mode } = props;

  // TODO:  QEV-2813
  const valueObj = fieldValues[0] as StandardValueObject | undefined;

  if (mode === ViewModeDictionary.READONLY) {
    return (
      <ReadonlyWrapper>
        <TextOr fontSize="sm" value={valueObj?.value} transform={ConvertBooleanStringToYesOrNo} />
      </ReadonlyWrapper>
    );
  }

  return (
    <QRadioGroup
      data-cy={'boolean-select'}
      onChange={(value) => {
        const newValueArray = createFieldValueArray(value, fieldId, valueObj, EnsureBooleanString);

        onChange(newValueArray);
      }}
      value={valueObj?.value}
    >
      <QStack direction="column">
        <QRadio data-cy={'boolean-select-true'} value="true">
          Yes
        </QRadio>
        <QRadio data-cy={'boolean-select-false'} value="false">
          No
        </QRadio>
      </QStack>
    </QRadioGroup>
  );
};

export default BooleanField;
