import { UpdateTask } from '../types';
import { TasksRequest, TaskListResponse, Task, TaskCreateRequest, TaskUpdateResponse } from '../types/eventTask';
import api, { createRequestConfig } from './index';

const tasksApi = {
  async getAllTasks(company: number, filterParams?: TasksRequest): Promise<TaskListResponse | undefined> {
    const res = await api.get(
      `/company/${company}/tasks`,
      createRequestConfig({
        params: filterParams,
      }),
    );
    return TaskListResponse.parse(res.data);
  },
  async create(company: number, payload: TaskCreateRequest): Promise<Task> {
    const response = await api.post(`/company/${company}/tasks`, {
      ...payload,
    });
    return Task.parse(response.data.data);
  },
  async update(company: number, taskId: number, payload: UpdateTask): Promise<TaskUpdateResponse> {
    const response = await api.patch(`/company/${company}/tasks/${taskId}`, {
      ...payload,
    });

    return TaskUpdateResponse.parse(response.data.data);
  },
};

export default tasksApi;
