import {
  UserTasksRequest,
  UserTasksListResponse,
  TaskCompleteRequest,
  UserTaskCommentResponse,
  AddUserTaskCommentPayload,
  AddUserTaskCommentResponse,
  UpdateTask,
  UserTask,
  UserTaskDetails,
} from '../types';
import { medtechApi, createRequestConfig } from './index';

export const UserTasksPageSize = 10;

const userTasksApi = {
  async getUserTasks(
    company: number,
    offset: number,
    limit: number,
    filterParams?: UserTasksRequest,
  ): Promise<UserTasksListResponse | undefined> {
    const res = await medtechApi.get(
      `/v2/${company}/usertasks`,
      createRequestConfig({
        params: { ...filterParams, limit, offset },
      }),
    );
    return UserTasksListResponse.parse(res.data);
  },

  async getUserTaskDetails(company: number, taskId: number): Promise<UserTaskDetails | undefined> {
    const res = await medtechApi.get(`/v2/${company}/usertasks/${taskId}`);
    return UserTaskDetails.parse(res.data);
  },

  async updateUserTask(company: number, taskId: number, updateTask: UpdateTask): Promise<UserTask> {
    const res = await medtechApi.patch(`/v2/${company}/usertasks/${taskId}`, updateTask);
    return UserTask.parse(res.data);
  },

  async close(company: number, taskId: number, data: TaskCompleteRequest) {
    const res = await medtechApi.post(`/v2/${company}/usertasks/${taskId}/close`, {
      user: { email: data.email, password: data.password },
      comment: data.comment,
      resolution: data.resolution,
    });
    return res.data;
  },

  async getComments(company: number, taskId: number) {
    const res = await medtechApi.get(`/v2/${company}/usertasks/${taskId}/comments`);
    return UserTaskCommentResponse.parse(res.data);
  },

  async addComment(company: number, taskId: number, data: AddUserTaskCommentPayload) {
    const res = await medtechApi.post(`/v2/${company}/usertasks/${taskId}/comments`, data);
    return AddUserTaskCommentResponse.parse(res.data);
  },
};

export default userTasksApi;
