import * as z from 'zod';
import { BaseSchema, StandardFieldValueSchema } from './common';
import { FieldTypeSchema } from '../common';

/**
 * All types in this file are for form fields that are "option select" fields,
 * meaning fields where the user has predefined options to choose from.
 */
export const OptionSelectFieldSchema = BaseSchema.extend({
  type: FieldTypeSchema.extract(['dropdown', 'checkbox', 'radio']),
  options: z.array(z.string()),
  values: z.array(StandardFieldValueSchema).nullish(),
});
