import React from 'react';
import { DateTime } from 'luxon';
import { QBox, QCenter, QIcon } from '@qualio/ui-components';
import { useLocalisedFormatDateTime } from '../../../utils/datetimeUtils';

type StepStartTimeConstraintInfoProps = {
  startTimeConstraint: number;
  lastUpdated: number | null;
};

const StepStartTimeConstraintInfo = ({ startTimeConstraint, lastUpdated }: StepStartTimeConstraintInfoProps) => {
  const formatDateTime = useLocalisedFormatDateTime();
  const now = DateTime.now().toSeconds();
  const startDate = lastUpdated || now;
  if (now < startDate + startTimeConstraint) {
    return (
      <QCenter bg="yellow.100" m={4} p={3} borderRadius="base">
        <QIcon iconName="Info" color="yellow.500" />
        <QBox ml={2}>
          This resolution cannot be sent for approval until {formatDateTime(startDate + startTimeConstraint)}
        </QBox>
      </QCenter>
    );
  }
  return null;
};

export default StepStartTimeConstraintInfo;
