import MockAdapter from 'axios-mock-adapter';
import { globalSearchApi, qriApi } from '../api';
import { eventsQuicksearch } from '../tests/fixtures/quicksearch/events';
import { usersQuicksearch } from '../tests/fixtures/quicksearch/users';
import { smartlinksV2 } from '../tests/fixtures/quicksearch/smartlinks';

import { qriBulkDetails } from '../tests/fixtures/quicksearch/details';
import { changeRequestsQuicksearch } from '../tests/fixtures/quicksearch/change_requests';

const globalSearchApiMock = new MockAdapter(globalSearchApi, { delayResponse: 1000, onNoMatch: 'throwException' });
const qriApiMock = new MockAdapter(qriApi, { delayResponse: 1000, onNoMatch: 'throwException' });

globalSearchApiMock.onGet('/quicksearch?companyId=1&term=&indices=events&size=10&page=1').reply(200, eventsQuicksearch);
globalSearchApiMock.onGet('/quicksearch?companyId=1&term=&indices=users&size=10&page=1').reply(200, usersQuicksearch);
globalSearchApiMock
  .onGet(/\/v2\/quicksearch\?companyId=1&resources=change-request,document,quality-event,supplier,user&term=.*&size=10/)
  .reply(200, smartlinksV2);
globalSearchApiMock
  .onGet('/quicksearch?companyId=1&term=&indices=change-requests&size=10&page=1')
  .reply(200, changeRequestsQuicksearch);

qriApiMock.onPost('/details').reply(200, qriBulkDetails);
