import { Export } from '../types/exports';
import { QualityEventsGet } from '../types/qualityEvent';
import api from './index';

export type ExportsFilterParams = QualityEventsGet & { format?: string };

const exportsApi = {
  async createExport(company: number, filterParams?: ExportsFilterParams): Promise<Export | undefined> {
    const res = await api.post(`/company/${company}/exports`, filterParams);
    return res.data.data as Export;
  },
  async createExportEvent(company: number, event_id: number): Promise<Export | undefined> {
    const res = await api.post(`/company/${company}/exports/events/${event_id}`);
    return res.data.data as Export;
  },
  async getExport(company: number, exportId: string): Promise<Export | undefined> {
    const res = await api.get(`/company/${company}/exports/${exportId}`);
    return res.data.data as Export;
  },
};

export default exportsApi;
