import React from 'react';
import { TableMenuItem } from '@qualio/ui-components';
import { useArchiveTemplate, useRestoreTemplate } from './hooks';
import { EventType } from '../../types';

type TemplateRowActionsProps = {
  companyId: number;
};

const TemplateRowActions = ({ companyId }: TemplateRowActionsProps) => {
  const { mutate: archiveTemplate } = useArchiveTemplate(companyId);
  const { mutate: restoreTemplate } = useRestoreTemplate(companyId);

  return (
    <>
      <TableMenuItem
        data-cy={'archive-template'}
        onClick={(template: EventType) => archiveTemplate(template.id)}
        isDisabled={(template: EventType) => !template.active}
      >
        Archive
      </TableMenuItem>
      <TableMenuItem
        data-cy={'restore-template'}
        onClick={(template: EventType) => restoreTemplate(template.id)}
        isDisabled={(template: EventType) => template.active}
      >
        Restore
      </TableMenuItem>
    </>
  );
};

export default TemplateRowActions;
