import React from 'react';
import { QRadioGroup, QRadio, QStack } from '@qualio/ui-components';
import { StandardValueObject, SubComponentProps, ViewModeDictionary } from '../EventFormField.types';
import ReadonlyWrapper from './ReadOnlyWrapper';
import { createFieldValueArray } from '../../../utils/eventFormUtils';
import TextOr from '../../TextOr/TextOr';
import './OptionsField.css';

const RadioField = (props: SubComponentProps) => {
  const { fieldValues, onChange, fieldId, mode, options } = props;

  // TODO:  QEV-2813
  const valueObj = fieldValues[0] as StandardValueObject | undefined;

  if (mode === ViewModeDictionary.READONLY) {
    return (
      <ReadonlyWrapper>
        <TextOr fontSize="sm" value={valueObj?.value} />
      </ReadonlyWrapper>
    );
  }

  return (
    <QRadioGroup
      data-cy="radio-buttons-field"
      onChange={(value) => {
        const newValueArray = createFieldValueArray(value, fieldId, valueObj);
        onChange(newValueArray);
      }}
      value={valueObj?.value}
    >
      <QStack direction="column" className="options-field-wrapper">
        {options?.map((option) => (
          <QRadio key={option} value={option}>
            {option}
          </QRadio>
        ))}
      </QStack>
    </QRadioGroup>
  );
};

export default RadioField;
