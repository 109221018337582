import { useMutation } from 'react-query';
import { useCurrentUser, useToastProvider } from '@qualio/ui-components';
import { AssociateDocumentPayload, AssociateDocumentResponse } from '../types';
import issuesApi from '../api/issues.api';
import { DefaultErrorMessage } from '../displayStrings';

type UseAssociateDocumentType = {
  associateDocumentMutate: ({ eventId, stepId }: AssociateDocumentPayload) => void;
  isAssociateDocumentLoading: boolean;
};

export const useAssociateDocument = (
  onSuccess: (data: AssociateDocumentResponse | undefined) => void,
): UseAssociateDocumentType => {
  const { companyId } = useCurrentUser();
  const { showToast } = useToastProvider();

  const { mutate: associateDocumentMutate, isLoading: isAssociateDocumentLoading } = useMutation(
    ({ eventId, stepId }: AssociateDocumentPayload) => issuesApi.associateDocument(companyId, eventId, stepId),
    {
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: () => {
        showToast({
          description: DefaultErrorMessage,
          status: 'error',
        });
      },
    },
  );

  return { associateDocumentMutate, isAssociateDocumentLoading };
};
