import React from 'react';
import { QBox, QMenuButton } from '@qualio/ui-components';
import { FieldType, FieldTypeSchema } from '../../../../../types/formFields/common';
import { mapRegularFieldToButton } from '../shared';

type AddFieldParams = {
  addField: (field: FieldType) => void;
  buttonLabel: string;
  variant?: 'outline' | 'link';
};

const FieldsToDisplay = [
  FieldTypeSchema.Enum.text,
  FieldTypeSchema.Enum.rich_text,
  FieldTypeSchema.Enum.date,
  FieldTypeSchema.Enum.numeric,
  FieldTypeSchema.Enum.boolean,
  FieldTypeSchema.Enum.attachment,
  FieldTypeSchema.Enum.dropdown,
  FieldTypeSchema.Enum.section,
  FieldTypeSchema.Enum.checkbox,
  FieldTypeSchema.Enum.radio,
] as const;
const ButtonOptions = FieldsToDisplay.map(mapRegularFieldToButton);

const AddField = ({ addField, buttonLabel, variant = 'link' }: AddFieldParams) => {
  return (
    <QBox data-cy={'add-field-menu'}>
      <QMenuButton
        buttonLabel={buttonLabel}
        itemSize="sm"
        items={ButtonOptions}
        onItemClick={(event) => addField(event.id as FieldType)}
        variant={variant}
      />
    </QBox>
  );
};

export default AddField;
