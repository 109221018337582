import React from 'react';
import { QCheckbox, QStack, QText } from '@qualio/ui-components';
import { StandardValueObject, SubComponentProps, ViewModeDictionary } from '../EventFormField.types';
import ReadonlyWrapper from './ReadOnlyWrapper';
import { v4 } from 'uuid';
import './OptionsField.css';

const CheckboxField = (props: SubComponentProps) => {
  const { onChange, fieldId, mode, options } = props;

  // TODO:  QEV-2813
  const fieldValues = props.fieldValues as StandardValueObject[];

  if (mode === ViewModeDictionary.READONLY) {
    return (
      <ReadonlyWrapper>
        {fieldValues.length ? (
          fieldValues.map((fieldVal) => {
            return (
              <QText key={fieldVal.id ?? v4()} fontSize="sm">
                {fieldVal.value}
              </QText>
            );
          })
        ) : (
          <QText fontSize="sm">--</QText>
        )}
      </ReadonlyWrapper>
    );
  }

  return (
    <QStack data-cy="checkboxes-field" direction="column" className="options-field-wrapper">
      {options?.map((option) => {
        return (
          <QCheckbox
            key={option}
            isChecked={!!fieldValues.find((fieldValue) => fieldValue.value === option)}
            onChange={(event) => {
              if (event.target.checked) {
                onChange([...fieldValues, { form_field_id: fieldId, value: option }]);
              } else {
                onChange([...fieldValues.filter((fieldValue) => fieldValue.value !== option)]);
              }
            }}
          >
            {option}
          </QCheckbox>
        );
      })}
    </QStack>
  );
};

export default CheckboxField;
