import React, { Key } from 'react';
import { QStack } from '@qualio/ui-components';
import Card from '../Card/Card';

type CardListProps<ItemType extends { uniqueId: Key; type: string }> = {
  items: readonly ItemType[];
  removeItem: (item: ItemType, index: number) => void;
  moveItem: (item: ItemType, currentIndex: number, newIndex: number) => void;
  getCardTitle?: (item: ItemType) => React.ReactNode;
  renderItem: (item: ItemType, index: number, fullArray: readonly ItemType[]) => React.ReactNode;
};

const CardList = <T extends { uniqueId: Key; type: string }>(props: CardListProps<T>) => {
  const { items: itemArray, renderItem, getCardTitle = () => undefined, removeItem, moveItem } = props;

  return (
    <QStack spacing={6}>
      {itemArray.map((item, index, fullArray) => (
        <Card
          key={item.uniqueId}
          title={getCardTitle(item)}
          data-cy={`card-item-${item.type}-${index}`}
          includeControls={true}
          onMove={(direction) => {
            const newIndex = direction === 'up' ? index - 1 : index + 1;

            moveItem(item, index, newIndex);
          }}
          onDelete={() => removeItem(item, index)}
          disableUp={index === 0}
          disableDown={index === itemArray.length - 1}
        >
          {renderItem(item, index, fullArray)}
        </Card>
      ))}
    </QStack>
  );
};

export default CardList;
