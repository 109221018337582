import React from 'react';
import { Product } from '../../types';
import ProductModal from './components/ProductModal';

type ProductUpdateModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSave: (payload: Product) => Promise<void>;
  product: Product;
};

const ProductUpdateModal = ({ onSave, product, ...props }: ProductUpdateModalProps) => {
  const handleOnSave = async (payload: Product) => {
    return onSave(payload);
  };
  const handleStatusMessage = (status: 'success' | 'error', name: string, errorMessage?: string) => {
    const errorDesc = errorMessage ? `Reason: ${errorMessage}` : 'Try again.';
    const messages = {
      success: { title: 'Product updated', description: `"${name}" was updated.` },
      error: { title: 'Product not updated', description: `Product was not updated because of an error. ${errorDesc}` },
    };
    return { ...messages[status], status };
  };

  return (
    <ProductModal
      {...props}
      onSave={handleOnSave}
      onStatusMessage={handleStatusMessage}
      title="Edit product"
      buttonName="Save"
      defaultValues={product}
    />
  );
};

export default ProductUpdateModal;
