import React from 'react';
import { QBox, QFlex, QIcon, QText } from '@qualio/ui-components';
import { UserTaskComment } from '../../../types';
import { useLocalisedFormatDateTime, DateTimeFormat } from '../../../utils/datetimeUtils';
import { sanitize } from 'dompurify';
import './TaskComment.css';

type TaskCommentProps = {
  userTaskComment: UserTaskComment;
};

const TaskComment: React.FC<TaskCommentProps> = ({ userTaskComment }) => {
  const formatDateTime = useLocalisedFormatDateTime();
  return (
    <QBox borderRadius="4px" borderColor="gray.200" borderWidth="1px" p={4}>
      <QFlex alignItems="center">
        <QIcon iconName="User" color="gray.500" size="sm" />
        <QText ml={1} color={'gray.500'} fontSize={'xs'}>
          {userTaskComment.user_full_name}
        </QText>
        <QText fontSize={'xl'} lineHeight={'18px'} mr={1} ml={1} width={'11px'} textAlign={'center'} color={'gray.500'}>
          &middot;
        </QText>
        <QText fontSize={'xs'} color={'gray.500'}>
          {formatDateTime(userTaskComment.sent_at, DateTimeFormat.TASK)}
        </QText>
      </QFlex>
      <QText color={'gray.800'} mt={2}>
        <div className="task-comment" dangerouslySetInnerHTML={{ __html: sanitize(userTaskComment.content) }} />
      </QText>
    </QBox>
  );
};

export default TaskComment;
