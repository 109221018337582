import React from 'react';
import { QLookup, QBadge, QText } from '@qualio/ui-components';
import { QObjectFieldType } from '../../../../types/formFields/common';

const eventsView: QLookup.DataView<QLookup.DataProvider.EventResult> = {
  code: {
    header: 'ID',
    render: (code) => <QBadge>{code}</QBadge>,
  },
  title: {
    header: 'Title',
    render: (title) => <QText>{title}</QText>,
  },
  owner: {
    header: 'Owner',
    render: (owner) => <QText> {owner}</QText>,
  },
};

const documentsView: QLookup.DataView<QLookup.DataProvider.DocumentResult> = {
  code: {
    header: 'ID',
    render: (code) => <QBadge>{code}</QBadge>,
  },
  title: {
    header: 'Title',
    render: (title) => <QText>{title}</QText>,
  },
};

const usersView: QLookup.DataView<QLookup.DataProvider.UserResult> = {
  full_name: {
    header: 'Name',
    render: (name) => <QText>{name}</QText>,
  },
  email: {
    header: 'Email',
    render: (email) => <QText>{email}</QText>,
  },
};

const suppliersView: QLookup.DataView<QLookup.DataProvider.SupplierResult> = {
  title: {
    header: 'Name',
    render: (title) => <QText>{title}</QText>,
  },
};

const changeRequestView: QLookup.DataView<QLookup.DataProvider.ChangeRequestResult> = {
  code: {
    header: 'ID',
    render: (code) => <QBadge>{code}</QBadge>,
  },
  title: {
    header: 'Title',
    render: (title) => <QText>{title}</QText>,
  },
};

export const dataViewMap = {
  event: eventsView,
  document: documentsView,
  user: usersView,
  supplier: suppliersView,
  change_request: changeRequestView,
} as const satisfies Record<Exclude<QObjectFieldType, 'registry' | 'design_controls'>, QLookup.DataView<any>>;
