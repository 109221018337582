import React from 'react';
import { QTag } from '@qualio/ui-components';
import { FormStatus } from '../types';
import * as DisplayStrings from '../displayStrings';

const variantColorMap = {
  draft: 'gray',
  for_review: 'blue',
  for_approval: 'blue',
  approved: 'green',
  approval_declined: 'red',
  effective: 'green',
  superseded: 'red',
  cancelled: 'red',
} as const;

type FormStatusBadgeProps = {
  formStatus: FormStatus;
};

const FormStatusBadge = ({ formStatus }: FormStatusBadgeProps) => {
  const variantColor = variantColorMap[formStatus];
  const displayText = DisplayStrings.EventFormStatuses[formStatus];

  return <QTag variantColor={variantColor}>{displayText}</QTag>;
};

export default FormStatusBadge;
