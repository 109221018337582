import { SectionDTO, SectionErrors } from '../../../types';
import { elementAtIndex } from '../../../utils/arrayUtils';

export const setDuplicatedTitleErrors = (sections: SectionDTO[], errors: SectionErrors[]) => {
  // Works out if titles are duplicated and sets the appropriate sectionErrors
  const checkedIndex = [];

  for (let i = 0; i < sections.length - 1; i++) {
    const currentSection = sections[i]!;
    let noDuplicationCurrentTitle = true;

    if (currentSection.title === '') {
      elementAtIndex(errors, i).duplicateTitle = false;
      continue;
    }

    if (checkedIndex.indexOf(i) > -1) {
      continue;
    }

    for (let j = i + 1; j < sections.length; j++) {
      if (currentSection.title === sections[j]!.title) {
        elementAtIndex(errors, i).duplicateTitle = true;
        elementAtIndex(errors, j).duplicateTitle = true;
        checkedIndex.push(j);
        noDuplicationCurrentTitle = false;
      }
    }

    if (noDuplicationCurrentTitle) {
      elementAtIndex(errors, i).duplicateTitle = false;
    }
  }
};
