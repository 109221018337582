import React from 'react';
import { IconNameType, QBox, QButton, QIcon } from '@qualio/ui-components';

type Props = {
  onMove: (direction: 'up' | 'down') => void;
  onDelete: () => void;
  disableUp?: boolean;
  disableDown?: boolean;
  disableDelete?: boolean;
  'data-cy'?: string;
  removeIcon?: Extract<IconNameType, 'Trash' | 'X'>;
};

const ListMovementControls = (props: Props) => {
  const {
    onMove,
    onDelete,
    disableUp = false,
    disableDown = false,
    disableDelete = false,
    removeIcon = 'Trash',
  } = props;

  return (
    <QBox display="flex" flexDirection="row" justifyContent="space-between">
      <QButton
        aria-label="Move up"
        data-cy={`move-up-${props['data-cy']}`}
        variant="ghost"
        onClick={() => onMove('up')}
        isDisabled={disableUp}
      >
        <QIcon iconName="ArrowUp" />
      </QButton>
      <QButton
        aria-label="Move down"
        data-cy={`move-down-${props['data-cy']}`}
        variant="ghost"
        onClick={() => onMove('down')}
        isDisabled={disableDown}
      >
        <QIcon iconName="ArrowDown" />
      </QButton>
      <QButton
        aria-label="Delete"
        data-cy={`delete-${props['data-cy']}`}
        variant="ghost"
        onClick={onDelete}
        isDisabled={disableDelete}
      >
        <QIcon iconName={removeIcon} />
      </QButton>
    </QBox>
  );
};

export default ListMovementControls;
