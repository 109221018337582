import * as R from 'ramda';

export const Noop = R.always(undefined);

/**
 * Modified version of R.toString that returns the original value if it is a string.
 * This is because the ramda version will wrap strings in quotes.
 */
export const modifiedToString = <T>(value: T): string => {
  if (typeof value === 'string') {
    return value;
  }

  return R.toString(value);
};

/**
 * Straight forward, but returns a function that will give you a new deep copy
 * of the initial value.
 */
export const alwaysClone = <T>(value: T) => {
  // Copying initial value to make sure no mutations are possible between calls
  const copiedVal = R.clone(value);

  return () => R.clone(copiedVal);
};
