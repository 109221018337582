import { elementAtIndex } from '../../../../utils/arrayUtils';
import { QualioContentSection, QualioSectionedContent } from '../models';
import { StringEscapeUtils } from './StringEscapeUtils';

export class PresentationUtils {
  static determineEditorContentFromSectionsData(data: QualioSectionedContent): string {
    return data.sections
      .map((section) => {
        const sectionTitle = PresentationUtils.determineSectionTitleTag(section);
        return `${sectionTitle}${
          section.type === 'resolution' ? PresentationUtils.resolutionCheckboxContent() : section.content || '<p></p>'
        }`;
      })
      .join('');
  }
  static resolutionCheckboxContent(): string {
    return '<h4 style="text-align:center;"><i><strong>What is the outcome of this event?</strong></i></h4><p><input type="checkbox" tabindex="-1">Success</p><p><input type="checkbox" tabindex="-1">Failure</p><p>&nbsp;</p>';
  }
  static determineSectionTitleTag(section: QualioContentSection): string {
    return `<h1 class="section-heading"><span class="locked-section-headers">${
      section.position
    }. ${PresentationUtils.processTitleForView(section.title)}</span></h1>`;
  }

  static processTitleForView(title: string): string {
    // replace multiple spaces with a single space
    const titleWithNoDoubleSpaces = title.replace(/\s\s+/g, ' ');
    return StringEscapeUtils.specialEscapeForCkEditor(titleWithNoDoubleSpaces).trim();
  }

  static splitEditorTextIntoSections(
    editorText: string,
    originalQualioContent: QualioSectionedContent,
  ): { sectionIndex: number; sectionText: string }[] {
    const results: { sectionIndex: number; sectionText: string }[] = [];

    const documentTextSectionCount = originalQualioContent.sections.length;
    if (documentTextSectionCount === 0) {
      return results;
    }

    const sectionParsingInfoList = originalQualioContent.sections.map((section, sectionIndex) => {
      return {
        sectionIndex,
        sectionTitleTag: PresentationUtils.determineSectionTitleTag(section),
      };
    });

    const lastSectionIndex = elementAtIndex(sectionParsingInfoList, sectionParsingInfoList.length - 1).sectionIndex;
    //ckeditor HTML encodes characters, decode them before comparing against what we got from the server originally
    let textToProcess = editorText;

    sectionParsingInfoList.forEach(({ sectionTitleTag, sectionIndex }, currentInfoListIndex) => {
      if (!textToProcess.startsWith(sectionTitleTag)) {
        throw new Error(
          `could not locate section title "${sectionTitleTag}" at start of editor text "${textToProcess}"`,
        );
      }

      let currentSectionText = '';
      if (sectionIndex !== lastSectionIndex) {
        const nextSectionTitle = elementAtIndex(sectionParsingInfoList, currentInfoListIndex + 1).sectionTitleTag;
        const nextSectionStartIndex = textToProcess.indexOf(nextSectionTitle);

        if (nextSectionStartIndex === -1) {
          throw new Error(`could not locate section title "${nextSectionTitle}" in editor text "${textToProcess}"`);
        }

        currentSectionText = textToProcess.substring(sectionTitleTag.length, nextSectionStartIndex);
        textToProcess = textToProcess.substring(nextSectionStartIndex);
      } else {
        currentSectionText = textToProcess.substring(sectionTitleTag.length);
        textToProcess = '';
      }

      results.push({ sectionIndex, sectionText: currentSectionText });
    });

    return results;
  }
}
