import React from 'react';
import { StandardValueObject, SubComponentProps, ViewModeDictionary } from '../../EventFormField.types';
import ReadonlyWrapper from '../ReadOnlyWrapper';
import DropdownFieldMultiSelect from './DropdownFieldMultiSelect';
import DropdownFieldSingleSelect from './DropdownFieldSingleSelect';
import { ConvertStringOptionsToQSelectOptions } from './shared';
import { QText } from '@qualio/ui-components';
import { v4 } from 'uuid';

/**
 * Main component for a DropdownField, handles readonly mode,
 * logs and returns in interactive mode if no options provided and if options are
 * provided it will render either a SingleSelect or MultiSelect component depending on the
 * multi prop.
 */
const DropdownField = (props: SubComponentProps) => {
  const { onChange, fieldId, mode, options, multi } = props;

  // TODO:  QEV-2813
  const fieldValues = props.fieldValues as StandardValueObject[];

  if (mode === ViewModeDictionary.READONLY) {
    return (
      <ReadonlyWrapper>
        {fieldValues.length ? (
          fieldValues.map((fieldVal) => {
            /**
             * Falling back to generated key if no id is provided, as this is only
             * used in readonly mode we should always have an id here, but I'm defaulting
             * to a v4 because it is not technically impossible from a typescript perspective
             */
            return (
              <QText key={fieldVal.id ?? v4()} fontSize="sm">
                {fieldVal.value}
              </QText>
            );
          })
        ) : (
          <QText fontSize="sm">--</QText>
        )}
      </ReadonlyWrapper>
    );
  }

  if (!options) {
    console.log('DropdownField has no options');

    return null;
  }

  const DropdownSubComponent = multi ? DropdownFieldMultiSelect : DropdownFieldSingleSelect;

  return (
    <DropdownSubComponent
      fieldValues={fieldValues}
      onChange={onChange}
      fieldId={fieldId}
      options={ConvertStringOptionsToQSelectOptions(options)}
    />
  );
};

export default DropdownField;
