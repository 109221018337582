import { useQuery } from 'react-query';
import { useCurrentUser, useToastProvider } from '@qualio/ui-components';
import { useFlags } from '../external/LaunchDarklyAdapter';
import { DefaultErrorMessage } from '../displayStrings';
import rootCausesApi from '../api/rootCauses.api';
import { RegistryCategoryMatrixID } from '../types/registry';
import { useRegistryItems } from './useRegistryItems';

export const useRootCauses = (eventRootCauseId?: string | number) => {
  const { companyId } = useCurrentUser();
  const isRegistryProductRootCauseEnabled = useFlags('registryProductRootCause');
  const { showToast } = useToastProvider();

  const newRegistryRootCausesQueryResult = useRegistryItems(
    RegistryCategoryMatrixID.ROOT_CAUSE_MATRIX_ID,
    eventRootCauseId,
  );
  const oldRootCausesQueryResult = useQuery({
    queryKey: ['rootCausesList', companyId],
    queryFn: () => rootCausesApi.getAllRootCauses(companyId, {}),
    enabled: !isRegistryProductRootCauseEnabled,
    select: (data) => data?.map((item) => ({ ...item, status: 'effective' })),
    onError: () => {
      showToast({
        title: 'Could not retrieve RootCauses',
        description: DefaultErrorMessage,
        status: 'error',
      });
    },
  });

  return isRegistryProductRootCauseEnabled ? newRegistryRootCausesQueryResult : oldRootCausesQueryResult;
};
