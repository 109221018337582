import { useQuery } from 'react-query';
import { useToastProvider } from '@qualio/ui-components';
import { Document } from '../types';
import documentsApi from '../api/documents.api';
import { DefaultErrorMessage } from '../displayStrings';

type UseDocumentDetailsType = {
  document: Document | undefined;
  isDocumentLoading: boolean;
};

export const useDocumentDetails = (documentId: number | null): UseDocumentDetailsType => {
  const { showToast } = useToastProvider();

  const { data: document, isLoading: isDocumentLoading } = useQuery(
    ['documentDetails', documentId],
    () => documentsApi.getDocumentDetails(documentId as number),
    {
      enabled: !!documentId,
      select: (data) => ({ ...data, audits: data?.audits.sort((a, b) => b.timestamp - a.timestamp) }) as Document,
      onError: () => {
        showToast({
          description: DefaultErrorMessage,
          status: 'error',
        });
      },
    },
  );

  return { document, isDocumentLoading };
};
