import React from 'react';
import {
  QButton,
  QCloseButton,
  QSelect,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QText,
  QSelectItem,
} from '@qualio/ui-components';
import { StepInputState } from '../../../types/eventTemplateStepInputState';
import { EventStepType } from '../../../types/eventStep';

type ValidationStepTypeModalProps = {
  stepInputState: StepInputState;
  currentType: EventStepType;
  closeInput: () => void;
  submit: (stepType: 'document' | 'form') => void;
};

type ValidationStepType = Extract<EventStepType, 'document' | 'form'>;

type ValidationStepTypeDropdown = QSelectItem<'Content' | 'Form', ValidationStepType>;

const ValidationStepTypeModal = ({ stepInputState, currentType, closeInput, submit }: ValidationStepTypeModalProps) => {
  const [stepType, setStepType] = React.useState<ValidationStepType>(currentType as ValidationStepType);

  return (
    <QModal isOpen={stepInputState.type === 'validation-type-change'} onClose={closeInput} size="xl">
      <QModalHeader>
        <QText>Change validation step type</QText>
        <QCloseButton onClick={closeInput}></QCloseButton>
      </QModalHeader>
      <QModalBody>
        <QSelect
          name="step-type"
          options={
            [
              { value: 'document', label: 'Content' },
              { value: 'form', label: 'Form' },
            ] as ValidationStepTypeDropdown[]
          }
          value={stepType}
          onChange={(changeObject) => {
            const newValue = changeObject ? changeObject.value : 'document';

            setStepType(newValue);
          }}
        />
      </QModalBody>
      <QModalActions>
        <QButton onClick={closeInput} variant="outline">
          Cancel
        </QButton>
        <QButton onClick={() => submit(stepType)}>Save</QButton>
      </QModalActions>
    </QModal>
  );
};

export default ValidationStepTypeModal;
