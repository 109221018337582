import React from 'react';
import { QBox, QText, QIcon, QFlex } from '@qualio/ui-components';

type StepHeaderProps = {
  title: string;
  status: string;
  headerButton?: React.ReactNode;
  createTaskButton?: React.ReactNode;
  completeStepButton?: React.ReactNode;
  revertStepButton?: React.ReactNode;
};

const StepHeader: React.FC<StepHeaderProps> = ({
  title,
  status,
  headerButton,
  createTaskButton,
  completeStepButton,
  revertStepButton,
}) => {
  const getHeaderIcon = (status: string) => {
    switch (status) {
      case 'completed':
        return <QIcon iconName="CheckCircleFilled" size="md" color="green.500" />;
      case 'closed':
        return <QIcon iconName="Slash" size="md" color="red.200" />;
      default:
        return <QIcon iconName="CheckCircleFilled" size="md" color="blackAlpha.200" />;
    }
  };
  const openStep = status === 'open';
  return (
    <QBox h="48px" pr={5} backgroundColor="gray.50" borderColor="gray.200" borderBottomWidth="1px">
      <QFlex h="100%" alignItems="center" justifyContent={'space-between'}>
        <QFlex h="100%" alignItems="center" ml={4}>
          {getHeaderIcon(status)}
          <QText ml={2} color="gray.700" fontWeight="semibold">
            {title}
          </QText>
        </QFlex>
        <QBox>
          {headerButton}
          {openStep && createTaskButton}
          {openStep && completeStepButton}
          {!openStep && revertStepButton}
        </QBox>
      </QFlex>
    </QBox>
  );
};

export default StepHeader;
