import React from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { QBadge, QBox, QFlex, QHeading, QMultiSelect, QStack, QText } from '@qualio/ui-components';
import EventFormFooter from '../EventFormFooter';
import EventFormField from '../../../../components/EventFormField/EventFormField';
import { ViewModeDictionary } from '../../../../components/EventFormField/EventFormField.types';
import { Link } from 'react-router-dom';
import { createPerson, createSection, makeUsersOptions } from '../../../../utils/eventFormPropertiesUtil';
import { Form, QualityEvent, User } from '../../../../types';
import { EventForm, EventFormSchema, EventFormFieldObject } from './types';
import { CreateFieldValue } from '../../../../types/formFields/forms';

type EventFormBodyProps = {
  form: Form;
  event: QualityEvent;
  validApproversList: User[];
  validReviewersList: User[];
  existingApprovers: number[];
  existingReviewers: number[];
  onSubmit: (values: CreateFieldValue[], reviewers: number[], approvers: number[]) => void;
};

const EventFormBody = (props: EventFormBodyProps) => {
  const formMethods = useForm<EventForm>({
    mode: 'onChange',
    resolver: zodResolver(EventFormSchema),
    defaultValues: EventFormSchema.parse({
      fields: props.form.fields,
      reviewers: props.existingReviewers,
      approvers: props.existingApprovers,
    }),
  });

  const { control, handleSubmit, formState } = formMethods;
  const { isValid } = formState;
  const { fields } = useFieldArray({ control, name: 'fields', keyName: 'customId' });
  const saveForm = handleSubmit(
    (data) =>
      props.onSubmit(
        data.fields.map((field) => field.values).flat(),
        data.reviewers.map(Number),
        data.approvers.map(Number),
      ),
    (errors) => console.error(errors),
  );

  const renderFormField = (formField: EventFormFieldObject, index: number) => {
    return (
      <Controller
        key={formField.id}
        name={`fields.${index}`}
        control={control}
        render={({ field: reactFormField }) => {
          const { onChange, value: fieldInfo } = reactFormField;

          return (
            <EventFormField
              fieldId={fieldInfo.id}
              mode={ViewModeDictionary.INTERACTIVE}
              inputType={fieldInfo.type}
              helper={fieldInfo.helptext ?? undefined}
              label={fieldInfo.label}
              isRequired={fieldInfo.mandatory}
              fieldValues={fieldInfo.values}
              multi={fieldInfo.multi}
              resourceSubType={fieldInfo.resource_sub_type}
              options={
                fieldInfo.type === 'dropdown' || fieldInfo.type === 'checkbox' || fieldInfo.type === 'radio'
                  ? fieldInfo.options
                  : undefined
              }
              onChange={(valueArray) => {
                const updatedField = {
                  ...fieldInfo,
                  values: [...valueArray],
                };

                onChange(updatedField);
              }}
              data-cy={`input-${fieldInfo.type}`}
            />
          );
        }}
      />
    );
  };

  const createApprovers = (approversType: string) => {
    const isApprover = approversType === 'approvers';
    const usersOptions = isApprover ? props.validApproversList : props.validReviewersList;
    return (
      <Controller
        key={approversType}
        name={isApprover ? 'approvers' : 'reviewers'}
        control={control}
        render={({ field: { ref: _ref, onChange, ...field } }) => {
          return (
            <QMultiSelect
              {...field}
              onChange={(changeObject) => changeObject && onChange(changeObject.map((v) => Number(v.value)))}
              options={makeUsersOptions(usersOptions)}
              aria-label={approversType}
            />
          );
        }}
      />
    );
  };

  return (
    <>
      <QFlex padding={6} justifyContent="center" alignItems="flex-start" gap={10}>
        <QBox w="640px">
          <QStack direction="column" spacing={8}>
            {fields.map(renderFormField)}
          </QStack>
        </QBox>
        <QBox width="280px">
          <QBox pb={6}>
            <QHeading size={'sm'} color="gray.700">
              Properties
            </QHeading>
          </QBox>
          {createSection(
            'Event title',
            <Link to={`/events/${props.event.id}`}>
              <QText>{props.event.title}</QText>
            </Link>,
          )}
          {createSection('Event ID', <QBadge color="gray.500">{props.event.code}</QBadge>)}
          {createSection('Assigned to', createPerson({ fullName: props.form.owner_full_name!, status: 'default' }))}
          {createSection('Reviewers', createApprovers('reviewers'))}
          {createSection('Approvers', createApprovers('approvers'))}
        </QBox>
      </QFlex>
      <EventFormFooter isDisabled={!isValid} saveForm={saveForm} />
    </>
  );
};

export default EventFormBody;
