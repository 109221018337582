import React from 'react';
import { QBox, QTag, QStack, QText, QIcon, QTagProps } from '@qualio/ui-components';
import { Audit, AuditGroup } from '../../../types';
import { getLastApproverAudit } from '../../../utils/auditUtils';

type AssigneesListProps = {
  label: string;
  assignees: AuditGroup[];
  audits: Audit[];
};

const AssigneesList: React.FC<AssigneesListProps> = ({ label, assignees, audits }) => {
  const getAssigneeIndicator = (
    auditAction: string,
  ): { variantColor: QTagProps['variantColor']; icon: React.ReactNode } => {
    switch (auditAction) {
      case 'doc_status_change_for_approval-approved':
        return { variantColor: 'gray', icon: <QIcon iconName="CheckCircleFilled" color="green.500" /> };
      case 'doc_status_change_for_approval-approval_declined':
        return { variantColor: 'red', icon: <QIcon iconName="Slash" color="red.500" /> };
      default:
        return { variantColor: 'gray', icon: <QIcon iconName="HelpCircle" /> };
    }
  };

  return (
    <QStack spacing={3}>
      <QBox>
        <QText fontWeight="semibold" fontSize="xs" color="gray.800">
          {label}
        </QText>
      </QBox>
      {assignees.map((assignee) => {
        const lastAudit = getLastApproverAudit(assignee.id, audits);
        const { variantColor, icon } = getAssigneeIndicator(lastAudit ? lastAudit.action : '');

        return (
          <QTag variantColor={variantColor} key={assignee.id}>
            {icon}
            <QText fontSize="xs" color="gray.800" fontWeight="normal" ml={1}>
              {assignee.full_name}
            </QText>
          </QTag>
        );
      })}
    </QStack>
  );
};

export default AssigneesList;
