import React from 'react';
import { QButton, QStack } from '@qualio/ui-components';
import * as DisplayStrings from '../../../displayStrings';

type EventFormFooterProps = {
  saveForm: () => void;
  isDisabled?: boolean;
};

const EventFormFooter: React.FC<EventFormFooterProps> = ({ saveForm, isDisabled = false }) => {
  return (
    <QStack direction={['column', 'row']} pt="2" justifyContent="flex-end">
      <QButton data-cy={'save-and-exit'} isDisabled={isDisabled} onClick={saveForm}>
        {DisplayStrings.SaveAndExit}
      </QButton>
    </QStack>
  );
};

export default EventFormFooter;
