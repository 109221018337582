import { QText } from '@qualio/ui-components';
import React from 'react';
import { SectionErrors } from '../../../types';

type ContentBoxErrorsProps = {
  sectionErrors: SectionErrors;
};

const ContentBoxErrors = (props: ContentBoxErrorsProps) => {
  return (
    <div>
      {props.sectionErrors.titleBlank && <QText color={'red'}>Empty titles are not allowed</QText>}
      {props.sectionErrors.duplicateTitle && (
        <QText color={'red'}>Duplicate titles are not allowed. Please use unique titles.</QText>
      )}
      {props.sectionErrors.invalidCharacters !== '' && (
        <QText color={'red'}>Invalid character(s) in title: {props.sectionErrors.invalidCharacters}</QText>
      )}
    </div>
  );
};

export default ContentBoxErrors;
