import { Plugin } from '@ckeditor/ckeditor5-core';

export class QualioSectionHeadingPlugin extends Plugin {
  static get pluginName(): string {
    return 'QualioSectionHeadingPlugin';
  }

  init(): void {
    this._setupSchema();
  }

  _setupSchema(): void {
    const editor = this.editor;

    editor.model.schema.register('sectionHeading', {
      // Cannot be split or left by the caret.
      isLimit: true,
      allowIn: '$root',
      // Allow content which is allowed in blocks (i.e. text with attributes).
      allowContentOf: '$block',
    });

    editor.conversion.elementToElement({
      model: 'sectionHeading',
      view: {
        name: 'h1',
        classes: 'section-heading',
      },
      converterPriority: 'highest',
    });
  }
}
