import React, { useMemo } from 'react';
import { QBox, QMenuButton, QMenuItemType, useCurrentUser } from '@qualio/ui-components';
import { useEventPermissions } from '../../../hooks';

type menuProps = {
  formStatus: string;
  formOwnerId: number;
  editMode: boolean;
  onSendForReview: () => void;
  onReassignStep: () => void;
  onManageReviewers: () => void;
  onManageApprovers: () => void;
  onRevertToDraft: () => void;
};

const menuItems = {
  sendForReview: {
    id: 'send-for-review',
    label: 'Send for review',
    'data-cy': 'send-for-review',
  },
  reassignStep: {
    id: 'reassign-step',
    label: 'Reassign step',
    'data-cy': 'reassign-step',
  },
  manageReviewers: {
    id: 'manage-reviewers',
    label: 'Manage reviewers',
    'data-cy': 'manage-reviewers',
  },
  manageApprovers: {
    id: 'manage-approvers',
    label: 'Manage approvers',
    'data-cy': 'manage-approvers',
  },
  revertToDraft: {
    id: 'revert-to-draft',
    label: 'Revert to draft',
    'data-cy': 'revert-to-draft',
  },
};

const EventFormDetailsMenu: React.FC<menuProps> = ({
  formStatus,
  formOwnerId,
  editMode,
  onSendForReview,
  onReassignStep,
  onManageReviewers,
  onManageApprovers,
  onRevertToDraft,
}) => {
  const { isQuality } = useEventPermissions();
  const { userId } = useCurrentUser();

  const handleMenuClick = (item: QMenuItemType) => {
    if (item.id === 'send-for-review') {
      onSendForReview();
    }
    if (item.id === 'reassign-step') {
      onReassignStep();
    }
    if (item.id === 'manage-reviewers') {
      onManageReviewers();
    }
    if (item.id === 'manage-approvers') {
      onManageApprovers();
    }
    if (item.id === 'revert-to-draft') {
      onRevertToDraft();
    }
  };

  const renderMenuItems = useMemo(() => {
    const isStatus = (expected: string[]) => expected.includes(formStatus);
    const isOwner = formOwnerId === userId;
    const canViewMenuItem = isOwner || isQuality;
    const items = [];
    if (!canViewMenuItem) {
      return [];
    }

    if (editMode || isStatus(['approval_declined'])) {
      return [menuItems.reassignStep];
    }

    if (isOwner && isStatus(['draft'])) {
      items.push(menuItems.sendForReview);
    }

    if (isStatus(['draft', 'for_review'])) {
      items.push(menuItems.reassignStep);
      items.push(menuItems.manageReviewers);
      items.push(menuItems.manageApprovers);
    }

    if (isStatus(['for_approval'])) {
      items.push(menuItems.reassignStep);
    }

    if (isOwner && isStatus(['for_approval', 'for_review'])) {
      items.push(menuItems.revertToDraft);
    }
    return items;
  }, [formOwnerId, userId, isQuality, editMode, formStatus]);

  return (
    <>
      {renderMenuItems.length > 0 && (
        <QBox data-cy="form-options">
          <QMenuButton
            buttonLabel="Options"
            iconName="MoreVertical"
            itemSize="sm"
            items={renderMenuItems}
            onItemClick={handleMenuClick}
            variant="icon"
          />
        </QBox>
      )}
    </>
  );
};

export default EventFormDetailsMenu;
