import * as z from 'zod';
import { gtTenYearsDaysErrorMessage, ltZeroDaysErrorMessage, tenYearsDays } from './common/constants';

export type UserTaskStatus = 'open' | 'closed_success' | 'closed_fail';

export const UserTask = z.object({
  id: z.number(),
  status: z.string(),
  title: z.string(),
  description: z.string().optional(),
  owner: z.string(),
  owner_id: z.number(),
  creator: z.string(),
  creator_id: z.number(),
  created_at: z.number(),
  updated_at: z.number(),
  closed_at: z.number().nullable(),
  time_limit: z.preprocess((val) => Number(val), z.number()),
  type: z.string(),
  related_to_id: z.number(),
  related_to_url: z.string(),
});

export const UpdateTask = UserTask.pick({ title: true, description: true, time_limit: true }).extend({
  owner_id: z.string(),
  time_limit: z.preprocess(
    (val) => Number(val),
    z.number().max(tenYearsDays, gtTenYearsDaysErrorMessage).min(0, ltZeroDaysErrorMessage),
  ),
});

export const UserTasksRequest = z.object({
  status: z.array(z.string()).optional(),
  filter_by_issue_step_ids: z.string().optional(),
  order_by: z.string().optional(),
});

export const UserTasksListResponse = z.object({
  items: z.array(UserTask),
  total: z.number(),
});

export const UserTaskDetails = UserTask.extend({
  related_issue_id: z.number(),
  related_issue_code: z.string(),
});

export const UserTaskComment = z.object({
  content: z.string(),
  id: z.number(),
  sent_at: z.number(),
  user_full_name: z.string(),
  user_id: z.number(),
});

export const UserTaskCommentResponse = z.object({
  items: z.array(UserTaskComment),
  total: z.number(),
});

export const AddUserTaskCommentPayload = z.object({
  comment: z.string(),
});

export const AddUserTaskCommentResponse = z.object({
  content: z.string(),
  id: z.number(),
  sent_at: z.number(),
  user_id: z.number(),
});

export type UpdateTask = z.infer<typeof UpdateTask>;
export type UserTask = z.infer<typeof UserTask>;
export type UserTaskDetails = z.infer<typeof UserTaskDetails>;
export type UserTasksRequest = z.infer<typeof UserTasksRequest>;
export type UserTasksListResponse = z.infer<typeof UserTasksListResponse>;
export type UserTaskComment = z.infer<typeof UserTaskComment>;
export type UserTaskCommentResponse = z.infer<typeof UserTaskCommentResponse>;
export type AddUserTaskCommentPayload = z.infer<typeof AddUserTaskCommentPayload>;
export type AddUserTaskCommentResponse = z.infer<typeof AddUserTaskCommentResponse>;
