import { QBox, QButton, QDivider, QStack } from '@qualio/ui-components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { EditContentTemplatePages } from '../../../views/EditContentTemplate/EditContentTemplatePages';

export type EditContentTemplateFooterProps = {
  page: EditContentTemplatePages;
  nextPage: () => void;
  onSubmit: () => void;
  previousPage: () => void;
  submitInProgress: boolean;
  isLoaded: boolean;
  templateId: string;
  contentTemplateId: string;
};

const EditContentTemplateFooter = (props: EditContentTemplateFooterProps) => {
  const navigate = useNavigate();

  const cancel = () => {
    navigate(`../templates/${props.templateId}`);
  };

  const isLastPage = () => {
    return props.page === EditContentTemplatePages.DefaultContent;
  };

  return (
    <>
      <QDivider orientation="horizontal" />
      <QBox pt={6}>
        <QStack direction={['column', 'row']} display="flex" justifyContent="flex-end" gap={'14px'}>
          <QButton variant="link" onClick={cancel} data-cy="cancelButton">
            Cancel
          </QButton>
          <QButton
            isDisabled={props.page === EditContentTemplatePages.DefaultSections}
            variant="outline"
            onClick={props.previousPage}
            data-cy="previousButton"
          >
            Previous
          </QButton>
          {isLastPage() && (
            <QButton
              variant="solid"
              onClick={props.onSubmit}
              isDisabled={props.submitInProgress}
              data-cy="createButton"
            >
              Update template
            </QButton>
          )}
          {!isLastPage() && (
            <QButton
              isDisabled={!props.isLoaded}
              variant="solid"
              onClick={props.nextPage}
              type="button"
              data-cy="nextButton"
            >
              Next
            </QButton>
          )}
        </QStack>
      </QBox>
    </>
  );
};

export default EditContentTemplateFooter;
