import * as z from 'zod';

export const Smartlink = z.object({
  title: z.string(),
  code: z.string().min(1),
  entity_type: z.string().min(1),
  uuid: z.string(),
});

export type Smartlink = z.infer<typeof Smartlink>;
