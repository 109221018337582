import { useQuery } from 'react-query';
import { useCurrentUser, useToastProvider } from '@qualio/ui-components';
import eventDetailsApi from '../api/eventDetails.api';
import { DefaultErrorMessage } from '../displayStrings';

export const useEventDetails = (eventId: number) => {
  const { companyId } = useCurrentUser();
  const { showToast } = useToastProvider();

  return useQuery(['eventDetails', eventId], () => eventDetailsApi.getEventDetails(companyId, eventId), {
    onError: () => {
      showToast({
        description: DefaultErrorMessage,
        status: 'error',
      });
    },
  });
};
