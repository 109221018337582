import { QBox, QPerson, QPersonProps, QText } from '@qualio/ui-components';
import React from 'react';
import { User } from '../types';

export const createSection = (title: string, children: React.ReactNode) => {
  return (
    <QBox data-cy={`form-details-${title.toLowerCase().replace(' ', '-')}-section`} pb={4}>
      <QText fontWeight={600} color="gray.700">
        {title}
      </QText>
      {children}
    </QBox>
  );
};

export const createPerson = (personParams: QPersonProps) => {
  return (
    <QBox pt={2}>
      <QPerson
        status={personParams.status}
        fullName={personParams.fullName}
        showPendingStatus={personParams.showPendingStatus}
        actionTimestamp={personParams.actionTimestamp}
      />
    </QBox>
  );
};

export const makeUsersOptions = (usersData: User[]) => {
  return usersData
    .map((user) => ({ value: user.usercompany_id, label: user.full_name }))
    .sort((a, b) => a.label.localeCompare(b.label));
};
