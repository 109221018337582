import React from 'react';
import { QBox, QCenter, QSpinner } from '@qualio/ui-components';

type StepBodyProps = {
  isLoading: boolean;
  children?: React.ReactNode;
};

const StepBody: React.FC<StepBodyProps> = ({ isLoading, children }) => {
  return (
    <QBox minHeight="270px">
      {isLoading ? (
        <QCenter pt={10}>
          <QSpinner />
        </QCenter>
      ) : (
        children
      )}
    </QBox>
  );
};

export default StepBody;
