import React from 'react';
import { FfcChildProps, FfcProps } from './shared';
import BasicFieldConfiguration from './components/BasicFieldConfiguration';
import DropdownFieldConfiguration from './components/DropdownFieldConfiguration';
import SectionFieldConfiguration from './components/SectionFieldConfiguration';
import DefaultContentFieldConfiguration from './components/DefaultContentFieldConfiguration';
import CheckboxFieldConfiguration from './components/CheckboxFieldConfiguration';
import RadioFieldConfiguration from './components/RadioFieldConfiguration';
import { FieldType } from '../../../../types/formFields/common';
import QLookupConfiguration from './components/QLookupConfiguration';

const ConfigurationComponentMap = {
  text: BasicFieldConfiguration,
  numeric: BasicFieldConfiguration,
  boolean: BasicFieldConfiguration,
  date: BasicFieldConfiguration,
  attachment: BasicFieldConfiguration,
  rich_text: DefaultContentFieldConfiguration,
  dropdown: DropdownFieldConfiguration,
  section: SectionFieldConfiguration,
  checkbox: CheckboxFieldConfiguration,
  radio: RadioFieldConfiguration,
  document: QLookupConfiguration,
  event: QLookupConfiguration,
  supplier: QLookupConfiguration,
  user: QLookupConfiguration,
  change_request: QLookupConfiguration,
  registry: QLookupConfiguration,
  design_controls: QLookupConfiguration,
} as const satisfies Record<FieldType, React.FC<FfcChildProps> | null>;

const FormFieldConfiguration = ({ fieldIndex, fieldType }: FfcProps) => {
  const FieldConfigurationComponent = ConfigurationComponentMap[fieldType];

  if (!FieldConfigurationComponent) {
    console.log('Attempted to use a component that is not implemented yet');
    return null;
  }

  return <FieldConfigurationComponent fieldIndex={fieldIndex} />;
};

export default FormFieldConfiguration;
