import { useQuery } from 'react-query';
import { useToastProvider } from '@qualio/ui-components';
import { UserTaskDetails } from '../types';
import userTasksApi from '../api/userTasks.api';
import { DefaultErrorMessage } from '../displayStrings';

type UseTaskDetailsType = {
  task: UserTaskDetails | undefined;
  isTaskLoading: boolean;
};

export const useTaskDetails = (
  companyId: number,
  taskId: number,
  enabled = true,
  onSuccess?: (data: UserTaskDetails) => void,
): UseTaskDetailsType => {
  const { showToast } = useToastProvider();

  const { data: task, isLoading: isTaskLoading } = useQuery(
    ['taskDetails', taskId],
    () => userTasksApi.getUserTaskDetails(companyId, taskId),
    {
      onError: () => {
        showToast({
          description: DefaultErrorMessage,
          status: 'error',
        });
      },
      onSuccess: (data: UserTaskDetails) => {
        if (onSuccess) {
          onSuccess(data);
        }
      },
      enabled: enabled && !!taskId,
    },
  );

  return { task, isTaskLoading };
};
