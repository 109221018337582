import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { FormEditorForm } from '../../../shared';
import { QFormControl } from '@qualio/ui-components';
import DefaultContentField from '../../DefaultContentField';

type Props = {
  control: UseFormReturn<FormEditorForm>['control'];
  fieldIndex: number;
  error?: string;
};

const FieldDefaultContent = ({ control, fieldIndex, error }: Props) => {
  return (
    <QFormControl isInvalid={Boolean(error)} error={error}>
      <Controller
        name={`fields.${fieldIndex}.default_content`}
        control={control}
        render={({ field }) => (
          <DefaultContentField
            data-cy={`default-content-${fieldIndex}`}
            description={field.value ?? ''}
            changeDefaultContent={field.onChange}
          />
        )}
      />
    </QFormControl>
  );
};

export default FieldDefaultContent;
