import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { useToastProvider, useCurrentUser } from '@qualio/ui-components';
import formsApi from '../api/forms.api';
import { extractMessageFromError } from '../utils/errorUtils';

export type MarkAsReviewedMutateType = {
  comment: string;
};

export const useMarkAsReviewed = (eventId: number, stepId: number, formId: number, onSuccess?: () => void) => {
  const { companyId } = useCurrentUser();
  const { showToast } = useToastProvider();
  const queryClient = useQueryClient();

  const mutationOptions = {
    onSuccess: () => {
      if (onSuccess) {
        onSuccess();
      }

      queryClient.invalidateQueries({ queryKey: ['reviewersList', formId] });
      queryClient.invalidateQueries({ queryKey: ['formDetails', formId] });

      showToast({
        title: 'Reviewed',
        description: 'You reviewed this step',
        status: 'success',
      });
    },
    onError: (error: AxiosError) => {
      showToast({
        title: 'Step not reviewed',
        description: extractMessageFromError(error),
        status: 'error',
      });
    },
  };

  return useMutation(
    ({ comment }: MarkAsReviewedMutateType) => formsApi.markAsReviewed(companyId, eventId, stepId, formId, comment),
    mutationOptions,
  );
};
