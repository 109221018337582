import * as z from 'zod';
import { TrimmedString } from './common/processed';

export const RootCause = z.object({
  id: z.string().min(1),
  name: TrimmedString,
  description: TrimmedString,
  companyId: z.string().optional(),
});

export type RootCause = z.infer<typeof RootCause>;

export const RootCauseListRowSchema = RootCause.extend({
  open_issues: z.number(),
  total_issues: z.number(),
});
export type RootCauseListRow = z.infer<typeof RootCauseListRowSchema>;

export interface RootCauseRequest {
  starts_with?: string;
}

export const CreateRootCause = RootCause.omit({ id: true });
export type CreateRootCause = z.infer<typeof CreateRootCause>;

// total_issues is optional, until MTBE is updated
export const RootCauseStats = z.object({
  rootcause_id: z.number(),
  open_issues: z.number(),
  total_issues: z.number().optional(),
});
export type RootCauseStats = z.infer<typeof RootCauseStats>;

export const RootCauseStatsResponseSchema = z.record(z.string(), RootCauseStats);
export type RootCauseStatsResponse = z.infer<typeof RootCauseStatsResponseSchema>;
