import React from 'react';
import { QButton } from '@qualio/ui-components';
import { useNavigate } from 'react-router-dom';

type ViewFormButtonProps = {
  eventId: number;
  stepId: number;
  formId: number;
};

const ViewFormButton: React.FC<ViewFormButtonProps> = ({ eventId, stepId, formId }) => {
  const navigate = useNavigate();

  return (
    <QButton
      variant="ghost"
      leftIcon="Search"
      onClick={() => navigate(`/events/${eventId}/steps/${stepId}/forms/${formId}`)}
      data-metrics="step-header-view-button"
      data-cy={'view-form'}
    >
      View
    </QButton>
  );
};

export default ViewFormButton;
