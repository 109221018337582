import React from 'react';
import { QFormControl, QInput, QStack, QTextarea } from '@qualio/ui-components';
import { Controller, UseFormReturn } from 'react-hook-form';
import { DigitalSignatureV2 } from '../../../types/digitalSignature';

type Props = {
  control: UseFormReturn<DigitalSignatureV2>['control'];
  isCommentRequired: boolean;
};

const DigitalSignatureForm: React.FC<Props> = ({ control, isCommentRequired }) => {
  return (
    <QStack direction="column" spacing={4}>
      <Controller
        control={control}
        name="email"
        render={({ field, fieldState }) => {
          return (
            <QFormControl
              label="Email"
              error={fieldState.error?.message}
              isInvalid={Boolean(fieldState.error)}
              isRequired
            >
              <QInput {...field} />
            </QFormControl>
          );
        }}
      />
      <Controller
        control={control}
        name="password"
        render={({ field, fieldState }) => {
          return (
            <QFormControl
              label="Password"
              error={fieldState.error?.message}
              isInvalid={Boolean(fieldState.error)}
              isRequired
            >
              <QInput role="textbox" type="password" {...field} />
            </QFormControl>
          );
        }}
      />
      <Controller
        control={control}
        name="comment"
        render={({ field, fieldState }) => {
          const { ref: _ref, ...fieldProps } = field;

          return (
            <QFormControl
              label="Comment"
              error={fieldState.error?.message}
              isInvalid={Boolean(fieldState.error)}
              isRequired={isCommentRequired}
            >
              <QTextarea data-cy="comment-input" {...fieldProps} />
            </QFormControl>
          );
        }}
      />
    </QStack>
  );
};

export default DigitalSignatureForm;
