import { CreateProduct, Product, ProductRequest, ProductStats, ProductStatsResponse } from '../types/product';
import api, { createRequestConfig, medtechApi } from './index';

const productsApi = {
  async getAllProducts(company: number, filterParams?: ProductRequest): Promise<Product[] | undefined> {
    const res = await api.get(
      `/company/${company}/products`,
      createRequestConfig({
        params: filterParams,
      }),
    );
    return res.data.data as Product[];
  },

  async create(company: number, payload: CreateProduct): Promise<void> {
    const response = await api.post(`/company/${company}/products`, payload);
    return response.data;
  },

  async update(company: number, product: Product): Promise<void> {
    const { id, ...payload } = product;
    const response = await api.patch(`/company/${company}/products/${id}`, payload);
    return response.data;
  },

  async delete(company: number, productId: string): Promise<void> {
    const response = await api.delete(`/company/${company}/products/${productId}`);
    return response.data;
  },

  async getAllProductsStats(company: number): Promise<ProductStatsResponse> {
    const response = await medtechApi.get(`/quality-events/${company}/stats/issues-per-product`);

    return Object.fromEntries(
      response.data
        .filter((item: ProductStats) => item.product_id !== null)
        .map((item: ProductStats) => {
          const parsed = ProductStats.parse(item);
          return [String(parsed.product_id), parsed];
        }),
    );
  },
};

export default productsApi;
