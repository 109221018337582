import React from 'react';
import StandardField from './components/StandardField';
import * as DisplayStrings from '../../../../displayStrings';
import { QStack } from '@qualio/ui-components';
import QObjectField from './components/QObjectField';
import { RegistryCategory } from '../../../../types/registry';
import { AddField } from '../../shared';

type AddFieldParams = {
  addField: AddField;
  registryCategories: RegistryCategory[];
};

const AddFieldButtons = (props: AddFieldParams) => {
  return (
    <QStack direction="row" spacing="24px">
      <StandardField addField={props.addField} buttonLabel={DisplayStrings.CreateNewField} variant="outline" />
      <QObjectField addField={props.addField} registryCategories={props.registryCategories} />
    </QStack>
  );
};

export default AddFieldButtons;
