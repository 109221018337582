import React, { useState } from 'react';
import { QBox, QButton, QHeading } from '@qualio/ui-components';
import DefaultContentModal from './DefaultContentModal';
import NonEditableRichText from '../../NonEditableRichText/NonEditableRichText';
import * as DisplayStrings from '../../../displayStrings';

type DefaultContentFieldProps = {
  description: string;
  changeDefaultContent: any;
};

const DefaultContentField = ({ description, changeDefaultContent }: DefaultContentFieldProps) => {
  const [showModal, setShowModal] = useState(false);

  const showDefaultContentModal = () => {
    setShowModal(true);
  };

  return (
    <>
      {description ? (
        <>
          <QBox display="flex" flexDirection="row" justifyContent="space-between">
            <QHeading size="xs">{DisplayStrings.DefaultContent}</QHeading>
            <QButton
              leftIcon="Edit3"
              variant="link"
              onClick={showDefaultContentModal}
              data-cy="edit-default-content-button"
            >
              {DisplayStrings.EditDefaultContent}
            </QButton>
          </QBox>
          <NonEditableRichText content={description} mode="READONLY" />
        </>
      ) : (
        <QButton variant="link" onClick={showDefaultContentModal} data-cy="add-default-content-button">
          {DisplayStrings.AddDefaultContent}
        </QButton>
      )}
      {showModal && (
        <DefaultContentModal
          setIfShow={setShowModal}
          description={description}
          changeDefaultContent={changeDefaultContent}
        />
      )}
    </>
  );
};

export default DefaultContentField;
