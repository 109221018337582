import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { FormEditorForm } from '../../../shared';
import { QFormControl } from '@qualio/ui-components';
import HelpTextField from '../../HelpTextField';

type Props = {
  control: UseFormReturn<FormEditorForm>['control'];
  fieldIndex: number;
  error?: string;
};

const FieldHelpText = ({ control, fieldIndex, error }: Props) => {
  return (
    <QFormControl isInvalid={Boolean(error)} error={error}>
      <Controller
        name={`fields.${fieldIndex}.helptext`}
        control={control}
        render={({ field }) => (
          <HelpTextField
            data-cy={`field-description-${fieldIndex}`}
            description={field.value ?? ''}
            changeHelpText={field.onChange}
          />
        )}
      />
    </QFormControl>
  );
};

export default FieldHelpText;
