import * as z from 'zod';
import { BaseSchema, ServerOnlyOmitKeys } from './common';
import { FieldTypeSchema } from '../common';

/**
 * All types in this file pertain to the template form fields (not the fields on
 * an instance of a form) that are "visual" fields, meaning that when a form field
 * is made from these template objects there is no actual input from the user.
 * They simply display static visuals. (Currently only "section" fields)
 */

export const VisualFieldSchema = BaseSchema.extend({
  type: FieldTypeSchema.extract(['section']),
  multi: z.literal(false),
  mandatory: z.literal(false),
});
export const NewVisualFieldSchema = VisualFieldSchema.omit(ServerOnlyOmitKeys);
