import React from 'react';
import otherStyles from '../ContentEditor/ContentEditor.module.css';
import { Parser } from 'html-to-react';
import styles from './EditorContentViewer.module.css';

export type EditorViewModes = 'READONLY' | 'DORMANT' | 'FOCUSED';

type EditorContentViewerProps = {
  content: string;
  mode?: EditorViewModes;
};

const baseClasses = `${styles['ck-restricted-editing_mode_restricted']} ck ck-content` as const;
const classNamesByMode = {
  READONLY: `${baseClasses} ck-editor__editable_inline--readonly`,
  DORMANT: `${baseClasses} ck-editor__editable_inline`,
  FOCUSED: `${baseClasses} ck-editor__editable_inline`,
} as const;

const htmlToReactParser = new Parser();

const EditorContentViewer: React.FC<EditorContentViewerProps> = ({ content, mode = 'FOCUSED' }) => {
  content = content.replace(/class="docreference"/g, 'class="docreference qualio_resource resource_document"');

  const htmlContent = htmlToReactParser.parse(content);
  const innerDivClass = classNamesByMode[mode];

  return (
    <div data-cy="contentViewer" className={`${otherStyles['sections-editor__document__section-content']} document`}>
      <div className={innerDivClass}>{htmlContent}</div>
    </div>
  );
};

export default EditorContentViewer;
