import { QAttachment } from '@qualio/ui-components';
import { attachmentAPIUrl } from '../api/attachments.api';

export const getAttachmentsAsHTMLList = (attachments: QAttachment[]) => {
  return attachments.map((file) => {
    return `<li class="froala-attachments-list_item">
          <a href="${generateAttachmentUrl(file.id)}" target="_blank" title="${file.filename}">${file.filename}</a>
        </li>`;
  });
};

export const buildAttachmentsHTML = (attachments: QAttachment[]) => {
  const urlList = getAttachmentsAsHTMLList(attachments);
  const attachmentClass = 'froala-attachments-list qualio-theme fr-desktop fr-top fr-basic fr-sticky-off fr-disabled';
  return urlList.length > 0
    ? `<div class="${attachmentClass}" contenteditable="false">
            <ul>${urlList.join(' ')}</ul>
         </div>`
    : '';
};

export const generateAttachmentUrl = (attachment_id: string) => {
  const url = new URL(attachmentAPIUrl);
  const attachmentURL = `${attachmentAPIUrl}/${attachment_id}`;
  return attachmentURL.replace(`${url.protocol}`, '');
};
