import * as z from 'zod';
import { TrimmedString } from './common/processed';

export const Product = z.object({
  id: z.string().min(1),
  name: TrimmedString,
  description: TrimmedString,
  companyId: z.string().optional(),
});

export type Product = z.infer<typeof Product>;

export const ProductListRowSchema = Product.extend({
  open_issues: z.number(),
  total_issues: z.number(),
});
export type ProductListRow = z.infer<typeof ProductListRowSchema>;

export interface ProductRequest {
  starts_with?: string;
}

export const CreateProduct = Product.omit({ id: true });
export type CreateProduct = z.infer<typeof CreateProduct>;

// total_issues is optional, until MTBE is updated
export const ProductStats = z.object({
  product_id: z.number(),
  open_issues: z.number(),
  total_issues: z.number().optional(),
});
export type ProductStats = z.infer<typeof ProductStats>;

export const ProductStatsResponseSchema = z.record(z.string(), ProductStats);
export type ProductStatsResponse = z.infer<typeof ProductStatsResponseSchema>;
