import React from 'react';
import { FormLabel } from '@chakra-ui/react';
import { QStack, QButton, QBox } from '@qualio/ui-components';
import * as DisplayStrings from '../../../../displayStrings';

const QObjectFormFieldLabel = ({
  label,
  showEditBtn,
  onEditBtnClick,
}: {
  label: string | undefined;
  showEditBtn: boolean;
  onEditBtnClick: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <QStack direction="row">
      <FormLabel fontWeight="semibold" fontSize="sm">
        {label}
      </FormLabel>
      {showEditBtn && (
        <QBox>
          <QButton variant="link" onClick={() => onEditBtnClick(true)}>
            {DisplayStrings.Edit}
          </QButton>
        </QBox>
      )}
    </QStack>
  );
};
export default QObjectFormFieldLabel;
