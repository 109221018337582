import React, { MutableRefObject, useCallback, useEffect, useMemo } from 'react';
import { useTable, useSortBy, Column, usePagination, SortingRule, TableOptions } from 'react-table';
import { Table, Tbody } from '@chakra-ui/react';
import { QRow, QTableHead, QPaginator } from './internal';

type QDataItem = string | number | boolean | Date | React.ReactElement;
export type QDataRow = { [name: string]: QDataItem };

export enum QColumnType {
  DATE = 'DATE',
  DATE_NO_YEAR_IF_CURRENT = 'DATE_NO_YEAR_IF_CURRENT',
  DATE_TIME = 'DATE_TIME',
  DATE_TIME_NO_YEAR_IF_CURRENT = 'DATE_TIME_NO_YEAR_IF_CURRENT',
}

export type QDataColumn = Column<QDataRow> & {
  type?: QColumnType;
};
export type QDefaultSortingColumn = { id: string; desc?: boolean };

export type RowCallback = (row: QDataRow) => void;

export type QDataTableProps = {
  columns: readonly QDataColumn[];
  data: readonly QDataRow[];
  onRowClick?: RowCallback;
  hasPagination?: boolean;
  hasPageSizeOptions?: boolean;
  /**
   * The number of rows to display per page when pagination is enabled.
   */
  pageSize?: number;
  manualPagination?: QManualPagination;
  manualSortBy?: QManualSortBy;
  refSetSortBy?: MutableRefObject<((columnAccessor: string, isSortedDesc: boolean | undefined) => void) | undefined>;
};

export type QManualPagination = {
  paginationCallback: (pageIndex: number, pageSize: number) => void;
  pageCount: number;
  initialPageIndex?: number;
  initialPageSize?: number;
};

export type QManualSortBy = {
  sortByCallback?: (sortParams: SortingRule<QDataRow>[]) => void;
  defaultSortByColumn?: QDefaultSortingColumn[];
};

export const QDataTable: React.FC<QDataTableProps> = ({
  columns,
  data,
  onRowClick,
  hasPagination = true,
  hasPageSizeOptions = false,
  pageSize: pageSizeOverride,
  manualPagination,
  manualSortBy,
  refSetSortBy,
}) => {
  const tableOptions = useMemo<TableOptions<QDataRow>>(() => {
    const options: TableOptions<QDataRow> = {
      columns,
      data,
      manualPagination: manualPagination !== undefined,
      manualSortBy: manualSortBy?.sortByCallback !== undefined,
      autoResetPage: false,
    };

    if (manualSortBy?.defaultSortByColumn) {
      options['initialState'] = {
        sortBy: manualSortBy?.defaultSortByColumn,
      };
    }

    if (manualPagination) {
      options['pageCount'] = manualPagination.pageCount;
      options['autoResetPage'] = false;
      options['initialState'] = {
        ...options['initialState'],
        pageIndex: manualPagination.initialPageIndex ?? 0,
        pageSize: manualPagination.initialPageSize ?? 10,
      };
    }

    if (pageSizeOverride) {
      options['initialState'] = {
        ...options['initialState'],
        pageSize: pageSizeOverride,
      };
    }

    return options;
  }, [columns, data, manualPagination, manualSortBy, pageSizeOverride]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    pageOptions,
    page,
    rows,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setPageSize,
    gotoPage,
    flatHeaders,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(tableOptions, useSortBy, usePagination);

  const items = useMemo(() => (hasPagination ? page : rows), [hasPagination, page, rows]);

  const setSortOrder = useCallback(
    (columnAccessor: string, isSortedDesc: boolean | undefined) => {
      const column = flatHeaders.find((header) => header.id === columnAccessor);
      if (column) {
        if (isSortedDesc === undefined) {
          column.clearSortBy();
        } else {
          column.toggleSortBy(isSortedDesc);
        }
      }
    },
    [flatHeaders],
  );

  useEffect(() => {
    if (refSetSortBy) {
      refSetSortBy.current = setSortOrder;
    }
  }, [refSetSortBy, setSortOrder]);

  const paginationCallback = manualPagination?.paginationCallback;
  useEffect(() => {
    if (paginationCallback) {
      paginationCallback(pageIndex, pageSize);
    }
  }, [paginationCallback, pageIndex, pageSize]);

  const sortByCallback = manualSortBy?.sortByCallback;
  useEffect(() => {
    if (sortByCallback) {
      sortByCallback(sortBy);
    }
  }, [sortByCallback, sortBy]);

  useEffect(() => {
    if (manualPagination?.initialPageIndex !== undefined) {
      gotoPage(manualPagination.initialPageIndex);
    }
  }, [gotoPage, manualPagination]);

  return (
    <>
      <Table {...getTableProps()} mb={4}>
        <QTableHead headerGroups={headerGroups} />
        <Tbody {...getTableBodyProps()}>
          {items.map((row) => {
            prepareRow(row);
            return <QRow key={row.id} row={row} onRowClick={onRowClick} dataCy={'event-' + row.original.id} />;
          })}
        </Tbody>
      </Table>
      {hasPagination ? (
        <QPaginator
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          previousPage={previousPage}
          hasPageSizeOptions={hasPageSizeOptions}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={manualPagination?.initialPageSize ?? pageSizeOverride}
        />
      ) : null}
    </>
  );
};
