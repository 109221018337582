import styled from 'styled-components';

export const QualityEventListContainer = styled.div`
  .rdt_TableCol {
    font-size: ${({ theme }) => theme.fontSizes.sm};
  }
  .rdt_TableRow {
    font-size: ${({ theme }) => theme.fontSizes.sm};
    button {
      margin-right: 8px;
    }
  }
  .qualityEventListTable {
    header {
      padding: 8px 0px 8px 8px;
    }
    .rdt_TableHead .rdt_TableCol {
      font-weight: ${({ theme }) => theme.fontWeights.bold};
    }
    .rdt_TableHeadRow {
      border-bottom: 2px solid rgba(0, 0, 0, 0.12);
    }
  }
`;

export const QualityEventListTitleLink = styled.a`
  color: ${({ theme }) => theme.colors.gray[900]};
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.gray[900]};
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  h4 {
    font-style: normal;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 24px;
    line-height: 32px;
  }
`;
