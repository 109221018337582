import { QText } from '@qualio/ui-components';
import React from 'react';

type Props = {
  children: React.ReactNode;
};

export const DetailRowText = ({ children }: Props) => {
  return (
    <QText fontSize="sm" color={'gray.500'}>
      {children}
    </QText>
  );
};
