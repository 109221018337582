export default {
  applicationId: '70b1ae91-3031-4dd3-ac6d-f4f746f88e6e',
  clientToken: 'pub53f069acc44bdbb5244212e249af076c',
  site: 'datadoghq.com',
  sampleRate: 100,
  replaySampleRate: 100,
  service: 'quality-events-fe',
  trackInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
  env: process.env.REACT_APP_ENVIRONMENT,
  allowedTracingOrigins: [process.env.REACT_APP_API_URL],
};
