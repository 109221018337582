import { useQuery } from 'react-query';
import { useToastProvider } from '@qualio/ui-components';
import qriServiceApi from '../api/qriServiceApi.api';
import { DefaultErrorMessage } from '../displayStrings';

export const BaseQueryKey = 'qriBulkDetails';

export const useQriBulkDetails = (qris: string[]) => {
  const { showToast } = useToastProvider();

  return useQuery([BaseQueryKey, qris], () => qriServiceApi.getBulkDetails(qris), {
    onError: () => {
      showToast({
        description: DefaultErrorMessage,
        status: 'error',
      });
    },
  });
};
