import React, { useState } from 'react';
import {
  QBox,
  QButton,
  QCloseButton,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QText,
  QTextarea,
} from '@qualio/ui-components';
import { UseMutateFunction } from 'react-query';
import * as DisplayStrings from '../../../displayStrings';
import { UpdateForm } from '../../../types';
import { MarkAsReviewedMutateType } from '../../../hooks';

type MarkAsReviewedModalProps = {
  isOpen: boolean;
  onSubmit: UseMutateFunction<UpdateForm, unknown, MarkAsReviewedMutateType, unknown>;
  onClose: () => void;
  formTitle: string;
  isSubmitBtnLoading: boolean;
};

const MarkAsReviewedModal = ({
  isOpen,
  onSubmit,
  onClose,
  formTitle,
  isSubmitBtnLoading,
}: MarkAsReviewedModalProps) => {
  const [comment, setComment] = useState('');

  const handleSubmit = () => {
    onSubmit({ comment });
  };

  return (
    <QModal isOpen={isOpen} onClose={onClose} size="md">
      <QModalHeader>
        <QText>{DisplayStrings.ReviewForm(formTitle)}</QText>
        <QCloseButton onClick={onClose}></QCloseButton>
      </QModalHeader>
      <QModalBody>
        <QBox>
          <QText fontWeight="semibold">Comment (Optional)</QText>
          <QTextarea
            onChange={(e) => {
              setComment(e.target.value);
            }}
            aria-label="comment"
            data-cy="add-comment"
          />
        </QBox>
      </QModalBody>
      <QModalActions>
        <QButton data-cy="cancel-review-button" onClick={onClose} variant="outline">
          {DisplayStrings.Cancel}
        </QButton>
        <QButton data-cy="submit-review-button" onClick={handleSubmit} isLoading={isSubmitBtnLoading}>
          Mark as reviewed
        </QButton>
      </QModalActions>
    </QModal>
  );
};

export default MarkAsReviewedModal;
