import React from 'react';
import { modifiedToString } from '../../utils/functionalUtils';
import { QText, QTextProps } from '@qualio/ui-components';

type Props<T> = {
  value?: T;
  transform?: (value: T) => string;
  fallback?: string;
} & Omit<QTextProps, 'transform'>;

const TextOr = <T,>(props: Props<T>) => {
  const { value, transform = modifiedToString, fallback = '--', ...qTextProps } = props;
  const displayText = value ? transform(value) : fallback;

  return <QText {...qTextProps}>{displayText}</QText>;
};

export default TextOr;
