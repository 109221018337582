import React from 'react';
import { QBox, QText, QSpinner } from '@qualio/ui-components';

type TasksCountProps = {
  label: string;
  count: number;
  isLoading?: boolean;
};

const TasksCount: React.FC<TasksCountProps> = ({ label, count, isLoading }) => {
  return (
    <QBox mr={10}>
      {isLoading ? (
        <QBox w="100%" textAlign="center">
          <QSpinner />
        </QBox>
      ) : (
        <>
          <QBox>
            <QText fontWeight="semibold" fontSize="xs" color="gray.800">
              {label}
            </QText>
          </QBox>
          <QBox>{count}</QBox>
        </>
      )}
    </QBox>
  );
};

export default TasksCount;
