import { EventTemplateFieldAttributeNames, EventTemplateFormValues } from '../../types';
import { getBlankStep } from '../../utils/eventTemplateUtils';

export const DefaultFormValues = {
  name: '',
  prefix: '',
  default_owner_id: '',
  default_time_limit: 28,
  start_time_constraint: 0,
  fields: {
    [EventTemplateFieldAttributeNames.Enum.product]: {
      attribute_name: EventTemplateFieldAttributeNames.Enum.product,
      mandatory: false,
    },
    [EventTemplateFieldAttributeNames.Enum.root_cause]: {
      attribute_name: EventTemplateFieldAttributeNames.Enum.root_cause,
      mandatory: false,
    },
    [EventTemplateFieldAttributeNames.Enum.risk]: {
      attribute_name: EventTemplateFieldAttributeNames.Enum.risk,
      mandatory: true,
    },
    [EventTemplateFieldAttributeNames.Enum.supplier]: {
      attribute_name: EventTemplateFieldAttributeNames.Enum.supplier,
      mandatory: false,
    },
  },
  steps: [
    {
      ...getBlankStep(),
      order: 1,
      label: 'Resolution',
      type: 'document',
    },
  ],
} satisfies EventTemplateFormValues;
