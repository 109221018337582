import React, { useMemo } from 'react';
import { KnownFlags, useFlags } from '../../external/LaunchDarklyAdapter';
import { Navigate } from 'react-router-dom';
import { useIsStaffUser } from '../../hooks/useIsStaffUser';
import { QSpinner } from '@qualio/ui-components';

export type ProtectedRouteProps = {
  outlet: JSX.Element;
  requiredFeatureFlags: KnownFlags[];
  redirectPath: string;
  requireStaff?: boolean;
};

export default function ProtectedRoute({
  redirectPath,
  requiredFeatureFlags,
  outlet,
  requireStaff,
}: ProtectedRouteProps) {
  const ldFlags = useFlags(requiredFeatureFlags);
  const hasAllFlags = useMemo(() => {
    return requiredFeatureFlags.every((flag) => ldFlags[flag]);
  }, [ldFlags, requiredFeatureFlags]);
  const { data: userIsStaff } = useIsStaffUser();

  if (userIsStaff === undefined) {
    return <QSpinner />;
  }

  const blockDueToLackOfStaffAccess = requireStaff ? !userIsStaff : false;

  if (blockDueToLackOfStaffAccess) {
    return <Navigate to={{ pathname: redirectPath }} replace />;
  }

  if (!hasAllFlags) {
    return <Navigate to={{ pathname: redirectPath }} replace />;
  }

  return outlet;
}
