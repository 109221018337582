import * as z from 'zod';
import { NewStandardInputSchema, StandardInputSchema } from './standardInputFields';
import { AttachmentSchema, NewAttachmentSchema, NewRichTextSchema, RichTextSchema } from './nonStandardInputFields';
import {
  CheckboxInputSchema,
  DropdownInputSchema,
  NewCheckboxInputSchema,
  NewDropdownInputSchema,
  NewRadioInputSchema,
  RadioInputSchema,
} from './optionSelectFields';
import { NewQLookupFieldSchema, QLookupFieldSchema } from './QLookupFields';
import { NewVisualFieldSchema, VisualFieldSchema } from './visualFields';

/**
 * All types in this file relate to form fields as they're used in form templates
 * as opposed to form fields being used by actual instances of forms in an event
 */

// Discriminated Union helps zod parse safer/faster
export const FormTemplateFieldSchema = z.discriminatedUnion('type', [
  StandardInputSchema,
  RichTextSchema,
  AttachmentSchema,
  DropdownInputSchema,
  CheckboxInputSchema,
  RadioInputSchema,
  QLookupFieldSchema,
  VisualFieldSchema,
]);
export type FormTemplateField = z.infer<typeof FormTemplateFieldSchema>;

/**
 * Here we are creating schemas/types that represent new template fields prior to them
 * being saved to the server.
 */
export const NewFormTemplateFieldSchema = z.discriminatedUnion('type', [
  NewStandardInputSchema,
  NewRichTextSchema,
  NewAttachmentSchema,
  NewDropdownInputSchema,
  NewCheckboxInputSchema,
  NewRadioInputSchema,
  NewQLookupFieldSchema,
  NewVisualFieldSchema,
]);
export type NewFormTemplateField = z.infer<typeof NewFormTemplateFieldSchema>;
