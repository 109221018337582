import { QButton, QButtonGroup } from '@qualio/ui-components';
import React from 'react';
import { useUserPermissions } from '../../../hooks';

type Props = {
  onClickHandler: () => void;
};

const AddRootCauseButton: React.FC<Props> = ({ onClickHandler }) => {
  const { canCUDRootCause } = useUserPermissions();

  return (
    <>
      {canCUDRootCause && (
        <QButtonGroup>
          <QButton variant="solid" onClick={onClickHandler} data-metrics={'add-root-cause-overview-button'}>
            Add root cause
          </QButton>
        </QButtonGroup>
      )}
    </>
  );
};

export default AddRootCauseButton;
