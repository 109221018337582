import React, { useMemo } from 'react';
import {
  createQColumnHelper,
  DataProvider,
  Pagination,
  QDataTable,
  QBox,
  QSpinner,
  TableMenuItem,
} from '@qualio/ui-components';
import { Product, ProductStatsResponse, ProductListRow } from '../../types';
import { useUserPermissions } from '../../hooks';
import { useFlags } from '../../external/LaunchDarklyAdapter';

type ProductListProps = {
  products: Product[] | undefined;
  stats: ProductStatsResponse | undefined;
  isLoading: boolean;
  onEditProduct: (payload: Product) => void;
  onDeleteProduct: (payload: Product) => void;
};

const ProductList = ({ products, stats, isLoading, onEditProduct, onDeleteProduct }: ProductListProps) => {
  const { canCUDProduct } = useUserPermissions();
  const qualioFrontendRefreshEnabled = useFlags('qualioFrontendRefresh');

  const columnHelper = createQColumnHelper<ProductListRow>();
  const columns = [
    columnHelper.text('name', { header: 'Product', minWidth: '40%' }),
    columnHelper.text('description', { header: 'Description', minWidth: '40%' }),
    columnHelper.textLink(
      (product) => openIssuesLabel(product.open_issues, product.total_issues),
      (product) => openIssuesLink(product.id),
      { id: 'open_events', header: 'Open events', minWidth: '15%', enableSorting: true },
    ),
  ];
  if (canCUDProduct) {
    columns.push(
      columnHelper.menu({
        items: (
          <>
            <TableMenuItem onClick={(item: Product) => onEditProduct(item)}>Edit</TableMenuItem>
            <TableMenuItem onClick={(item: Product) => onDeleteProduct(item)}>Delete</TableMenuItem>
          </>
        ),
      }),
    );
  }

  const openIssuesLabel = (openIssues: number, totalIssues: number): string => {
    return `${openIssues} / ${totalIssues}`;
  };

  const openIssuesLink = (product_id: string): string => {
    let url = `/events?status=open&product_id=${product_id}`;
    if (!qualioFrontendRefreshEnabled) {
      url = '/quality-events' + url;
    }

    return url;
  };

  const mappedData = useMemo(
    () =>
      products?.map((item: Product) => ({
        id: item.id || '',
        name: item.name,
        description: item.description || '',
        open_issues: (stats && stats[item.id]?.open_issues) || 0,
        total_issues: (stats && stats[item.id]?.total_issues) || 0,
      })) || [],
    [products, stats],
  );

  return (
    <QBox>
      {isLoading ? (
        <QBox w="100%" textAlign="center" p={5}>
          <QSpinner />
        </QBox>
      ) : (
        <Pagination.Auto itemCount={mappedData.length} clientSide>
          <DataProvider.Fixed data={mappedData}>
            <QDataTable columns={columns} />
          </DataProvider.Fixed>
        </Pagination.Auto>
      )}
    </QBox>
  );
};

export default ProductList;
