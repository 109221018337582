export const characterAtIndex = (input: string, index: number) => {
  const character = input[index];

  if (character === undefined) {
    throw new Error(`String "${input}" does not have a character at position ${index}`);
  }

  return character;
};

export const trimAndReplaceMultiSpaceWithOneSpace = (input: string) => {
  return input.trim().replace(/\s\s+/g, ' ');
};

export const convertToNullableNumber = (stringNumber: string | null): number | null => {
  if (stringNumber === '' || stringNumber == null) {
    return null;
  }
  return Number(stringNumber);
};

const processDividers = (newS: string): string => {
  if (newS.length === 0 || newS.endsWith(' ')) {
    return newS;
  }
  return `${newS} `;
};

const processNumbers = (newS: string, c: string): string => {
  if (newS.length === 0 || newS.endsWith(' ')) {
    return `${newS}${c}`;
  }
  if (/[a-zA-Z]/.test(characterAtIndex(newS, newS.length - 1))) {
    return `${newS} ${c}`;
  }
  return `${newS}${c}`;
};

const processCapitalLetters = (newS: string, c: string): string => {
  if (newS.length === 0 || newS.endsWith(' ')) {
    return `${newS}${c}`;
  }
  if (/[a-z0-9]/.test(characterAtIndex(newS, newS.length - 1))) {
    return `${newS} ${c}`;
  }
  return `${newS}${c}`;
};

const processSmallLetters = (newS: string, c: string, s: string, i: number) => {
  if (newS.length === 0 || newS.endsWith(' ')) {
    return `${newS}${c.toUpperCase()}`;
  }
  if (/\d/.test(characterAtIndex(newS, newS.length - 1))) {
    return `${newS} ${c.toUpperCase()}`;
  }
  if (i > 1 && /[A-Z]/.test(characterAtIndex(s, i - 1)) && /[A-Z]/.test(characterAtIndex(s, i - 2))) {
    return `${newS.slice(0, newS.length - 1)} ${characterAtIndex(newS, newS.length - 1)}${c}`;
  }
  return `${newS}${c}`;
};

export const camelOrSnakeToTitle = (s: string): string =>
  s.split('').reduce((newS, c, i) => {
    if (/[-+_]/.test(c)) {
      return processDividers(newS);
    }
    if (/\d/.test(c)) {
      return processNumbers(newS, c);
    }
    if (/[A-Z]/.test(c)) {
      return processCapitalLetters(newS, c);
    }
    if (/[a-z]/.test(c)) {
      return processSmallLetters(newS, c, s, i);
    }
    return newS;
  }, '');

export const keyToTitle = (s: string) => {
  if (s.length === 0) {
    return s;
  }
  const newS = s.replace(/-|_/g, ' ');

  if (newS.match(/^[a-z]/)) {
    const firstLetter = newS.charAt(0).toUpperCase();
    return newS.length > 1 ? firstLetter + newS.slice(1) : firstLetter;
  }

  return newS;
};
