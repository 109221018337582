import * as z from 'zod';
import { Assignee } from './eventStep';
import { FieldSchema } from './formFields/forms';
import { NumericIdSchema } from './id';

/**
 * All types in this file relate to a Form instance associated to an event. Not
 * to be confused for any types associated to a FormTemplate.  There are some commonalities
 * between types in the two, but if you are importing a type from here it should be in
 * relation to displaying/creating/modifying a form instance, not a template.
 */

// Schema/Type that represent all known possible statuses for a form
export const FormStatusSchema = z.enum([
  'draft',
  'for_review',
  'for_approval',
  'approved',
  'approval_declined',
  'effective',
  'superseded',
  'cancelled',
]);
export type FormStatus = z.infer<typeof FormStatusSchema>;

// Schema/Type for a form object as returned from the server
export const FormSchema = z.object({
  id: NumericIdSchema,
  title: z.string(),
  status: FormStatusSchema,
  creator_id: NumericIdSchema,
  owner_id: NumericIdSchema,
  form_template_id: NumericIdSchema,
  issue_step_id: NumericIdSchema,
  created_at: z.number(),
  updated_at: z.number(),
  fields: z.array(FieldSchema),
  owner_full_name: z.string().optional().nullable(),
  approvers: z.array(Assignee).optional(),
  reviewers: z.array(Assignee).optional(),
});
export type Form = z.infer<typeof FormSchema>;

export const UpdateFormSchema = FormSchema.omit({ fields: true });
export type UpdateForm = z.infer<typeof UpdateFormSchema>;

export const FormCommentStatus = z.enum(['default', 'approved', 'declined', 'reviewed']);

export const FormCommentSchema = z.object({
  commentTimeStamp: z.string(),
  userFullName: z.string(),
  status: FormCommentStatus,
  comment: z.string(),
});
export type FormComment = z.infer<typeof FormCommentSchema>;
