import { useMutation, useQuery, useQueryClient } from 'react-query';
import templatesApi from '../../api/templates.api';
import { EventTemplateCreateEdit, EventTemplateFormValues } from '../../types';
import { useToastProvider } from '@qualio/ui-components';
import { AxiosError } from 'axios';
import { convertResponseToTemplateFormValues } from '../../utils/formTemplateUtils';
import { extractMessageFromError } from '../../utils/errorUtils';
import { getUserTZ } from '../../utils/userUtils';
import usersApi from '../../api/users.api';
import { useNavigate } from 'react-router-dom';
import { baseEventTemplateQueryKey } from '../../hooks';
import { DefaultErrorMessage } from '../../displayStrings';
import { useFlags } from '../../external/LaunchDarklyAdapter';

export const useTemplateQuery = (companyId: number, eventTemplateId: string | undefined, enabled: boolean) => {
  const { showToast } = useToastProvider();

  return useQuery(
    [baseEventTemplateQueryKey, companyId, eventTemplateId],
    async () => {
      const data = await templatesApi.getEventTemplateDetails(companyId, Number(eventTemplateId));
      return {
        eventTemplateFormValues: convertResponseToTemplateFormValues(data),
        defaultOwnerHasPermission: data.default_owner_has_permission,
      };
    },
    {
      enabled,
      onError: (error: AxiosError) => {
        if (error?.response?.status === 404) {
          window.location.href = `/_rpm?reqUrl=${window.location.pathname}`;
        } else {
          showToast({
            title: 'Failed to fetch event template details',
            description: DefaultErrorMessage,
            status: 'error',
          });
        }
      },
      onSuccess(data) {
        if (data.eventTemplateFormValues.steps.length) {
          data.eventTemplateFormValues.steps.sort((step1, step2) => {
            return step1.order - step2.order;
          });
        }
      },
    },
  );
};

type CreateArg = {
  mode: 'create';
  companyId: number;
};

type UpdateArg = {
  mode: 'update';
  companyId: number;
  templateId: number;
  originalTemplate: EventTemplateFormValues;
};

const generateCreateFn = (
  { companyId }: CreateArg,
  navigate: ReturnType<typeof useNavigate>,
  qeDefaultOwners: boolean,
) => {
  return async (payload: EventTemplateFormValues) => {
    const { steps, fields, default_owner_id, ...restOfKeys } = payload;
    const fieldsArray = Object.values(fields);
    const createObj: EventTemplateCreateEdit = { steps, fields: fieldsArray, ...restOfKeys };

    if (qeDefaultOwners) {
      createObj.default_owner_id = default_owner_id ? Number(default_owner_id) : undefined;
    }

    const data = await templatesApi.createTemplate(companyId, createObj, fieldsArray, steps);
    navigate(`/templates/${data.id}`);
    return convertResponseToTemplateFormValues(data);
  };
};

const generateUpdateFn = ({ companyId, templateId, originalTemplate }: UpdateArg, qeDefaultOwners: boolean) => {
  return async (payload: EventTemplateFormValues) => {
    const { steps, fields, default_owner_id, ...restOfKeys } = payload;
    const fieldsArray = Object.values(fields).map((field) => {
      const originalField = originalTemplate.fields[field.attribute_name];
      const baseObject = originalField ?? {};

      return {
        ...baseObject,
        ...field,
      };
    });

    const updateObject: EventTemplateCreateEdit = {
      id: templateId,
      steps,
      fields: fieldsArray,
      ...restOfKeys,
    };

    if (qeDefaultOwners) {
      updateObject.default_owner_id = default_owner_id ? Number(default_owner_id) : undefined;
    }

    const data = await templatesApi.updateTemplate(companyId, `${templateId}`, updateObject, fieldsArray, steps);
    return convertResponseToTemplateFormValues(data);
  };
};

export const useTemplateMutation = (args: CreateArg | UpdateArg) => {
  const navigate = useNavigate();
  const qeDefaultOwnersEnabled = useFlags('qeDefaultOwners');
  const { showToast } = useToastProvider();
  const queryClient = useQueryClient();
  const mutationFn =
    args.mode === 'create'
      ? generateCreateFn(args, navigate, qeDefaultOwnersEnabled)
      : generateUpdateFn(args, qeDefaultOwnersEnabled);

  let mutationCallbacks;

  if (args.mode === 'create') {
    mutationCallbacks = {
      onSuccess: (data: EventTemplateFormValues) => {
        showToast({
          title: 'Event template created',
          description: `${data.name} was created`,
          status: 'success',
        });
      },
      onError: (error: AxiosError) => {
        showToast({
          title: 'Event template not created',
          description: extractMessageFromError(error),
          status: 'error',
        });
      },
    };
  } else if (args.mode === 'update') {
    mutationCallbacks = {
      onSuccess: (data: EventTemplateFormValues) => {
        queryClient.invalidateQueries([baseEventTemplateQueryKey, args.companyId, `${args.templateId}`]).then(() => {
          showToast({
            title: 'Event template updated',
            description: `${data.name} was successfully updated`,
            status: 'success',
          });
        });
      },
      onError: (error: AxiosError) => {
        showToast({
          title: 'Event template not updated',
          description: extractMessageFromError(error),
          status: 'error',
        });
      },
    };
  }

  return useMutation(mutationFn, mutationCallbacks);
};

export const useGetUserTimezone = (userId: number, companyId: number) => {
  return useQuery(['userTimeZone', companyId, userId], async () => {
    const data = await usersApi.getAllUsers(companyId, { status: 'accepted' });
    return getUserTZ(userId, data);
  });
};
