import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import {
  QButton,
  QCloseButton,
  QFormControl,
  QInput,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QSelect,
  QStack,
  QText,
} from '@qualio/ui-components';

import { EventTemplateCreateEdit, EventTemplateFormSchema, User } from '../../types';
import { filterByPermission } from '../../utils/userUtils';

type CreateEditEventTemplateModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  allUsers: User[];
};

const CreateEditEventTemplateModal: React.FC<CreateEditEventTemplateModalProps> = ({ isOpen, setIsOpen, allUsers }) => {
  const defaultOwnerOptions = useMemo(
    () =>
      filterByPermission(allUsers, 'can_work_on_issue').map((owner) => ({
        value: owner.id,
        label: owner.full_name,
      })),
    [allUsers],
  );

  const formMethods = useForm<EventTemplateCreateEdit>({
    mode: 'onSubmit',
    resolver: zodResolver(EventTemplateFormSchema),
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = formMethods;

  const formHasErrors = !!Object.keys(errors).length;

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const onSubmit = useCallback((payload: EventTemplateCreateEdit) => {
    //todo
    console.info(payload);
  }, []);

  return (
    <QModal isOpen={isOpen} onClose={handleClose} size="xl">
      <QModalHeader>
        <QText>Create event template</QText>
        <QCloseButton onClick={handleClose} data-metrics="create-event-template-close-modal-button" />
      </QModalHeader>
      <QModalBody>
        <QStack direction="column" color="gray.700" spacing={4}>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <QStack direction="column" spacing={4}>
                <QFormControl
                  label="Title"
                  error={errors.name && errors.name.message}
                  isInvalid={!!errors.name}
                  data-metrics="event-template-title-input"
                  isRequired
                >
                  <Controller
                    control={control}
                    name="name"
                    render={({ field: { ref: _ref, ...field } }) => <QInput {...field} />}
                  />
                </QFormControl>
                <QFormControl
                  label="Prefix"
                  data-metrics="event-template-prefix-input"
                  error={errors.prefix && errors.prefix.message}
                  isInvalid={!!errors.prefix}
                  isRequired
                >
                  <Controller
                    control={control}
                    name="prefix"
                    render={({ field: { ref: _ref, ...field } }) => <QInput {...field} />}
                  />
                </QFormControl>
                <QFormControl
                  label="Default event owner"
                  helper="to do.........."
                  error={errors.default_owner_id && errors.default_owner_id.message}
                  isInvalid={!!errors.default_owner_id}
                  data-metrics="event-template-default-event-owner-input"
                  isRequired
                >
                  <Controller
                    control={control}
                    name="default_owner_id"
                    render={({ field: { ref: _ref, onChange, ...field } }) => (
                      <QSelect
                        {...field}
                        onChange={(changeObject) => {
                          if (changeObject) {
                            onChange(changeObject.value);
                          }
                        }}
                        options={defaultOwnerOptions}
                        menuPosition="fixed"
                      />
                    )}
                  />
                </QFormControl>
                <QFormControl
                  label="Default time limit"
                  helper="to do......"
                  data-metrics="event-template-default-time-limit-input"
                >
                  <Controller
                    control={control}
                    name="default_time_limit"
                    render={({ field: { ref: _ref, ...field } }) => <QInput {...field} />}
                  />
                </QFormControl>
                <QFormControl
                  label="Validation step time delay"
                  helper="to do......"
                  data-metrics="event-template-validation-step-time-delay-input"
                >
                  <Controller
                    control={control}
                    name="start_time_constraint"
                    render={({ field: { ref: _ref, ...field } }) => <QInput {...field} />}
                  />
                </QFormControl>
              </QStack>
            </form>
          </FormProvider>
        </QStack>
      </QModalBody>
      <QModalActions>
        <QButton onClick={handleClose} variant="outline" data-metrics="create-event-template-cancel-button">
          Cancel
        </QButton>
        <QButton
          onClick={handleSubmit(onSubmit)}
          isDisabled={formHasErrors}
          data-metrics="create-event-template-button"
        >
          Create template
        </QButton>
      </QModalActions>
    </QModal>
  );
};

export default CreateEditEventTemplateModal;
