import React from 'react';
import {
  QBox,
  QFlex,
  QFormControl,
  QInput,
  QRadio,
  QRadioGroup,
  QStack,
  QTextarea,
  QAttachments,
  QAttachment,
  QText,
  QAttachmentUploadInfo,
} from '@qualio/ui-components';
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form';
import { TaskCompleteRequest } from '../../../types';
import { attachmentAPIUrl } from '../../../api/attachments.api';

type Props = {
  formMethods: UseFormReturn<TaskCompleteRequest>;
  onSubmit: any;
  attachments: QAttachment[];
  setAttachments: (attachments: QAttachment[]) => void;
};

const TaskCompleteForm: React.FC<Props> = ({ formMethods, onSubmit, attachments, setAttachments }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <QStack direction="column" spacing={4}>
          <QFormControl label="Email" error={errors.email?.message} isInvalid={!!errors.email} isRequired>
            <Controller
              control={control}
              name="email"
              render={({ field: { ref: _ref, ...field } }) => (
                <QInput {...field} autoComplete="username" placeholder="Email" />
              )}
            />
          </QFormControl>

          <QFormControl label="Password" error={errors.password?.message} isInvalid={!!errors.password} isRequired>
            <Controller
              control={control}
              name="password"
              render={({ field: { ref: _ref, ...field } }) => (
                <QInput
                  type="password"
                  autoComplete="current-password"
                  aria-label="password"
                  placeholder="Password"
                  {...field}
                />
              )}
            />
          </QFormControl>

          <QFormControl isRequired label="Comment" isInvalid={!!errors.comment} error={errors.comment?.message}>
            <Controller
              control={control}
              name="comment"
              render={({ field: { ref: _ref, ...field } }) => <QTextarea {...field} />}
            />
          </QFormControl>
          <QBox>
            <QText mb={2}>Attachments</QText>
            <QAttachments
              attachments={[]}
              uploadInfoProvider={() => {
                return Promise.resolve({
                  url: attachmentAPIUrl,
                  destination: 'MEDTECH',
                } as QAttachmentUploadInfo);
              }}
              onAdd={(attachment: QAttachment) => {
                setAttachments([...attachments, attachment]);
                return Promise.resolve();
              }}
              onClick={() => {
                return Promise.resolve();
              }}
              onRemove={(fileId: string) => {
                setAttachments(attachments.filter((a) => a.id !== fileId));
                return Promise.resolve();
              }}
              onRevert={(fileId: string) => {
                setAttachments(attachments.filter((a) => a.id !== fileId));
                return Promise.resolve();
              }}
              maxFiles={10}
              maxFileSize="100MB"
              maxTotalFileSize="1000MB"
            />
          </QBox>
          <QFormControl
            label="Resolution"
            isRequired
            isInvalid={!!errors.resolution}
            error={errors.resolution?.message}
          >
            <Controller
              control={control}
              name="resolution"
              render={({ field: { ref: _ref, ...field } }) => (
                <QRadioGroup {...field}>
                  <QFlex direction="row">
                    <QBox mr={4}>
                      <QRadio value="closed_success">Success</QRadio>
                    </QBox>
                    <QRadio value="closed_fail">Failure</QRadio>
                  </QFlex>
                </QRadioGroup>
              )}
            />
          </QFormControl>
        </QStack>
      </form>
    </FormProvider>
  );
};

export default TaskCompleteForm;
