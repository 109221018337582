import React from 'react';

import { QText } from '@qualio/ui-components';
import { Link } from 'react-router-dom';

type Props = {
  name: string | undefined | null;
  url: string;
  index?: number;
};

const IconlessLink: React.FC<Props> = ({ name, url, index = 0 }) => {
  if (!name || !url) {
    return <QText>-</QText>;
  }
  return (
    <QText>
      <Link to={url}>{name}</Link>
    </QText>
  );
};

export default IconlessLink;
