import React from 'react';
import { QBox, QButton, QButtonGroup, QHeading } from '@qualio/ui-components';
import { useUserPermissions } from '../../../hooks';

type QualityEventListHeaderProps = {
  setIsModalOpen: (open: boolean) => void;
};

const QualityEventListHeader: React.FC<QualityEventListHeaderProps> = ({ setIsModalOpen }) => {
  const { canCreateEvent } = useUserPermissions();

  return (
    <QBox display="flex" flexDirection="row" justifyContent="space-between">
      <QHeading size="lg">Events</QHeading>
      <QButtonGroup>
        {canCreateEvent && (
          <QButton variant="solid" onClick={() => setIsModalOpen(true)} data-metrics="create-event-overview-button">
            Create event
          </QButton>
        )}
      </QButtonGroup>
    </QBox>
  );
};

export default QualityEventListHeader;
