import React from 'react';
import { QAlert, QStack } from '@qualio/ui-components';
import EventFormField from '../EventFormField/EventFormField';
import * as DisplayStrings from '../../displayStrings';
import { ViewModeDictionary } from '../EventFormField/EventFormField.types';
import { FormTemplateField, NewFormTemplateField } from '../../types/formFields/formTemplates';
import { FieldTypeSchema } from '../../types/formFields/common';

type FieldForDisplay = {
  uniqueId: string | number;
  id?: FormTemplateField['id'];
} & (FormTemplateField | NewFormTemplateField);

type EventFormProps = {
  formFields: FieldForDisplay[];
};

const EventFormPreview: React.FC<EventFormProps> = ({ formFields }) => {
  return (
    <QStack direction="column">
      <QAlert status="info" description={DisplayStrings.FormPreviewWarning} />
      <QStack direction="column" spacing="40px">
        {formFields.map((formField) => {
          return (
            <EventFormField
              key={formField.id ?? formField.uniqueId}
              data-cy={`${formField.type}-${formField.id ?? formField.uniqueId}`}
              mode={ViewModeDictionary.PREVIEW}
              defaultContent={formField.default_content ?? ''}
              inputType={formField.type}
              helper={formField.helptext ?? undefined}
              label={formField.label}
              isRequired={formField.mandatory}
              multi={formField.multi}
              resourceSubType={formField.resource_sub_type}
              options={
                formField.type === FieldTypeSchema.Enum.dropdown ||
                formField.type === FieldTypeSchema.Enum.checkbox ||
                formField.type === FieldTypeSchema.Enum.radio
                  ? formField.form_option_list.options.map(({ value }) => value)
                  : undefined
              }
            />
          );
        })}
      </QStack>
    </QStack>
  );
};

export default EventFormPreview;
