import { QBox, QText } from '@qualio/ui-components';
import React from 'react';

type Props = {
  title: string;
  children: React.ReactNode;
};

export const DetailRow = ({ title, children }: Props) => {
  return (
    <QBox
      mt={4}
      mb={4}
      display="grid"
      gridTemplateColumns={{
        md: '1fr 2fr',
      }}
    >
      <QText fontSize="sm" fontWeight={600} color={'gray.800'}>
        {title}
      </QText>
      {children}
    </QBox>
  );
};
