import {
  CreateRootCause,
  RootCause,
  RootCauseRequest,
  RootCauseStats,
  RootCauseStatsResponse,
} from '../types/rootCause';
import api, { createRequestConfig, medtechApi } from './index';

const rootCausesApi = {
  async getAllRootCauses(company: number, filterParams?: RootCauseRequest): Promise<RootCause[] | undefined> {
    const res = await api.get(
      `/company/${company}/root-causes`,
      createRequestConfig({
        params: filterParams,
      }),
    );
    return res.data.data as RootCause[];
  },

  async create(company: number, payload: CreateRootCause): Promise<void> {
    const response = await api.post(`/company/${company}/root-causes`, payload);
    return response.data;
  },

  async update(company: number, rootCause: RootCause): Promise<void> {
    const { id, ...payload } = rootCause;
    const response = await api.patch(`/company/${company}/root-causes/${id}`, payload);
    return response.data;
  },

  async delete(company: number, rootCauseId: string): Promise<void> {
    const response = await api.delete(`/company/${company}/root-causes/${rootCauseId}`);
    return response.data;
  },

  async getAllStats(company: number): Promise<RootCauseStatsResponse> {
    const response = await medtechApi.get(`/quality-events/${company}/stats/issues-per-rootcause`);
    return Object.fromEntries(
      response.data
        .filter((item: RootCauseStats) => item.rootcause_id !== null)
        .map((item: RootCauseStats) => {
          const parsed = RootCauseStats.parse(item);
          return [String(parsed.rootcause_id), parsed];
        }),
    );
  },
};

export default rootCausesApi;
