import React from 'react';
import { StandardValueObject, SubComponentProps, ViewModeDictionary } from '../EventFormField.types';
import { createFieldValueArray } from '../../../utils/eventFormUtils';
import ReadonlyWrapper from './ReadOnlyWrapper';
import NonEditableRichText from '../../NonEditableRichText/NonEditableRichText';
import RichTextInput from '../../RichTextInput/RichTextInput';

const RichTextField = (props: SubComponentProps) => {
  const { fieldValues, onChange, fieldId, mode } = props;

  // TODO:  QEV-2813
  const valueObj = fieldValues[0] as StandardValueObject | undefined;

  const val =
    mode === ViewModeDictionary.PREVIEW && props.defaultContent ? props.defaultContent : valueObj?.value ?? '';

  const handleChange = (newVal: string) => {
    const newValueArray = createFieldValueArray(newVal, fieldId, valueObj);
    onChange(newValueArray);
    return Promise.resolve();
  };

  if (mode === ViewModeDictionary.READONLY) {
    return (
      <ReadonlyWrapper>
        <NonEditableRichText content={val || '--'} mode="READONLY" />
      </ReadonlyWrapper>
    );
  }
  return <RichTextInput value={val} onChange={handleChange} />;
};

export default RichTextField;
