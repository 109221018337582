import axios, { AxiosProgressEvent, CancelToken } from 'axios';
import {
  Attachment,
  QualioDocument,
  SmartLinkedDocumentSuggestion,
  SmartLinksSuggest,
  ResolvedSmartLinkInformation,
} from '../models';
import { medtechApi, medtechFileUploadApi } from '../../../../api';

export const documentApi = {
  async fetch(id: string): Promise<QualioDocument> {
    const res = await medtechApi.get(`/documents/${id}?include_attachments=true&process_references=1`);
    return res.data;
  },
  async uploadAttachment(
    file: File,
    setProgress: (progress: AxiosProgressEvent) => void,
    cancelToken: CancelToken,
    formData?: { [key: string]: string },
  ): Promise<Attachment | undefined> {
    const data = new FormData();
    data.append('file_1', file);
    if (formData) {
      for (const key in formData) {
        data.append(key, formData[key]!);
      }
    }
    let httpResp;
    try {
      httpResp = await medtechFileUploadApi.post('/attachments?entity=template', data, {
        onUploadProgress: setProgress,
        cancelToken,
      });
    } catch (thrown) {
      if (!axios.isCancel(thrown)) {
        throw thrown;
      }
    }
    return httpResp?.data;
  },
  async smartLinksSuggest(companyId: number, payload: SmartLinksSuggest): Promise<SmartLinkedDocumentSuggestion[]> {
    const httpResp: any = await medtechApi.get(`/v2/${companyId}/smartlinks/suggest?search=${payload.search}`);
    return httpResp.data;
  },
  async smartLinksResolve(companyId: number, codes: Set<string>): Promise<ResolvedSmartLinkInformation[]> {
    const codesAsQueryParam = '?codes=' + [...codes].join(',');
    const httpResp: any = await medtechApi.get(`/v2/${companyId}/smartlinks/resolve-bulk${codesAsQueryParam}`);
    return httpResp.data;
  },
  async smartLinksMatrixResolve(companyId: number, matrixIds: Set<string>): Promise<ResolvedSmartLinkInformation[]> {
    const matrixIdsAsQueryParam = '?ids=' + [...matrixIds].join(',');
    const httpResp: any = await medtechApi.get(
      `/v2/${companyId}/smartlinks/resolve-bulk-matrix${matrixIdsAsQueryParam}`,
    );
    return httpResp.data;
  },
};
