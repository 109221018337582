import React from 'react';
import { QBox, QText } from '@qualio/ui-components';

type MetaDataItemProps = {
  label: string;
  children: React.ReactNode;
};
const MetaDataItem = ({ label, children }: MetaDataItemProps) => {
  return (
    <QBox mr={10}>
      <QBox>
        <QText fontWeight="semibold" fontSize="xs" color="gray.800">
          {label}
        </QText>
      </QBox>
      <QBox>{children}</QBox>
    </QBox>
  );
};

export default MetaDataItem;
