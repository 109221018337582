import { useMutation } from 'react-query';
import { TaskUpdateResponse, UpdateTask } from '../types';
import { useCurrentUser, useToastProvider } from '@qualio/ui-components';
import tasksApi from '../api/tasks.api';
import { AxiosError } from 'axios';
import { extractMessageFromError } from '../utils/errorUtils';

type UseTaskEditType = {
  editTaskMutate: (payload: UpdateTask) => void;
  isTaskEditLoading: boolean;
};

export const useTaskEdit = (
  taskId: number,
  onSuccess: (data: TaskUpdateResponse | undefined) => void,
): UseTaskEditType => {
  const { companyId } = useCurrentUser();
  const { showToast } = useToastProvider();

  const { mutate: editTaskMutate, isLoading: isTaskEditLoading } = useMutation(
    (payload: UpdateTask) => tasksApi.update(companyId, taskId, payload),
    {
      onSuccess: (data) => {
        showToast({
          title: 'Changes saved',
          description: `"${data.title}" task was saved`,
          status: 'success',
        });
        onSuccess(data);
      },
      onError: (error: AxiosError) => {
        const errorMessage = extractMessageFromError(error);
        showToast({
          title: 'Task update failed',
          description: errorMessage,
          status: 'error',
        });
      },
    },
  );

  return { editTaskMutate, isTaskEditLoading };
};
