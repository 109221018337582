import * as z from 'zod';

export const EventStepType = z.union([z.literal('document'), z.literal('actions'), z.literal('form')]);

export const Assignee = z.object({
  id: z.number(),
  name: z.string(),
  action_pending: z.boolean(),
  action_time: z.number().nullable(),
});

export const StepDocument = z.object({
  id: z.number(),
  title: z.string(),
  summary: z.string(),
  description: z.string(),
  code: z.string(),
  type: z.string(),
  status: z.string(),
  status_id: z.string(),
  modified_time: z.number(),
  version: z.string(),
  author_full_name: z.string(),
  approvers: z.array(Assignee),
  reviewers: z.array(Assignee),
});

export const EventStep = z.object({
  id: z.number(),
  issue_id: z.number(),
  label: z.string(),
  succeeds: z.number().nullable(),
  precedes: z.number().nullable(),
  template_id: z.number().nullable(),
  form_template_id: z.number().nullable().optional(),
  document_id: z.number().nullable(),
  document: StepDocument.optional(),
  start_time_constraint: z.number().nullable(),
  order: z.number(),
  status: z.string(),
  previous_status: z.string().nullable(),
  status_updated_at: z.number().nullable(),
  type: EventStepType,
  form_id: z.number().nullable().optional(),
});

export type EventStepType = z.infer<typeof EventStepType>;
export type Assignee = z.infer<typeof Assignee>;
export type StepDocument = z.infer<typeof StepDocument>;
export type EventStep = z.infer<typeof EventStep>;
