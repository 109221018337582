import React from 'react';
import { QBox, QTabHeader, QTabs } from '@qualio/ui-components';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const Navigation: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleClick = (tab: QTabHeader) => {
    navigate(tab.id);
  };

  const getActiveTabId = (): string => {
    let activeTabId;
    switch (true) {
      case pathname.includes('/events'):
        activeTabId = 'events';
        break;
      case pathname.includes('/products'):
        activeTabId = 'products';
        break;
      case pathname.includes('/root-causes'):
        activeTabId = 'root-causes';
        break;
      default:
        activeTabId = 'events';
    }
    return activeTabId;
  };

  const activeTabId = getActiveTabId();

  return (
    <>
      <QBox mb={6}>
        <QTabs
          key={activeTabId}
          activeTabId={activeTabId}
          tabs={[
            {
              id: 'events',
              title: 'Events',
            },
            {
              id: 'products',
              title: 'Products',
            },
            {
              id: 'root-causes',
              title: 'Root causes',
            },
          ]}
          onClick={handleClick}
          data-metrics="events-main-navigation-tabs"
        />
      </QBox>
      <Outlet />
    </>
  );
};

export default Navigation;
