import React from 'react';
import * as z from 'zod';
import {
  QModal,
  QStack,
  QFormControl,
  QButton,
  QModalActions,
  QModalHeader,
  QText,
  QModalBody,
  QCloseButton,
  QSelect,
  QSelectItem,
  QBox,
  QPerson,
} from '@qualio/ui-components';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { User } from '../../../types';
import { useUsersOptions } from '../../../hooks/useUsersOptions';
import * as DisplayStrings from '../../../displayStrings';

type AssignFormProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSave: (newOwnerId: string) => void;
  users: User[];
  reassign: boolean;
  currentAssignedUserName?: string;
  eventStepName?: string;
};

const localFormSchema = z.object({
  owner_id: z.string(),
});
type LocalForm = z.infer<typeof localFormSchema>;

const AssignFormModal = ({
  isOpen,
  setIsOpen,
  onSave,
  users,
  reassign,
  currentAssignedUserName,
  eventStepName,
}: AssignFormProps) => {
  const formMethods = useForm<LocalForm>({
    mode: 'onSubmit',
    resolver: zodResolver(localFormSchema),
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = formMethods;

  const usersOptions: QSelectItem[] = useUsersOptions(users, 'can_work_on_issue');

  const formHasErrors = !!Object.keys(errors).length;

  const prefix = reassign ? 'Reassign' : 'Assign';
  const label = `${prefix} to`;
  const title = `${prefix} step`;
  const dataMetricsPrefix = `${prefix.toLowerCase()}-form`;
  const defaultInfoTitle = reassign ? 'Assigned to' : 'Step';
  const defaultInfoValue = reassign ? (
    <QBox data-cy="reassign-to-select" pt={2}>
      <QPerson status="default" fullName={currentAssignedUserName ?? ''} />
    </QBox>
  ) : (
    <QText>{eventStepName}</QText>
  );

  const handleClose = () => {
    setIsOpen(false);
    reset();
  };
  const saveChanges = handleSubmit((data: LocalForm) => {
    onSave(data.owner_id);
  });

  const form = (
    <FormProvider {...formMethods}>
      <form>
        <QStack spacing={4}>
          <QFormControl
            label={label}
            error={errors.owner_id && errors.owner_id.message}
            isInvalid={!!errors.owner_id}
            isRequired
          >
            <Controller
              control={control}
              name="owner_id"
              render={({ field: { ref: _ref, onChange, ...field } }) => (
                <QBox data-cy="reassign-step-users">
                  <QSelect
                    {...field}
                    onChange={(changeObject) => changeObject && onChange(changeObject.value)}
                    options={usersOptions}
                    aria-label="reassign-step-users"
                  />
                </QBox>
              )}
            />
          </QFormControl>
        </QStack>
      </form>
    </FormProvider>
  );

  return (
    <QModal isOpen={isOpen} onClose={handleClose} size="xl">
      <QModalHeader>
        <QText>{title}</QText>
        <QCloseButton onClick={handleClose} data-metrics={`${dataMetricsPrefix}-close-modal-button`}></QCloseButton>
      </QModalHeader>
      <QModalBody data-cy="assign-user-form-modal">
        <QStack direction="column" color="gray.700" spacing={4}>
          <QBox>
            <QText fontWeight={600} color="gray.700">
              {defaultInfoTitle}
            </QText>
            {defaultInfoValue}
          </QBox>
          {form}
        </QStack>
      </QModalBody>
      <QModalActions>
        <QButton
          onClick={handleClose}
          variant="outline"
          data-metrics={`${dataMetricsPrefix}-cancel-button`}
          data-cy={'cancel-changes'}
        >
          {DisplayStrings.Cancel}
        </QButton>
        <QButton
          onClick={saveChanges}
          isDisabled={formHasErrors}
          data-metrics={`${dataMetricsPrefix}-button`}
          data-cy="save-changes"
        >
          {DisplayStrings.SaveChanges}
        </QButton>
      </QModalActions>
    </QModal>
  );
};

export default AssignFormModal;
