import React from 'react';
import { QBox, QStack, QText } from '@qualio/ui-components';
import ListMovementControls from '../ListMovementControls/ListMovementControls';

type ControlProps = {
  disableUp?: boolean;
  disableDown?: boolean;
  onMove: (direction: 'up' | 'down') => void;
  onDelete: () => void;
};

type BaseCardProps = {
  title?: React.ReactNode;
  children: React.ReactNode;
  'data-cy'?: string;
};

type CardWithoutControls = BaseCardProps & { includeControls?: false };
type CardWithControlsProps = BaseCardProps & ControlProps & { includeControls: true };

const Card = (props: CardWithoutControls | CardWithControlsProps) => {
  const { children, title, includeControls } = props;

  return (
    <QBox
      data-cy={props['data-cy']}
      borderColor="gray.200"
      borderWidth="1px"
      borderRadius="base"
      p={6}
      backgroundColor="white"
    >
      <QStack direction="column">
        <QBox display="flex" flexDirection="row" justifyContent="space-between">
          <QText>{title}</QText>
          {includeControls ? (
            <ListMovementControls
              disableUp={props.disableUp}
              disableDown={props.disableDown}
              onMove={props.onMove}
              onDelete={props.onDelete}
              data-cy={props['data-cy']}
            />
          ) : null}
        </QBox>
        <QBox>{children}</QBox>
      </QStack>
    </QBox>
  );
};

export default Card;
