import React from 'react';
import { QualioContentSection, QualioSectionedContent } from '../CKESharedTypes';
import EditorContentViewer from '../../../shared/EditorContentViewer/EditorContentViewer';

type ContentViewerProps = {
  content: QualioSectionedContent;
};

class PresentationUtils {
  static determineEditorContentFromSectionsData(data: QualioSectionedContent): string {
    return data.sections
      .map((section) => {
        const sectionTitle = PresentationUtils.determineSectionTitleTag(section);
        return `${sectionTitle}${section.content || '<p></p>'}`;
      })
      .join('');
  }

  static determineSectionTitleTag(section: QualioContentSection): string {
    return `<h1 class="section-heading"><span class="locked-section-headers">${
      section.position
    }. ${PresentationUtils.processTitleForView(section.title)}</span></h1>`;
  }

  static processTitleForView(title: string): string {
    // replace multiple spaces with a single space
    const titleWithNoDoubleSpaces = title.replace(/\s\s+/g, ' ');
    return PresentationUtils.specialEscapeForCkEditor(titleWithNoDoubleSpaces).trim();
  }

  static specialEscapeForCkEditor(myString: string): string {
    // This is a reverse engineered from the 'he' library
    // It will escape only he characters &, <, and >.
    // This is because CKEdior does not like encoded quote and double quotes

    const regexEscape = /[&<>`]/g;
    const escapeMap: { [index: string]: string } = {
      '&': '&amp;',
      '<': '&lt;',
      // See https://mathiasbynens.be/notes/ambiguous-ampersands: in HTML, the
      // following is not strictly necessary unless it’s part of a tag or an
      // unquoted attribute value. We’re only escaping it to support those
      // situations, and for XML support.
      '>': '&gt;',
    };
    return myString.replace(regexEscape, function ($0): string {
      // Note: there is no need to check `has(escapeMap, $0)` here.
      return escapeMap[$0]!;
    });
  }
}

const ContentViewer: React.FC<ContentViewerProps> = ({ content }) => {
  const processedDocumentContent = PresentationUtils.determineEditorContentFromSectionsData(content);

  return <EditorContentViewer content={processedDocumentContent} />;
};

export default ContentViewer;
