import { SectionDTO, ContentTemplateDTO, SectionResolutionDTO } from './contentTemplateDTO';

export class ContentTemplateRequestBody {
  sections: SectionDTO[];
  resolutions: SectionResolutionDTO[];

  constructor(contentTemplateDto: ContentTemplateDTO, resolutions: SectionResolutionDTO[]) {
    this.sections = contentTemplateDto.sections;
    this.resolutions = resolutions;
  }
}
