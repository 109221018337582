import {
  QButton,
  QCloseButton,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QStack,
  QText,
  useToastProvider,
} from '@qualio/ui-components';
import React from 'react';
import { RootCause } from '../../types/rootCause';
import { extractMessageFromError } from '../../utils/errorUtils';
import * as DisplayStrings from '../../displayStrings';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSave: (rootCause: RootCause) => Promise<void>;
  rootCause: RootCause;
};

const RootCauseDeleteModal: React.FC<Props> = ({ onSave, rootCause, isOpen, setIsOpen }) => {
  const { showToast } = useToastProvider();

  const handleDeleteClick = async () => {
    try {
      await onSave(rootCause);
      setIsOpen(false);
      showToast({
        title: 'Root cause deleted',
        description: `"${rootCause.name}" was deleted.`,
        status: 'success',
      });
    } catch (error: any) {
      showToast({
        title: 'Failed to delete',
        description: extractMessageFromError(error),
        status: 'error',
      });
    }
  };

  const handleClose = () => setIsOpen(false);

  return (
    <QModal isOpen={isOpen} onClose={handleClose} size="md">
      <QModalHeader>
        <QText>Delete root cause</QText>
        <QCloseButton onClick={handleClose}></QCloseButton>
      </QModalHeader>
      <QModalBody>
        <QStack direction="column" color="gray.700" spacing={4}>
          <QText>{DisplayStrings.DeleteRootCauseConfirmation(rootCause.name)}</QText>
        </QStack>
      </QModalBody>
      <QModalActions>
        <QButton onClick={handleClose} variant="outline">
          {DisplayStrings.Cancel}
        </QButton>
        <QButton onClick={() => handleDeleteClick()} isDestructive>
          {DisplayStrings.Delete}
        </QButton>
      </QModalActions>
    </QModal>
  );
};

export default RootCauseDeleteModal;
