import React, { useCallback, useState } from 'react';
import { QSpinner, useCurrentUser } from '@qualio/ui-components';
import { useParams } from 'react-router-dom';
import EditContentTemplateHeader from '../../components/EditContentTemplate/Header/EditContentTemplateHeader';
import { EditContentTemplateContainer } from './EditContentTemplate.styles';
import EditContentTemplateContent from './EditContentTemplateContent';
import { SectionErrors, ContentTemplateDTO, ContentTemplate } from '../../types';
import { useContentTemplateDetails } from '../../hooks';
import { usePureState } from '../../hooks/usePureState';

const EditContentTemplate: React.FC = () => {
  const { companyId } = useCurrentUser();
  const { eventTemplateId, contentTemplateId } = useParams();
  const [templateDto, setTemplateDto] = useState<ContentTemplateDTO>();
  const [originalTemplateDto, setOriginalTemplateDto] = usePureState<ContentTemplateDTO>();
  const [sectionErrors, setSectionErrors] = useState<SectionErrors[]>([new SectionErrors()]);
  const [sectionTitlesTouched, setSectionTitlesTouched] = useState<boolean[]>([false]);

  if (!contentTemplateId || !Number.isFinite(Number(contentTemplateId))) {
    window.location.href = `${window.location.origin}/404`;
  }

  const onSuccessCallback = useCallback(
    (data: ContentTemplate | undefined) => {
      if (data) {
        const originalTemplate = new ContentTemplateDTO(data);

        const originalSectionAmount = originalTemplate.sections.length;
        const sectionTitlesTouchedForEdit = new Array(originalSectionAmount);
        const sectionTitleErrors = [];
        for (let i = 0; i < originalSectionAmount; i++) {
          sectionTitlesTouchedForEdit[i] = true;
          sectionTitleErrors.push(new SectionErrors());
        }
        setSectionTitlesTouched(sectionTitlesTouchedForEdit);
        setSectionErrors(sectionTitleErrors);

        setOriginalTemplateDto(originalTemplate);
        setTemplateDto(originalTemplate);
      }
    },
    [setTemplateDto, setOriginalTemplateDto],
  );

  const { data: templateDetails } = useContentTemplateDetails(
    Number(contentTemplateId),
    onSuccessCallback,
    !originalTemplateDto,
  );

  if (templateDto && originalTemplateDto && eventTemplateId && contentTemplateId) {
    return (
      <EditContentTemplateContainer>
        <EditContentTemplateHeader templateId={eventTemplateId} templateName={templateDetails?.name ?? ''} />
        <EditContentTemplateContent
          companyId={companyId}
          templateId={eventTemplateId}
          contentTemplateId={contentTemplateId}
          originalContentTemplateDto={originalTemplateDto}
          contentTemplateDto={templateDto}
          setContentTemplateDto={setTemplateDto}
          sectionErrors={sectionErrors}
          setSectionErrors={setSectionErrors}
          sectionTitlesTouched={sectionTitlesTouched}
          setSectionTitlesTouched={setSectionTitlesTouched}
        />
      </EditContentTemplateContainer>
    );
  } else {
    return <QSpinner />;
  }
};

export default EditContentTemplate;
