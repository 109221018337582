import React, { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { QBox, QHeading, QStack, useCurrentUser } from '@qualio/ui-components';
import rootCauseApi from '../../api/rootCauses.api';
import { CreateRootCause, RootCause } from '../../types/rootCause';
import RootCauseUpdateModal from '../../components/RootCauseModal/RootCauseUpdateModal';
import RootCauseDeleteModal from '../../components/RootCauseModal/RootCauseDeleteModal';
import RootCauseList from '../../components/RootCauseList/RootCauseList';
import RootCauseCreateModal from '../../components/RootCauseModal/RootCauseCreateModal';
import AddRootCauseButton from '../../components/RootCauseList/components/AddRootCauseButton';
import { useDocTitle } from '../../hooks';
import { useRootCauses } from '../../hooks/useRootCauses';

const RootCauseListOverview = () => {
  const { companyId } = useCurrentUser();
  const { data, refetch, isLoading } = useRootCauses();
  const { data: statsData } = useQuery('rootCauseStats', () => rootCauseApi.getAllStats(companyId));

  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const [currentRootCause, setCurrentRootCause] = useState<RootCause>();

  useDocTitle('Quality Events - Root Causes - Qualio');

  const handleAdd = useCallback(
    async (payload: CreateRootCause) => {
      await rootCauseApi.create(companyId, payload);
      refetch();
    },
    [companyId, refetch],
  );

  const handleUpdate = useCallback(
    async (rootCause: RootCause) => {
      await rootCauseApi.update(companyId, rootCause);
      refetch();
    },
    [companyId, refetch],
  );

  const handleDelete = useCallback(
    async (rootCause: RootCause) => {
      await rootCauseApi.delete(companyId, rootCause.id);
      refetch();
    },
    [companyId, refetch],
  );

  const handleEditClick = useCallback((rootCause: RootCause) => {
    setCurrentRootCause(rootCause);
    setIsUpdateModalOpen(true);
  }, []);

  const handleDeleteClick = useCallback((rootCause: RootCause) => {
    setCurrentRootCause(rootCause);
    setIsDeleteModalOpen(true);
  }, []);

  return (
    <>
      <QStack direction="column" spacing={6}>
        <QBox display="flex" flexDirection="row" justifyContent="space-between">
          <QHeading size="lg">Root causes</QHeading>
          <AddRootCauseButton onClickHandler={() => setIsAddModalOpen(true)} />
        </QBox>
        <RootCauseList
          rootCauses={data}
          stats={statsData}
          isLoading={isLoading}
          onEditRootCause={handleEditClick}
          onDeleteRootCause={handleDeleteClick}
        />
      </QStack>
      <RootCauseCreateModal isOpen={isAddModalOpen} setIsOpen={setIsAddModalOpen} onSave={handleAdd} />
      {currentRootCause && (
        <RootCauseUpdateModal
          isOpen={isUpdateModalOpen}
          setIsOpen={setIsUpdateModalOpen}
          onSave={handleUpdate}
          rootCause={currentRootCause}
        />
      )}
      {currentRootCause && (
        <RootCauseDeleteModal
          isOpen={isDeleteModalOpen}
          setIsOpen={setIsDeleteModalOpen}
          onSave={handleDelete}
          rootCause={currentRootCause}
        />
      )}
    </>
  );
};

export default RootCauseListOverview;
