import * as z from 'zod';
import { QualityEventCreateWithTitleRestriction } from '../../types';
import { gtTenYearsDaysErrorMessage, ltZeroDaysErrorMessage, tenYearsDays } from '../../types/common/constants';
import { NonEmptyStringSchema, OptionalStringSchema } from '../../types/commonZodSchemas/strings';
import { convertEmptyValuesToUndefined } from '../../utils/objectUtils';

export const ModalFormSchema = z.object({
  title: NonEmptyStringSchema.max(100, 'Title can be no longer than 100 characters'),
  workflow_id: NonEmptyStringSchema,
  owner_id: NonEmptyStringSchema,
  description: OptionalStringSchema,
  time_limit: z
    .string()
    .trim()
    .min(1, 'Required')
    // During the form life cycle, time limit needs to remain a string that "can become"
    // a valid number.
    .superRefine((val, ctx) => {
      const numberedVal = Number(val);

      if (!Number.isFinite(numberedVal)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Must be a number',
        });

        return;
      }

      if (!Number.isInteger(numberedVal)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Must be a whole number',
        });

        return;
      }

      if (numberedVal < 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: ltZeroDaysErrorMessage,
        });

        return;
      }

      if (numberedVal > tenYearsDays) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: gtTenYearsDaysErrorMessage,
        });

        return;
      }
    }),
});

export type ModalForm = z.infer<typeof ModalFormSchema>;

export const processFormValuesForSubmit = (formValues: ModalForm) => {
  return QualityEventCreateWithTitleRestriction.parse({
    ...convertEmptyValuesToUndefined(formValues),
  });
};
