import { useCurrentUser } from '@qualio/ui-components';
import React from 'react';

export type UserPermissionsContextType = {
  canCUDProduct: boolean;
  canCUDRootCause: boolean;
  canCreateEvent: boolean;
};

export const UserPermissionsContext = React.createContext<UserPermissionsContextType | undefined>(undefined);

export type UserPermissionsProviderType = {
  userType: string;
};

export const UserPermissionsProvider: React.FC<UserPermissionsProviderType> = ({ userType, children }) => {
  const { permissions } = useCurrentUser();
  const isQualityUser = userType === 'quality';
  const canCUDProduct = isQualityUser;
  const canCUDRootCause = isQualityUser;
  const canCreateEvent = permissions['can_create_issue'] === true;

  const value = { canCUDProduct, canCUDRootCause, canCreateEvent };
  return <UserPermissionsContext.Provider value={value}>{children}</UserPermissionsContext.Provider>;
};
