import { useMutation } from 'react-query';
import { useCurrentUser, useToastProvider } from '@qualio/ui-components';
import formsApi from '../api/forms.api';
import { UpdateForm } from '../types/form';
import { DefaultErrorMessage } from '../displayStrings';

type FormUpdate = {
  title?: string;
  status?: string;
  owner_id?: number;
};

type UseEventFormEditType = {
  editEventFormMutate: (payload: FormUpdate, callback: { onSuccess: (data: UpdateForm) => void }) => void;
  isEventFormEditLoading: boolean;
  isEventFormEditSuccess: boolean;
};

export const useEventFormEdit = (
  eventId: number,
  stepId: number,
  formId: number,
  onSuccess: () => void,
): UseEventFormEditType => {
  const { companyId } = useCurrentUser();
  const { showToast } = useToastProvider();

  const {
    mutate: editEventFormMutate,
    isLoading: isEventFormEditLoading,
    isSuccess: isEventFormEditSuccess,
  } = useMutation((payload: FormUpdate) => formsApi.updateForm(companyId, eventId, stepId, formId, payload), {
    onSuccess: () => {
      onSuccess();
    },
    onError: (err: any) => {
      const message = err?.response?.data?.message || DefaultErrorMessage;
      showToast({
        description: message,
        status: 'error',
      });
    },
  });

  return { editEventFormMutate, isEventFormEditLoading, isEventFormEditSuccess };
};
