import { useContext } from 'react';
import { UserPermissionsContext, UserPermissionsContextType } from '../context';

export const useUserPermissions = (): UserPermissionsContextType => {
  const context = useContext(UserPermissionsContext);
  if (!context) {
    throw new Error('useUserPermissions can only be used inside UserPermissionsProvider');
  }
  return context;
};
