import React from 'react';
import { QBox, QFlex, QStack, QTag } from '@qualio/ui-components';
import { EventTag } from '../../../types/eventTag';
import { PropertySection } from './PropertySection';
import { PropertySectionText } from './PropertySectionText';

type Props = {
  tags: EventTag[];
};

const TagsSection: React.FC<Props> = ({ tags }) => {
  return (
    <QStack>
      <PropertySection title="Tags">
        <QFlex flexWrap="wrap">
          {tags.length === 0 ? (
            <PropertySectionText>-</PropertySectionText>
          ) : (
            tags.map((tag) => (
              <QBox key={tag.id} mr={2} mb={2}>
                <QTag>{tag.name}</QTag>
              </QBox>
            ))
          )}
        </QFlex>
      </PropertySection>
    </QStack>
  );
};

export default TagsSection;
