import { EventTag } from '../types/eventTag';
import api, { createRequestConfig } from './index';

const tagsApi = {
  async getAllTags(company: number): Promise<EventTag[] | undefined> {
    const res = await api.get(`/company/${company}/tags`, createRequestConfig({}));
    return res.data.data as EventTag[];
  },
};

export default tagsApi;
