import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { QBox, QButton, QCenter, QHeading, QStack, QText } from '@qualio/ui-components';
import { ContentTemplate, EventTemplateStepCreateEdit, FormTemplateWithWorkflowId } from '../../../types';
import { useLocalisedFormatDateTime, DateTimeFormat } from '../../../utils/datetimeUtils';

type TemplateDrawerHeaderProps = {
  isContentTemplate: boolean;
  template: ContentTemplate | FormTemplateWithWorkflowId;
  onDelete: (step: EventTemplateStepCreateEdit) => void;
  step: EventTemplateStepCreateEdit;
  preventStepDelete: (step: EventTemplateStepCreateEdit) => boolean;
};

const TemplateDrawerHeader: React.FC<TemplateDrawerHeaderProps> = ({
  isContentTemplate,
  template,
  onDelete,
  step,
  preventStepDelete,
}) => {
  let modifiedTimeInSeconds;
  if (isContentTemplate) {
    modifiedTimeInSeconds = (template as ContentTemplate).modified_time ?? (template as ContentTemplate).created_time;
  } else {
    modifiedTimeInSeconds =
      (template as FormTemplateWithWorkflowId).updated_at ?? (template as FormTemplateWithWorkflowId).created_at;
  }

  const navigate = useNavigate();
  const formatDateTime = useLocalisedFormatDateTime();

  const navigateToTemplateEditPage = useCallback(
    (isContentTemplate, template: ContentTemplate | FormTemplateWithWorkflowId) => {
      if (isContentTemplate) {
        navigate(
          `../templates/${(template as ContentTemplate).workflow_id}/content-templates/${
            (template as ContentTemplate).id
          }/edit`,
        );
      } else {
        navigate(
          `../templates/${(template as FormTemplateWithWorkflowId).workflow_id}/form-templates/${
            (template as FormTemplateWithWorkflowId).id
          }/edit`,
        );
      }
    },
    [navigate],
  );

  const deleteAction = () => {
    onDelete(step);
  };

  return (
    <QBox display="flex" flexDirection="row" justifyContent="space-between" w={'100%'}>
      <QStack direction={['row', 'column']} spacing="0">
        <QHeading as="h2" size="md" color="gray.900" fontWeight={600}>
          {isContentTemplate ? (template as ContentTemplate).name || '' : step.label}
        </QHeading>
        <QStack direction={['column', 'row']} pt="2">
          <QText fontSize="sm" fontWeight={600} color="gray.800">
            Last modified{' '}
          </QText>
          <QText color="gray.500" fontSize="sm" fontWeight={400}>
            {formatDateTime(modifiedTimeInSeconds, DateTimeFormat.TASK)}
          </QText>
        </QStack>
      </QStack>
      {(isContentTemplate || (!isContentTemplate && (template as FormTemplateWithWorkflowId).fields.length > 0)) && (
        <QCenter>
          <QStack direction={['column', 'row']} spacing="4">
            {template && (
              <>
                <QButton
                  onClick={deleteAction}
                  variant="outline"
                  data-cy="deleteStepButton"
                  aria-label="delete-step"
                  isDisabled={preventStepDelete(step)}
                  isDestructive
                >
                  Delete step
                </QButton>
                <QButton
                  onClick={() => navigateToTemplateEditPage(isContentTemplate, template)}
                  variant="outline"
                  data-cy="editTemplateButton"
                  aria-label="edit-template"
                >
                  Edit
                </QButton>
              </>
            )}
          </QStack>
        </QCenter>
      )}
    </QBox>
  );
};

export default TemplateDrawerHeader;
