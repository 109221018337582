import { QBox, QButton, QFormControl, QHeading, QIcon, QInput, QStack, QSwitch, QText } from '@qualio/ui-components';
import React from 'react';
import { SectionErrors, ContentTemplateDTO } from '../../../types';
import { trimAndReplaceMultiSpaceWithOneSpace } from '../../../utils/textUtils';
import ContentBoxErrors from './ContentBoxErrors';
import { elementAtIndex } from '../../../utils/arrayUtils';

export type ContentBoxProps = {
  templateDto: ContentTemplateDTO;
  setTemplateDto: (templateDto: ContentTemplateDTO) => void;
  index: number;
  sectionErrors: SectionErrors[];
  setSectionErrors: (errors: SectionErrors[]) => void;
  sectionTitlesTouched: boolean[];
  setSectionTitlesTouched: (touched: boolean[]) => void;
  setPageError: (value: boolean) => void;
  resolutionSection: number | null;
};

const INVALID_TITLE_CHARS = '\'"';
const getResolutionSectionHelpText = (isResolutionSection: boolean, resolutionSectionExists: boolean) => {
  return !isResolutionSection && resolutionSectionExists
    ? `Only one section of type "Resolution" is allowed. Please change type of other section first.`
    : `Section of type "Resolution" will be presented as an editable checkbox to confirm if issue was resolved ok or not.`;
};

const ContentBox = (props: ContentBoxProps) => {
  const section = elementAtIndex(props.templateDto.sections, props.index);
  const resolutionSectionAllowed = props.templateDto.canHaveResolutionSection;
  const isResolutionSection = section.type === 'resolution';

  const deleteSection = () => {
    const template = { ...props.templateDto };
    template.sections.splice(props.index, 1);
    for (let i = props.index; i < template.sections.length; i++) {
      template.sections[i]!.position--;
    }
    props.setTemplateDto(template);

    const sectionErrors = [...props.sectionErrors];
    sectionErrors.splice(props.index, 1);
    props.setSectionErrors(sectionErrors);
    checkPageError(sectionErrors);

    const sectionTitlesTouched = [...props.sectionTitlesTouched];
    sectionTitlesTouched.splice(props.index, 1);
    props.setSectionTitlesTouched(sectionTitlesTouched);
  };

  const move = (sectionPos: number, direction: number) => {
    const template = { ...props.templateDto };
    const sectionToMove = elementAtIndex(template.sections, sectionPos);

    sectionToMove.position = sectionToMove.position + direction;

    const sectionAtTarget = elementAtIndex(template.sections, sectionPos + direction);

    template.sections[sectionPos] = sectionAtTarget;

    sectionAtTarget.position = elementAtIndex(template.sections, sectionPos).position - direction;

    template.sections[sectionPos + direction] = sectionToMove;
    props.setTemplateDto(template);

    const errors = [...props.sectionErrors];
    const errorToChange = elementAtIndex(errors, sectionPos + direction);

    errors[sectionPos + direction] = elementAtIndex(errors, sectionPos);
    errors[sectionPos] = errorToChange;
    props.setSectionErrors(errors);

    const sectionTitlesTouched = [...props.sectionTitlesTouched];
    const touchedToChange = elementAtIndex(sectionTitlesTouched, sectionPos + direction);

    sectionTitlesTouched[sectionPos + direction] = elementAtIndex(sectionTitlesTouched, sectionPos);
    sectionTitlesTouched[sectionPos] = touchedToChange;
    props.setSectionTitlesTouched(sectionTitlesTouched);
  };

  const moveUp = () => move(props.index, -1);
  const moveDown = () => move(props.index, 1);

  const checkPageError = (errors: SectionErrors[]) => {
    let pageHasError = false;

    for (const error of errors) {
      pageHasError = pageHasError || error.hasErrors();
    }

    if (!pageHasError) {
      props.setPageError(false);
    }
  };

  return (
    <QBox
      mt="6"
      border={'1px'}
      borderColor={'gray.200'}
      rounded={'md'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      overflow="hidden"
    >
      <QBox p={6}>
        <QBox pb={4}>
          <QHeading size="sm">Section {section.position}</QHeading>
        </QBox>
        <QFormControl>
          <QStack direction={['column']} spacing="4">
            <QBox>
              <QText>Title</QText>
              <QInput
                onChange={(e) => {
                  const template = { ...props.templateDto };

                  elementAtIndex(template.sections, props.index).title = e.target.value;
                  props.setTemplateDto(template);

                  const errors = [...props.sectionErrors];

                  elementAtIndex(errors, props.index).reset();
                  props.setSectionErrors(errors);

                  checkPageError(errors);
                }}
                onBlur={(e) => {
                  const title = e.target.value;
                  const template = { ...props.templateDto };

                  elementAtIndex(template.sections, props.index).title = trimAndReplaceMultiSpaceWithOneSpace(title);
                  props.setTemplateDto(template);

                  if (title.trim() === '') {
                    const errors = [...props.sectionErrors];
                    elementAtIndex(errors, props.index).titleBlank = true;
                    props.setSectionErrors(errors);
                    props.setPageError(true);
                  } else {
                    for (const char of INVALID_TITLE_CHARS) {
                      if (title.indexOf(char) !== -1) {
                        const errors = [...props.sectionErrors];
                        elementAtIndex(errors, props.index).invalidCharacters += char;
                        props.setSectionErrors(errors);
                        props.setPageError(true);
                      }
                    }
                  }

                  if (elementAtIndex(props.sectionErrors, props.index).duplicateTitle) {
                    props.setPageError(true);
                  }

                  const sectionTitlesTouched = [...props.sectionTitlesTouched];
                  sectionTitlesTouched[props.index] = true;
                  props.setSectionTitlesTouched(sectionTitlesTouched);
                }}
                value={section.title}
                name="title"
                isInvalid={elementAtIndex(props.sectionErrors, props.index).hasErrors()}
                data-cy={'title' + section.position}
              />
              <ContentBoxErrors sectionErrors={elementAtIndex(props.sectionErrors, props.index)} />
            </QBox>
            {resolutionSectionAllowed ? (
              <QFormControl
                size="sm"
                helper={getResolutionSectionHelpText(isResolutionSection, Boolean(props.resolutionSection))}
              >
                <QSwitch
                  isChecked={isResolutionSection}
                  isDisabled={isResolutionSection ? false : Boolean(props.resolutionSection)}
                  onChange={(event) => {
                    const isChecked = event.target.checked;

                    const template = { ...props.templateDto };
                    elementAtIndex(template.sections, props.index).type = isChecked ? 'resolution' : 'text';

                    props.setTemplateDto(template);
                  }}
                >
                  Make this a resolution section
                </QSwitch>
              </QFormControl>
            ) : null}
          </QStack>
        </QFormControl>
      </QBox>
      <QBox
        border={'1px'}
        borderColor={'gray.200'}
        backgroundColor={'gray.50'}
        py={2}
        px={4}
        display={'flex'}
        justifyContent={'space-between'}
        m={'-1'}
      >
        <QButton
          variant="ghost"
          leftIcon="Trash"
          isDisabled={props.templateDto.sections.length === 1}
          onClick={deleteSection}
          data-cy={'delete' + section.position}
        >
          Delete
        </QButton>
        <QBox>
          <QButton
            variant="ghost"
            onClick={moveUp}
            isDisabled={props.index === 0}
            data-cy={'moveup' + section.position}
          >
            <QIcon iconName={'ArrowUp'} />
          </QButton>
          <QButton
            variant="ghost"
            onClick={moveDown}
            isDisabled={section.position === props.templateDto.sections.length}
            data-cy={'movedown' + section.position}
          >
            <QIcon iconName={'ArrowDown'} />
          </QButton>
        </QBox>
      </QBox>
    </QBox>
  );
};

export default ContentBox;
