import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { QCenter, QSpinner, useCurrentUser } from '@qualio/ui-components';
import FormTemplateEditor from '../../components/FormTemplateEditor/FormTemplateEditor';
import { useEventTemplateDetails, useFormTemplateDetails } from '../../hooks';
import { useUpdateFormTemplate } from './hooks';
import { EventTemplateDetailsResponse, EventTemplateStep } from '../../types';
import { useRegistryCategories } from '../../hooks/useRegistryCategories';
import { useFlags } from '../../external/LaunchDarklyAdapter';

const FormEditorOverview: React.FC = () => {
  const { eventTemplateId, formTemplateId } = useParams();
  const navigate = useNavigate();
  const { companyId } = useCurrentUser();
  const dataRegistryEnabled = useFlags('dataRegistry');
  const { data: registryCategoriesData } = useRegistryCategories(dataRegistryEnabled);
  const registryCategories = dataRegistryEnabled ? registryCategoriesData : { items: [] };

  // This should not be possible, but ensuring it allows typescript to narrow the type
  if (!eventTemplateId || !formTemplateId) {
    throw new Error('Missing eventTemplateId or formTemplateId');
  }

  const {
    data: eventTemplate,
    isLoading: isEventTemplateLoading,
    isError: isEventTemplateError,
  } = useEventTemplateDetails(companyId, Number(eventTemplateId));

  const step = (eventTemplate as EventTemplateDetailsResponse)?.steps.find(
    (step: EventTemplateStep) => step.form_template_id === Number(formTemplateId),
  );

  const {
    data: formTemplate,
    isLoading: isFormTemplateLoading,
    isError,
    isIdle,
    isFetchedAfterMount,
  } = useFormTemplateDetails(companyId, Number(eventTemplateId), step?.id, Number(formTemplateId), !!eventTemplate);

  const { mutate: updateTemplate } = useUpdateFormTemplate();

  useEffect(() => {
    if (isError || isEventTemplateError || (!isEventTemplateLoading && !step)) {
      navigate(`/templates/${eventTemplateId}`);
    }
  }, [isError, isEventTemplateError, step, eventTemplateId, navigate, isEventTemplateLoading]);

  if (
    isEventTemplateLoading ||
    isFormTemplateLoading ||
    isIdle ||
    !isFetchedAfterMount ||
    !registryCategories ||
    !step ||
    !formTemplate
  ) {
    return (
      <QCenter pt={10}>
        <QSpinner />
      </QCenter>
    );
  }

  return (
    <FormTemplateEditor
      formStepName={step.label}
      formTemplateId={Number(formTemplateId)}
      eventTemplateId={Number(eventTemplateId)}
      fieldsArray={formTemplate.fields}
      registryCategories={registryCategories.items}
      onSave={(data) =>
        updateTemplate(
          {
            companyId,
            eventTemplateId: step.workflow_id,
            stepId: step.id,
            formTemplateId: Number(formTemplateId),
            newFields: data,
            formTemplateStatus: formTemplate.status,
          },
          { onSuccess: () => navigate(`/templates/${eventTemplateId}`) },
        )
      }
    />
  );
};

export default FormEditorOverview;
