import React, { useEffect } from 'react';
import { QFormControl, QMultiSelect, QSelectItem, QStack } from '@qualio/ui-components';
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form';
import { EventTag } from '../../../types/eventTag';
import MultiSelectWithSuggestions from '../../EventModal/components/MultiSelectWithSuggestions';

import { QualityEventCreate, QualityEvent } from '../../../types';
import { eventFieldsSetter } from '../../../utils/eventUtils';
import { quickSearchApiFactory } from '../../../api/quickSearch.api';
import { RequiredFieldHelper } from '../../../displayStrings';

export type UpdateEventPropertiesSelectsData = {
  eventTags: EventTag[];
  relatedEvents: QSelectItem[];
  relatedDocuments: QSelectItem[];
  relatedSuppliers: QSelectItem[];
  sourceEvents: QSelectItem[];
  escalatedTo: QSelectItem[];
};

type UpdateEventPropertiesFormPropsProps = {
  formMethods: UseFormReturn<QualityEventCreate>;
  onSubmit: any;
  data: UpdateEventPropertiesSelectsData;
  event?: QualityEvent;
  suppliersConfiguration: { enabled: boolean; required: boolean };
};

const UpdateEventPropertiesForm: React.FC<UpdateEventPropertiesFormPropsProps> = ({
  formMethods,
  onSubmit,
  data,
  event,
  suppliersConfiguration,
}) => {
  const { handleSubmit, control, setValue } = formMethods;
  const documentsSuggestionApi = quickSearchApiFactory('documents');
  const eventsSuggestionApi = quickSearchApiFactory('events');
  const supplierSuggestionApi = quickSearchApiFactory('suppliers');

  const eventTagsOptions = data.eventTags.map((tag) => {
    return { value: String(tag.id), label: tag.name };
  });
  const { relatedEvents, relatedDocuments, relatedSuppliers, sourceEvents, escalatedTo } = data;

  useEffect(() => {
    eventFieldsSetter(event, setValue);
  }, [event, setValue]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <QStack direction="column" spacing={4}>
          <>
            <QFormControl label="Related events" data-metrics="event-related-event-select">
              <Controller
                control={control}
                name="related_events"
                render={({ field: { ref: _ref, onChange, ...field } }) => {
                  return (
                    <MultiSelectWithSuggestions
                      {...field}
                      suggestionApi={eventsSuggestionApi}
                      defaults={relatedEvents}
                      valuePropFromSuggestion="qri"
                      domain="events"
                      omitFromSuggestionResults={event ? [event.id] : []}
                      onChange={(
                        items: {
                          value: any;
                        }[],
                      ) => onChange(items.map((item: { value: any }) => item.value))}
                    />
                  );
                }}
              />
            </QFormControl>
            <QFormControl label="Related documents" data-metrics="event-related-document-select" isRequired={false}>
              <Controller
                control={control}
                name="related_documents"
                render={({ field: { ref: _ref, onChange, ...field } }) => (
                  <MultiSelectWithSuggestions
                    {...field}
                    suggestionApi={documentsSuggestionApi}
                    defaults={relatedDocuments}
                    valuePropFromSuggestion="qri"
                    domain="documents"
                    onChange={(
                      items: {
                        value: any;
                      }[],
                    ) => onChange(items.map((item: { value: any }) => item.value))}
                  />
                )}
              />
            </QFormControl>
          </>
          <>
            <QFormControl label="Source event" data-metrics="event-source-event-select" isRequired={false}>
              <Controller
                control={control}
                name="escalated_from"
                render={({ field: { ref: _ref, onChange, ...field } }) => (
                  <MultiSelectWithSuggestions
                    {...field}
                    suggestionApi={eventsSuggestionApi}
                    defaults={sourceEvents}
                    valuePropFromSuggestion="id"
                    domain="events"
                    omitFromSuggestionResults={event ? [event.id] : []}
                    onChange={(
                      items: {
                        value: any;
                      }[],
                    ) => onChange(items.map((item: { value: any }) => item.value))}
                  />
                )}
              />
            </QFormControl>
            <QFormControl label="Escalated to" data-metrics="event-escalated-to-select" isRequired={false}>
              <Controller
                control={control}
                name="escalated_to"
                render={({ field: { ref: _ref, onChange, ...field } }) => (
                  <MultiSelectWithSuggestions
                    {...field}
                    suggestionApi={eventsSuggestionApi}
                    defaults={escalatedTo}
                    valuePropFromSuggestion="id"
                    domain="events"
                    omitFromSuggestionResults={event ? [event.id] : []}
                    onChange={(
                      items: {
                        value: any;
                      }[],
                    ) => onChange(items.map((item: { value: any }) => item.value))}
                  />
                )}
              />
            </QFormControl>
          </>
          {suppliersConfiguration.enabled ? (
            <QFormControl
              label="Related suppliers"
              data-metrics="event-supplier-select"
              isRequired={suppliersConfiguration.required}
              helper={suppliersConfiguration.required ? RequiredFieldHelper : undefined}
            >
              <Controller
                control={control}
                name="suppliers"
                render={({ field: { ref: _ref, onChange, ...field }, fieldState }) => (
                  <MultiSelectWithSuggestions
                    {...field}
                    fieldState={fieldState}
                    suggestionApi={supplierSuggestionApi}
                    defaults={relatedSuppliers}
                    valuePropFromSuggestion="qri"
                    domain="suppliers"
                    onChange={(
                      items: {
                        value: any;
                      }[],
                    ) => onChange(items.map((item: { value: any }) => item.value))}
                  />
                )}
              />
            </QFormControl>
          ) : undefined}
          <QFormControl label="Tags" data-metrics="event-tags-select">
            <Controller
              control={control}
              name="tags_ids"
              render={({ field: { ref: _ref, onChange, ...field } }) => (
                <QMultiSelect
                  {...field}
                  onChange={(val) => onChange(val.map((c) => c.value))}
                  options={eventTagsOptions}
                />
              )}
            />
          </QFormControl>
        </QStack>
      </form>
    </FormProvider>
  );
};

export default UpdateEventPropertiesForm;
