import React from 'react';
import { QStack } from '@qualio/ui-components';
import * as DisplayStrings from '../../../../../displayStrings';

type FieldConfigurationType = 'default' | 'section';

type LabelsConfig = {
  fieldTitle: string;
  fieldHeader: string;
  saveButton: string;
  editButton: string;
  modalHeader: string;
  modalEditHeader: string;
};

const defaultLabelsConfig = {
  fieldTitle: DisplayStrings.FieldTitle,
  fieldHeader: DisplayStrings.HelpText,
  modalHeader: DisplayStrings.AddHelpText,
  modalEditHeader: DisplayStrings.EditHelpText,
  saveButton: DisplayStrings.AddHelpText,
  editButton: DisplayStrings.EditHelpText,
};

const labelsConfig: Record<FieldConfigurationType, LabelsConfig> = {
  default: defaultLabelsConfig,
  section: {
    fieldTitle: DisplayStrings.SectionFieldTitle,
    fieldHeader: DisplayStrings.SectionDescription,
    modalHeader: DisplayStrings.AddSectionDescription,
    modalEditHeader: DisplayStrings.EditSectionDescription,
    saveButton: DisplayStrings.AddSectionDescription,
    editButton: DisplayStrings.EditSectionDescription,
  },
};

export type FieldConfigurationContextType = {
  labels: LabelsConfig;
};

export const FormConfigurationContext = React.createContext<FieldConfigurationContextType>({
  labels: labelsConfig['default'],
});

type Props = {
  children: React.ReactNode;
  fieldConfigType?: FieldConfigurationType;
};

const FieldConfigurationWrapper = ({ children, fieldConfigType = 'default' }: Props) => {
  const labels = labelsConfig[fieldConfigType];
  return (
    <FormConfigurationContext.Provider value={{ labels }}>
      <QStack direction="column" spacing={4}>
        {children}
      </QStack>
    </FormConfigurationContext.Provider>
  );
};

export default FieldConfigurationWrapper;
