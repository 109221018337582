import React from 'react';
import { QTag, QTagProps } from '@qualio/ui-components';
import { EventStatus } from '../../types';
import * as DisplayStrings from '../../displayStrings';

const statusToTagProps: Record<EventStatus, QTagProps> = {
  open: {
    variantColor: 'blue',
    children: DisplayStrings.EventStatues.open,
  },
  closed: {
    variantColor: 'green',
    children: DisplayStrings.EventStatues.closed,
  },
  imported: {
    variantColor: 'yellow',
    children: DisplayStrings.EventStatues.imported,
  },
  rejected: {
    variantColor: 'red',
    children: DisplayStrings.EventStatues.rejected,
  },
  cancelled: {
    variantColor: 'red',
    children: DisplayStrings.EventStatues.cancelled,
  },
};

type StatusTagProps = {
  status: EventStatus | undefined;
};

const StatusTag = ({ status }: StatusTagProps) => {
  if (!status) {
    return <>{''}</>;
  }

  return <QTag {...statusToTagProps[status]} />;
};

export default StatusTag;
