import React from 'react';
import { QBox, QText, QFlex, QTag } from '@qualio/ui-components';
import { StepDocument } from '../../../types/eventStep';
import { useLocalisedFormatDateTime } from '../../../utils/datetimeUtils';

type ContentStepMetadataProps = {
  document: StepDocument | undefined;
};

const ContentStepMetadata: React.FC<ContentStepMetadataProps> = ({ document }) => {
  const formatDateTime = useLocalisedFormatDateTime();
  const createItem = (label: string, children: React.ReactNode) => {
    return (
      <QBox mr={10}>
        <QBox>
          <QText fontWeight="semibold" fontSize="xs" color="gray.800">
            {label}
          </QText>
        </QBox>
        <QBox>{children}</QBox>
      </QBox>
    );
  };

  const emptyString = '--';

  const contentStatus = (stepDocument: StepDocument | undefined) => {
    if (stepDocument) {
      return <QTag variantColor="gray">{stepDocument.status}</QTag>;
    }
    return emptyString;
  };

  return (
    <QBox h="70px" borderColor="gray.200" borderBottomWidth="1px">
      <QFlex h="100%" ml={4} direction="row" align="center">
        {createItem('Content status', contentStatus(document))}
        {createItem('Version', document ? `${document.version}` : emptyString)}
        {createItem('Author', document ? document.author_full_name : emptyString)}
        {createItem('Last modified', document ? formatDateTime(document.modified_time) : emptyString)}
      </QFlex>
    </QBox>
  );
};

export default ContentStepMetadata;
