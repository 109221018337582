import { useMutation } from 'react-query';
import { useCurrentUser, useToastProvider } from '@qualio/ui-components';
import formsApi from '../api/forms.api';
import { DefaultErrorMessage } from '../displayStrings';

type AssociateFormPayload = {
  eventId: number;
  stepId: number;
  ownerId?: number;
};

export const useAssociateForm = () => {
  const { companyId } = useCurrentUser();
  const { showToast } = useToastProvider();

  return useMutation(
    ({ eventId, stepId, ownerId }: AssociateFormPayload) => formsApi.associateForm(companyId, eventId, stepId, ownerId),
    {
      onError: () => {
        showToast({
          description: DefaultErrorMessage,
          status: 'error',
        });
      },
    },
  );
};
