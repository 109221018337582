import * as z from 'zod';

export const AuditUser = z.object({
  id: z.number(),
  email: z.string(),
  full_name: z.string(),
  staff: z.boolean().nullable(),
  created_time: z.number(),
  tz: z.string().nullable(),
});

export const Audit = z.object({
  id: z.number(),
  action: z.string(),
  document_id: z.number(),
  company_id: z.number().nullable(),
  change_id: z.number().nullable(),
  description: z.string(),
  user_id: z.number().nullable(),
  issue_id: z.number().nullable(),
  task_id: z.number().nullable(),
  timestamp: z.number(),
  comment: z.string().nullable(),
  extra: z.object({ major: z.number(), minor: z.number() }),
  ip_address: z.string(),
  location: z
    .object({
      coords: z.object({ lat: z.union([z.string(), z.number()]), lon: z.union([z.string(), z.number()]) }),
      isp: z.string(),
    })
    .nullable(),
  user: AuditUser.nullable(),
});

export const AuditGroup = z.object({
  id: z.number(),
  user_id: z.number(),
  company_id: z.number(),
  active: z.boolean().nullable(),
  ghost: z.boolean().nullable(),
  previous_user_company: z.number().nullable(),
  invite_time: z.number().nullable(),
  invite_status: z.string().nullable(),
  invite_acted_on_at: z.number().nullable(),
  currently_selected: z.boolean(),
  usercompany_id: z.number(),
  full_name: z.string(),
  email: z.string(),
  permissions: z.array(z.string()),
  usergroups: z.array(z.string()),
  is_qm: z.boolean(),
  action_pending: z.boolean(),
  action_done: z.boolean(),
  previous_action_is_valid: z.boolean(),
  action_time: z.number().nullable(),
});

export const Document = z.object({
  id: z.number(),
  uuid: z.string(),
  category: z.string(),
  template_id: z.number(),
  template_changed_at: z.number().nullable(),
  document_matrix_id: z.string(),
  title: z.string(),
  author_id: z.number(),
  owner_id: z.number(),
  created_time: z.number(),
  modified_time: z.number(),
  effective_on_approval: z.boolean(),
  training_available: z.boolean(),
  training_required: z.boolean(),
  text_sections_titles_visible: z.boolean(),
  approval_date: z.number().nullable(),
  effective_date: z.number().nullable(),
  minor_version: z.number(),
  major_version: z.number(),
  status_id: z.string(),
  last_audit_significant_change_at: z.number(),
  orientation: z.string(),
  code: z.string(),
  company_id: z.number(),
  type: z.string(),
  author: z.object({
    full_name: z.string(),
    id: z.number(),
    email: z.string(),
  }),
  owner: z.object({ full_name: z.string(), id: z.number(), email: z.string() }),
  status: z.string(),
  issue_id: z.number(),
  audits: z.array(Audit),
  audit_groups: z.object({
    approver_group: z.array(AuditGroup).optional(),
    author_group: z.array(AuditGroup),
    qm_group: z.array(AuditGroup).optional(),
    review_group: z.array(AuditGroup).optional(),
  }),
  tag_ids: z.array(z.unknown()),
  is_most_recent_version: z.boolean(),
  is_concrete_version: z.boolean(),
  content_last_modified_at: z.number(),
  issue: z.object({
    issue_step_id: z.number(),
    issue_step_label: z.string(),
    id: z.number(),
    title: z.string(),
    code: z.string(),
    issue_is_closed: z.boolean(),
    step_is_open: z.boolean(),
  }),
});

export const AssociateDocumentPayload = z.object({
  eventId: z.number(),
  stepId: z.number(),
});

export const AssociateDocumentResponse = z.object({
  id: z.number(),
  title: z.string(),
});

export type AuditUser = z.infer<typeof AuditUser>;
export type Audit = z.infer<typeof Audit>;
export type AuditGroup = z.infer<typeof AuditGroup>;
export type Document = z.infer<typeof Document>;
export type AssociateDocumentPayload = z.infer<typeof AssociateDocumentPayload>;
export type AssociateDocumentResponse = z.infer<typeof AssociateDocumentResponse>;
