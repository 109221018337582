import React from 'react';
import { QBox, QCloseButton, QHeading, QStack, QText } from '@qualio/ui-components';

type FormTemplateEditorHeaderProps = {
  formStepName: string;
  pageHeaderLabel: string;
  onClose: () => void;
};

const FormTemplateEditorHeader = ({ pageHeaderLabel, formStepName, onClose }: FormTemplateEditorHeaderProps) => {
  return (
    <QStack direction={['column', 'row']} display="flex" justifyContent="space-between" pb={6}>
      <QBox>
        <QHeading size="lg">{pageHeaderLabel}</QHeading>
        <QText mt={1} color={'gray.500'}>
          {formStepName}
        </QText>
      </QBox>
      <QCloseButton onClick={onClose} />
    </QStack>
  );
};

export default FormTemplateEditorHeader;
