import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import {
  QButton,
  QCloseButton,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QSelectItem,
  QStack,
  QText,
  useToastProvider,
} from '@qualio/ui-components';

import { QualityEvent } from '../../types';
import { ProbabilityType, RiskType, SeverityType } from '../../types/eventMetadata';
import { EventTag } from '../../types/eventTag';
import { Product } from '../../types/product';
import { QualityEventCreate } from '../../types/qualityEvent';
import { Relation } from '../../types/relation';
import { RootCause } from '../../types/rootCause';
import { User } from '../../types/user';
import EventForm from './components/EventForm';
import { DefaultErrorMessage } from '../../displayStrings';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  event: QualityEvent;
  related: Relation[];
  suppliers?: QSelectItem[];
  selectsData: {
    eventTags: EventTag[];
    probabilities: ProbabilityType[];
    severities: SeverityType[];
    risks: RiskType[];
    users: User[];
    products: Product[];
    rootCauses: RootCause[];
  };
  onSave: (payload: QualityEventCreate) => Promise<void>;
};

const EventOpenModal: React.FC<Props> = ({ isOpen, setIsOpen, selectsData, onSave, event, related, suppliers }) => {
  const { showToast } = useToastProvider();

  const formMethods = useForm<QualityEventCreate>({
    mode: 'onSubmit',
    resolver: zodResolver(QualityEventCreate),
  });

  const {
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = formMethods;

  const formHasErrors = !!Object.keys(errors).length;

  const handleClose = () => {
    setIsOpen(false);
    reset();
  };

  const onSubmit = useCallback(
    async (data: QualityEventCreate) => {
      try {
        await onSave(data);
        setIsOpen(false);
        showToast({
          title: 'Event opened',
          description: `"${data.title}" was opened`,
          status: 'success',
        });
        reset();
      } catch (err: any) {
        showToast({
          title: 'Event not opened',
          description: DefaultErrorMessage,
          status: 'error',
        });
      }
    },
    [onSave, showToast, setIsOpen, reset],
  );

  return (
    <QModal isOpen={isOpen} onClose={handleClose} size="xl">
      <QModalHeader>
        <QText>Open event</QText>
        <QCloseButton onClick={handleClose} data-metrics="open-event-close-modal-button"></QCloseButton>
      </QModalHeader>
      <QModalBody>
        <QStack direction="column" color="gray.700" spacing={4}>
          <EventForm
            formMethods={formMethods}
            onSubmit={onSubmit}
            data={{ ...selectsData, eventTypes: [] }}
            event={event}
            related={related}
            suppliers={suppliers}
            optionalFields={event.template_fields}
          />
        </QStack>
      </QModalBody>
      <QModalActions>
        <QButton onClick={handleClose} variant="outline" data-metrics="open-event-cancel-button">
          Cancel
        </QButton>
        <QButton
          onClick={() => handleSubmit(onSubmit)()}
          isDisabled={formHasErrors || isSubmitting}
          data-metrics="open-event-button"
        >
          Open event
        </QButton>
      </QModalActions>
    </QModal>
  );
};

export default EventOpenModal;
