import React from 'react';
import { DateTime } from 'luxon';
import { QText, useCurrentUser } from '@qualio/ui-components';
import { getDueValue } from '../../utils/datetimeUtils';
import { useFlags } from '../../external/LaunchDarklyAdapter';

type DueColumnProps = {
  referenceDatetime: DateTime;
  createdAtDatetime: DateTime;
  dueAtDatetime?: DateTime;
  timeLimit?: number;
  closedAtDatetime?: DateTime;
  isClosed?: boolean;
};

const DueColumn: React.FC<DueColumnProps> = ({
  referenceDatetime,
  createdAtDatetime,
  dueAtDatetime,
  timeLimit,
  closedAtDatetime,
  isClosed,
}) => {
  const { localiseDate } = useCurrentUser();
  const isUseLegacyDomainForMtfeEnabled = useFlags('useLegacyDomainForMtfe');

  const dueAt = dueAtDatetime ?? createdAtDatetime.plus({ days: timeLimit });

  const referenceDateTimestamp = referenceDatetime.startOf('day').toMillis();
  const dueAtDateTimestamp = dueAt.startOf('day').toMillis();
  const dueLimitTimestamp = closedAtDatetime ? closedAtDatetime.startOf('day').toMillis() : referenceDateTimestamp;

  const textColor = dueAtDateTimestamp < dueLimitTimestamp && !isClosed ? 'red.500' : 'gray.800';

  const dueValue = getDueValue(referenceDatetime, dueAt, localiseDate, isUseLegacyDomainForMtfeEnabled, isClosed);

  return <QText color={textColor}>{dueValue}</QText>;
};

export default DueColumn;
