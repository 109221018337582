import { QualioCodeBlockCommand } from '../plugins/CodeBlock/QualioCodeBlockCommand';

export const CONTENT_EDITOR_TOOLBAR_ITEMS: string[] = [
  'undo',
  'redo',
  '|',
  'heading',
  '|',
  'bold',
  'italic',
  'underline',
  'fontSize',
  'fontColor',
  '|',
  'link',
  'qualioFileUploadButton',
  'uploadImage',
  '|',
  'alignment',
  '|',
  'bulletedList',
  'numberedList',
  'qualioSectionListButton',
  '|',
  'outdent',
  'indent',
  '|',
  'insertTable',
  'qualioCheckboxButton',
  'specialCharacters',
  'subscript',
  'superscript',
  '|',
  QualioCodeBlockCommand.commandName,
  'blockQuote',
  '|',
  'fontBackgroundColor',
  'strikethrough',
  '|',
  'removeFormat',
  'pageBreak',
];

export const FORM_FIELD_TOOLBAR_ITEMS: string[] = [
  'undo',
  'redo',
  '|',
  'bold',
  'italic',
  'underline',
  'fontSize',
  'fontColor',
  'fontBackgroundColor',
  '|',
  'link',
  'qualioFileUploadButton',
  'uploadImage',
  '|',
  'alignment',
  '|',
  'bulletedList',
  'numberedList',
  '|',
  'outdent',
  'indent',
  '|',
  'insertTable',
  'specialCharacters',
  'subscript',
  'superscript',
  '|',
  'removeFormat',
];
