export const isUrlNewFrontend = (isUseLegacyDomainForMtfeEnabled: boolean) => {
  // If coming from local env, it's always new frontend
  if (window.location.host.includes('localhost:3001')) {
    return true;
  }

  // check if the URL contains the word 'frontend'
  const isFrontendInURL =
    window.location.host.includes('frontend.staging.qualio.com') ||
    window.location.host.includes('frontend.app.qualio.com');

  // When FF is enabled, frontend.* will be serving MTFE
  // When FF is disabled, frontend.* will be serving QFE
  return isUseLegacyDomainForMtfeEnabled ? !isFrontendInURL : isFrontendInURL;
};

export const stripQualioHost = (str: string): string => {
  try {
    const url = new URL(str);
    if (url.host.includes('qualio')) {
      return url.pathname + url.search;
    }
  } catch (e) {
    // ignore: not a valid URL (might be a relative URL (i.e. /path/to/page))
    return str;
  }
  return str;
};
