import * as z from 'zod';
import { BaseSchema, StandardFieldValueSchema } from './common';
import { FieldTypeSchema } from '../common';

// Schema/Type for a single form field as returned from the Server
export const StandardFieldSchema = BaseSchema.extend({
  type: FieldTypeSchema.extract([
    'text',
    'date',
    'boolean',
    'numeric',
    'rich_text',
    'attachment',
    'event',
    'document',
    'user',
    'change_request',
    'supplier',
    'registry',
    'design_controls',
  ]),
  values: z.array(StandardFieldValueSchema).nullish(),
});
