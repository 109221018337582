import { QSelectItem } from '@qualio/ui-components';
import { SubComponentProps } from '../../EventFormField.types';

export type DropdownSubProps = Pick<SubComponentProps, 'fieldValues' | 'onChange' | 'fieldId'> & {
  options: QSelectItem[];
};

// As the name says, converts array of string options to QSelectItem[]
export const ConvertStringOptionsToQSelectOptions = (options: string[]) =>
  options.map((option) => ({ label: option, value: option }));
