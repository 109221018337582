import { medtechApi } from '.';
import { FormTemplateWithWorkflowId, FormTemplateSchema } from '../types';
import { FormTemplateField, NewFormTemplateField } from '../types/formFields/formTemplates';

const formTemplateApi = {
  async getFormTemplate(
    companyId: number,
    eventTemplateId: number,
    stepId: number,
    formTemplateId: number,
  ): Promise<FormTemplateWithWorkflowId> {
    const path = `/quality-events/${companyId}/workflows/${eventTemplateId}/steps/${stepId}/form-templates/${formTemplateId}`;
    const res = await medtechApi.get(path);
    const formTemplate = FormTemplateSchema.parse(res.data);

    return {
      ...formTemplate,
      workflow_id: eventTemplateId,
    };
  },

  async updateFormTemplate(
    companyId: number,
    eventTemplateId: number,
    stepId: number,
    formTemplateId: number,
    formFields: (FormTemplateField | NewFormTemplateField)[],
  ): Promise<FormTemplateWithWorkflowId> {
    const path = `/quality-events/${companyId}/workflows/${eventTemplateId}/steps/${stepId}/form-templates/${formTemplateId}`;
    const payload = { fields: formFields };
    const res = await medtechApi.put(path, payload);
    const updatedTemplate = FormTemplateSchema.parse(res.data);

    return {
      ...updatedTemplate,
      workflow_id: eventTemplateId,
    };
  },

  async createNewFormTemplateVersion(
    companyId: number,
    eventTemplateId: number,
    stepId: number,
    previousFormTemplateId: number,
    formFields: (FormTemplateField | NewFormTemplateField)[],
  ): Promise<FormTemplateWithWorkflowId> {
    const path = `/quality-events/${companyId}/workflows/${eventTemplateId}/steps/${stepId}/form-templates`;
    const payload = { previous_version_form_template_id: previousFormTemplateId, fields: formFields };
    const res = await medtechApi.post(path, payload);
    const updatedTemplate = FormTemplateSchema.parse(res.data);

    return {
      ...updatedTemplate,
      workflow_id: eventTemplateId,
    };
  },
};

export default formTemplateApi;
