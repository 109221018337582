import React from 'react';
import { QButton, QDivider, QFormControl, QInput, QStack, QText } from '@qualio/ui-components';
import * as DisplayStrings from '../../../../../displayStrings';
import { Controller, UseFormReturn, useFieldArray, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FormEditorForm } from '../../../shared';
import ListMovementControls from '../../../../ListMovementControls/ListMovementControls';
import { isFirstElement, isLastElement } from '../../../../../utils/arrayUtils';

type Props = {
  control: UseFormReturn<FormEditorForm>['control'];
  fieldIndex: number;
};

const FieldOptionLst = ({ fieldIndex, control }: Props) => {
  const { formState, trigger, setValue } = useFormContext<FormEditorForm>();
  const {
    fields: dropdownOptions,
    append,
    remove,
    move,
  } = useFieldArray({
    control,
    keyName: 'uniqueId',
    name: `fields.${fieldIndex}.form_option_list.options`,
  });

  return (
    <QFormControl label={DisplayStrings.OptionSectionLabel} data-cy="dropdownOptionList">
      <QDivider />
      {dropdownOptions.map((option, index, fullArray) => {
        const optionItem = `fields.${fieldIndex}.form_option_list.options.${index}` as const;
        const optionFieldPath = `${optionItem}.value` as const;
        const optionOrder = `${optionItem}.order` as const;
        setValue(optionOrder, index);
        return (
          <div key={option.uniqueId}>
            <QStack direction="row" padding="10px">
              <QStack direction="column" width="container.md">
                <Controller
                  name={optionFieldPath}
                  control={control}
                  render={({ field: innerField }) => (
                    <QInput
                      {...innerField}
                      onChange={(changeEvent) => {
                        innerField.onChange(changeEvent);
                        trigger(`fields.${fieldIndex}.form_option_list.options`);
                      }}
                    />
                  )}
                />
                <ErrorMessage
                  errors={formState.errors}
                  name={optionFieldPath}
                  render={({ message }) => {
                    return (
                      <QText color="red" fontSize="sm">
                        {message}
                      </QText>
                    );
                  }}
                />
              </QStack>
              <ListMovementControls
                onMove={(direction) => {
                  const newIndex = direction === 'up' ? index - 1 : index + 1;

                  move(index, newIndex);
                }}
                onDelete={() => remove(index)}
                disableUp={isFirstElement(fullArray, index)}
                disableDown={isLastElement(fullArray, index)}
                disableDelete={fullArray.length === 1}
                removeIcon="X"
              />
            </QStack>
            <QDivider />
          </div>
        );
      })}
      <QButton
        leftIcon="Plus"
        variant="ghost"
        onClick={() => {
          append({ value: '', order: dropdownOptions.length + 1 });
        }}
      >
        {DisplayStrings.AddOption}
      </QButton>
    </QFormControl>
  );
};

export default FieldOptionLst;
