import React from 'react';
import { QBox, useCurrentUser } from '@qualio/ui-components';
import { CKEditorInstance } from '../shared/CKEditor/types/CKEditorInstance';
import { ContentEditor } from '../shared/ContentEditor/ContentEditor';
import { FORM_FIELD_TOOLBAR_ITEMS } from '../shared/CKEditor/config/ToolbarItems';
import { v4 } from 'uuid';

type RichTextInputProps = {
  value: string | null;
  onChange: (values: string) => void;
};

const RichTextInput = ({ value, onChange }: RichTextInputProps) => {
  const { companyId } = useCurrentUser();

  const handleCKEditorChange = (event: any, editor: CKEditorInstance) => {
    onChange(editor.getData());
    return Promise.resolve();
  };

  return (
    <QBox pt={3}>
      <ContentEditor
        data={value ?? ''}
        companyId={companyId}
        handleChange={handleCKEditorChange}
        toolbarItems={FORM_FIELD_TOOLBAR_ITEMS}
        toolbarParentId={v4()}
      />
    </QBox>
  );
};

export default RichTextInput;
