import { useQuery } from 'react-query';
import { useToastProvider } from '@qualio/ui-components';
import { AxiosError } from 'axios';
import * as R from 'ramda';
import templatesApi from '../api/templates.api';
import { EventTemplateDetailsResponse, EventTemplateFormValues } from '../types';
import { convertResponseToTemplateFormValues } from '../utils/formTemplateUtils';
import { DefaultErrorMessage } from '../displayStrings';

export const baseEventTemplateQueryKey = 'eventTemplateDetails';

const useBaseQuery = (companyId: number, eventTemplateId: number, enabled: boolean, select: any = R.identity) => {
  const { showToast } = useToastProvider();

  return useQuery<EventTemplateDetailsResponse, AxiosError, EventTemplateFormValues | EventTemplateDetailsResponse>(
    [baseEventTemplateQueryKey, companyId, eventTemplateId],
    () => {
      return templatesApi.getEventTemplateDetails(companyId, Number(eventTemplateId));
    },
    {
      enabled,
      onError: (error: AxiosError) => {
        if (error?.response?.status === 404) {
          window.location.href = `/_rpm?reqUrl=${window.location.pathname}`;
        } else {
          showToast({
            title: 'Failed to fetch event template details',
            description: DefaultErrorMessage,
            status: 'error',
          });
        }
      },
      onSuccess: (data: EventTemplateFormValues | EventTemplateDetailsResponse) => {
        if (data?.steps?.length) {
          data.steps.sort((step1, step2) => {
            return step1.order - step2.order;
          });
        }
      },
      select,
    },
  );
};

export const useEventTemplateFormValues = (companyId: number, eventTemplateId: number, enabled = true) => {
  return useBaseQuery(companyId, eventTemplateId, enabled, convertResponseToTemplateFormValues);
};

export const useEventTemplateDetails = (companyId: number, eventTemplateId: number, enabled = true) => {
  return useBaseQuery(companyId, eventTemplateId, enabled);
};
