import React from 'react';

import { QualityEventsGet } from '../../types/qualityEvent';
import { QCenter, QMenuButton, QMenuItemType, useCurrentUser } from '@qualio/ui-components';

import exportsApi from '../../api/exports.api';
import ExportUtilDefinition from '../../utils/exportUtils';

type ExportButtonProps = {
  queryParams: QualityEventsGet;
  exportUtil: ExportUtilDefinition;
};

const ExportButton: React.FC<ExportButtonProps> = ({ queryParams, exportUtil }) => {
  const { companyId } = useCurrentUser();

  const handleExportClick = async (exportType: QMenuItemType) => {
    try {
      const response = await exportsApi.createExport(companyId, { ...queryParams, format: exportType.id });
      exportUtil.displayExportStatus(companyId, response?.id as string);
    } catch (error) {
      exportUtil.displayErrorTryingToExport();
    }
  };

  return (
    <>
      <QCenter _hover={{ backgroundColor: '#DBE8FF', borderRadius: '4px' }}>
        <QMenuButton
          buttonLabel="ExportOptions"
          iconName="MoreVertical"
          itemSize="sm"
          items={[
            {
              id: 'xlsx',
              label: 'Export list as XLSX',
            },
            {
              id: 'pdf',
              label: 'Export list as PDF',
            },
          ]}
          onItemClick={handleExportClick}
          variant="icon"
        />
      </QCenter>
    </>
  );
};

export default ExportButton;
