import { useContext } from 'react';
import { TaskPermissionsContext, TaskPermissionsContextType } from '../context';

export const useTaskPermissions = (): TaskPermissionsContextType => {
  const context = useContext(TaskPermissionsContext);
  if (!context) {
    throw new Error('useTaskPermissions can only be used inside TaskPermissionsProvider');
  }
  return context;
};
