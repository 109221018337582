import React, { useState } from 'react';
import { QButton, QStack, useCurrentUser, QLink } from '@qualio/ui-components';
import { EventStep, AssociateDocumentResponse } from '../../../types';
import StepContainer from './StepContainer';
import StepHeader from './StepHeader';
import StepBody from './StepBody';
import EmptyStep from './EmptyStep';
import ContentStepMetadata from './ContentStepMetadata';
import AssigneesList from './AssigneesList';
import DigitalSignatureModal from '../../DigitalSignatureModal/DigitalSignatureModal';
import issueStepsApi from '../../../api/issueSteps.api';
import { DigitalSignature, DigitalSignatureInput } from '../../../types/digitalSignature';
import { useDocumentDetails, useAssociateDocument, useEventPermissions } from '../../../hooks';
import ReviewerList from './ReviewerList';
import StepStartTimeConstraintInfo from './StepStartTimeConstraintInfo';
import { useFlags } from '../../../external/LaunchDarklyAdapter';
import { isUrlNewFrontend } from '../../../utils/UrlUtils';
import { isStepNotCompleted } from '../../../utils/eventUtils';

type ContentStepProps = {
  step: EventStep;
  refetch: () => void;
  lastUpdated: number | null;
};

const ContentStep: React.FC<ContentStepProps> = ({ step, refetch, lastUpdated }) => {
  const stepId = step.id;
  const eventId = step.issue_id;
  const documentId = step.document_id;
  const stepDocument = step.document;
  const { companyId } = useCurrentUser();
  const { document, isDocumentLoading } = useDocumentDetails(documentId);
  const [isRevertStepModalOpen, setIsRevertStepModalOpen] = useState(false);
  const { canAddDocumentToStep, canRevertStep } = useEventPermissions();
  const audits = document?.audits || [];
  const stepStatus = step.status;
  const stepNotCompleted = isStepNotCompleted(stepStatus);
  const isDocumentOverviewRefreshEnabled = useFlags('documentOverviewRefresh');
  const isUseLegacyDomainForMtfeEnabled = useFlags('useLegacyDomainForMtfe');

  // This is a temporary fix
  // when opening from new app (frontend.*). we should redirect to ?intent=edit
  // once new fe goes GA ie. we are using app.* for new frontend, make sure to
  // remove the part of the condition so that it will always redirect to correct Url
  const isNewFrontend = isUrlNewFrontend(isUseLegacyDomainForMtfeEnabled);
  const onAssociateDocumentSuccess = (data: AssociateDocumentResponse | undefined) => {
    if (data) {
      window.location.href =
        isDocumentOverviewRefreshEnabled || isNewFrontend
          ? `/workspace/documents/${data.id}?intent=edit`
          : `/workspace/documents/${data.id}/edit`;
    }
  };

  const { associateDocumentMutate, isAssociateDocumentLoading } = useAssociateDocument(onAssociateDocumentSuccess);

  const handleRevertStep = async (payload: DigitalSignature) => {
    await issueStepsApi.revert(companyId, step.issue_id, step.id, payload);
    refetch();
  };

  const ViewDocumentButton = ({ documentId }: { documentId: number | null }) => (
    <QLink href={`/workspace/documents/${documentId}`} isCrossMFE>
      <QButton variant="ghost" leftIcon="Search" data-metrics="step-header-view-button">
        View
      </QButton>
    </QLink>
  );

  const RevertStepButton = () => (
    <QButton
      variant="ghost"
      leftIcon="CornerUpLeft"
      onClick={() => setIsRevertStepModalOpen(true)}
      isDisabled={!canRevertStep}
      data-metrics="step-header-revert-step-button"
    >
      Revert
    </QButton>
  );

  const revertStepModalInput: DigitalSignatureInput = {
    headingText: 'Revert step completion?',
    subText: `Sign off to revert the completion of the "${step.label}" step`,
    submitButtonText: 'Revert step',
    successToastHeader: 'Step reverted',
    successToastDescription: `"${step.label}" step completion was reverted`,
  };

  return (
    <StepContainer>
      <StepHeader
        title={step.label}
        status={stepStatus}
        headerButton={documentId && <ViewDocumentButton documentId={documentId} />}
        revertStepButton={<RevertStepButton />}
      />
      <StepBody isLoading={isDocumentLoading}>
        <ContentStepMetadata document={stepDocument} />
        {!!step.start_time_constraint && stepDocument?.status_id === 'draft' && (
          <StepStartTimeConstraintInfo startTimeConstraint={step.start_time_constraint} lastUpdated={lastUpdated} />
        )}
        {documentId && document ? (
          <>
            <QStack direction="row" spacing={20} mt={4} ml={4}>
              <AssigneesList label="Approvers" assignees={document.audit_groups.approver_group ?? []} audits={audits} />
              <ReviewerList assignees={document.audit_groups.review_group ?? []} />
            </QStack>
          </>
        ) : (
          <EmptyStep
            text={
              stepNotCompleted
                ? 'Add content and send it for review and approval when ready.'
                : 'There is no content associated with this step.'
            }
          >
            {stepNotCompleted && (
              <QButton
                variant="ghost"
                leftIcon="Edit3"
                onClick={() => associateDocumentMutate({ eventId, stepId })}
                isLoading={isAssociateDocumentLoading}
                isDisabled={!canAddDocumentToStep}
              >
                Add content
              </QButton>
            )}
          </EmptyStep>
        )}
      </StepBody>
      <DigitalSignatureModal
        isOpen={isRevertStepModalOpen}
        setIsOpen={setIsRevertStepModalOpen}
        onSave={handleRevertStep}
        inputTexts={revertStepModalInput}
        isCommentRequired={false}
      />
    </StepContainer>
  );
};

export default ContentStep;
