import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormEditorForm } from '../../../shared';
import { FfcChildProps, getFieldErrors } from '../shared';
import FieldTitle from './FieldTitle';
import FieldHelpText from './FieldHelpText';
import RequiredSwitch from './RequiredSwitch';
import FieldConfigurationWrapper from './FieldConfigurationWrapper';

const BasicFieldConfiguration = ({ fieldIndex }: FfcChildProps) => {
  const { control, formState } = useFormContext<FormEditorForm>();
  const { label: titleError, helptext: descriptionError } = getFieldErrors(formState.errors.fields, fieldIndex);

  return (
    <FieldConfigurationWrapper>
      <FieldTitle control={control} fieldIndex={fieldIndex} error={titleError} />
      <FieldHelpText control={control} fieldIndex={fieldIndex} error={descriptionError} />
      <RequiredSwitch control={control} fieldIndex={fieldIndex} />
    </FieldConfigurationWrapper>
  );
};

export default BasicFieldConfiguration;
