import { Relation } from '../types/relation';
import { medtechApi } from './index';

const relationsApi = {
  async get(company: number, issueId: number): Promise<Relation[]> {
    const res = await medtechApi.get(`/v2/${company}/issues/${issueId}/related`);
    return res.data.related_entities.map((item: Relation) => Relation.parse(item));
  },
};

export default relationsApi;
