import React from 'react';

import { QAlert, QBox, QButton, QFlex } from '@qualio/ui-components';

import { useEventPermissions } from '../../hooks';

type IncompleteRequiredFieldsAlertProps = {
  incompleteRequiredFields: string[];
  alertButtonAction: () => void;
};

const IncompleteRequiredFieldsAlert: React.FC<IncompleteRequiredFieldsAlertProps> = ({
  incompleteRequiredFields,
  alertButtonAction,
}) => {
  const { canModifyEvent } = useEventPermissions();
  // ignore typescript:S2871 because alphabetical sort is intended, c.f. https://github.com/SonarSource/SonarJS/issues/4046
  incompleteRequiredFields.sort(); // NOSONAR
  const alertDescription = (
    <QFlex justify="space-between">
      <QBox>
        The following fields must be completed before this event can be approved and closed:{' '}
        <strong>{incompleteRequiredFields.join(', ')}.</strong>
      </QBox>
      {canModifyEvent && (
        <QFlex align="center" alignSelf="center">
          <QButton aria-label="Update event" size="sm" onClick={alertButtonAction}>
            Edit event
          </QButton>
        </QFlex>
      )}
    </QFlex>
  );
  return incompleteRequiredFields.length > 0 ? (
    <QAlert title="Missing required fields" status="warning" description={alertDescription} marginTop={4}></QAlert>
  ) : null;
};

export default IncompleteRequiredFieldsAlert;
