export const smartlinksV2 = {
  results: [
    {
      domain: 'users',
      companyId: null,
      resource: 'user',
      resourceSubType: 'read_only',
      identifiers: {
        id: '9',
        code: 'testuser9@example.com',
        qri: 'https://qri.app.staging.beta.qualio.com/dXNlcnMvdXNlci8tLzk',
      },
      status: 'accepted',
      urls: { view: null, data: 'https://api.staging.beta.qualio.com/users/9' },
      name: 'Ella Gibson',
      date: { created: '2014-05-08T13:03:55.000Z', lastModified: '2024-04-17T08:32:20.000Z' },
      owner: null,
    },
    {
      domain: 'users',
      companyId: null,
      resource: 'user',
      resourceSubType: 'read_only',
      identifiers: {
        id: '7',
        code: 'testuser7@example.com',
        qri: 'https://qri.app.staging.beta.qualio.com/dXNlcnMvdXNlci8tLzc',
      },
      status: 'accepted',
      urls: { view: null, data: 'https://api.staging.beta.qualio.com/users/7' },
      name: 'Ian Connolly',
      date: { created: '2014-05-08T13:03:55.000Z', lastModified: '2022-03-15T17:35:38.000Z' },
      owner: null,
    },
    {
      domain: 'users',
      companyId: null,
      resource: 'user',
      resourceSubType: 'quality',
      identifiers: {
        id: '1',
        code: 'testuser1@example.com',
        qri: 'https://qri.app.staging.beta.qualio.com/dXNlcnMvdXNlci8tLzE',
      },
      status: 'accepted',
      urls: { view: null, data: 'https://api.staging.beta.qualio.com/users/1' },
      name: 'José Bermúdez',
      date: { created: '2014-05-08T13:03:55.000Z', lastModified: '2024-05-20T13:49:17.000Z' },
      owner: null,
    },
    {
      domain: 'users',
      companyId: null,
      resource: 'user',
      resourceSubType: 'normal',
      identifiers: {
        id: '5',
        code: 'testuser5@example.com',
        qri: 'https://qri.app.staging.beta.qualio.com/dXNlcnMvdXNlci8tLzU',
      },
      status: 'accepted',
      urls: { view: null, data: 'https://api.staging.beta.qualio.com/users/5' },
      name: 'Melissa Palmer',
      date: { created: '2014-05-08T13:03:55.000Z', lastModified: '2022-09-22T14:23:19.000Z' },
      owner: null,
    },
    {
      domain: 'users',
      companyId: null,
      resource: 'user',
      resourceSubType: 'quality',
      identifiers: {
        id: '3',
        code: 'testuser3@example.com',
        qri: 'https://qri.app.staging.beta.qualio.com/dXNlcnMvdXNlci8tLzM',
      },
      status: 'accepted',
      urls: { view: null, data: 'https://api.staging.beta.qualio.com/users/3' },
      name: 'Nicholas Jordan',
      date: { created: '2014-05-08T13:03:55.000Z', lastModified: '2024-02-08T14:22:01.000Z' },
      owner: null,
    },
    {
      domain: 'documents',
      companyId: 1,
      resource: 'document',
      resourceSubType: 'Basic',
      identifiers: {
        id: '577',
        code: 'DOC-9',
        qri: 'https://qri.app.staging.beta.qualio.com/ZG9jdW1lbnRzL2RvY3VtZW50LzEvNjYxY2I3MGEtZWIyZC00NDUxLTg3M2ItZjFkNjdlOTQwYjFhLzU3Nw',
        matrixId: '661cb70a-eb2d-4451-873b-f1d67e940b1a',
      },
      status: 'draft',
      urls: {
        view: 'https://app.staging.beta.qualio.com/library/e/577',
        data: 'https://api.staging.beta.qualio.com/documents/577',
      },
      name: 'Test Document',
      date: {
        created: '2021-03-04T16:24:03.596Z',
        lastModified: '2022-07-15T21:04:40.840Z',
      },
      owner: {
        id: '26',
        fullName: 'Daniel Tosh',
        email: 'dtosh@example.com',
      },
    },
    {
      domain: 'documents',
      companyId: 1,
      resource: 'document',
      resourceSubType: 'Basic',
      identifiers: {
        id: '502',
        code: 'DOC-6',
        qri: 'https://qri.app.staging.beta.qualio.com/ZG9jdW1lbnRzL2RvY3VtZW50LzEvZTUyNWEwYjUtM2E5ZC00ZmU1LTlmNDItYzljYzY5YjQ4Zjk4LzUwMg',
        matrixId: 'e525a0b5-3a9d-4fe5-9f42-c9cc69b48f98',
      },
      status: 'draft',
      urls: {
        view: 'https://app.staging.beta.qualio.com/library/e/502',
        data: 'https://api.staging.beta.qualio.com/documents/502',
      },
      name: 'Untitled document',
      date: {
        created: '2021-02-01T12:44:30.382Z',
        lastModified: '2022-07-15T21:00:20.112Z',
      },
      owner: {
        id: '26',
        fullName: 'Daniel Tosh',
        email: 'dtosh@example.com',
      },
    },
  ],
  page: 1,
  size: 10,
  total: 7,
  totalRelation: 'eq',
};
