import { Td } from '@chakra-ui/react';
import React from 'react';
import { Cell } from 'react-table';
import { formatDate, formatDateTime } from './dateFormat';
import { QDataRow, QDataColumn, QColumnType } from '../DataTable';

export interface QCellProps {
  cell: Cell<QDataRow>;
  column: QDataColumn;
}

export const QCell: React.FC<QCellProps> = ({ cell, column }) => {
  const render: string | React.ReactNode = React.useMemo(() => {
    switch (column.type) {
      case QColumnType.DATE:
        return formatDate(cell.value);
      case QColumnType.DATE_NO_YEAR_IF_CURRENT:
        return formatDate(cell.value, true);
      case QColumnType.DATE_TIME:
        return formatDateTime(cell.value);
      case QColumnType.DATE_TIME_NO_YEAR_IF_CURRENT:
        return formatDateTime(cell.value, true);
      default:
        return cell.render('Cell');
    }
  }, [cell, column]);

  return <Td>{render}</Td>;
};
