import * as z from 'zod';
import { ArrayElement } from '../../utils/typeUtils';

/**
 * This file exists to store all type information that is common to both concepts
 * of "FormFields".  Both the formField, which is a completable field on an instance
 * of a form associated to an event step.  And also a formTemplateField which is a
 * field defined on a form template.
 */

// Zod enum of all potential "types" of form fields that can be created/used
export const FieldTypeSchema = z.enum([
  // Standard input fields
  'text',
  'date',
  'boolean',
  'numeric',
  // Non Standard input fields
  'rich_text',
  'attachment',
  // Option Selecting fields
  'dropdown',
  'checkbox',
  'radio',
  // Visual Fields
  'section',
  // Q Object Fields
  'document',
  'event',
  'supplier',
  'user',
  'change_request',
  'design_controls',
  // Registry is similar to QObject fields, but is different in some distinct ways
  // specifically how Display names get generated which comes from a resource sub type
  // and not from a static string
  'registry',
]);

export const QObjectFieldTypes = [
  FieldTypeSchema.Enum.document,
  FieldTypeSchema.Enum.supplier,
  FieldTypeSchema.Enum.event,
  FieldTypeSchema.Enum.user,
  FieldTypeSchema.Enum.change_request,
  FieldTypeSchema.Enum.registry,
  FieldTypeSchema.Enum.design_controls,
] as const satisfies readonly FieldType[];

export const RegularFieldTypes = [
  FieldTypeSchema.Enum.text,
  FieldTypeSchema.Enum.rich_text,
  FieldTypeSchema.Enum.date,
  FieldTypeSchema.Enum.numeric,
  FieldTypeSchema.Enum.boolean,
  FieldTypeSchema.Enum.attachment,
  FieldTypeSchema.Enum.dropdown,
  FieldTypeSchema.Enum.section,
  FieldTypeSchema.Enum.checkbox,
  FieldTypeSchema.Enum.radio,
] as const satisfies readonly FieldType[];

// Union type of all potential "types" of form fields that can be created/used
export type FieldType = z.infer<typeof FieldTypeSchema>;
export type QObjectFieldType = ArrayElement<typeof QObjectFieldTypes>;
export type RegularFieldType = ArrayElement<typeof RegularFieldTypes>;

export const MandatorySchema = z.boolean();
export const OrderSchema = z.number();
export const LabelSchema = z
  .string()
  .max(512, 'Field title can be no longer that 512 characters')
  .min(1, 'Field title is required');
export const HelptextSchema = z.string().nullable();
export const DefaultContentSchema = z.string().nullable();
export const MultiSchema = z.boolean();
export const CreatedAtSchema = z.number();
export const UpdatedAtSchema = z.number();
