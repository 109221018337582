import React from 'react';
import { FieldTypeSchema, QObjectFieldType, RegularFieldType } from '../../../../types/formFields/common';
import { RegistryCategory } from '../../../../types/registry';
import { DisplayNames } from '../../shared';
import { QIcon } from '@qualio/ui-components';
import { IconNameType } from '@qualio/ui-components/lib/types/Icons';

const iconNameMapping = {
  document: 'ResourceDocuments',
  supplier: 'ResourceSuppliers',
  event: 'ResourceEvents',
  user: 'ResourceUsers',
  change_request: 'ResourceCR',
  registry: 'ResourceRegistry',
  design_controls: 'ResourceDesignControls',
} as const satisfies Record<QObjectFieldType, IconNameType>;

export const mapStaticObjectFieldToButton = (fieldType: QObjectFieldType) => {
  return {
    id: fieldType,
    label: DisplayNames[fieldType],
    'data-cy': `menu-item-${fieldType}`,
    icon: <QIcon iconName={iconNameMapping[fieldType]} />,
  } as const;
};

export const mapRegularFieldToButton = (fieldType: RegularFieldType) => {
  return {
    id: fieldType,
    label: DisplayNames[fieldType],
    'data-cy': `menu-item-${fieldType}`,
  } as const;
};

export const mapRegistryFieldToButton = (registryCategory: RegistryCategory) => {
  return {
    id: `registryCategory:${registryCategory.registry_category_matrix_id}`,
    label: registryCategory.name,
    'data-cy': `menu-item-registry`,
    icon: <QIcon iconName={iconNameMapping[FieldTypeSchema.Enum.registry]} />,
  } as const;
};
