import React, { useCallback } from 'react';
import {
  QModal,
  QStack,
  QText,
  useToastProvider,
  QModalHeader,
  QModalBody,
  QModalActions,
  QButton,
  QCloseButton,
} from '@qualio/ui-components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { QualityEventCreate, User } from '../../types';
import ChangeOwnerForm from './components/ChangeOwnerForm';
import { QualityEvent } from '../../types';
import { filterByPermission } from '../../utils/userUtils';
import { DefaultErrorMessage } from '../../displayStrings';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  event: QualityEvent;
  selectsData: {
    users: User[];
  };
  onSave: (payload: QualityEventCreate) => Promise<void>;
};

const ChangeOwnerModal: React.FC<Props> = ({ isOpen, setIsOpen, selectsData, onSave, event }) => {
  const { showToast } = useToastProvider();

  const formMethods = useForm<QualityEventCreate>({
    mode: 'onSubmit',
    resolver: zodResolver(QualityEventCreate),
  });

  const {
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = formMethods;

  const formHasErrors = !!Object.keys(errors).length;

  const handleClose = () => {
    setIsOpen(false);
    reset();
  };

  const onSubmit = useCallback(
    async (data: QualityEventCreate) => {
      try {
        await onSave(data);
        setIsOpen(false);
        showToast({
          title: 'Owner changed',
          description: `Owner for ${event.code} successfully changed`,
          status: 'success',
        });
        reset();
      } catch (err: any) {
        showToast({
          title: 'Owner not changed',
          description: DefaultErrorMessage,
          status: 'error',
        });
      }
    },
    [onSave, showToast, setIsOpen, reset, event],
  );

  return (
    <QModal data-cy={'change-owner-modal'} isOpen={isOpen} onClose={handleClose} size="xl">
      <QModalHeader>
        <QText>Select a new owner</QText>
        <QCloseButton onClick={handleClose}></QCloseButton>
      </QModalHeader>
      <QModalBody>
        <QStack direction="column" color="gray.700" spacing={4}>
          <ChangeOwnerForm
            formMethods={formMethods}
            onSubmit={onSubmit}
            data={{ users: filterByPermission(selectsData.users, 'can_work_on_issue') }}
            event={event}
          />
        </QStack>
      </QModalBody>
      <QModalActions>
        <QButton onClick={handleClose} variant="outline">
          Cancel
        </QButton>
        <QButton onClick={() => handleSubmit(onSubmit)()} isDisabled={formHasErrors || isSubmitting}>
          Change owner
        </QButton>
      </QModalActions>
    </QModal>
  );
};

export default ChangeOwnerModal;
