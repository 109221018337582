import { BaseSchema, ServerOnlyOmitKeys } from './common';
import { FieldTypeSchema, MandatorySchema, MultiSchema } from '../common';

/**
 * All types in this file pertain to the template form fields (not the fields on
 * an instance of a form) that are "standard" input fields, meaning they all
 * adhere to a consistent and basic schema.
 */

export const StandardInputSchema = BaseSchema.extend({
  type: FieldTypeSchema.extract(['text', 'numeric', 'boolean', 'date']),
  multi: MultiSchema,
  mandatory: MandatorySchema,
});
export const NewStandardInputSchema = StandardInputSchema.omit(ServerOnlyOmitKeys);
