import React from 'react';
import ReactDOM from 'react-dom';
import { CurrentUserProvider, QualioQFrontEndProvider, ToastProvider } from '@qualio/ui-components';
import { ThemeProvider } from 'styled-components';
import ErrorBoundary from './views/ErrorBoundary';
import { theme } from './theme';
import AppContainer from './container';
import App from './App';
import { initDatadog } from './external/datadog';
import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';
import { CreateLaunchDarklyProvider } from './external/LaunchDarklyAdapter';
import { QeFeQueryClientProvider } from './queryClient';

if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
  initDatadog();
}

let rootNode: Element | null;

(window as any).renderQualityEvents = async (containerId: string, currentUser: CurrentUser) => {
  rootNode = document.getElementById(containerId);

  const LDProvider = await CreateLaunchDarklyProvider(currentUser);

  ReactDOM.render(
    <ErrorBoundary>
      <LDProvider>
        <QeFeQueryClientProvider>
          <AppContainer containerId={containerId}>
            <QualioQFrontEndProvider user={currentUser}>
              <ThemeProvider theme={theme}>
                <ToastProvider>
                  <CurrentUserProvider user={currentUser}>
                    <App />
                  </CurrentUserProvider>
                </ToastProvider>
              </ThemeProvider>
            </QualioQFrontEndProvider>
          </AppContainer>
        </QeFeQueryClientProvider>
      </LDProvider>
    </ErrorBoundary>,
    rootNode,
  );
};

(window as any).unmountQualityEvents = (containerId: string) => {
  try {
    console.log('Attempting to unmount container', containerId);
    if (rootNode) {
      ReactDOM.unmountComponentAtNode(rootNode);
    }
  } catch (e) {
    console.log('did not unmount component: ', e);
    // even with the if statements its possible we have a race condition that is the if states the element exists BUT
    // then before we remove it the Application container has removed it
  }
};

if (process.env.REACT_APP_MOCK_SERVER === 'true') {
  console.log('Using mock servers');
  require('./mocks');
}
