import React from 'react';

import { PropertySection } from './PropertySection';
import { PropertySectionText } from './PropertySectionText';
import { EventStatus } from '../../../types/eventStatus';
import { EventActionLog as EventActionLogType } from '../../../types/eventActionLog';
import { useLocalisedFormatDateTime } from '../../../utils/datetimeUtils';

type EventActionLogProps = {
  eventStatus: EventStatus;
  eventActionLog: EventActionLogType;
};

type PartialEventStatus = Extract<EventStatus, 'closed' | 'rejected' | 'cancelled'>;

const statusTimestampTitleMap: Record<PartialEventStatus, string> = {
  cancelled: 'Canceled on',
  rejected: 'Rejected on',
  closed: 'Closed early on',
};

const statusReasonTitleMap: Record<PartialEventStatus, string> = {
  cancelled: 'Cancelation reason',
  rejected: 'Rejection reason',
  closed: 'Close reason',
};

const EventActionLog = (props: EventActionLogProps) => {
  const formatDateTime = useLocalisedFormatDateTime();
  return (
    <>
      <PropertySection title={statusTimestampTitleMap[props.eventStatus as PartialEventStatus]}>
        <PropertySectionText>{`${formatDateTime(props.eventActionLog.action_timestamp)} by ${
          props.eventActionLog.user_name
        }`}</PropertySectionText>
      </PropertySection>
      <PropertySection title={statusReasonTitleMap[props.eventStatus as PartialEventStatus]}>
        <PropertySectionText>{props.eventActionLog.comment}</PropertySectionText>
      </PropertySection>
    </>
  );
};

export default EventActionLog;
