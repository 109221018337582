import * as z from 'zod';

export const RegistryCategorySchema = z.object({
  id: z.number(),
  registry_category_matrix_id: z.string(),
  name: z.string(),
  qualio_managed: z.boolean(),
  company_id: z.number().nullable(),
});
export type RegistryCategory = z.infer<typeof RegistryCategorySchema>;

export const RegistryCategoriesResponseSchema = z.object({
  total: z.number(),
  items: z.array(RegistryCategorySchema),
});

export const RegistryItemsSchema = z.object({
  id: z.number(),
  registry_item_matrix_id: z.string(),
  registry_category_id: z.string(),
  company_id: z.number(),
  status: z.string(),
  name: z.string(),
  display_id: z.string(),
  description: z.string().nullable(),
});
export type RegistryItems = z.infer<typeof RegistryItemsSchema>;

export const RegistryItemsResponseSchema = z.object({
  total: z.number(),
  items: z.array(RegistryItemsSchema),
});
export type RegistryItemsResponseType = z.infer<typeof RegistryItemsResponseSchema>;

export enum RegistryCategoryMatrixID {
  PRODUCT_MATRIX_ID = '28dea922-4900-4bec-8541-6818cb985b5e',
  ROOT_CAUSE_MATRIX_ID = '48042031-2fd2-4d71-8ec6-9bf029c4ebb5',
}
