import React from 'react';
import { QStack } from '@qualio/ui-components';
import ContentStep from './components/ContentStep';
import TasksStep from './components/TasksStepV2';
import { EventStep, User } from '../../types';
import FormStep from './components/FormStep';

type EventStepsProps = {
  steps: EventStep[];
  users: User[];
  refetch: () => void;
  userTZ: string;
};

const EventSteps: React.FC<EventStepsProps> = ({ steps, users, refetch, userTZ }) => {
  const lastUpdated = Math.max(...steps.map((step) => step.status_updated_at as number));

  return (
    <QStack w="100%" mr={6} mt={6} spacing={6}>
      {steps.map((step: EventStep) => {
        if (step.type === 'document') {
          return <ContentStep key={`step-${step.id}`} step={step} refetch={refetch} lastUpdated={lastUpdated} />;
        }
        if (step.type === 'actions') {
          return <TasksStep key={`step-${step.id}`} step={step} users={users} refetch={refetch} />;
        }
        if (step.type === 'form') {
          return <FormStep key={`step-${step.id}`} step={step} users={users} lastUpdated={lastUpdated} />;
        }
        return null;
      })}
    </QStack>
  );
};

export default EventSteps;
