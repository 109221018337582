import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { QualityEventsGet } from '../../types/qualityEvent';
import {
  QBox,
  QDatepicker,
  QMultiSelect,
  QSelect,
  QStack,
  QText,
  QSpacer,
  QBadge,
  QSelectPlaceholder,
} from '@qualio/ui-components';
import { Product } from '../../types/product';
import { EventType } from '../../types/eventType';
import { RootCause } from '../../types/rootCause';
import { User } from '../../types/user';
import { EventTag } from '../../types/eventTag';
import { useSearchParams } from 'react-router-dom';
import ExportButton from './ExportButton';
import ExportUtil from '../../utils/exportUtils';
import { convertDateToString, convertUtcStringToDate } from '../../utils/datetimeUtils';
import { getAllUrlParameter, getUrlParameter } from '../../utils/searchParamUtils';
import SearchControl from './Search/SearchControl';
import { useFlags } from '../../external/LaunchDarklyAdapter';
import * as DisplayStrings from '../../displayStrings';

const FilterDropdownItem: React.FC<{ label: string }> = ({ label }) => (
  <QBox maxWidth="170px" title={label} textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
    {label}
  </QBox>
);

const EventTypeDropdownItem: React.FC<{ eventPrefix: string; eventName: string }> = ({ eventPrefix, eventName }) => (
  <QStack isInline maxWidth="170px">
    <QBox minWidth={14}>
      <QBadge
        title={eventPrefix}
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
        maxWidth="55px"
        textTransform="none"
      >
        {eventPrefix}
      </QBadge>
    </QBox>
    <QText title={eventName} textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
      {eventName}
    </QText>
  </QStack>
);

type QualityEventListFiltersProps = {
  setFilterParamsFn: CallableFunction;
  pageSize: number;
  pageNumber: number;
  setEventsPageNumber: (pageNumber: number) => void;
  sortBy: string | undefined;
  exportUtil: ExportUtil;
  products: Product[] | undefined;
  eventTypes: EventType[] | undefined;
  rootCauses: RootCause[] | undefined;
  users: User[] | undefined;
  tags: EventTag[] | undefined;
  onTextSearch: (term: string | undefined, prevTerm: string | undefined) => void;
};

const QualityEventListFilters = ({
  setFilterParamsFn,
  pageSize,
  pageNumber,
  sortBy,
  exportUtil,
  ...props
}: QualityEventListFiltersProps) => {
  const qeDefaultOwnersEnabled = useFlags('qeDefaultOwners');
  const [searchParams, setSearchParams] = useSearchParams();
  const sortedSearchParams = useRef<string | null>(null);

  let initialStatus = getAllUrlParameter(searchParams, 'status');

  // Set initial status to open if no status set directly in url
  if (!initialStatus.length) {
    initialStatus = qeDefaultOwnersEnabled ? ['open', 'imported'] : ['open'];
  }

  const [selectedEventType, setSelectedEventType] = useState(getUrlParameter(searchParams, 'event_type'));
  const [selectedStatus, setSelectedStatus] = useState(initialStatus);
  const [selectedOwnerId, setSelectedOwnerId] = useState(getUrlParameter(searchParams, 'owner_id'));
  const [selectedRootCause, setSelectedRootCause] = useState(getUrlParameter(searchParams, 'rootcause_id'));
  const [selectedProduct, setSelectedProduct] = useState(getUrlParameter(searchParams, 'product_id'));
  const [selectedFilterTags, setSelectedFilterTags] = useState(getAllUrlParameter(searchParams, 'filter_tags'));
  const [selectedStartDate, setSelectedStartDate] = useState(
    convertUtcStringToDate(getUrlParameter(searchParams, 'created_at_start_date')),
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    convertUtcStringToDate(getUrlParameter(searchParams, 'created_at_end_date')),
  );
  const [showOverdue, setShowOverdue] = useState(getUrlParameter(searchParams, 'is_overdue'));
  const [term, setTerm] = useState(getUrlParameter(searchParams, 'term'));
  const [prevTerm, setPrevTerm] = useState(term);

  const activeTab = searchParams.get('tab') || 'events';

  const products = useMemo(() => props.products || [], [props]);
  const eventTypes = useMemo(() => props.eventTypes || [], [props]);
  const rootCauses = useMemo(() => props.rootCauses || [], [props]);
  const users = useMemo(() => props.users || [], [props]);
  const tags = useMemo(() => props.tags || [], [props]);
  const eventStatusOptions = useMemo(() => {
    const baseStatuses = [
      { value: 'open', label: DisplayStrings.EventStatues.open },
      { value: 'closed', label: DisplayStrings.EventStatues.closed },
      { value: 'imported', label: DisplayStrings.EventStatues.imported },
    ];
    const newStatuses = [
      { value: 'rejected', label: DisplayStrings.EventStatues.rejected },
      { value: 'cancelled', label: DisplayStrings.EventStatues.cancelled },
    ];

    return qeDefaultOwnersEnabled ? [...baseStatuses, ...newStatuses] : baseStatuses;
  }, [qeDefaultOwnersEnabled]);

  const eventTypesOptions = useMemo(
    () =>
      eventTypes
        .filter(
          (eventType) =>
            eventType.active === true ||
            (eventType.active === false && (eventType.issuesCount ? eventType.issuesCount > 0 : false)),
        )
        .map((eventType) => ({
          value: String(eventType.id),
          label: <EventTypeDropdownItem eventPrefix={eventType.prefix} eventName={eventType.name} />,
        })),
    [eventTypes],
  );

  const productsOptions = useMemo(
    () =>
      products.map((product) => ({
        value: String(product.id),
        label: <FilterDropdownItem label={product.name} />,
      })),
    [products],
  );

  const rootCausesOptions = useMemo(
    () =>
      rootCauses.map((rootCause) => ({
        value: String(rootCause.id),
        label: <FilterDropdownItem label={rootCause.name} />,
      })),
    [rootCauses],
  );

  const usersOptions = useMemo(
    () =>
      [...users]
        .sort((a, b) => a.full_name.localeCompare(b.full_name))
        .map((user) => ({
          value: String(user.id),
          label: <FilterDropdownItem label={user.full_name} />,
        })),
    [users],
  );

  const tagsOptions = useMemo(
    () => tags.map((tag) => ({ value: String(tag.id), label: <FilterDropdownItem label={tag.name} /> })),
    [tags],
  );

  const createQualityEventsRequest = useCallback((): QualityEventsGet => {
    const emptyValues: Array<any> = ['', null, undefined];

    const params: QualityEventsGet = {
      status: selectedStatus,
      owner_id: selectedOwnerId,
      event_type: selectedEventType,
      rootcause_id: selectedRootCause,
      product_id: selectedProduct,
      filter_tags: selectedFilterTags,
      is_overdue: showOverdue,
      created_at_start_date: convertDateToString(selectedStartDate),
      created_at_end_date: convertDateToString(selectedEndDate),
      page_number: pageNumber + 1,
      page_size: pageSize,
      order_by: !sortBy && !term ? '-created_at' : sortBy,
      term: term,
    };
    return Object.fromEntries(
      Object.entries(params).filter(([_, v]) => !(emptyValues.includes(v) || (Array.isArray(v) && v.length === 0))),
    );
  }, [
    selectedStatus,
    selectedOwnerId,
    selectedFilterTags,
    selectedProduct,
    selectedEventType,
    selectedRootCause,
    selectedStartDate,
    selectedEndDate,
    showOverdue,
    pageSize,
    pageNumber,
    sortBy,
    term,
  ]);

  useEffect(() => {
    const requestParams = createQualityEventsRequest();
    const newSearchObject = { ...requestParams, tab: activeTab };
    const newSearchParams = new URLSearchParams(newSearchObject as any);
    newSearchParams.sort();
    const newSearchParamsString = newSearchParams.toString();
    if (newSearchParamsString !== sortedSearchParams.current) {
      sortedSearchParams.current = newSearchParamsString;
      setSearchParams(newSearchObject as any);
      setFilterParamsFn(requestParams);
    }
  }, [setFilterParamsFn, createQualityEventsRequest, setSearchParams, sortedSearchParams, activeTab]);

  const eventTypeFilter = useCallback((option: any, searchText: string) => {
    const eventPrefix = option.data.label.props.eventPrefix;
    const eventName = option.data.label.props.eventName;
    if (
      eventName.toLowerCase().includes(searchText.toLowerCase()) ||
      eventPrefix.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    }
    return false;
  }, []);

  const dropdownFilter = useCallback((option: any, searchText: string) => {
    const label = option.data.label.props.label;
    if (label.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    }
    return false;
  }, []);

  const onSearch = useCallback(
    (searchTerm: string) => {
      props.onTextSearch(searchTerm, prevTerm);
      if (term !== prevTerm) {
        setPrevTerm(term);
      }
      setTerm(searchTerm);
      props.setEventsPageNumber(0);
    },
    [term, props, prevTerm],
  );

  return (
    <QStack>
      <SearchControl query={term} setQuery={onSearch}></SearchControl>
      <QStack direction="row" spacing={2} align="stretch">
        <QBox w="100%" data-metrics="events-filter-event-type">
          <QSelect
            options={eventTypesOptions}
            onChange={(event) => {
              props.setEventsPageNumber(0);
              if (!event) {
                setSelectedEventType('');
                return;
              }
              setSelectedEventType(event.value);
            }}
            value={selectedEventType}
            aria-label="Event type"
            isClearable={true}
            filterOption={eventTypeFilter}
          >
            <QSelectPlaceholder>
              <QText>Event type</QText>
            </QSelectPlaceholder>
          </QSelect>
        </QBox>
        <QBox w="100%" data-metrics="events-filter-statuses" data-cy="status-filter-input">
          <QMultiSelect
            options={eventStatusOptions}
            onChange={(selectValues) => {
              props.setEventsPageNumber(0);
              setSelectedStatus(() => selectValues.map((v) => v.value));
            }}
            value={selectedStatus}
            aria-label="Status"
          >
            <QSelectPlaceholder>
              <QText>Status</QText>
            </QSelectPlaceholder>
          </QMultiSelect>
        </QBox>
        <QBox w="100%" data-metrics="events-filter-product">
          <QSelect
            options={productsOptions}
            onChange={(changeObject) => {
              props.setEventsPageNumber(0);
              if (!changeObject) {
                setSelectedProduct('');
                return;
              }
              setSelectedProduct(changeObject.value);
            }}
            value={selectedProduct}
            aria-label="Products"
            isClearable={true}
            filterOption={dropdownFilter}
          >
            <QSelectPlaceholder>
              <QText>Product</QText>
            </QSelectPlaceholder>
          </QSelect>
        </QBox>
        <QBox w="100%" data-metrics="events-filter-root-cause">
          <QSelect
            options={rootCausesOptions}
            onChange={(changeObject) => {
              props.setEventsPageNumber(0);
              if (!changeObject) {
                setSelectedRootCause('');
                return;
              }
              setSelectedRootCause(changeObject.value);
            }}
            value={selectedRootCause}
            aria-label="Root causes"
            isClearable={true}
            filterOption={dropdownFilter}
          >
            <QSelectPlaceholder>
              <QText>Root cause</QText>
            </QSelectPlaceholder>
          </QSelect>
        </QBox>
        <QBox w="100%" data-metrics="events-filter-tags">
          <QMultiSelect
            options={tagsOptions}
            onChange={(values) => {
              props.setEventsPageNumber(0);
              setSelectedFilterTags(values.map((v) => v.value));
            }}
            value={selectedFilterTags}
            aria-label="Tags"
            filterOption={dropdownFilter}
          >
            <QSelectPlaceholder>
              <QText>Tags</QText>
            </QSelectPlaceholder>
          </QMultiSelect>
        </QBox>
        <QBox w="100%" data-metrics="events-filter-owner">
          <QSelect
            options={usersOptions}
            onChange={(changeObject) => {
              props.setEventsPageNumber(0);
              if (!changeObject) {
                setSelectedOwnerId('');
                return;
              }
              setSelectedOwnerId(changeObject.value);
            }}
            value={selectedOwnerId}
            aria-label="Owners"
            isClearable={true}
            filterOption={dropdownFilter}
          >
            <QSelectPlaceholder>
              <QText>Owner</QText>
            </QSelectPlaceholder>
          </QSelect>
        </QBox>
        <ExportButton queryParams={createQualityEventsRequest()} exportUtil={exportUtil} />
      </QStack>
      <QStack isInline>
        <QText alignSelf="center">Created between</QText>
        <QBox w="10rem" data-metrics="events-filter-start-date">
          <QDatepicker
            placeholder="Start date"
            value={selectedStartDate}
            onChange={(value) => {
              props.setEventsPageNumber(0);
              setSelectedStartDate(value);
            }}
            isClearable={true}
          />
        </QBox>
        <QText alignSelf="center">and</QText>
        <QBox w="10rem" data-metrics="events-filter-end-date">
          <QDatepicker
            placeholder="End date"
            value={selectedEndDate}
            onChange={(value) => {
              props.setEventsPageNumber(0);
              setSelectedEndDate(value);
            }}
            isClearable={true}
          />
        </QBox>
        <QSpacer />
        <QBox w="10rem" data-metrics="events-filter-overdue">
          <QSelect
            options={[
              { value: 'true', label: 'Overdue' },
              { value: 'false', label: 'On time' },
            ]}
            onChange={(changeObject) => {
              props.setEventsPageNumber(0);
              if (!changeObject) {
                setShowOverdue('');
                return;
              }
              setShowOverdue(changeObject.value);
            }}
            value={showOverdue}
            aria-label="Due"
            isClearable={true}
          >
            <QSelectPlaceholder>
              <QText>Due</QText>
            </QSelectPlaceholder>
          </QSelect>
        </QBox>
      </QStack>
    </QStack>
  );
};

export default QualityEventListFilters;
