import React, { useState } from 'react';
import { QBox, QCenter, QSpinner, useToastProvider } from '@qualio/ui-components';
import aiEventsApi from '../../api/ai.api';
import EditorContentViewer from '../shared/EditorContentViewer/EditorContentViewer';
import { sanitize } from 'dompurify';

type AiSummaryProps = {
  companyId: number;
  eventId: number;
};

const AiSummary: React.FC<AiSummaryProps> = ({ companyId, eventId }) => {
  const { showToast } = useToastProvider();
  const [summary, setSummary] = useState<string>('');
  aiEventsApi
    .getEventSummary(companyId, eventId)
    .then((data) => {
      setSummary(data);
    })
    .catch((error) => {
      console.error(error);
      showToast({
        title: 'AI summary failed',
        description: 'Could not retrieve summary from AI service',
        status: 'error',
      });
    });
  return (
    <QBox mt="3">
      {!summary ? (
        <QCenter pt={10}>
          <QSpinner />
        </QCenter>
      ) : (
        <EditorContentViewer content={sanitize(summary)}></EditorContentViewer>
      )}
    </QBox>
  );
};

export default AiSummary;
