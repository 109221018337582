export const qriBulkDetails = {
  'https://qri.app.staging.beta.qualio.com/cXVhbGl0eS1ldmVudHMvcXVhbGl0eS1ldmVudC8xLzk1NTYw': {
    domain: 'quality-events',
    companyId: 1,
    resource: 'quality-event',
    identifiers: {
      id: '152056',
      code: 'AAB-2',
      qri: 'https://qri.app.staging.beta.qualio.com/cXVhbGl0eS1ldmVudHMvcXVhbGl0eS1ldmVudC8xLzE1MjA1Ng',
    },
    status: 'open',
    short: false,
    urls: {
      data: 'https://app.staging.beta.qualio.com/quality-events/events/152056',
    },
    name: 'Event 2',
    displayLabel: 'AAB-2 Event 2',
    date: {
      created: '2024-03-08T13:46:14.000Z',
      lastModified: '2024-03-08T13:46:14.000Z',
    },
    owner: {
      id: '1',
      fullName: 'José Bermúdez',
      email: 'testuser1@example.com',
    },
    hasAccess: true,
  },
  'https://qri.app.staging.beta.qualio.com/cXVhbGl0eS1ldmVudHMvcXVhbGl0eS1ldmVudC8xLzk4NzEx': {
    domain: 'quality-events',
    companyId: 1,
    resource: 'quality-event',
    identifiers: {
      id: '151256',
      code: 'AAB-1',
      qri: 'https://qri.app.staging.beta.qualio.com/cXVhbGl0eS1ldmVudHMvcXVhbGl0eS1ldmVudC8xLzE1MTI1Ng',
    },
    status: 'open',
    short: false,
    urls: {
      data: 'https://app.staging.beta.qualio.com/quality-events/events/151256',
    },
    name: 'Event 1',
    displayLabel: 'AAB-1 Event 1',
    date: {
      created: '2024-03-06T15:14:55.000Z',
      lastModified: '2024-03-06T15:14:55.000Z',
    },
    owner: {
      id: '1',
      fullName: 'José Bermúdez',
      email: 'testuser1@example.com',
    },
    hasAccess: true,
  },
  'https://qri.app.staging.beta.qualio.com/cXVhbGl0eS1ldmVudHMvcXVhbGl0eS1ldmVudC8xLzkzODY3': {
    domain: 'quality-events',
    companyId: 1,
    resource: 'quality-event',
    identifiers: {
      id: '161605',
      code: 'AAB-6',
      qri: 'https://qri.app.staging.beta.qualio.com/cXVhbGl0eS1ldmVudHMvcXVhbGl0eS1ldmVudC8xLzE2MTYwNQ',
    },
    status: 'open',
    short: false,
    urls: {
      data: 'https://app.staging.beta.qualio.com/quality-events/events/161605',
    },
    name: 'Event 6',
    displayLabel: 'AAB-6 Event 6',
    date: {
      created: '2024-04-05T12:07:30.000Z',
      lastModified: '2024-04-05T12:07:30.000Z',
    },
    owner: {
      id: '1',
      fullName: 'José Bermúdez',
      email: 'testuser1@example.com',
    },
    hasAccess: true,
  },
  'https://qri.app.staging.beta.qualio.com/dXNlcnMvdXNlci8tLzI': {
    domain: 'users',
    resource: 'user',
    companyId: 1,
    type: 'quality',
    name: 'Alice Mills',
    displayLabel: 'Alice Mills',
    status: 'accepted',
    date: {
      created: '2023-07-14T11:19:42.000Z',
      lastModified: '2024-02-19T15:32:30.000Z',
    },
    owner: null,
    identifiers: {
      id: '23456',
      qri: 'https://qri.app.staging.beta.qualio.com/dXNlcnMvdXNlci8tLzI',
    },
    urls: {
      data: 'https://api.app.qualio.com/users/23456',
      view: null,
    },
  },
  'https://qri.app.staging.beta.qualio.com/dXNlcnMvdXNlci8tLzc3Njk': {
    domain: 'users',
    resource: 'user',
    companyId: 1,
    type: 'quality',
    name: 'John Doe',
    displayLabel: 'John Doe',
    status: 'accepted',
    date: {
      created: '2023-07-14T11:19:42.000Z',
      lastModified: '2024-02-19T15:32:30.000Z',
    },
    owner: null,
    identifiers: {
      id: '23456',
      qri: 'https://qri.app.staging.beta.qualio.com/dXNlcnMvdXNlci8tLzI',
    },
    urls: {
      data: 'https://api.app.qualio.com/users/23456',
      view: null,
    },
  },
};
