export class StringEscapeUtils {
  static specialEscapeForCkEditor(myString: string): string {
    // This is a reverse engineered from the 'he' library
    // It will escape only he characters &, <, and >.
    // This is because CKEdior does not like encoded quote and double quotes

    const regexEscape = /[&<>`]/g;
    const escapeMap: { [index: string]: string } = {
      '&': '&amp;',
      '<': '&lt;',
      // See https://mathiasbynens.be/notes/ambiguous-ampersands: in HTML, the
      // following is not strictly necessary unless it’s part of a tag or an
      // unquoted attribute value. We’re only escaping it to support those
      // situations, and for XML support.
      '>': '&gt;',
    };
    return myString.replace(regexEscape, function ($0): string {
      // Note: there is no need to check `has(escapeMap, $0)` here.
      return escapeMap[$0]!;
    });
  }
}
