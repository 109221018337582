import { Matcher } from '@ckeditor/ckeditor5-engine';
import { EventInfo } from '@ckeditor/ckeditor5-utils';

export const upcastHighlightToMarker =
  (config: { view: any; model: any }) =>
  (dispatcher: { on: any }): void =>
    dispatcher.on('element:span', (_evt: EventInfo, data: any, conversionApi: any) => {
      const { writer } = conversionApi;

      const matcher = new Matcher(config.view);
      const matcherResult = matcher.match(data.viewItem);

      // If there is no match, this callback should not do anything.
      if (!matcherResult) {
        return;
      }

      const match = matcherResult.match;

      // Force consuming element's name (taken from upcast helpers elementToElement converter).
      match.name = true;

      const { modelRange: convertedChildrenRange } = conversionApi.convertChildren(data.viewItem, data.modelCursor);
      conversionApi.consumable.consume(data.viewItem, match);

      const markerName = config.model(data.viewItem);
      const fakeMarkerStart = writer.createElement('$marker', {
        'data-name': markerName,
      });
      const fakeMarkerEnd = writer.createElement('$marker', {
        'data-name': markerName,
      });

      // Insert in reverse order to use converter content positions directly (without recalculating).
      writer.insert(fakeMarkerEnd, convertedChildrenRange.end);
      writer.insert(fakeMarkerStart, convertedChildrenRange.start);

      data.modelRange = writer.createRange(
        writer.createPositionBefore(fakeMarkerStart),
        writer.createPositionAfter(fakeMarkerEnd),
      );
      data.modelCursor = data.modelRange.end;
    });
