import React, { useEffect, useState } from 'react';
import { QActivity, QBox, QButton, QDivider, QFlex, QIcon, QText } from '@qualio/ui-components';
import { FormComment } from '../../../types';

type EventFormCommentsProps = {
  comments: FormComment[];
};

const EventFormComments: React.FC<EventFormCommentsProps> = ({ comments }) => {
  const [newestFirst, setNewestFirst] = useState(true);
  const [orderedComments, setOrderedComments] = useState(comments);
  const orderButtonIconText = newestFirst ? 'Newest first' : 'Oldest first';
  const orderButtonIconName = newestFirst ? 'ArrowDown' : 'ArrowUp';

  useEffect(() => {
    const orderedComments = [...comments];
    if (!newestFirst) {
      orderedComments.reverse();
    }
    setOrderedComments(orderedComments);
  }, [comments, newestFirst]);

  const reverseComments = () => setNewestFirst(!newestFirst);

  return (
    <QBox pt={2}>
      <QDivider />
      <QFlex alignItems="center" justify="space-between" pt={4}>
        <QText weight="semibold">Comments</QText>
        <QButton variant="link" onClick={reverseComments}>
          <QText mr={2}>{orderButtonIconText}</QText>
          <QIcon iconName={orderButtonIconName} />
        </QButton>
      </QFlex>
      <QBox>
        {orderedComments.map((comment, index) => (
          <QBox key={`${comment.commentTimeStamp}-${comment.userFullName}`} pt={4}>
            <QActivity
              status={comment.status}
              fullName={comment.userFullName}
              actionTimestamp={comment.commentTimeStamp}
            >
              <QText color="gray.900" fontSize="sm" fontWeight="400">
                {comment.comment}
              </QText>
            </QActivity>
          </QBox>
        ))}
      </QBox>
    </QBox>
  );
};

export default EventFormComments;
