import React from 'react';
import { StandardValueObject, SubComponentProps, ViewModeDictionary } from '../EventFormField.types';
import { QInput } from '@qualio/ui-components';
import ReadonlyWrapper from './ReadOnlyWrapper';
import TextOr from '../../TextOr/TextOr';

const TextField = (props: SubComponentProps) => {
  const { fieldValues, onChange, fieldId, mode } = props;

  // TODO:  QEV-2813
  const valueObj = fieldValues[0] as StandardValueObject | undefined;

  if (mode === ViewModeDictionary.READONLY) {
    return (
      <ReadonlyWrapper>
        <TextOr fontSize="sm" value={valueObj?.value} />
      </ReadonlyWrapper>
    );
  }

  return (
    <QInput
      type="text"
      value={valueObj?.value ?? ''}
      data-cy={'text-input'}
      onChange={(event) => {
        const newVal = event.target.value;

        if (!newVal) {
          onChange([]);
          return;
        }

        const newValObject = {
          ...valueObj,
          form_field_id: fieldId,
          value: event.target.value,
        };

        onChange([newValObject]);
      }}
    />
  );
};

export default TextField;
