import React from 'react';
import ProductModal from './components/ProductModal';
import { CreateProduct } from '../../types';

type ProductCreateModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSave: (payload: CreateProduct) => Promise<void>;
};

const ProductCreateModal = (props: ProductCreateModalProps) => {
  const handleStatusMessage = (status: 'success' | 'error', name: string, errorMessage?: string) => {
    const errorDesc = errorMessage ? `Reason: ${errorMessage}` : 'Try again.';

    const messages = {
      success: { title: 'Product created', description: `"${name}" was created.` },
      error: { title: 'Product not created', description: `Product was not created because of an error. ${errorDesc}` },
    };
    return { ...messages[status], status };
  };

  return (
    <>
      <ProductModal {...props} onStatusMessage={handleStatusMessage} title="Add product" buttonName="Add product" />
    </>
  );
};

export default ProductCreateModal;
