import { QFormControlProps } from '@qualio/ui-components';
import { ObjectValues, PartialBy } from '../../utils/typeUtils';
import { FieldType } from '../../types/formFields/common';
import { StandardFieldValue } from '../../types/formFields/forms/common';
import { QLookupStandardValue, QLookupSuppliersValue } from '../../types/formFields/forms/QLookupFields';

const ViewModeDictionary = {
  PREVIEW: 'preview',
  INTERACTIVE: 'interactive',
  READONLY: 'readonly',
} as const satisfies Record<string, string>;

/**
 * ViewMode is used to determine how the EventFormField should be rendered. It
 * currently supports three modes:
 * - preview: Used when the form is being displayed to the user but changes are ephemeral
 * - interactive: Used when the form is being filled out by the user
 * - readonly: Used when the form is being displayed to the user and is not interactive
 */
type ViewMode = ObjectValues<typeof ViewModeDictionary>;

type StandardValueObject = PartialBy<StandardFieldValue, 'id'>;
type QLookupStandardValueObject = PartialBy<QLookupStandardValue, 'id'>;
type QLookupSuppliersValueObject = PartialBy<QLookupSuppliersValue, 'id'>;

type ValueObject = StandardValueObject | QLookupStandardValueObject | QLookupSuppliersValueObject;

type FieldId = number;
type FieldValues = ValueObject[];
type ChangeHandler = (values: FieldValues) => void;

/**
 * BaseProps is used by all modes of the EventFormField.
 */
type BaseProps = Pick<QFormControlProps, 'helper' | 'isRequired'> & {
  label: QFormControlProps['label'];
  inputType: FieldType;
  multi: boolean;
  'data-cy'?: string;
  options?: string[];
  resourceSubType?: string | null | undefined;
};

/**
 * InteractiveModeProps is used when the form is being filled out by the user.
 */
type InteractiveModeProps = BaseProps & {
  fieldId: FieldId;
  fieldValues: FieldValues;
  mode: Extract<ViewMode, 'interactive'>;
  onChange: ChangeHandler;
};

/**
 * PreviewModeProps is used when the form is being displayed to the user and is
 * not interactive.  Does not allow for values or change handler to be passed.
 */
type PreviewModeProps = BaseProps & {
  mode: Extract<ViewMode, 'preview'>;
  defaultContent: string;
};

/**
 * ReadonlyModeProps is used when the form is being displayed to the user and is
 * not interactive.  Does not allow for values or change handler to be passed.
 */
type ReadonlyModeProps = BaseProps & {
  mode: Extract<ViewMode, 'readonly'>;
  fieldId: FieldId;
  fieldValues: FieldValues;
};

type EventFieldProps = InteractiveModeProps | PreviewModeProps | ReadonlyModeProps;

/**
 * SubComponentProps is used by individual sub-components of EventFormField.
 * This is after we have created fake change values/handler/id for previewMode
 */
type SubComponentProps = Pick<BaseProps, 'options' | 'multi' | 'inputType' | 'resourceSubType'> & {
  mode: ViewMode;
  fieldId: FieldId;
  fieldValues: FieldValues;
  onChange: ChangeHandler;
  isEditModalOpen: boolean;
  setIsEditModalOpen: (isOpen: boolean) => void;
  defaultContent?: string;
} & { label?: BaseProps['label'] };

export { ViewModeDictionary };
export type { EventFieldProps, ValueObject, SubComponentProps, ChangeHandler, FieldValues };

// Only exported until larger type issues can be resolved
export type { StandardValueObject, QLookupStandardValueObject, QLookupSuppliersValueObject };
