import React, { useMemo } from 'react';
import { QCloseButton, QDrawer, QDrawerHeader, QText, deprecated } from '@qualio/ui-components';
import { IntegrationFields } from '../../../types';

type Props = {
  fields: IntegrationFields;
  isOpen: boolean;
  onClose: () => void;
};

const IntegrationVariablesDrawer: React.FC<Props> = ({ fields, isOpen, onClose }) => {
  const columns = useMemo(
    () => [
      {
        accessor: 'name',
        disableSortBy: true,
        width: '40%',
      },
      {
        accessor: 'value',
        disableSortBy: true,
        width: '60%',
      },
    ],
    [],
  );

  const data = useMemo(
    () =>
      Object.keys(fields).map((key) => {
        return { name: key, value: fields[key] instanceof Object ? JSON.stringify(fields[key]) : fields[key] };
      }),
    [fields],
  );

  const variablesCount = Object.keys(fields).length;

  return (
    <QDrawer onClose={() => onClose()} size="md" isOpen={isOpen}>
      <QDrawerHeader>
        Integration variables
        <QCloseButton onClick={() => onClose()} />
      </QDrawerHeader>
      <QText>{variablesCount} variables</QText>
      <deprecated.QDataTable columns={columns} hasPagination={false} data={data} />
    </QDrawer>
  );
};

export default IntegrationVariablesDrawer;
