import React from 'react';
import { QCloseButton, QDivider, QHeading, QStack, QText, QBox } from '@qualio/ui-components';
import { useNavigate } from 'react-router-dom';

type EditContentTemplateHeaderProps = {
  templateId: string;
  templateName: string;
};

const EditContentTemplateHeader = (props: EditContentTemplateHeaderProps) => {
  const navigate = useNavigate();
  const goToTemplateListPage = () => {
    navigate(`../templates/${props.templateId}`);
  };

  return (
    <>
      <QStack direction={['column', 'row']} pb={6} display="flex" justifyContent="space-between">
        <QBox>
          <QHeading size="lg">Edit content template</QHeading>
          <QText mt={1} color="gray.500">
            {props.templateName}
          </QText>
        </QBox>
        <QCloseButton onClick={goToTemplateListPage} />
      </QStack>
      <QDivider color={'gray.200'} />
    </>
  );
};

export default EditContentTemplateHeader;
