import { Audit } from '../types';

export const getLastApproverAudit = (assigneeId: number, audits: Audit[]): Audit | undefined => {
  const approverAudits = audits.filter(
    (audit) =>
      (audit.user_id === assigneeId &&
        ['doc_status_change_for_approval-approval_declined', 'doc_status_change_for_approval-approved'].includes(
          audit.action,
        )) ||
      [
        'doc_status_change_draft-for_approval',
        'doc_status_change_for_review-for_approval',
        'doc_status_change_for_approval-draft',
      ].includes(audit.action),
  );
  return approverAudits[0];
};

export const getLastReviewerAudit = (assigneeId: number, audits: Audit[]): Audit | undefined => {
  const reviewerAudits = audits.filter(
    (audit) =>
      (audit.user_id === assigneeId && ['document-review'].includes(audit.action)) ||
      ['doc_status_change_draft-for_review', 'doc_status_change_for_approval-draft'].includes(audit.action),
  );
  return reviewerAudits[0];
};
