import * as z from 'zod';

import { EventStep } from './eventStep';
import { QualityEvent } from './qualityEvent';

export const QualityEventDetailsResponse = z.object({
  issue: QualityEvent,
  steps: z.array(EventStep),
});

export type QualityEventDetailsResponse = z.infer<typeof QualityEventDetailsResponse>;
