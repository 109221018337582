import { v4 } from 'uuid';

export const addUniqueIdAttribute = <T extends Record<string, unknown>>(obj: T) => {
  const uniqueId = obj.id && (typeof obj.id === 'string' || typeof obj.id === 'number') ? obj.id : v4();

  return {
    ...obj,
    uniqueId,
  };
};

/**
 * The following type and function are Typescript utilities for an improved
 * experience for Object.entries.  The native Object.entries function returns
 * an array of key-value pairs, but the keys are strings and lose the strictness
 * of any strongly typed objects.
 *
 * Native Object.entries:
 * @example
 * ```typescript
 * const obj = { a: 1, b: 2, c: 3 } as const;
 * const entries = Object.entries(obj); // Entries here is typed as [key: string, value: number]
 * ```
 *
 * ObjectEntries:
 * @example
 * ```typescript
 * const obj = { a: 1, b: 2, c: 3 } as const;
 * const entries = ObjectEntries(obj); // Entries here is typed as [key: "a" | "b" | "c", value: 1 | 2 | 3]
 * ```
 *
 * NOTE: This doesn't work when a property on the object is potentially undefined.
 * Need to look into this improvement. i.e.
 * ```typescript
 * type MyType = { foo: string bar?: string };
 * const myThing: MyType = { foo: 'hello' };
 *
 * ObjectEntries(myThing); // Won't produce expected results
 * ```
 */
export type Entries<T> = { [K in keyof T]: [K, T[K]] }[keyof T];
export function ObjectEntries<T extends object>(t: T): Entries<T>[] {
  return Object.entries(t) as Entries<T>[];
}

/**
 * Used primarily by forms, which use empty strings for non filled in fields, but need those empty values
 * converted back to undefined when the form has been submitted.  This will also convert empty arrays to
 * undefined, which is useful for multiselect components where an empty array is the same as an undefined
 * value.
 */
export const convertEmptyValuesToUndefined = <T extends object>(obj: T) => {
  return Object.fromEntries(
    ObjectEntries(obj).map(([key, val]) => {
      if (val === '') {
        return [key, undefined];
      }

      if (Array.isArray(val) && val.length === 0) {
        return [key, undefined];
      }

      return [key, val];
    }),
  );
};
