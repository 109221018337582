import { useCurrentUser } from '@qualio/ui-components';
import { useQuery } from 'react-query';
import usersApi from '../api/users.api';

export const BASE_QUERY_KEY = 'user';

export const useIsStaffUser = () => {
  const { userId } = useCurrentUser();

  return useQuery({
    queryKey: [BASE_QUERY_KEY, userId],
    queryFn: () => usersApi.getUser(userId),
    select: (fetchedUser) => fetchedUser.staff,
  });
};
