import React, { useCallback, useEffect, useMemo } from 'react';
import {
  QModal,
  QInput,
  QTextarea,
  QStack,
  QFormControl,
  useToastProvider,
  QButton,
  QModalActions,
  QModalHeader,
  QText,
  QModalBody,
  QCloseButton,
  QToastProps,
} from '@qualio/ui-components';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { CreateProduct, Product } from '../../../types/product';
import { extractMessageFromError } from '../../../utils/errorUtils';

type ProductModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSave: (payload: any) => Promise<void>;
  onStatusMessage: (status: 'success' | 'error', name: string, errorMessage?: string) => QToastProps;
  title: string;
  buttonName: string;
  defaultValues?: CreateProduct;
};

const ProductModal = ({
  isOpen,
  setIsOpen,
  onSave,
  onStatusMessage,
  title,
  buttonName,
  defaultValues,
}: ProductModalProps) => {
  const { showToast } = useToastProvider();

  const formMethods = useForm<Product | CreateProduct>({
    mode: 'onSubmit',
    resolver: zodResolver(defaultValues === undefined ? CreateProduct : Product),
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = formMethods;

  const formHasErrors = !!Object.keys(errors).length;

  const onSubmit = useCallback(
    async (data: Product | CreateProduct) => {
      try {
        await onSave(data);
        setIsOpen(false);
        showToast({ ...onStatusMessage('success', data.name) });
        reset();
      } catch (err: any) {
        showToast(onStatusMessage('error', data.name, extractMessageFromError(err)));
      }
    },
    [onSave, showToast, setIsOpen, reset, onStatusMessage],
  );

  const handleClose = () => {
    setIsOpen(false);
    reset();
  };

  const dataMetricsPrefix = title.toLowerCase().split(' ').join('-');

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const form = (
    <FormProvider {...formMethods}>
      <form>
        <QStack spacing={4}>
          <QFormControl
            label="Name"
            error={errors.name && errors.name.message}
            isInvalid={!!errors.name}
            data-metrics={`${dataMetricsPrefix}-name-input`}
            isRequired
          >
            <Controller
              control={control}
              name="name"
              render={({ field: { ref, ...field } }) => <QInput {...field} />}
            />
          </QFormControl>

          <QFormControl
            label="Description"
            error={errors.description && errors.description.message}
            isInvalid={!!errors.description}
            data-metrics={`${dataMetricsPrefix}-description-input`}
            isRequired
          >
            <Controller
              control={control}
              name="description"
              render={({ field: { ref, ...field } }) => <QTextarea placeholder="Describe the product" {...field} />}
            />
          </QFormControl>
        </QStack>
      </form>
    </FormProvider>
  );

  return (
    <QModal isOpen={isOpen} onClose={handleClose} size="xl">
      <QModalHeader>
        <QText>{title}</QText>
        <QCloseButton onClick={handleClose} data-metrics={`${dataMetricsPrefix}-close-modal-button`}></QCloseButton>
      </QModalHeader>
      <QModalBody>
        <QStack direction="column" color="gray.700" spacing={4}>
          {form}
        </QStack>
      </QModalBody>
      <QModalActions>
        <QButton onClick={handleClose} variant="outline" data-metrics={`${dataMetricsPrefix}-cancel-button`}>
          Cancel
        </QButton>
        <QButton
          onClick={() => handleSubmit(onSubmit)()}
          isDisabled={formHasErrors}
          data-metrics={`${dataMetricsPrefix}-button`}
        >
          {buttonName}
        </QButton>
      </QModalActions>
    </QModal>
  );
};

export default ProductModal;
