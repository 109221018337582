import { QBox, QText } from '@qualio/ui-components';
import React from 'react';

type Props = {
  title: string;
  children: React.ReactNode;
  dataCySuffix?: string;
};

export const PropertySection = ({ title, children, dataCySuffix }: Props) => {
  return (
    <QBox marginBottom={4}>
      <QText
        fontSize="sm"
        fontWeight={600}
        data-cy={'properties-' + title.toLowerCase().replace(/ /g, '-') + (dataCySuffix ? `-${dataCySuffix}` : '')}
      >
        {title}
      </QText>
      {children}
    </QBox>
  );
};
