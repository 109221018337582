import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { FormEditorForm } from '../../../shared';
import { QFormControl, QInput } from '@qualio/ui-components';
import { useFieldsConfigContext } from '../shared';

type Props = {
  control: UseFormReturn<FormEditorForm>['control'];
  fieldIndex: number;
  error?: string;
};

const FieldTitle = ({ control, fieldIndex, error }: Props) => {
  const { labels } = useFieldsConfigContext();
  return (
    <QFormControl label={labels.fieldTitle} isRequired isInvalid={Boolean(error)} error={error}>
      <Controller
        name={`fields.${fieldIndex}.label`}
        control={control}
        render={({ field }) => <QInput data-cy={`field-title-${fieldIndex}`} {...field} />}
      />
    </QFormControl>
  );
};

export default FieldTitle;
