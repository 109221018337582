import { Approver, ApproverSchema, ApproverType } from '../types';
import { medtechApi } from './index';

const typeMapper = {
  reviewers: 'event_form_reviewer',
  approvers: 'event_form_approver',
};

const approversApi = {
  async getApprovers(company: number, formId: number, userType: ApproverType): Promise<Approver[]> {
    const response = await medtechApi.get(
      `/v2/${company}/approvers/item-type/${typeMapper[userType]}/item-id/${formId}`,
    );
    return response.data.map((approver: Approver) => ApproverSchema.parse(approver));
  },
  async updateApprovers(
    companyId: number,
    issueId: number,
    stepId: number,
    formId: number,
    approversToRemove: number[],
    approversToAdd: number[],
    userType: ApproverType,
    removeComment?: string,
  ): Promise<undefined> {
    const requestBody = {
      approvers_to_remove: approversToRemove,
      approvers_to_add: approversToAdd,
      comment: removeComment,
    };
    return medtechApi.patch(
      `/quality-events/${companyId}/issues/${issueId}/steps/${stepId}/forms/${formId}/item-type/${typeMapper[userType]}/approvers`,
      requestBody,
    );
  },
};

export default approversApi;
