import styled from 'styled-components';

export const EditContentTemplateContainer = styled.div`
  .stepper-content {
    min-height: 33em;
    max-width: 1000px;
    width: 100%;
  }

  .stepper-wrapper .chakra-stack > div {
    cursor: default;
  }

  .stepper-wrapper .chakra-stack .css-17dbl7j:hover {
    background: white;
  }
`;
