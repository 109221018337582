import React from 'react';
import { SubComponentProps } from '../EventFormField.types';
import { QHeading } from '@qualio/ui-components';

const SectionField = (props: SubComponentProps) => {
  const { label } = props;

  return (
    <QHeading data-cy="section-field" size="md">
      {label}
    </QHeading>
  );
};

export default SectionField;
