import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser, QBox, QCenter, QSpinner } from '@qualio/ui-components';
import { useTaskDetails } from '../../hooks';

const TaskDetailsProxy: React.FC = () => {
  const navigate = useNavigate();
  const { companyId } = useCurrentUser();
  const { taskId } = useParams();
  const { task } = useTaskDetails(companyId, Number(taskId));

  useEffect(() => {
    if (task) {
      navigate(`/events/${task.related_issue_id}?task_id=${task.id}`);
    }
  }, [task, navigate]);

  return (
    <QBox minHeight="270px">
      <QCenter pt={10}>
        <QSpinner />
      </QCenter>
    </QBox>
  );
};

export default TaskDetailsProxy;
