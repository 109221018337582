import React, { useCallback } from 'react';
import { QButton, QCloseButton, QModal, QModalActions, QModalBody, QModalHeader, QText } from '@qualio/ui-components';
import RichTextInput from '../../RichTextInput/RichTextInput';
import { useFieldsConfigContext } from './FormFieldConfiguration/shared';
import * as DisplayStrings from '../../../displayStrings';

type HelpTextModalProps = {
  setIfShow: React.Dispatch<React.SetStateAction<boolean>>;
  description: string | null;
  changeHelpText: any;
};

const HelpTextModal = ({ setIfShow, description, changeHelpText }: HelpTextModalProps) => {
  const [desc, setDesc] = React.useState<string>(description ?? '');
  const { labels } = useFieldsConfigContext();

  const saveButtonText = description ? DisplayStrings.SaveChanges : labels.saveButton;
  const header = description ? labels.modalEditHeader : labels.modalHeader;

  const close = () => {
    setIfShow(false);
  };

  const changeHelpTextClick = useCallback(() => {
    changeHelpText(desc);
    setIfShow(false);
  }, [changeHelpText, setIfShow, desc]);

  return (
    <QModal isOpen={true} onClose={close} size="3xl" trapFocus={false} autoFocus={false}>
      <QModalHeader>
        <QText>{header}</QText>
        <QCloseButton onClick={close}></QCloseButton>
      </QModalHeader>
      <QModalBody>
        <RichTextInput value={description} onChange={setDesc} />
      </QModalBody>
      <QModalActions>
        <QButton data-cy={'cancel-save-description'} onClick={close} variant="outline">
          {DisplayStrings.Cancel}
        </QButton>
        <QButton data-cy={'save-description'} onClick={changeHelpTextClick}>
          {saveButtonText}
        </QButton>
      </QModalActions>
    </QModal>
  );
};

export default HelpTextModal;
