import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { QBadge, QBox, QFlex, QHeading, QLink, QTag, QText } from '@qualio/ui-components';

import { StatusTag } from '../../components';
import { IntegrationFields, QriDetails, QualityEventDetailsResponse } from '../../types';
import { EventTemplateFieldAttributeNames } from '../../types/eventField';
import { EventTag } from '../../types/eventTag';

import { useLocalisedFormatDateTime } from '../../utils/datetimeUtils';
import { isFieldVisible } from '../../utils/eventTemplateUtils';
import IntegrationVariablesDrawer from './components/IntegrationVariablesDrawer';
import TagsSection from './components/TagsSection';
import { EscalationDetails } from '../../types/escalationDetails';
import IconlessLink from './components/IconlessLink';
import { PropertySection } from './components/PropertySection';
import { PropertySectionText } from './components/PropertySectionText';
import { stripQualioHost } from '../../utils/UrlUtils';
import { useFlags } from '../../external/LaunchDarklyAdapter';
import EventActionLog from './components/EventActionLog';
import { EventActionLog as EventActionLogType } from '../../types/eventActionLog';

type Props = {
  event: QualityEventDetailsResponse;
  relatedSuppliers: QriDetails[] | undefined;
  relatedDocuments: QriDetails[] | undefined;
  relatedEvents: QriDetails[] | undefined;
  tags: EventTag[] | undefined;
  eventActionLog: EventActionLogType | undefined;
  relatedProduct?: QriDetails | null;
  relatedRootCause?: QriDetails | null;
};

const EventProperties: React.FC<Props> = ({
  event,
  relatedSuppliers,
  relatedEvents,
  relatedDocuments,
  relatedProduct,
  relatedRootCause,
  tags,
  eventActionLog,
}) => {
  const issue = event.issue;
  const [isIntegrationFieldsVisible, setIsIntegrationFieldsVisible] = useState(false);
  const isRegistryProductRootCauseEnabled = useFlags('registryProductRootCause');
  const qeCancelAndRejectEnabled = useFlags('qeCancelAndReject');

  const product = useMemo(() => {
    if (isRegistryProductRootCauseEnabled) {
      return {
        name: relatedProduct?.name,
        link: relatedProduct?.urls.view ?? '',
        dataCySuffix: 'registry',
      };
    } else {
      return {
        name: issue.product_name,
        link: `/events?status=open&product_id=${issue.product_id}`,
      };
    }
  }, [relatedProduct, issue, isRegistryProductRootCauseEnabled]);

  const rootCause = useMemo(() => {
    if (isRegistryProductRootCauseEnabled) {
      return {
        name: relatedRootCause?.name,
        link: relatedRootCause?.urls.view ?? '',
        dataCySuffix: 'registry',
      };
    } else {
      return {
        name: issue.rootcause_name,
        link: `/events?status=open&rootcause_id=${issue.rootcause_id}`,
      };
    }
  }, [relatedRootCause, issue, isRegistryProductRootCauseEnabled]);

  let issueTags: EventTag[] = [];
  const formatDateTime = useLocalisedFormatDateTime();

  const visibleFields = useMemo(() => {
    const visibleFields = new Set<string>();
    for (const field in EventTemplateFieldAttributeNames.Values) {
      if (isFieldVisible(event?.issue?.template_fields, EventTemplateFieldAttributeNames.parse(field))) {
        visibleFields.add(field);
      }
    }
    return visibleFields;
  }, [event]);

  if (tags && issue.tags_ids) {
    const ids = issue.tags_ids;
    issueTags = tags.filter((tag) => ids.includes(Number(tag.id)));
  }

  const createIntegrationVariablesSection = (fields: IntegrationFields) => {
    const variablesCount = Object.keys(fields).length;
    return (
      <>
        <PropertySection title="Integration variables">
          <button onClick={() => setIsIntegrationFieldsVisible(true)}>
            <QText color="blue.500">{variablesCount} variables</QText>
          </button>
        </PropertySection>
        <IntegrationVariablesDrawer
          fields={fields}
          isOpen={isIntegrationFieldsVisible}
          onClose={() => setIsIntegrationFieldsVisible(false)}
        />
      </>
    );
  };

  const createRelatedEntitiesBadges = (entities: QriDetails[]) => {
    if (!entities || entities.length === 0) {
      return <QText>-</QText>;
    }
    return (
      <QFlex flexWrap="wrap">
        {entities.map(({ identifiers: { code }, name, urls: { view }, domain }) => (
          <QBadge mr={2} mb={2} key={code} textTransform="none">
            {view ? (
              <Link color="blue.500" data-metrics={`event-details-related-${domain}-link`} to={view} title={name}>
                {code}
              </Link>
            ) : (
              <>{code}</>
            )}
          </QBadge>
        ))}
      </QFlex>
    );
  };

  const createEscalationLinks = (details: EscalationDetails[]) => {
    if (details.length === 0) {
      return <QText>-</QText>;
    }
    return (
      <QFlex flexWrap="wrap">
        {details.map(({ id, code, title }) => (
          <QBadge mr={2} mb={2} key={code} textTransform="none">
            <Link
              color="blue.500"
              data-metrics={`event-details-escalation-${id}-link`}
              to={`/events/${id}`}
              title={title}
            >
              {code}
            </Link>
          </QBadge>
        ))}
      </QFlex>
    );
  };

  const createSupplierLinks = (suppliers: QriDetails[]): JSX.Element => {
    if (!suppliers || suppliers.length === 0) {
      return <QText>-</QText>;
    }
    const links = suppliers.reduce(
      (result, supplier, index, array) => {
        result.push(
          supplier.urls.view ? (
            <QLink key={supplier.identifiers.id} href={stripQualioHost(supplier.urls.view)} isCrossMFE>
              {supplier.name}
            </QLink>
          ) : (
            <QText key={supplier.identifiers.id}>{supplier.name}</QText>
          ),
        );
        if (index < array.length - 1) {
          result.push(', ');
        }
        return result;
      },
      [] as (JSX.Element | string)[],
    );
    return <QText>{links}</QText>;
  };

  const riskSection = useMemo(() => {
    const issueRisk = issue.risk ? <QTag>{issue.risk}</QTag> : <PropertySectionText>{'-'}</PropertySectionText>;
    return visibleFields.has(EventTemplateFieldAttributeNames.Enum.risk) ? (
      <PropertySection title="Risk">{issueRisk}</PropertySection>
    ) : null;
  }, [visibleFields, issue]);

  const shouldShowEventActionLog = useCallback(
    (issueStatus: string, selectedActionLog: EventActionLogType): boolean => {
      if (!qeCancelAndRejectEnabled) {
        return false;
      }
      if (['cancelled', 'rejected'].includes(issueStatus) && selectedActionLog) {
        return true;
      }
      // This logic is needed because closed and closed early have same status
      if (issueStatus === 'closed' && selectedActionLog && selectedActionLog.destination_status === 'closed') {
        return true;
      }
      return false;
    },
    [qeCancelAndRejectEnabled],
  );

  return (
    <QBox>
      <QBox marginTop={4} marginBottom={4}>
        <QHeading size={'sm'}>Properties</QHeading>
      </QBox>
      <PropertySection title="Status">
        <StatusTag status={issue.status} />
      </PropertySection>
      {eventActionLog && shouldShowEventActionLog(issue.status, eventActionLog) ? (
        <EventActionLog eventStatus={issue.status} eventActionLog={eventActionLog} />
      ) : null}
      <PropertySection title="Due">
        <PropertySectionText>{formatDateTime(issue.due_at)}</PropertySectionText>
      </PropertySection>
      <PropertySection title="Last modified">
        <PropertySectionText>{formatDateTime(issue.updated_at)}</PropertySectionText>
      </PropertySection>
      <PropertySection title="Owner">
        <PropertySectionText>{issue.owner}</PropertySectionText>
      </PropertySection>
      <PropertySection title="Created">
        <PropertySectionText>{`${formatDateTime(issue.created_at)} by ${issue.creator}`}</PropertySectionText>
      </PropertySection>
      <PropertySection title="Related events">{createRelatedEntitiesBadges(relatedEvents ?? [])}</PropertySection>
      <PropertySection title="Related documents">{createRelatedEntitiesBadges(relatedDocuments ?? [])}</PropertySection>
      <PropertySection title="Source event">{createEscalationLinks(event.issue.escalated_from ?? [])}</PropertySection>
      <PropertySection title="Escalated to">{createEscalationLinks(event.issue.escalated_to ?? [])}</PropertySection>
      {visibleFields.has(EventTemplateFieldAttributeNames.Enum.supplier) ? (
        <PropertySection title="Related suppliers">{createSupplierLinks(relatedSuppliers ?? [])}</PropertySection>
      ) : null}
      {riskSection}
      {visibleFields.has(EventTemplateFieldAttributeNames.Enum.risk) ? (
        <PropertySection title="Severity">
          <PropertySectionText>{issue.severity ?? '-'}</PropertySectionText>
        </PropertySection>
      ) : null}
      {visibleFields.has(EventTemplateFieldAttributeNames.Enum.risk) ? (
        <PropertySection title="Probability">
          <PropertySectionText>{issue.probability ?? '-'}</PropertySectionText>
        </PropertySection>
      ) : null}
      {visibleFields.has(EventTemplateFieldAttributeNames.Enum.product) ? (
        <PropertySection title="Product" dataCySuffix={`${product.dataCySuffix ?? ''}`}>
          <IconlessLink name={product.name} url={product.link} />
        </PropertySection>
      ) : null}
      {visibleFields.has(EventTemplateFieldAttributeNames.Enum.root_cause) ? (
        <PropertySection title="Root cause" dataCySuffix={`${rootCause.dataCySuffix ?? ''}`}>
          <IconlessLink name={rootCause.name} url={rootCause.link} />
        </PropertySection>
      ) : null}
      {issue.qe_source ? (
        <PropertySection title="Source">
          {issue.qe_source.type === 'file-import' ? (
            <QFlex alignItems="center">File import</QFlex>
          ) : (
            <QLink href={issue.qe_source.url} isExternal>
              <QFlex alignItems="center">{issue.qe_source.name}</QFlex>
            </QLink>
          )}
        </PropertySection>
      ) : null}
      {issue.fields && createIntegrationVariablesSection(issue.fields)}
      <TagsSection tags={issueTags} />
    </QBox>
  );
};

export default EventProperties;
