import React, { useCallback } from 'react';
import {
  QModal,
  QStack,
  QText,
  QModalHeader,
  QModalBody,
  QModalActions,
  QButton,
  QCloseButton,
} from '@qualio/ui-components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import TaskForm from './components/TaskForm';
import { UpdateTask, User, UserTask } from '../../types';
import { useTaskPermissions } from '../../hooks';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectsData: {
    users: User[];
  };
  task?: UserTask;
  onSave: (payload: UpdateTask) => void;
};

const TaskEditModal: React.FC<Props> = ({ isOpen, setIsOpen, selectsData, task, onSave }) => {
  const { canEditTaskTimeLimit } = useTaskPermissions();

  const formMethods = useForm<UpdateTask>({
    mode: 'onSubmit',
    resolver: zodResolver(UpdateTask),

    defaultValues: {
      time_limit: 60,
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = formMethods;

  const formHasErrors = !!Object.keys(errors).length;

  const handleClose = () => {
    setIsOpen(false);
    reset();
  };

  const onSubmit = useCallback(
    async (formData: UpdateTask) => {
      onSave(formData);
      setIsOpen(false);
      reset();
    },
    [onSave, setIsOpen, reset],
  );

  return (
    <QModal data-cy={'edit-task-modal'} isOpen={isOpen} onClose={handleClose} size="xl">
      <QModalHeader>
        <QText>Edit task</QText>
        <QCloseButton onClick={handleClose}></QCloseButton>
      </QModalHeader>
      <QModalBody>
        <QStack direction="column" color="gray.700" spacing={4}>
          <TaskForm
            formMethods={formMethods}
            onSubmit={onSubmit}
            data={selectsData}
            taskData={task}
            canEditTaskTimeLimit={canEditTaskTimeLimit}
          />
        </QStack>
      </QModalBody>
      <QModalActions>
        <QButton onClick={handleClose} variant="outline">
          Cancel
        </QButton>
        <QButton onClick={() => handleSubmit(onSubmit)()} isDisabled={formHasErrors}>
          Save
        </QButton>
      </QModalActions>
    </QModal>
  );
};

export default TaskEditModal;
