import React, { useState } from 'react';
import { QBox, QFormControl, QDivider } from '@qualio/ui-components';
import { EventFieldProps, ValueObject, ViewModeDictionary } from './EventFormField.types';
import TextField from './components/TextField';
import NumericField from './components/NumericField';
import BooleanField from './components/BooleanField';
import DateField from './components/DateField';
import AttachmentField from './components/AttachmentField';
import RichTextField from './components/RichTextField';
import NonEditableRichText from '../NonEditableRichText/NonEditableRichText';
import DropdownField from './components/DropdownField/DropdownField';
import SectionField from './components/SectionField';
import CheckboxField from './components/CheckboxField';
import RadioField from './components/RadioField';
import ReadonlyWrapper from './components/ReadOnlyWrapper';
import SectionWrapper from './components/SectionWrapper';
import QObjectField from './components/QObjectField/QObjectField';
import QObjectFormFieldLabel from './components/QObjectField/QObjectFormFieldLabel';
import { FieldTypeSchema, FieldType } from '../../types/formFields/common';
import { fieldTypeIsQObjectType } from '../../utils/eventFormUtils';
import QObjectFieldV2 from './components/QObjectFieldV2/QObjectFieldV2';

const componentMap = {
  text: TextField,
  numeric: NumericField,
  boolean: BooleanField,
  date: DateField,
  attachment: AttachmentField,
  rich_text: RichTextField,
  dropdown: DropdownField,
  section: SectionField,
  checkbox: CheckboxField,
  radio: RadioField,
  document: QObjectField,
  event: QObjectField,
  supplier: QObjectField,
  user: QObjectField,
  change_request: QObjectField,
  registry: QObjectFieldV2,
  design_controls: QObjectFieldV2,
};

const visualComponents: readonly FieldType[] = [FieldTypeSchema.Enum.section] as const;

const EventFormField = (props: EventFieldProps) => {
  const { helper, label, isRequired, inputType, options, multi, mode, resourceSubType } = props;
  const Component = componentMap[inputType];
  const isVisualComponent = visualComponents.includes(inputType);
  const isQObjectComponent = fieldTypeIsQObjectType(inputType);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  if (!Component) {
    throw new Error(`Attempted to use a component that is not implemented yet: ${inputType}`);
  }

  /**
   * Here we are setting the values and handlers to handle the different "modes"
   * we can have for the form fields. These modes are:
   * - PREVIEW: We use a local state and stateHandler as all values are ephemeral
   * - INTERACTIVE: We use the values and stateHandler given from the parent component
   * - READONLY: We use the values given from the parent component, but we use a local
   *    stateHandler to handle the "onChange" event. Because none of the subcomponents
   *    should allow changes.
   */
  const initialValueData = mode === ViewModeDictionary.PREVIEW || isVisualComponent ? [] : props.fieldValues;
  const [localValue, setLocalValue] = useState<ValueObject[]>(initialValueData);
  const fieldId = mode === ViewModeDictionary.PREVIEW ? 0 : props.fieldId;
  const fieldValues = mode !== ViewModeDictionary.INTERACTIVE ? localValue : props.fieldValues;
  const changeHandler = mode !== ViewModeDictionary.INTERACTIVE ? setLocalValue : props.onChange;
  const defaultContent = mode !== ViewModeDictionary.PREVIEW ? undefined : props.defaultContent;
  const showEditButton = isQObjectComponent && mode !== ViewModeDictionary.READONLY && fieldValues.length > 0;

  const childProps = {
    mode,
    label,
    fieldId,
    fieldValues,
    options,
    multi,
    defaultContent,
    inputType,
    onChange: changeHandler,
    isEditModalOpen,
    setIsEditModalOpen,
    resourceSubType,
  };

  if (!Component) {
    console.log('Attempted to use a component that is not implemented yet');
    return null;
  }

  if (isVisualComponent) {
    return (
      <QFormControl data-cy={props['data-cy']} isRequired={false}>
        <SectionWrapper>
          <Component {...childProps} />
          <QDivider />
          <NonEditableRichText content={helper ?? ''} mode="READONLY" textColor="GrayText" />
        </SectionWrapper>
      </QFormControl>
    );
  }

  return (
    <QFormControl data-cy={props['data-cy']} label={isQObjectComponent ? undefined : label} isRequired={isRequired}>
      {isQObjectComponent && (
        <QObjectFormFieldLabel label={label} showEditBtn={showEditButton} onEditBtnClick={setIsEditModalOpen} />
      )}
      <ReadonlyWrapper>
        <NonEditableRichText content={helper ?? ''} mode="READONLY" textColor="GrayText" />
      </ReadonlyWrapper>
      <QBox marginTop="8px">
        <Component {...childProps} />
      </QBox>
    </QFormControl>
  );
};

export default EventFormField;
