import React, { useState, KeyboardEvent, MouseEvent, useCallback } from 'react';
import { QStack, QButton, QInput } from '@qualio/ui-components';

type SearchControlProps = {
  query: string;
  setQuery: (query: string) => void;
};

const SearchControl: React.FC<SearchControlProps> = ({ query, setQuery }) => {
  const [searchQueryEntered, setSearchQueryEntered] = useState(query);
  const dispatchSearch = useCallback(
    (event: KeyboardEvent | MouseEvent) => {
      if (event.type === 'click' || ('key' in event && event.key.toLowerCase() === 'enter')) {
        setQuery(searchQueryEntered);
      }
    },
    [searchQueryEntered, setQuery],
  );

  const onClearButtonClicked = useCallback(() => {
    setQuery('');
  }, [setQuery]);

  const onChange = useCallback(
    (event: any) => {
      setSearchQueryEntered(event.target.value);
      if (event.type === 'click') {
        // this is a clear button click, lets trigger a request
        onClearButtonClicked();
      }
    },
    [onClearButtonClicked],
  );

  return (
    <QStack isInline w="30rem" onKeyPress={dispatchSearch}>
      <QInput
        iconLeftName="Search"
        value={searchQueryEntered}
        onChange={onChange}
        placeholder="Type your search query here"
        isClearable={true}
        data-cy="SearchBox"
      />
      <QButton onClick={dispatchSearch} data-cy="SearchButton">
        Search
      </QButton>
    </QStack>
  );
};

export default SearchControl;
