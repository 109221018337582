import { QButton, QButtonGroup } from '@qualio/ui-components';
import React from 'react';
import { useUserPermissions } from '../../../hooks';

type Props = {
  onClickHandler: () => void;
};

const AddProductButton: React.FC<Props> = ({ onClickHandler }) => {
  const { canCUDProduct } = useUserPermissions();

  return (
    <>
      {canCUDProduct && (
        <QButtonGroup>
          <QButton variant="solid" onClick={onClickHandler} data-metrics={'add-product-overview-button'}>
            Add product
          </QButton>
        </QButtonGroup>
      )}
    </>
  );
};

export default AddProductButton;
